import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { getSectorETFHoldings } from '../../../services/data_api';
import './SectorHoldings.css';
import Tooltip from '../../Tooltip';

const HOLDINGS_TOOLTIPS = {
  nav: `Net asset value (NAV) represents the per-share value of the ETF's assets minus its liabilities. It is calculated by dividing the total value of all the securities in the portfolio by the number of outstanding shares.`,
  
  nav_premium: `NAV premium/discount shows the percentage difference between the ETF's market price and its NAV. A positive value indicates the ETF is trading at a premium to its NAV, while a negative value indicates a discount.`,
  
  pe_ratio: `Price-to-earnings (P/E) ratio measures the ETF's weighted average share price relative to its weighted average earnings per share. It indicates how much investors are willing to pay for each dollar of the ETF's earnings.`
};

const MetricCard = ({ label, value, tooltip }) => (
  <div className="sector-holdings-metric-card">
    <div className="sector-holdings-metric-label">
      {label}
      <span className="tooltip-wrapper">
        <Tooltip content={tooltip} forceDirection="bottom" />
      </span>
    </div>
    <div className="sector-holdings-metric-value">{value}</div>
  </div>
);

const SectorHoldings = ({ data }) => {
  const [holdingsData, setHoldingsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!data?.etf) return;
      try {
        setLoading(true);
        const response = await getSectorETFHoldings(data.etf);
        setHoldingsData(response);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [data]);

  const HoldingsTable = ({ data }) => (
    <div className="sector-holdings-table">
      <table>
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Holding</th>
            <th>Weight</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.symbol}</td>
              <td>{item.holding}</td>
              <td>{item.assets_percentage.toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const getChartOption = () => {
    const holdings = holdingsData.data;
    const topHoldings = holdings.slice(0, 10);
    const otherHoldings = holdings.slice(10);
    
    let chartData = topHoldings.map(item => ({
      name: item.symbol,
      value: item.assets_percentage,
      fullName: item.holding
    }));

    if (otherHoldings.length > 0) {
      const otherWeight = otherHoldings.reduce((sum, item) => sum + item.assets_percentage, 0);
      chartData.push({
        name: 'Others',
        value: otherWeight.toFixed(2)
      });
    }

    return {
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          if (params.data.fullName) {
            return `${params.data.name} - ${params.data.fullName}<br/>Weight: ${params.value}%`;
          }
          return `${params.name}: ${params.value}%`;
        }
      },
      legend: {
        orient: 'horizontal',
        top: 0,
        left: 'center',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 12,
          color: '#666'
        }
      },
      series: [
        {
          name: 'Holdings',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '55%'],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 4,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: 'bold'
            }
          },
          data: chartData
        }
      ]
    };
  };

  const renderMetricsGrid = () => (
    <div className="sector-holdings-metrics-grid">
      <MetricCard 
        label="NAV"
        value={`$${data.nav.toFixed(2)}`}
        tooltip={HOLDINGS_TOOLTIPS.nav}
      />
      <MetricCard 
        label="NAV Premium"
        value={
          <span className={`sector-holdings-premium ${data.nav_premium >= 0 ? 'positive' : 'negative'}`}>
            {data.nav_premium >= 0 ? '+' : '-'}${(Math.abs(data.nav_premium) * 100).toFixed(2)}%
          </span>
        }
        tooltip={HOLDINGS_TOOLTIPS.nav_premium}
      />
      <MetricCard 
        label="P/E Ratio"
        value={data.pe_ratio.toFixed(2)}
        tooltip={HOLDINGS_TOOLTIPS.pe_ratio}
      />
    </div>
  );

  if (loading) {
    return (
      <div className="sector-holdings-container">
        {renderMetricsGrid()}
        <div className="sector-holdings-chart-loading">Loading holdings data...</div>
      </div>
    );
  }

  if (error || !holdingsData || !holdingsData.data) {
    return (
      <div className="sector-holdings-container">
        {renderMetricsGrid()}
        <div className="sector-holdings-chart-empty">
          <i className="fas fa-chart-pie sector-holdings-chart-empty-icon"></i>
          <p className="sector-holdings-chart-empty-text">No holdings data available</p>
        </div>
      </div>
    );
  }

  return (
    <div className="sector-holdings-container">
      {renderMetricsGrid()}
      <div className="sector-holdings-chart">
        <div className="sector-holdings-chart-title">
          {data.etf} Holdings Composition
        </div>
        <div className="sector-holdings-content">
          <div className="sector-holdings-chart-container">
            <ReactECharts 
              option={getChartOption()}
              style={{ height: '400px' }}
              opts={{ renderer: 'svg' }}
            />
          </div>
          <HoldingsTable data={holdingsData.data} />
        </div>
      </div>
    </div>
  );
};

export default SectorHoldings; 