import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import './SectorOverview.css';
import Tooltip from '../../Tooltip';
import { getSectorETFHeatScore, getSectorETFRiskMetrics } from '../../../services/data_api';

const TIME_RANGES = [
  { label: '1W', days: 7 },
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: 'ALL', days: null }
];

const GRADE_LABELS = {
  heat: {
    A: 'Very High',
    B: 'High',
    C: 'Medium',
    D: 'Low',
    E: 'Very Low'
  },
  risk: {
    A: 'Very Low',
    B: 'Low',
    C: 'Medium',
    D: 'High',
    E: 'Very High'
  }
};

const OVERVIEW_TOOLTIPS = {
  aum: `Assets Under Management (AUM) represents the total market value of assets that the ETF manages, indicating the fund's size and potential liquidity.`,
  
  expense_ratio: `Expense Ratio represents the annual fee that the ETF charges to cover its operating expenses, expressed as a percentage of assets. A lower expense ratio means lower costs for investors.`,
  heat_score: `Heat Score measures the overall market sentiment and momentum. A higher score indicates stronger positive momentum.`,
  risk_rating: `Risk Rating evaluates the overall investment risk. A lower grade indicates lower risk level.`
};

const SectorOverview = ({ data, historyData }) => {
  const [timeRange, setTimeRange] = useState('ALL');
  const [chartData, setChartData] = useState([]);
  const [heatScore, setHeatScore] = useState(null);
  const [riskRank, setRiskRank] = useState(null);

  useEffect(() => {
    if (historyData?.data) {
      const data = historyData.data.sort((a, b) => new Date(a.date) - new Date(b.date));
      let filteredData = [...data];

      if (timeRange !== 'ALL') {
        const selectedDays = TIME_RANGES.find(r => r.label === timeRange)?.days;
        const cutoffDate = new Date();
        const latestDate = new Date(Math.max(...data.map(item => new Date(item.date))));
        cutoffDate.setTime(latestDate.getTime() - (selectedDays * 24 * 60 * 60 * 1000));

        filteredData = data.filter(item => new Date(item.date) >= cutoffDate);
      }

      if (filteredData.length === 0 && data.length > 0) {
        filteredData = data.slice(-7);
      }

      setChartData(filteredData);
    }
  }, [historyData, timeRange]);

  useEffect(() => {
    const fetchScores = async () => {
      try {
        const [heatResponse, riskResponse] = await Promise.all([
          getSectorETFHeatScore(data.etf),
          getSectorETFRiskMetrics(data.etf)
        ]);
        
        setHeatScore(heatResponse.data);
        setRiskRank(riskResponse.data);
      } catch (error) {
        console.error('Error fetching scores:', error);
      }
    };

    if (data?.etf) {
      fetchScores();
    }
  }, [data?.etf]);

  const getHistoryChartOption = () => {
    if (!chartData.length) return {};

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: { color: '#999' }
        },
        formatter: function(params) {
          const date = new Date(params[0].axisValue);
          const price = params[0].data;
          const volume = params[1]?.data;
          return `
            <div style="font-size:15px;color:#333;line-height:1.6">
              <div style="margin-bottom:8px;font-weight:500">
                ${date.toLocaleDateString('en-US', { 
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              </div>
              <div style="display:flex;justify-content:space-between;align-items:center">
                <span style="color:#1a73e8">●</span>
                <span style="margin:0 10px">Price:</span>
                <span style="font-weight:500">$${price.toFixed(2)}</span>
              </div>
              <div style="display:flex;justify-content:space-between;align-items:center">
                <span style="color:#34a853">●</span>
                <span style="margin:0 10px">Volume:</span>
                <span style="font-weight:500">${(volume/1000000).toFixed(2)}M</span>
              </div>
            </div>
          `;
        }
      },
      legend: {
        data: ['Price', 'Volume'],
        top: '0%',
        left: 'center',
        textStyle: { fontSize: 15, color: '#666' },
        itemGap: 30,
        icon: 'circle',
        itemWidth: 8,
        itemHeight: 8
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false,
          moveOnMouseMove: true,
          preventDefaultMouseMove: true,
          zoomOnMouseWheel: true,
          moveOnMouseWheel: false
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 0,
          start: 0,
          end: 100,
          handleIcon: 'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7v-1.2h6.6z M13.3,22H6.7v-1.2h6.6z M13.3,19.6H6.7v-1.2h6.6z',
          handleSize: 20,
          handleStyle: {
            color: '#1a73e8',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          },
          textStyle: {
            color: '#666',
            fontSize: 12
          },
          borderColor: '#e0e0e0',
          backgroundColor: '#f5f5f5',
          fillerColor: 'rgba(26,115,232,0.1)',
          selectedDataBackground: {
            lineStyle: { color: '#1a73e8' },
            areaStyle: { color: '#1a73e8' }
          }
        }
      ],
      grid: {
        left: '3%',
        right: '3%',
        bottom: '15%',
        top: '8%',
        containLabel: true
      },
      xAxis: [{
        type: 'category',
        data: chartData.map(item => item.date),
        boundaryGap: true,
        axisLine: { lineStyle: { color: '#E0E0E0' } },
        axisTick: { show: false },
        axisLabel: {
          color: '#666',
          fontSize: 13,
          formatter: (value) => {
            const date = new Date(value);
            return date.toLocaleDateString('en-US', { 
              year: 'numeric',
              month: 'short'
            });
          }
        }
      }],
      yAxis: [
        {
          type: 'value',
          name: 'Price',
          position: 'left',
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { lineStyle: { color: '#E0E0E0', type: 'dashed' } },
          axisLabel: {
            color: '#666',
            fontSize: 13,
            formatter: value => `$${value.toFixed(2)}`
          }
        },
        {
          type: 'value',
          name: 'Volume',
          position: 'right',
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: {
            color: '#666',
            fontSize: 13,
            formatter: value => `${(value/1000000).toFixed(0)}M`
          }
        }
      ],
      series: [
        {
          name: 'Price',
          type: 'line',
          data: chartData.map(item => item.close),
          symbol: 'none',
          smooth: true,
          lineStyle: { color: '#1a73e8', width: 2.5 },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0, y: 0, x2: 0, y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(26,115,232,0.2)'
              }, {
                offset: 1,
                color: 'rgba(26,115,232,0.05)'
              }]
            }
          }
        },
        {
          name: 'Volume',
          type: 'bar',
          yAxisIndex: 1,
          data: chartData.map(item => item.volume),
          barWidth: '60%',
          itemStyle: { color: '#34a853', opacity: 0.3 }
        }
      ]
    };
  };

  const renderHeatScore = (score, grade) => {
    if (!score) return null;
    return (
      <div className="sector-overview-heat-wrapper">
        <div className="sector-overview-heat-circle">
          <div className={`sector-overview-heat-value heat-${grade?.toLowerCase()}`}>
            {score?.toFixed(2)}
          </div>
          <div className="sector-overview-heat-rings">
            {[...Array(3)].map((_, i) => (
              <div key={i} className={`heat-ring heat-${grade?.toLowerCase()}`} />
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderRiskGrade = (grade) => {
    if (!grade) return null;
    const label = GRADE_LABELS.risk[grade];
    const riskLevel = ['A', 'B', 'C', 'D', 'E'].indexOf(grade) + 1;
    const percentage = (riskLevel / 5) * 100;
    
    return (
      <div className="sector-overview-risk-wrapper">
        <div className="sector-overview-risk-meter">
          <div 
            className={`sector-overview-risk-level risk-${grade.toLowerCase()}`}
            style={{ width: `${percentage}%` }}
          />
          <div className="sector-overview-risk-label">{label}</div>
        </div>
      </div>
    );
  };

  if (!data) return null;

  const formatNumber = (num) => {
    if (!num) return 'N/A';
    if (num >= 1e9) return `${(num / 1e9).toFixed(2)}B`;
    if (num >= 1e6) return `${(num / 1e6).toFixed(2)}M`;
    return num.toLocaleString();
  };

  const formatPercentage = (num) => {
    if (!num) return 'N/A';
    return (num * 100).toFixed(2) + '%';
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  return (
    <div className="sector-overview-container">
      <div className="sector-overview-metrics-grid">
        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">Price</div>
          <div className="sector-overview-metric-value">
            ${data.price?.toFixed(2)}
            {data.nav_premium && (
              <span className={`sector-overview-change ${data.nav_premium >= 0 ? 'positive' : 'negative'}`}>
                ({formatPercentage(data.nav_premium)})
              </span>
            )}
          </div>
        </div>

        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">
            Heat Score
            <Tooltip content={OVERVIEW_TOOLTIPS.heat_score} />
          </div>
          {renderHeatScore(heatScore?.heat_score, heatScore?.grade)}
        </div>

        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">
            Risk Rating
            <Tooltip content={OVERVIEW_TOOLTIPS.risk_rating} />
          </div>
          {renderRiskGrade(riskRank?.risk_rank)}
        </div>

        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">Market Cap</div>
          <div className="sector-overview-metric-value">
            ${formatNumber(data.market_cap)}
          </div>
        </div>

        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">
            AUM
            <Tooltip content={OVERVIEW_TOOLTIPS.aum} />
          </div>
          <div className="sector-overview-metric-value">
            ${formatNumber(data.aum)}
          </div>
        </div>

        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">Volume</div>
          <div className="sector-overview-volume-wrapper">
            <div>Previous: {formatNumber(data.volume_last)}</div>
            <div>10D avg: {formatNumber(data.avg_volume_10d)}</div>
          </div>
        </div>

        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">
            Expense Ratio
            <Tooltip content={OVERVIEW_TOOLTIPS.expense_ratio} />
          </div>
          <div className="sector-overview-metric-value">
            {formatPercentage(data.expense_ratio)}
          </div>
        </div>

        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">Issuer</div>
          <div className="sector-overview-metric-value">
            {data.issuer}
          </div>
        </div>

        <div className="sector-overview-metric-card">
          <div className="sector-overview-metric-label">Inception Date</div>
          <div className="sector-overview-metric-value">
            {formatDate(data.inception_date)}
          </div>
        </div>
      </div>

      {/* 描述部分 */}
      {data.introduction && (
        <div className="sector-overview-description">
          <h3 className="sector-overview-description-title">Introduction</h3>
          <div className="sector-overview-description-content">
            {data.introduction}
          </div>
        </div>
      )}

      {/* 历史价格图表 */}
      <div className="sector-overview-chart-section">
        <div className="sector-overview-chart-header">
          <h3 className="sector-overview-chart-title">Historical Price & Volume</h3>
          <div className="sector-overview-time-range">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`sector-overview-time-button ${timeRange === range.label ? 'active' : ''}`}
                onClick={() => setTimeRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>
        <div className="sector-overview-chart">
          <ReactECharts 
            option={getHistoryChartOption()} 
            style={{ height: '400px' }}
            notMerge={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SectorOverview; 