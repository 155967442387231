import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageLayout from './PageLayout';
import './PaymentSuccessPage.css';

const PaymentSuccessPage = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => prev - 1);
    }, 1000);

    const redirectTimer = setTimeout(() => {
      navigate('/profile');
    }, 3000);

    return () => {
      clearInterval(timer);
      clearTimeout(redirectTimer);
    };
  }, [navigate]);

  return (
    <PageLayout>
      <div className="payment-result-page success">
        <div className="success-container">
          <div className="success-icon">
            <svg viewBox="0 0 24 24" width="64" height="64">
              <path fill="#4CAF50" d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"/>
            </svg>
          </div>
          <h2>Payment Successful!</h2>
          <p className="message">Thank you for your purchase</p>
          <div className="redirect-message">
            <div className="loading-bar">
              <div className="loading-progress" style={{ width: `${((3 - countdown) / 3) * 100}%` }}></div>
            </div>
            <p>Redirecting to profile in {countdown}s...</p>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PaymentSuccessPage; 