import React from 'react';
import './StockProfitabilityMetrics.css';
import Tooltip from '../../Tooltip';

const METRIC_TOOLTIPS = {
  gross_profit_margin: `Gross Profit Margin measures the percentage of revenue remaining after deducting cost of goods sold. It indicates a company's efficiency in production and pricing power. Higher margins suggest stronger competitive position and better ability to generate profit from core operations.`,

  ebit_margin: `EBIT (Earnings Before Interest and Taxes) Margin shows operating profit as a percentage of revenue. It measures operational efficiency and profitability before financing and tax impacts. Higher margins indicate better operational performance and cost management.`,

  ebitda_margin: `EBITDA Margin shows earnings before interest, taxes, depreciation, and amortization as a percentage of revenue. It indicates operational profitability excluding non-cash expenses and capital structure effects. Higher margins suggest stronger operational efficiency.`,

  net_income_margin: `Net Income Margin represents the percentage of revenue that becomes profit after all expenses. It shows overall profitability including all business aspects. Higher margins indicate better overall efficiency in converting revenue to profit.`,

  levered_fcf_margin: `Levered Free Cash Flow Margin shows cash available to equity holders as a percentage of revenue. It indicates the company's ability to generate cash after all obligations. Higher margins suggest better cash generation efficiency.`,

  return_on_common_equity: `Return on Common Equity (ROCE) measures profit generated from shareholders' equity. It shows how efficiently a company uses shareholder investments to generate earnings. Higher returns indicate better efficiency in using equity capital.`,

  return_on_total_capital: `Return on Total Capital measures profit relative to all invested capital (debt and equity). It shows how efficiently a company uses all its capital to generate earnings. Higher returns suggest better overall capital efficiency.`,

  return_on_total_assets: `Return on Total Assets (ROA) measures profit relative to total assets. It indicates how efficiently a company uses its assets to generate earnings. Higher returns suggest better asset utilization.`,

  capex_sales_ratio: `Capital Expenditure to Sales Ratio shows investment in long-term assets relative to revenue. It indicates the intensity of capital investment needed to generate sales. Lower ratios might suggest more efficient use of capital.`,

  asset_turnover_ratio: `Asset Turnover Ratio measures revenue generated relative to assets. It shows how efficiently a company uses its assets to generate sales. Higher ratios indicate better asset utilization efficiency.`,

  net_income_per_employee: `Net Income Per Employee measures average profit generated by each employee. It indicates workforce productivity and operational efficiency. Higher values suggest better labor efficiency and management.`,

  cash_from_operations: `Cash From Operations shows cash generated from core business activities. It indicates the company's ability to generate cash from its main operations. Higher values suggest stronger operational cash generation.`,

  cash_per_share: `Cash Per Share shows available cash relative to outstanding shares. It indicates liquidity on a per-share basis. Higher values suggest stronger liquidity position and financial flexibility.`
};

const METRICS_CONFIG = [
  {
    id: 'margins',
    title: 'Margin Metrics',
    metrics: [
      { key: 'gross_profit_margin', label: 'Gross Profit Margin', format: 'percentage', tooltip: METRIC_TOOLTIPS.gross_profit_margin },
      { key: 'ebit_margin', label: 'EBIT Margin', format: 'percentage', tooltip: METRIC_TOOLTIPS.ebit_margin },
      { key: 'ebitda_margin', label: 'EBITDA Margin', format: 'percentage', tooltip: METRIC_TOOLTIPS.ebitda_margin },
      { key: 'net_income_margin', label: 'Net Income Margin', format: 'percentage', tooltip: METRIC_TOOLTIPS.net_income_margin },
      { key: 'levered_fcf_margin', label: 'Levered FCF Margin', format: 'percentage', tooltip: METRIC_TOOLTIPS.levered_fcf_margin }
    ]
  },
  {
    id: 'returns',
    title: 'Return Ratios',
    metrics: [
      { key: 'return_on_common_equity', label: 'Return on Common Equity', format: 'percentage', tooltip: METRIC_TOOLTIPS.return_on_common_equity },
      { key: 'return_on_total_capital', label: 'Return on Total Capital', format: 'percentage', tooltip: METRIC_TOOLTIPS.return_on_total_capital },
      { key: 'return_on_total_assets', label: 'Return on Total Assets', format: 'percentage', tooltip: METRIC_TOOLTIPS.return_on_total_assets }
    ]
  },
  {
    id: 'operations',
    title: 'Operational Efficiency',
    metrics: [
      { key: 'capex_sales_ratio', label: 'CAPEX / Sales', format: 'percentage', tooltip: METRIC_TOOLTIPS.capex_sales_ratio },
      { key: 'asset_turnover_ratio', label: 'Asset Turnover Ratio', format: 'percentage', tooltip: METRIC_TOOLTIPS.asset_turnover_ratio },
      { key: 'net_income_per_employee', label: 'Net Income Per Employee', format: 'currency', tooltip: METRIC_TOOLTIPS.net_income_per_employee },
      { key: 'cash_from_operations', label: 'Cash From Operations', format: 'currency', tooltip: METRIC_TOOLTIPS.cash_from_operations },
      { key: 'cash_per_share', label: 'Cash Per Share', format: 'decimal', tooltip: METRIC_TOOLTIPS.cash_per_share }
    ]
  }
];

const formatValue = (value, format) => {
  if (value === undefined || value === null) return 'N/A';
  
  switch (format) {
    case 'percentage':
      return `${value.toFixed(2)}%`;
    case 'currency':
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0
      }).format(value);
    case 'decimal':
      return value.toFixed(2);
    default:
      return value.toString();
  }
};

const StockProfitabilityMetrics = ({ profitabilityData }) => {
  if (!profitabilityData) return null;

  return (
    <div className="longterm-profitability-metrics">
      {METRICS_CONFIG.map(section => (
        <div key={section.id} className="longterm-profitability-metrics-section">
          <h3 className="longterm-profitability-metrics-title">{section.title}</h3>
          <div className="longterm-profitability-metrics-grid">
            {section.metrics.map(metric => (
              <div 
                key={metric.key} 
                className="longterm-profitability-metric-card"
                data-type={metric.format}
              >
                <div className="longterm-profitability-metric-label">
                  {metric.label}
                  {metric.tooltip && <Tooltip content={metric.tooltip} />}
                </div>
                <div className="longterm-profitability-metric-value">
                  {formatValue(profitabilityData[metric.key], metric.format)}
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StockProfitabilityMetrics; 