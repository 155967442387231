import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSectorETFOverview, getSectorETFHistory, getSectorETFPriceReturn } from '../../../services/data_api';
import SectorOverview from './SectorOverview';
import SectorHoldings from './SectorHoldings';
import SectorHeat from './SectorHeat';
import SectorRisk from './SectorRisk';
import SectorRecommendation from './SectorRecommendation';
import './SectorDetails.css';

const SectorDetails = () => {
  const { etf } = useParams();
  const [activeTab, setActiveTab] = useState('overview');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sectorData, setSectorData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [priceReturnData, setPriceReturnData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!etf) return;
      try {
        setLoading(true);
        const [overviewResponse, historyResponse, priceReturnResponse] = await Promise.all([
          getSectorETFOverview(etf),
          getSectorETFHistory(etf),
          getSectorETFPriceReturn(etf)
        ]);
        
        setSectorData({
          ...overviewResponse.data,
          price: priceReturnResponse?.data?.current_price,
          previous_close: priceReturnResponse?.data?.previous_close,
          current_return: priceReturnResponse?.data?.current_return
        });
        setHistoryData(historyResponse);
        setPriceReturnData(priceReturnResponse?.data);
      } catch (err) {
        console.error('Error fetching sector data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [etf]);

  if (loading) return <div className="sector-detail-loading">Loading...</div>;
  if (error) return <div className="sector-detail-error">Error: {error}</div>;
  if (!sectorData) return <div className="sector-detail-no-data">No data available</div>;

  return (
    <div className="sector-detail-container">
      <div className="sector-detail-header">
        <div className="sector-detail-title-wrapper">
          <h1 className="sector-detail-title">
            {sectorData.name} (ETF)
          </h1>
          <span className="sector-detail-tag">{sectorData.sector}</span>
        </div>
      </div>
      
      <div className="sector-detail-tabs">
        {[
          { id: 'overview', label: 'Overview' },
          { id: 'holdings', label: 'Holdings' },
          { id: 'heat', label: 'Heat' },
          { id: 'risk', label: 'Risk' },
          { id: 'recommendation', label: 'Recommendation' }
        ].map(tab => (
          <button
            key={tab.id}
            className={`sector-tab ${activeTab === tab.id ? 'active' : ''}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </button>
        ))}
      </div>

      <div className="sector-detail-content">
        {activeTab === 'overview' && (
          <SectorOverview 
            data={sectorData} 
            historyData={historyData}
            priceReturnData={priceReturnData}
          />
        )}
        {activeTab === 'holdings' && <SectorHoldings data={sectorData} />}
        {activeTab === 'heat' && <SectorHeat data={sectorData} />}
        {activeTab === 'risk' && <SectorRisk data={sectorData} />}
        {activeTab === 'recommendation' && <SectorRecommendation data={sectorData} />}
      </div>
    </div>
  );
};

export default SectorDetails; 