import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import PageLayout from './PageLayout';
import { PRODUCTS } from '../config';
import { createCheckoutSession, getSubscriptionInfo, activateOneDayPass, stripePromise } from '../services/stripe_api';
import './PricingPage.css';
import { toast } from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';

const PricingPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [timeLeft, setTimeLeft] = useState('');

  const userData = useSelector(state => state.auth.userData);
  const subscription = useSelector(state => state.auth.subscription);

  useEffect(() => {
    // Calculate end date 24 days from now
    const endDate = new Date();
    endDate.setDate(endDate.getDate() + 24);
    
    const updateCountdown = () => {
      const now = new Date();
      const timeLeft = endDate - now;

      if (timeLeft > 0) {
        const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

        setTimeLeft(`${days} Days ${hours}:${minutes}:${seconds}`);
      } else {
        setTimeLeft("Offer has ended!");
      }
    };

    const timer = setInterval(updateCountdown, 1000);
    return () => clearInterval(timer);
  }, []);

  const handleSubscribe = async (priceId) => {
    try {
      if (!userData?.userid) {
        navigate('/login');
        return;
      }

      setLoading(true);
      setError(null);

      const subscriptionType = priceId === PRODUCTS[0].yearlyPriceId ? 'yearly' : 'monthly';

      const checkoutSession = await createCheckoutSession(
        priceId,
        userData.userid,
        subscriptionType
      );

      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error(t('errors.stripeLoadFailed'));
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: checkoutSession.id
      });

      if (error) {
        console.error('Stripe redirect error:', error);
        throw error;
      }
    } catch (err) {
      console.error('Subscribe error:', err);
      setError(t(err.message) || t('errors.paymentFailed'));
    } finally {
      setLoading(false);
    }
  };

  const handleOneDayPass = async () => {
    try {
      if (!userData?.userid) {
        toast.error('Please login first');
        navigate('/login');
        return;
      }

      setLoading(true);
      
      const response = await activateOneDayPass(userData.userid);
      console.log('One-day pass response:', response);
      
      if (response?.success) {
        const newSubscriptionData = await getSubscriptionInfo(userData.userid);
        dispatch({ 
          type: 'UPDATE_SUBSCRIPTION_DATA', 
          payload: newSubscriptionData 
        });

        toast.success('One-day pass activated successfully');
        navigate('/profile');
      }
    } catch (err) {
      console.error('One-day pass activation error:', err);
      const errorMessage = err.response?.data?.error || // axios 错误响应
                          err.error || // 直接错误对象
                          err.message || // 标准 Error 对象
                          'Activation failed, please try again'; // 默认错误消息
      
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const calculateDiscount = (monthlyPrice, yearlyPrice) => {
    if (monthlyPrice === 0 || yearlyPrice === 0) return 0;
    const monthlyTotal = monthlyPrice * 12;
    const yearlyTotal = yearlyPrice;
    const discount = ((monthlyTotal - yearlyTotal) / monthlyTotal) * 100;
    return Math.round(discount);
  };

  return (
    <PageLayout>
      <div className="pricing-page">
        <Toaster 
          position="top-center"
          toastOptions={{
            duration: 5000,
            style: {
              background: '#363636',
              color: '#fff',
              padding: '16px 32px',
              fontSize: '18px',
              maxWidth: '600px',
              marginTop: '100px',
              borderRadius: '12px',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            },
            success: {
              iconTheme: {
                primary: '#4CAF50',
                secondary: '#fff',
              },
              style: {
                background: '#1a472a',
                border: '2px solid #2e7d32',
              }
            },
            error: {
              iconTheme: {
                primary: '#ff4b4b',
                secondary: '#fff',
              },
              style: {
                background: '#442726',
                border: '2px solid #d32f2f',
              }
            },
          }}
        />
        
        <div className="content">
          <h1 className="pricing-title">Pricing</h1>
          <p className="pricing-subtitle">
            
          </p>

          <div className="offer-banner">
            <h2>🎉 New Year Special Offer 🎉</h2>
            <p><strong>Ends in: <span>{timeLeft}</span></strong></p>
            <p>Monthly Plan: 25% OFF ($15) | Annual Plan: 40% OFF ($144)</p>
            <p>Get 2 Extra Months with Annual Plan | Get 15 Extra Days with Monthly Plan!</p>
          </div>

          <div className="pricing-plans">
            {/* Monthly Plan */}
            <div className="plan">
              <h2 className="plan-header">Monthly Plan</h2>
              <p>Perfect for short-term users exploring our tools.</p>
              <div className="plan-price">
                <span className="price-amount">$15</span>
                <span className="price-period">/mo</span>
                <div className="original-price">$20/mo</div>
              </div>
              <p>Includes 15 extra days!</p>
              <button 
                className="subscribe-button" 
                onClick={() => handleSubscribe(PRODUCTS[0].monthlyPriceId)}
                disabled={loading}
              >
                Subscribe Now
              </button>
            </div>

            {/* Annual Plan */}
            <div className="plan">
              <div className="save-badge">Save 40%</div>
              <h2 className="plan-header">Best Value: Annual Plan</h2>
              <p>Save 40% and enjoy 2 extra months of premium tools.</p>
              <div className="plan-price">
                <div className="original-price">$240/yr</div>
                <span className="price-amount">$144</span>
                <span className="price-period">/yr</span>
              </div>
              <p>Includes 2 extra months!</p>
              <button 
                className="subscribe-button" 
                onClick={() => handleSubscribe(PRODUCTS[0].yearlyPriceId)}
                disabled={loading}
              >
                Subscribe Now
              </button>
            </div>
          </div>

          <div className="upgrade-info">
            <h2>Why Upgrade to Premium?</h2>
            <div className="feature-list">
              <div className="feature-item">
                <div className="feature-header">Full Stock Pool</div>
                <div className="feature-content">Access exclusive Bitcoin-related stock data and metrics.</div>
              </div>
              <div className="feature-item">
                <div className="feature-header">Advanced Insights</div>W
                <div className="feature-content">Get expert recommendations and comprehensive analytics.</div>
              </div>
              <div className="feature-item">
                <div className="feature-header">Custom Tools</div>
                <div className="feature-content">Unlock top picks and advanced stock screeners.</div>
              </div>
            </div>
            <div className="coming-soon">
              More features coming soon, including BTC-Related ETF and Long-Term Stocks modules!
            </div>
          </div>

          {error && (
            <div className="error-message" style={{ marginTop: '1rem' }}>
              {error}
            </div>
          )}
        </div>

        <div className="footer-copyright">
          © 2024 ChainSec Limited. All Rights Reserved.
        </div>
      </div>
    </PageLayout>
  );
};

export default PricingPage;
