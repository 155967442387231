import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { getEtfBtcBalance } from '../../services/data_api';
import './ETFBtcHoldingChart.css';

const TIME_RANGES = {
  '1M': 30,
  '3M': 90,
  '6M': 180,
  '1Y': 365,
  'ALL': Number.MAX_SAFE_INTEGER
};

const formatLargeNumber = (value) => {
  if (value >= 1e9) return `${(value / 1e9).toFixed(2)}B`;
  if (value >= 1e6) return `${(value / 1e6).toFixed(2)}M`;
  if (value >= 1e3) return `${(value / 1e3).toFixed(2)}K`;
  return value.toFixed(2);
};

const ETFBtcHoldingChart = ({ ticker }) => {
  const [holdingData, setHoldingData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getEtfBtcBalance(ticker);
      setHoldingData(data);
      setLoading(false);
    };
    fetchData();
  }, [ticker]);

  if (loading) {
    return <div className="etf-btc-chart-loading">Loading chart data...</div>;
  }

  if (!holdingData || !holdingData.data || holdingData.data.length === 0) {
    return (
      <div className="etf-btc-holding-chart">
        <div className="etf-btc-holding-chart-header">
          <div className="etf-btc-holding-chart-title">
            {ticker} - BTC Holding Over Time
          </div>
          <div className="etf-btc-holding-chart-time-range">
            {Object.keys(TIME_RANGES).map(range => (
              <button
                key={range}
                className={`etf-btc-holding-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
                onClick={() => setSelectedRange(range)}
                disabled
              >
                {range}
              </button>
            ))}
          </div>
        </div>
        <div className="etf-btc-holding-chart-empty">
          <i className="fas fa-database etf-btc-holding-chart-empty-icon"></i>
          <p className="etf-btc-holding-chart-empty-text">No holding data available</p>
        </div>
      </div>
    );
  }

  const getChartData = () => {
    const days = TIME_RANGES[selectedRange];
    const filteredData = holdingData.data.slice(-days);
    return {
      dates: filteredData.map(item => item.date),
      btcHoldings: filteredData.map(item => item.btc_balance),
      usdValues: filteredData.map(item => item.usd_value_of_btc_holdings)
    };
  };

  const chartData = getChartData();

  const option = {
    title: {
      show: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function(params) {
        const date = params[0].axisValue;
        const btcHolding = formatLargeNumber(params[0].value);
        const usdValue = formatLargeNumber(params[1].value);
        return `<div style="padding: 3px;">
          <div style="margin-bottom: 4px;"><strong>${date}</strong></div>
          <div style="color: #1a73e8">BTC Holding: ${btcHolding}</div>
          <div style="color: #34a853">USD Value: $${usdValue}</div>
        </div>`;
      }
    },
    legend: {
      data: ['BTC Holding', 'USD Value of BTC Holding'],
      top: 30,
      textStyle: {
        fontSize: 14
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '15%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: chartData.dates,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      axisLabel: {
        fontSize: 12,
        color: '#666'
      }
    },
    yAxis: [
      {
        type: 'value',
        name: 'BTC Holding',
        position: 'left',
        axisLabel: {
          formatter: value => formatLargeNumber(value),
          fontSize: 12,
          color: '#666'
        },
        splitLine: {
          lineStyle: {
            color: '#f5f5f5',
            type: 'dashed'
          }
        }
      },
      {
        type: 'value',
        name: 'USD Value',
        position: 'right',
        axisLabel: {
          formatter: value => '$' + formatLargeNumber(value),
          fontSize: 12,
          color: '#666'
        },
        splitLine: {
          show: false
        }
      }
    ],
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        name: 'BTC Holding',
        type: 'line',
        data: chartData.btcHoldings,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 3,
          color: '#1a73e8'
        }
      },
      {
        name: 'USD Value of BTC Holding',
        type: 'line',
        yAxisIndex: 1,
        data: chartData.usdValues,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 3,
          color: '#34a853'
        }
      }
    ]
  };

  return (
    <div className="etf-btc-holding-chart">
      <div className="etf-btc-holding-chart-header">
        <div className="etf-btc-holding-chart-title">
          {ticker} - BTC Holding Over Time
        </div>
        <div className="etf-btc-holding-chart-time-range">
          {Object.keys(TIME_RANGES).map(range => (
            <button
              key={range}
              className={`etf-btc-holding-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
              onClick={() => setSelectedRange(range)}
            >
              {range}
            </button>
          ))}
        </div>
      </div>
      <ReactECharts 
        option={option}
        style={{ height: '400px' }}
        opts={{ renderer: 'svg' }}
      />
    </div>
  );
};

export default ETFBtcHoldingChart; 