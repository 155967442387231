import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { callLoginApi } from '../login_api';
import './ForgotPasswordPage.css';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    try {
      const response = await callLoginApi('/forgot-password', { 
        username: email.trim() 
      });
      
      if (!response || !response.message) {
        throw new Error('Invalid response from server');
      }

      setIsSuccess(true);
      setMessage(t('passwordResetEmailSent'));
    } catch (error) {
      console.error('Password reset error:', error);
      setIsSuccess(false);

      if (!navigator.onLine) {
        setMessage(t('errors.networkError'));
      } else if (error.message === 'User not found') {
        setMessage(t('errors.userNotFound'));
      } else if (error.message === 'Invalid response from server') {
        setMessage(t('errors.serverError'));
      } else {
        setMessage(t('errors.serverError'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (message) {
      setMessage('');
      setIsSuccess(false);
    }
  };

  return (
    <div className="forgot-password-container">
      <form className="forgot-password-form" onSubmit={handleSubmit}>
        <h2>{t('forgotPassword')}</h2>
        {message && (
          <div className={`message ${isSuccess ? 'success' : 'error'}`}>
            {message}
            {isSuccess && (
              <p className="success-info">
                {t('checkEmailInstructions')}
              </p>
            )}
          </div>
        )}
        <div className="form-group">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={handleEmailChange}
            placeholder={t('enterEmailPlaceholder')}
            required
            disabled={isLoading || isSuccess}
            autoComplete="email"
          />
        </div>
        <button
          type="submit"
          className={`submit-button ${isLoading ? 'loading' : ''}`}
          disabled={isLoading || isSuccess}
        >
          {isLoading ? (
            <div className="button-content">
              <div className="spinner"></div>
              <span>{t('sending')}</span>
            </div>
          ) : (
            t('sendResetLink')
          )}
        </button>
        {isSuccess && (
          <p className="resend-info">
            {t('noEmailReceived')} <br />
            <button 
              type="button" 
              className="resend-button"
              onClick={() => {
                setIsSuccess(false);
                setMessage('');
              }}
            >
              {t('tryAgain')}
            </button>
          </p>
        )}
      </form>
      <p className="toggle-text">
        <Link to="/login">{t('backToLogin')}</Link>
      </p>
    </div>
  );
};

export default ForgotPasswordPage;