import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import './ETFBciMetrics.css';
import ETFPriceRatioChart from './ETFPriceRatioChart';
import ETFBtcHoldingChart from './ETFBtcHoldingChart';

const MetricCard = ({ label, value, tooltip }) => (
  <div className="etf-bci-metric-card">
    <div className="etf-bci-metric-header">
      <span className="etf-bci-metric-label">
        {label}
        {tooltip && <Tooltip content={tooltip} />}
      </span>
    </div>
    <div className="etf-bci-metric-value">{value}</div>
  </div>
);

const RatingIndicator = ({ rating }) => {
  return (
    <div className="etf-bci-rating">
      <div className="etf-bci-rating-bar-wrapper">
        <div className="etf-bci-rating-bar">
          <div 
            className="etf-bci-rating-marker" 
            style={{ 
              left: `${rating === 'A' ? '87.5%' : rating === 'B' ? '62.5%' : rating === 'C' ? '37.5%' : '12.5%'}`
            }} 
          />
        </div>
        <div className="etf-bci-rating-labels">
          <span>Weak</span>
          <span>Medium</span>
          <span>Strong</span>
          <span>Very Strong</span>
        </div>
      </div>
    </div>
  );
};

const ETFBciMetrics = ({ bciData }) => {
  if (!bciData) return <div className="etf-bci-loading">Loading...</div>;

  const formatValue = (value) => {
    if (value === null || value === undefined || isNaN(value)) return '-';
    return Number(value).toFixed(6);
  };

  const metrics = [
    {
      key: 'btc_per_share',
      label: 'BTC Per Share',
      tooltip: 'The amount of Bitcoin represented by each outstanding share of the ETF, indicating the level of Bitcoin exposure per share.'
    },
    {
      key: 'last_day_btc_price_ratio',
      label: 'BTC Price Ratio',
      tooltip: 'A ratio comparing the stock price to the price of Bitcoin, illustrating how the ETF\'s performance correlates with Bitcoin\'s market movements.'
    },
    {
      key: 'correlation',
      label: 'Correlation with BTC',
      tooltip: 'The BTC Relationship Score is a metric from 1 to 10 that reflects the correlation between the ETF and Bitcoin, based on AI analysis. A higher score indicates a stronger relationship with Bitcoin.'
    },
    {
      key: 'beta',
      label: 'Beta with BTC',
      tooltip: 'A measure of the ETF\'s volatility in relation to Bitcoin, indicating how sensitive the ETF is to Bitcoin price movements.'
    },
    {
      key: 'alpha',
      label: 'Alpha with BTC',
      tooltip: 'A measure of the ETF\'s excess return compared to Bitcoin, indicating whether the ETF outperforms or underperforms Bitcoin.'
    },
    {
      key: 'btc_correlation_index',
      label: 'BTC Correlation Index',
      tooltip: 'A comprehensive index measuring the overall strength of the relationship between the ETF and Bitcoin, considering multiple factors including price correlation and trading patterns.'
    }
  ];

  return (
    <div className="etf-bci-container">
      <div className="etf-bci-header">
        <div className="etf-bci-type">
          <i className="fas fa-chart-line etf-bci-type-icon"></i>
          {bciData.type}
        </div>
      </div>
      
      <div className="etf-bci-rating-section">
        <RatingIndicator rating={bciData.rating_adjusted_bci} />
      </div>

      <div className="etf-bci-metrics-grid">
        {metrics.map(metric => (
          <MetricCard
            key={metric.key}
            label={metric.label}
            value={formatValue(bciData[metric.key])}
            tooltip={metric.tooltip}
          />
        ))}
      </div>

      <ETFPriceRatioChart ticker={bciData.ticker} />
      <ETFBtcHoldingChart ticker={bciData.ticker} />
    </div>
  );
};

export default ETFBciMetrics; 