import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { getFearGreedStats, getFearGreedHistory } from '../services/data_api';
import FearGreedMeter from './FearGreedMeter';
import './FearGreedIndex.css';
import SectionTitleBar from './common/SectionTitleBar';

const TIME_RANGES = ['ALL', '1Y', '1M', '1W'];

const FearGreedIndex = () => {
  const { t } = useTranslation();
  const [stats, setStats] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [selectedTimeRange, setSelectedTimeRange] = useState('ALL');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [statsResponse, historyResponse] = await Promise.all([
          getFearGreedStats(),
          getFearGreedHistory()
        ]);
        setStats(statsResponse.data);
        setHistoryData(historyResponse.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getChartOption = () => {
    if (!historyData) return {};

    let filteredData = [...historyData];
    const now = new Date();
    
    switch(selectedTimeRange) {
      case '1W':
        const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        filteredData = historyData.filter(item => new Date(item.date) >= weekAgo);
        break;
      case '1M':
        const monthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        filteredData = historyData.filter(item => new Date(item.date) >= monthAgo);
        break;
      case '1Y':
        const yearAgo = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
        filteredData = historyData.filter(item => new Date(item.date) >= yearAgo);
        break;
      default:
        // 'ALL' case - use all data
        break;
    }

    const formatValue = (value) => {
      if (value >= 1e12) return `${(value / 1e12).toFixed(1)}T`;
      if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`;
      if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
      if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
      return value.toString();
    };

    return {
      backgroundColor: '#ffffff',
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
        formatter: function(params) {
          const date = params[0].axisValue;
          const fgiValue = params[0].data;
          const price = params[1]?.data;

          return `<div style="padding: 3px;">
            <div style="margin-bottom: 4px;"><strong>${date}</strong></div>
            <div style="color: #FFB300">
              Fear & Greed Index: ${fgiValue}
            </div>
            <div style="color: #64B5F6">
              BTC Price: $${formatValue(price)}
            </div>
          </div>`;
        }
      },
      legend: {
        data: ['Fear & Greed Index', 'BTC Price'],
        top: 0,
        textStyle: {
          fontSize: 16
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomOnMouseWheel: true
        },
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100
        }
      ],
      xAxis: {
        type: 'category',
        data: filteredData.map(item => item.date.split(' ')[0]),
        axisLabel: {
          rotate: 45,
          fontSize: 14,
          margin: 14
        },
        nameTextStyle: {
          fontSize: 16
        }
      },
      yAxis: [
        {
          type: 'value',
          name: 'Fear & Greed Index',
          min: 0,
          max: 100,
          position: 'left',
          axisLabel: {
            fontSize: 16
          },
          nameTextStyle: {
            fontSize: 16,
            padding: [0, 0, 0, 50]
          }
        },
        {
          type: 'value',
          name: 'BTC Price',
          position: 'right',
          axisLabel: {
            formatter: (value) => '$' + formatValue(value),
            fontSize: 16
          },
          nameTextStyle: {
            fontSize: 16,
            padding: [0, 0, 0, 50]
          }
        }
      ],
      series: [
        {
          name: 'Fear & Greed Index',
          type: 'line',
          data: filteredData.map(item => item.value),
          smooth: true,
          lineStyle: { 
            width: 2,
            color: '#FFB300'
          },
          itemStyle: {
            color: '#FFB300'
          }
        },
        {
          name: 'BTC Price',
          type: 'bar',
          yAxisIndex: 1,
          data: filteredData.map(item => item.price),
          barWidth: '60%',
          itemStyle: {
            color: '#64B5F6'
          }
        }
      ]
    };
  };

  const getCurrentValue = () => {
    if (!historyData || historyData.length === 0) return 0;
    return historyData[historyData.length - 1].value;
  };

  const getStatsForPeriod = (period) => {
    if (!stats) return [];
    
    // Create a mapping between button labels and API periods
    const periodMap = {
      'ALL': 'All',
      '1Y': '1 year',
      '1M': '1 month',
      '1W': '1 week'
    };

    // Use the mapped period value
    return stats.filter(item => item.period === periodMap[period])
      .sort((a, b) => {
        const order = ['Extreme Fear', 'Fear', 'Neutral', 'Greed', 'Extreme Greed'];
        return order.indexOf(a.category) - order.indexOf(b.category);
      });
  };

  if (loading) return <div className="fgi-loading">{t('loading')}</div>;
  if (error) return <div className="fgi-error">{t('error')}: {error}</div>;

  return (
    <div className="fgi-container">
      <div className="fgi-header">
        <div className="fgi-title">
          <img src="/images/Bitcoinlogo.png" alt="BTC" className="fgi-btc-logo" />
          <h1>Bitcoin. (BTC)</h1>
        </div>
      </div>

      <div className="fgi-content">
        <div className="fgi-meter-section">
          <FearGreedMeter value={getCurrentValue()} />
        </div>

        <div className="fgi-stats-section">
          <div className="fgi-stats-header">
            <div className="fgi-time-range-selector">
              {TIME_RANGES.map(range => (
                <button
                  key={range}
                  className={`fgi-range-button ${selectedRange === range ? 'active' : ''}`}
                  onClick={() => setSelectedRange(range)}
                >
                  {range}
                </button>
              ))}
            </div>
          </div>
          <div className="fgi-stats-table">
            <table>
              <thead>
                <tr>
                  <th>Category</th>
                  <th>Days</th>
                  <th>Percentage</th>
                </tr>
              </thead>
              <tbody>
                {getStatsForPeriod(selectedRange).map((stat, index) => (
                  <tr key={index}>
                    <td>{stat.category}</td>
                    <td>{stat.days}</td>
                    <td>{stat.percentage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="fgi-chart-section">
        <SectionTitleBar title="Fear & Greed Index History" />
        <div className="fgi-chart">
          <div className="fgi-chart-header">
            <div className="fgi-chart-time-range">
              {TIME_RANGES.map(range => (
                <button
                  key={range}
                  className={`fgi-chart-range-button ${selectedTimeRange === range ? 'active' : ''}`}
                  onClick={() => setSelectedTimeRange(range)}
                >
                  {range}
                </button>
              ))}
            </div>
          </div>
          <ReactECharts 
            option={getChartOption()} 
            style={{ height: '500px' }}
            className="fgi-chart"
          />
        </div>
      </div>

      <div className="fgi-explanation">
        <section className="fgi-section">
          <h2>What is the Fear and Greed Index?</h2>
          <p>The Fear and Greed Index measures Bitcoin market sentiment on a scale of 0 to 100:</p>
          <ul className="fgi-highlight-list">
            <li><strong>0 = Extreme Fear</strong>, indicating high investor anxiety.</li>
            <li><strong>100 = Extreme Greed</strong>, showing market overconfidence.</li>
          </ul>
          <p>It analyzes key data points like volatility, trading volume, and social behavior, helping investors identify buying or selling opportunities and avoid emotional decision-making.</p>
        </section>

        <section className="fgi-section">
          <h2>What is it made of?</h2>
          <p>The Fear and Greed Index is based on six key components, each contributing to the overall score:</p>
          <div className="fgi-components">
            <div className="fgi-component">
              <h3>Volatility (25%)</h3>
              <p>Compares current price swings to past averages (30/90 days). High volatility often signals fear in the market.</p>
            </div>
            <div className="fgi-component">
              <h3>Market Momentum/Volume (25%)</h3>
              <p>Tracks trading activity; strong buying momentum in a rising market often points to greed.</p>
            </div>
            <div className="fgi-component">
              <h3>Social Media (15%)</h3>
              <p>Analyzes hashtags and interactions on platforms like Twitter. Sudden spikes in activity suggest increased public interest and greed.</p>
            </div>
            <div className="fgi-component">
              <h3>Dominance (10%)</h3>
              <p>Measures Bitcoin's market share. Rising dominance reflects cautious behavior as investors move to safer assets, indicating fear.</p>
            </div>
            <div className="fgi-component">
              <h3>Trends (10%)</h3>
              <p>Uses Google Trends to track search volumes for Bitcoin-related terms, with fear-related queries (e.g., "Bitcoin price crash") hinting at market anxiety.</p>
            </div>
          </div>
          <p className="fgi-highlight">These components combine to provide a comprehensive view of market sentiment.</p>
        </section>

        <section className="fgi-section">
          <h2>How to use it for trading?</h2>
          <p>The Fear and Greed Index can guide trading decisions based on historical patterns:</p>
          <ul className="fgi-trading-points">
            <li>When the index enters the <strong>Fear zone</strong>, it often signals a good buying opportunity as the market may be undervalued.</li>
            <li>In the <strong>Extreme Fear zone</strong>, it could indicate a potential bottom and a chance to buy at a discount.</li>
            <li>Conversely, when the index reaches the <strong>Greed</strong> or <strong>Extreme Greed zones</strong>, it's a sign the market may be overheated, and investors should consider reducing positions or taking profits.</li>
          </ul>
          <p className="fgi-highlight">By leveraging the index, traders can make more informed and less emotional decisions, aligning their strategies with market sentiment.</p>
        </section>
      </div>
    </div>
  );
};

export default FearGreedIndex; 