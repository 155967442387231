import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { getEtfNavPremium } from '../../services/data_api';
import './ETFNavPremiumChart.css';

const TIME_RANGES = {
  '1M': 30,
  '3M': 90,
  '6M': 180,
  '1Y': 365,
  'ALL': Number.MAX_SAFE_INTEGER
};

const ETFNavPremiumChart = ({ ticker }) => {
  const [premiumData, setPremiumData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const data = await getEtfNavPremium(ticker);
      if (data) {
        setPremiumData(data);
      } else {
        setError('Failed to load NAV premium data');
      }
      setLoading(false);
    };
    fetchData();
  }, [ticker]);

  if (loading) {
    return <div className="etf-nav-premium-chart-loading">Loading chart data...</div>;
  }

  if (error || !premiumData || !premiumData.data || premiumData.data.length === 0) {
    return (
      <div className="etf-nav-premium-chart">
        <div className="etf-nav-premium-chart-header">
          <div className="etf-nav-premium-chart-title">
            Daily {ticker} Premium/Discount to NAV
          </div>
          <div className="etf-nav-premium-chart-time-range">
            {Object.keys(TIME_RANGES).map(range => (
              <button
                key={range}
                className={`etf-nav-premium-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
                onClick={() => setSelectedRange(range)}
                disabled
              >
                {range}
              </button>
            ))}
          </div>
        </div>
        <div className="etf-nav-premium-chart-empty">
          <i className="fas fa-chart-line etf-nav-premium-chart-empty-icon"></i>
          <p className="etf-nav-premium-chart-empty-text">
            No data available
          </p>
        </div>
      </div>
    );
  }

  const getChartData = () => {
    const days = TIME_RANGES[selectedRange];
    const sortedData = [...premiumData.data].sort((a, b) => 
      new Date(a.date) - new Date(b.date)
    );
    const filteredData = days === Number.MAX_SAFE_INTEGER 
      ? sortedData 
      : sortedData.slice(-days);

    return {
      dates: filteredData.map(item => item.date),
      nav: filteredData.map(item => item.nav),
      marketPrice: filteredData.map(item => item.market_price),
      premium: filteredData.map(item => item.premium_discount_percent)
    };
  };

  const chartData = getChartData();

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function(params) {
        const date = params[0].axisValue;
        const nav = params.find(p => p.seriesName === 'NAV')?.value.toFixed(2);
        const marketPrice = params.find(p => p.seriesName === 'Market Price')?.value.toFixed(2);
        const premium = params.find(p => p.seriesName === 'Premium/Discount')?.value.toFixed(2);
        
        return `<div style="padding: 3px;">
          <div style="margin-bottom: 4px;"><strong>${date}</strong></div>
          <div style="color: #1a73e8">NAV: $${nav || '-'}</div>
          <div style="color: #34a853">Market Price: $${marketPrice || '-'}</div>
          <div style="color: ${premium >= 0 ? '#34a853' : '#ea4335'}">Premium: ${premium || '-'}%</div>
        </div>`;
      }
    },
    legend: {
      data: ['NAV', 'Market Price', 'Premium/Discount'],
      top: 0,
      left: 'center',
      textStyle: {
        fontSize: 14,
        color: '#666'
      },
      itemWidth: 15,
      itemHeight: 3,
      itemGap: 25
    },
    grid: {
      left: '3%',
      right: '8%',
      bottom: '3%',
      top: '40px',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: chartData.dates,
      boundaryGap: false,
      axisLabel: {
        fontSize: 12,
        color: '#666'
      }
    },
    yAxis: [
      {
        type: 'value',
        name: 'Price ($)',
        position: 'left',
        axisLabel: {
          formatter: '${value}',
          fontSize: 12,
          color: '#666'
        },
        nameTextStyle: {
          fontSize: 14,
          color: '#666'
        }
      },
      {
        type: 'value',
        name: 'Premium (%)',
        position: 'right',
        axisLabel: {
          formatter: '{value}%',
          fontSize: 12,
          color: '#666'
        },
        nameTextStyle: {
          fontSize: 14,
          color: '#666'
        },
        splitLine: {
          show: false
        }
      }
    ],
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      },
      {
        type: 'slider',
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        name: 'NAV',
        type: 'line',
        data: chartData.nav,
        yAxisIndex: 0,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 2,
          color: '#1a73e8'
        },
        z: 2
      },
      {
        name: 'Market Price',
        type: 'line',
        data: chartData.marketPrice,
        yAxisIndex: 0,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 2,
          color: '#34a853',
          opacity: 0.8
        },
        z: 1
      },
      {
        name: 'Premium/Discount',
        type: 'line',
        data: chartData.premium,
        yAxisIndex: 1,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 2,
          color: '#ea4335'
        }
      }
    ]
  };

  return (
    <div className="etf-nav-premium-chart">
      <div className="etf-nav-premium-chart-header">
        <div className="etf-nav-premium-chart-title">
          Daily {ticker} Premium/Discount to NAV
        </div>
        <div className="etf-nav-premium-chart-time-range">
          {Object.keys(TIME_RANGES).map(range => (
            <button
              key={range}
              className={`etf-nav-premium-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
              onClick={() => setSelectedRange(range)}
            >
              {range}
            </button>
          ))}
        </div>
      </div>
      <ReactECharts 
        option={option}
        style={{ height: '400px' }}
        opts={{ renderer: 'svg' }}
      />
    </div>
  );
};

export default ETFNavPremiumChart; 