import React from 'react';
import './StockPremium.css';
import StockPremiumMetrics from './StockPremiumMetrics';

const RatingIndicator = ({ rating }) => {
  const getLabel = (rating) => {
    switch (rating) {
      case 'A': return 'Very Low';
      case 'B': return 'Low';
      case 'C': return 'Medium';
      case 'D': return 'High';
      case 'E': return 'Very High';
      default: return 'N/A';
    }
  };

  const getColor = (rating) => {
    switch (rating) {
      case 'A': return '#2E7D32';  // 深绿色
      case 'B': return '#4CAF50';  // 绿色
      case 'C': return '#FFA726';  // 橙色
      case 'D': return '#EF5350';  // 红色
      case 'E': return '#B71C1C';  // 深红色
      default: return '#9E9E9E';   // 灰色
    }
  };

  const getPosition = (rating) => {
    switch (rating) {
      case 'A': return -144;  // Very Low - 最左
      case 'B': return -72;   // Low - 左
      case 'C': return 0;     // Medium - 中
      case 'D': return 72;    // High - 右
      case 'E': return 144;   // Very High - 最右
      default: return 0;
    }
  };

  return (
    <div className="longterm-premium-rating">
      <div className="longterm-premium-rating-gauge">
        <div className="longterm-premium-rating-circle">
          <div className="longterm-premium-rating-segments">
            {['E', 'D', 'C', 'B', 'A'].map((g) => (
              <div
                key={g}
                className={`longterm-premium-rating-segment segment-${g}`}
                style={{
                  opacity: g === rating ? 1 : 0.3
                }}
              />
            ))}
          </div>
          <div 
            className="longterm-premium-rating-needle"
            style={{
              transform: `rotate(${getPosition(rating)}deg)`,
              backgroundColor: '#1a1a1a'
            }}
          />
          <div className="longterm-premium-rating-center" />
        </div>
      </div>
      <div className="longterm-premium-rating-labels">
        {['E', 'D', 'C', 'B', 'A'].reverse().map((g) => (
          <span 
            key={g}
            className={rating === g ? 'active' : ''}
          >
            {getLabel(g)}
          </span>
        ))}
      </div>
      <div 
        className="longterm-premium-rating-value"
        style={{ color: getColor(rating) }}
      >
        {getLabel(rating)}
      </div>
    </div>
  );
};

const StockPremium = ({ screenerData, premiumMetricsData }) => {
  if (!screenerData) {
    return (
      <div className="longterm-premium-container">
        <div className="longterm-premium-header">
          <h2 className="longterm-premium-title">Premium Score</h2>
          <div className="longterm-premium-subtitle">Loading...</div>
        </div>
      </div>
    );
  }

  // 直接使用 screenerData 中的 premium
  const premium = screenerData.premium || 'C';

  return (
    <div className="longterm-premium-container">
      <div className="longterm-premium-header">
        <h2 className="longterm-premium-title">Premium Score</h2>
        <div className="longterm-premium-subtitle">Based on valuation metrics analysis</div>
      </div>
      
      <div className="longterm-premium-main">
        <RatingIndicator rating={premium} />
      </div>

      {/* 添加估值指标组件 */}
      <StockPremiumMetrics premiumMetricsData={premiumMetricsData} />
    </div>
  );
};

export default StockPremium; 