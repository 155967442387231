import React, { useState, useEffect } from 'react';
import { getSectorETFRiskMetrics } from '../../../services/data_api';
import { formatNumber } from '../../../utils/formatters';
import Tooltip from '../../Tooltip';
import './SectorRisk.css';

const RiskGauge = ({ grade }) => {
  const getGradeColor = (grade) => {
    switch (grade) {
      case 'A': return { color: '#22c55e', secondaryColor: '#86efac' };
      case 'B': return { color: '#86efac', secondaryColor: '#bbf7d0' };
      case 'C': return { color: '#fbbf24', secondaryColor: '#fde68a' };
      case 'D': return { color: '#f87171', secondaryColor: '#fca5a5' };
      case 'E': return { color: '#dc2626', secondaryColor: '#f87171' };
      default: return { color: '#fbbf24', secondaryColor: '#fde68a' };
    }
  };

  const getGradeText = (grade) => {
    switch (grade) {
      case 'A': return 'Very Low';
      case 'B': return 'Low';
      case 'C': return 'Medium';
      case 'D': return 'High';
      case 'E': return 'Very High';
      default: return 'Medium';
    }
  };

  const colors = getGradeColor(grade);
  const scorePercentage = (5 - ['A', 'B', 'C', 'D', 'E'].indexOf(grade)) * 20;

  return (
    <div className="sector-risk-circle-wrapper">
      {/* 背景装饰圆环 */}
      <div className="sector-risk-decoration-ring"></div>
      
      {/* 主要进度圆环 */}
      <svg className="sector-risk-progress" viewBox="0 0 120 120">
        <defs>
          <linearGradient id="riskProgressGradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor={colors.color} />
            <stop offset="100%" stopColor={colors.secondaryColor} />
          </linearGradient>
        </defs>
        <circle className="sector-risk-progress-bg" cx="60" cy="60" r="54" />
        <circle 
          className="sector-risk-progress-bar" 
          cx="60" 
          cy="60" 
          r="54"
          style={{
            stroke: 'url(#riskProgressGradient)',
            strokeDasharray: `${scorePercentage * 3.39}, 339.292`
          }}
        />
      </svg>
      
      {/* 中心内容 */}
      <div className="sector-risk-circle-content">
        <div 
          className="sector-risk-status"
          style={{
            background: `linear-gradient(135deg, ${colors.color}, ${colors.secondaryColor})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent'
          }}
        >
          {getGradeText(grade)}
        </div>
      </div>
      
      {/* 装饰点 */}
      <div className="sector-risk-decoration-dots">
        {[...Array(8)].map((_, i) => (
          <div 
            key={i} 
            className="sector-risk-dot"
            style={{
              transform: `rotate(${i * 45}deg) translateX(140px)`
            }}
          />
        ))}
      </div>
    </div>
  );
};

const RISK_TOOLTIPS = {
  max_drawdown: `Maximum Drawdown represents the largest peak-to-trough decline in the ETF's value, indicating the worst historical loss an investor could have experienced.`,
  var_95: `Value at Risk (VaR) at 95% confidence level indicates the maximum potential loss that is not expected to be exceeded with 95% probability over a specific time period.`,
  sharpe_ratio: `Sharpe Ratio measures the risk-adjusted return by comparing excess returns above the risk-free rate to volatility, where a higher ratio indicates better risk-adjusted performance.`,
  sortino_ratio: `Sortino Ratio is similar to the Sharpe ratio but only considers downside volatility, making it particularly useful for evaluating investments with non-normal return distributions.`,
  aum: `Assets Under Management (AUM) represents the total market value of assets that the ETF manages, indicating the fund's size and potential liquidity.`,
  lifecycle: `Lifecycle represents the number of days since the ETF's inception, indicating the fund's operational history and track record.`
};

const MetricCard = ({ label, value, tooltip, isLastSection }) => (
  <div className="sector-risk-metric-card">
    <div className="sector-risk-metric-label">
      {label}
      {tooltip && (
        isLastSection ? (
          <span className="static-tooltip">
            <Tooltip content={tooltip} />
          </span>
        ) : (
          <Tooltip content={tooltip} />
        )
      )}
    </div>
    <div className="sector-risk-metric-value">{value}</div>
  </div>
);

const SectorRisk = ({ data: sectorData }) => {
  const [riskData, setRiskData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSectorETFRiskMetrics(sectorData.etf);
        setRiskData(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [sectorData.etf]);

  if (loading) {
    return <div className="sector-risk-loading">Loading risk data...</div>;
  }

  if (error || !riskData) {
    return <div className="sector-risk-error">Failed to load risk data</div>;
  }

  const formatPercentage = (value) => {
    if (value == null) return 'N/A';
    return `${(value * 100).toFixed(2)}%`;
  };

  const formatDecimal = (value) => {
    if (value == null) return 'N/A';
    return value.toFixed(2);
  };

  return (
    <div className="sector-risk-container">
      {/* Risk Gauge at the top */}
      <RiskGauge grade={riskData.risk_rank} />

      {/* Volatility & Liquidity Section */}
      <div className="sector-risk-section">
        <h2>Volatility & Liquidity</h2>
        <div className="sector-risk-metrics-grid">
          <MetricCard 
            label="Volatility (5Y)"
            value={formatPercentage(riskData.volatility)}
          />
          <MetricCard 
            label="Beta (5Y)"
            value={formatDecimal(riskData.beta_spy)}
          />
          <MetricCard 
            label="Volume (60 D)"
            value={`${formatNumber(riskData.volume_60d_avg)}m`}
          />
        </div>
      </div>

      {/* Loss Potential Section */}
      <div className="sector-risk-section">
        <h2>Loss Potential</h2>
        <div className="sector-risk-metrics-grid">
          <MetricCard 
            label="Maximum drawdown(5Y)"
            value={formatPercentage(riskData.max_drawdown)}
            tooltip={RISK_TOOLTIPS.max_drawdown}
          />
          <MetricCard 
            label="VaR(5%) (5Y)"
            value={formatPercentage(riskData.var_95)}
            tooltip={RISK_TOOLTIPS.var_95}
          />
        </div>
      </div>

      {/* Sharpe Ratio Section */}
      <div className="sector-risk-section">
        <h2>Sharpe Ratio</h2>
        <div className="sector-risk-metrics-grid">
          <MetricCard 
            label="Sharpe ratio(5Y)"
            value={formatDecimal(riskData.sharpe_ratio)}
            tooltip={RISK_TOOLTIPS.sharpe_ratio}
          />
          <MetricCard 
            label="Sortino Ratio(5Y)"
            value={formatDecimal(riskData.sortino_ratio)}
            tooltip={RISK_TOOLTIPS.sortino_ratio}
          />
        </div>
      </div>

      {/* ETF Risk Section */}
      <div className="sector-risk-section">
        <h2>ETF Risk</h2>
        <div className="sector-risk-metrics-grid">
          <MetricCard 
            label="AUM"
            value={`$${formatNumber(riskData.aum)}`}
            tooltip={RISK_TOOLTIPS.aum}
            isLastSection={true}
          />
          <MetricCard 
            label="Lifecycle (D)"
            value={formatNumber(riskData.lifecycle_d)}
            tooltip={RISK_TOOLTIPS.lifecycle}
            isLastSection={true}
          />
        </div>
      </div>
    </div>
  );
};

export default SectorRisk; 