import React, { useState, useRef, useEffect } from 'react';
import './Tooltip.css';

const Tooltip = ({ children, content, forceDirection }) => {
  const [position, setPosition] = useState({ horizontal: 'center', vertical: forceDirection || 'bottom' });
  const containerRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    const checkPosition = () => {
      if (!containerRef.current || !contentRef.current) return;
      
      const containerRect = containerRef.current.getBoundingClientRect();
      const viewportWidth = window.innerWidth;
      
      // 水平位置检测
      let horizontal = 'center';
      if (containerRect.left < 200) {
        horizontal = 'left';
      } else if (viewportWidth - containerRect.right < 200) {
        horizontal = 'right';
      }

      // 只在未指定 forceDirection 时检测垂直位置
      let vertical = forceDirection || position.vertical;
      if (!forceDirection) {
        const contentRect = contentRef.current.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        
        if (containerRect.top < contentRect.height + 20) {
          vertical = 'top';
        } else if (viewportHeight - containerRect.bottom < contentRect.height + 20) {
          vertical = 'bottom';
        }
      }

      setPosition({ horizontal, vertical });
    };

    checkPosition();
    window.addEventListener('resize', checkPosition);
    window.addEventListener('scroll', checkPosition);
    
    return () => {
      window.removeEventListener('resize', checkPosition);
      window.removeEventListener('scroll', checkPosition);
    };
  }, [forceDirection]);

  return (
    <span 
      ref={containerRef}
      className={`tooltip-container tooltip-${position.horizontal} tooltip-${position.vertical}`}
    >
      <span className="tooltip-icon">?</span>
      {children}
      <div 
        ref={contentRef}
        className={`tooltip-content tooltip-content-${position.horizontal} tooltip-content-${position.vertical}`}
      >
        {content}
      </div>
    </span>
  );
};

export default Tooltip; 