import React, { useState, useEffect } from 'react';
import './StockOverview.css';
import { formatNumber } from '../../../utils/formatters';
import ReactECharts from 'echarts-for-react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts';

const TIME_RANGES = [
  { label: '1W', days: 7 },
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: 'ALL', days: 365 * 10 }
];

const RatingCard = ({ title, rating, type }) => {
  const getRatingConfig = (rating, type) => {
    const baseConfig = {
      levels: ['E', 'D', 'C', 'B', 'A'],
      colors: ['#ef5350', '#ff7043', '#ffa726', '#66bb6a', '#43a047'], // 红橙黄浅绿深绿
      labels: {
        premium: ['Very High', 'High', 'Medium', 'Low', 'Very Low'],
        growth: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
        profitability: ['Very Weak', 'Weak', 'Medium', 'Strong', 'Very Strong'],
        momentum: ['Very Weak', 'Weak', 'Medium', 'Strong', 'Very Strong'],
        risk: ['Very High', 'High', 'Medium', 'Low', 'Very Low'],
        sentiment: ['Very Bearish', 'Bearish', 'Neutral', 'Bullish', 'Very Bullish']
      },
      gradients: {
        premium: 'linear-gradient(90deg, #43a047 0%, #66bb6a 25%, #ffa726 50%, #ff7043 75%, #ef5350 100%)', // 从左到右：绿到红
        growth: 'linear-gradient(90deg, #ef5350 0%, #ff7043 25%, #ffa726 50%, #66bb6a 75%, #43a047 100%)', // 从左到右：红到绿
        profitability: 'linear-gradient(90deg, #ef5350 0%, #ff7043 25%, #ffa726 50%, #66bb6a 75%, #43a047 100%)', // 从左到右：红到绿
        momentum: 'linear-gradient(90deg, #ef5350 0%, #ff7043 25%, #ffa726 50%, #66bb6a 75%, #43a047 100%)', // 从左到右：红到绿
        risk: 'linear-gradient(90deg, #43a047 0%, #66bb6a 25%, #ffa726 50%, #ff7043 75%, #ef5350 100%)', // 从左到右：绿到红
        sentiment: 'linear-gradient(90deg, #ef5350 0%, #ff7043 25%, #ffa726 50%, #66bb6a 75%, #43a047 100%)' // 从左到右：红到绿
      }
    };

    const ratingIndex = baseConfig.levels.indexOf(rating);
    const isReversed = ['premium', 'risk'].includes(type);
    const actualIndex = isReversed ? 4 - ratingIndex : ratingIndex;

    return {
      activeIndex: ratingIndex,
      gradient: baseConfig.gradients[type],
      color: baseConfig.colors[actualIndex],
      label: baseConfig.labels[type]?.[actualIndex] || rating
    };
  };

  const config = getRatingConfig(rating, type);
  const position = `${config.activeIndex * 25}%`;

  return (
    <div className="longterm-rating-card">
      <div className="longterm-rating-header">
        <h3>{title}</h3>
        <div className="longterm-rating-value" style={{ color: config.color }}>
          {config.label}
        </div>
      </div>
      <div className="longterm-rating-content">
        <div className="longterm-rating-bar-container">
          <div 
            className="longterm-rating-bar"
            style={{ background: config.gradient }}
          />
          <div className="longterm-rating-indicator-wrapper" style={{ left: position }}>
            <div className="longterm-rating-indicator">
              <div className="longterm-rating-indicator-inner" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const StockOverview = ({ data, priceData, screenerData, historyData, errors, isLoading }) => {
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [timeRange, setTimeRange] = useState('ALL');
  const [chartData, setChartData] = useState([]);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);

  // Debug logs
  console.log('History Data received:', historyData);
  console.log('History Data type:', typeof historyData);
  console.log('History Data structure:', {
    hasData: !!historyData,
    isArray: Array.isArray(historyData?.data),
    length: historyData?.data?.length
  });

  useEffect(() => {
    if (historyData?.data) {
      const data = historyData.data;
      const currentDate = new Date();
      let filteredData = [];

      switch (timeRange) {
        case '1M':
          filteredData = data.filter(item => {
            const itemDate = new Date(item.date);
            return currentDate - itemDate <= 30 * 24 * 60 * 60 * 1000;
          });
          break;
        case '3M':
          filteredData = data.filter(item => {
            const itemDate = new Date(item.date);
            return currentDate - itemDate <= 90 * 24 * 60 * 60 * 1000;
          });
          break;
        case '6M':
          filteredData = data.filter(item => {
            const itemDate = new Date(item.date);
            return currentDate - itemDate <= 180 * 24 * 60 * 60 * 1000;
          });
          break;
        case '1Y':
          filteredData = data.filter(item => {
            const itemDate = new Date(item.date);
            return currentDate - itemDate <= 365 * 24 * 60 * 60 * 1000;
          });
          break;
        case 'ALL':
          filteredData = data;
          break;
        default:
          filteredData = data;
      }

      // Format dates and ensure data is sorted
      const formattedData = filteredData.map(item => ({
        ...item,
        date: new Date(item.date).toLocaleDateString()
      })).sort((a, b) => new Date(a.date) - new Date(b.date));

      // 添加调试日志
      console.log('Filtered and formatted data:', formattedData);
      setChartData(formattedData);
    }
  }, [historyData, timeRange]);

  const getHistoryChartOption = () => {
    if (!historyData?.data) return {};

    const selectedDays = TIME_RANGES.find(r => r.label === timeRange)?.days || 365;
    const filteredData = historyData.data.slice(-selectedDays);

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        },
        formatter: function(params) {
          const date = new Date(params[0].axisValue);
          const price = params[0].data;
          const volume = params[1]?.data;
          return `
            <div style="font-size:15px;color:#333;line-height:1.6">
              <div style="margin-bottom:8px;font-weight:500">
                ${date.toLocaleDateString('en-US', { 
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric'
                })}
              </div>
              <div style="display:flex;justify-content:space-between;align-items:center">
                <span style="color:#1a73e8">●</span>
                <span style="margin:0 10px">Price:</span>
                <span style="font-weight:500">$${price.toFixed(2)}</span>
              </div>
              <div style="display:flex;justify-content:space-between;align-items:center">
                <span style="color:#34a853">●</span>
                <span style="margin:0 10px">Volume:</span>
                <span style="font-weight:500">${(volume/1000000).toFixed(2)}M</span>
              </div>
            </div>
          `;
        }
      },
      legend: {
        data: ['Price', 'Volume'],
        top: '0%',
        left: 'center',
        textStyle: {
          fontSize: 15,
          color: '#666'
        },
        itemGap: 30,
        icon: 'circle',
        itemWidth: 8,
        itemHeight: 8,
        borderRadius: 4
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false,
          moveOnMouseMove: true,
          preventDefaultMouseMove: true
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 0,
          start: 0,
          end: 100,
          borderColor: '#ccc',
          fillerColor: 'rgba(26,115,232,0.1)',
          handleStyle: {
            color: '#1a73e8',
            borderColor: '#1a73e8'
          },
          moveHandleSize: 8,
          textStyle: {
            fontSize: 13
          }
        }
      ],
      grid: {
        left: '3%',
        right: '3%',
        bottom: '15%',
        top: '8%',
        containLabel: true
      },
      xAxis: [
        {
          type: 'category',
          data: filteredData.map(item => item.date),
          boundaryGap: true,
          axisLine: { lineStyle: { color: '#E0E0E0' } },
          axisTick: { show: false },
          axisLabel: {
            color: '#666',
            fontSize: 13,
            formatter: (value) => {
              const date = new Date(value);
              return date.toLocaleDateString('en-US', { 
                year: 'numeric',
                month: 'short'
              });
            },
            interval: 'auto',
            showMaxLabel: true
          }
        }
      ],
      yAxis: [
        {
          type: 'value',
          name: 'Price',
          position: 'left',
          nameTextStyle: {
            fontSize: 14,
            padding: [0, 0, 0, 0]
          },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { lineStyle: { color: '#E0E0E0', type: 'dashed' } },
          axisLabel: {
            color: '#666',
            fontSize: 13,
            formatter: value => `$${value.toFixed(2)}`
          }
        },
        {
          type: 'value',
          name: 'Volume',
          position: 'right',
          nameTextStyle: {
            fontSize: 14,
            padding: [0, 0, 0, 0]
          },
          axisLine: { show: false },
          axisTick: { show: false },
          splitLine: { show: false },
          axisLabel: {
            color: '#666',
            fontSize: 13,
            formatter: value => `${(value/1000000).toFixed(0)}M`
          }
        }
      ],
      series: [
        {
          name: 'Price',
          type: 'line',
          data: filteredData.map(item => item.close),
          symbol: 'none',
          smooth: true,
          lineStyle: {
            color: '#1a73e8',
            width: 2.5
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(26,115,232,0.2)'
              }, {
                offset: 1,
                color: 'rgba(26,115,232,0.05)'
              }]
            }
          }
        },
        {
          name: 'Volume',
          type: 'bar',
          yAxisIndex: 1,
          data: filteredData.map(item => item.volume),
          barWidth: '60%',
          itemStyle: {
            color: '#34a853',
            opacity: 0.3
          }
        }
      ]
    };
  };

  const renderPriceChart = () => {
    if (!historyData?.data || historyData.data.length === 0) {
      return (
        <div className="longterm-history-chart">
          <div className="longterm-chart-header">
            <h3>Historical Price & Volume</h3>
          </div>
          <div className="longterm-no-data">No historical data available</div>
        </div>
      );
    }

    return (
      <div className="longterm-history-chart">
        <div className="longterm-chart-header">
          <h3>Historical Price & Volume</h3>
          <div className="longterm-time-range-selector">
            {['1W', '1M', '3M', 'ALL'].map(range => (
              <button
                key={range}
                className={`longterm-range-button ${timeRange === range ? 'longterm-range-button-active' : ''}`}
                onClick={() => setTimeRange(range)}
              >
                {range}
              </button>
            ))}
          </div>
        </div>
        <ReactECharts 
          option={getHistoryChartOption()} 
          style={{ height: '400px' }}
          notMerge={true}
        />
      </div>
    );
  };

  if (isLoading) {
    return <div className="longterm-overview-loading">Loading...</div>;
  }

  const formatPriceChange = (price, change) => {
    if (!price || change === undefined) return 'N/A';
    const changeStr = change > 0 ? `+${change.toFixed(2)}%` : `${change.toFixed(2)}%`;
    const changeClass = change >= 0 ? 'positive' : 'negative';
    return (
      <div className="longterm-price-wrapper">
        <span className="longterm-price-value">${price.toFixed(2)}</span>
        <span className={`longterm-price-change ${changeClass}`}>({changeStr})</span>
      </div>
    );
  };

  return (
    <div className="longterm-overview-container">
      {/* 评级指标区域 */}
      <div className="longterm-ratings-grid">
        <RatingCard title="Premium" rating={screenerData?.premium} type="premium" />
        <RatingCard title="Growth" rating={screenerData?.growth} type="growth" />
        <RatingCard title="Profitability" rating={screenerData?.profitability} type="profitability" />
        <RatingCard title="Momentum" rating={screenerData?.momentum} type="momentum" />
        <RatingCard title="Risk" rating={screenerData?.risk} type="risk" />
        <RatingCard title="Sentiment" rating={screenerData?.sentiment} type="sentiment" />
      </div>

      {/* 基本指标区域 */}
      <div className="longterm-metrics-grid">
        <div className="longterm-metric-card">
          <div className="longterm-metric-label">Price</div>
          <div className="longterm-metric-value">
            {formatPriceChange(priceData?.current_price, priceData?.current_return)}
          </div>
        </div>

        <div className="longterm-metric-card">
          <div className="longterm-metric-label">Market Cap</div>
          <div className="longterm-metric-value">
            ${formatNumber(data?.market_cap)}
          </div>
        </div>

        <div className="longterm-metric-card">
          <div className="longterm-metric-label">EPS</div>
          <div className="longterm-metric-value">
            ${data?.eps || 'N/A'}
          </div>
        </div>

        <div className="longterm-metric-card">
          <div className="longterm-metric-label">Volume</div>
          <div className="longterm-volume-wrapper">
            <div>Previous: {formatNumber(data?.volume)}</div>
            <div>10D avg: {formatNumber(data?.avg_volume)}</div>
          </div>
        </div>

        <div className="longterm-metric-card">
          <div className="longterm-metric-label">Sector</div>
          <div className="longterm-metric-value">
            {data?.sector || 'N/A'}
          </div>
        </div>
      </div>

      {/* 公司简介 */}
      <div className="longterm-description-section">
        <h3 className="longterm-description-title">Company Description</h3>
        <div className={`longterm-description-content ${isDescriptionExpanded ? 'longterm-description-expanded' : ''}`}>
          {data?.description || 'No description available.'}
        </div>
        <button 
          className="longterm-description-toggle"
          onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
        >
          {isDescriptionExpanded ? (
            <>
              Show Less
              <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
              </svg>
            </>
          ) : (
            <>
              Show More
              <svg width="16" height="16" viewBox="0 0 24 24" fill="currentColor">
                <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z" />
              </svg>
            </>
          )}
        </button>
      </div>

      {/* 历史图表部分 */}
      {renderPriceChart()}
    </div>
  );
};

export default StockOverview; 