import React from 'react';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className="coming-soon-container">
      <div className="coming-soon-content">
        <div className="coming-soon-icon">📊</div>
        <h1>BTC ETF Analysis</h1>
        <div className="launch-badge">Coming Soon</div>
      </div>
    </div>
  );
};

export default ComingSoon; 