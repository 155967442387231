import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageLayout from '../PageLayout';
import ArticleCard from './ArticleCard';
import './InsightsPage.css';

// 在组件外部定义文章数据
const articles = {
  educational: [
    {
      id: 'top-8-bitcoin-stocks',
      slug: 'top-8-bitcoin-stocks',
      title: 'Top 8 Bitcoin-Related Stocks in 2025',
      description: 'Discover the top 8 Bitcoin-related stocks that offer a safer way to benefit from Bitcoin\'s growth in 2025.',
      category: 'educational',
      coverImage: '/images/WhyBTC.jpg', //添加封面图
      date: '2025-01-15',
      readTime: 12,
      author: {
        name: 'FinStock Team',
        avatar: '/images/favicon.ico' //作者图像
      }
    }
  ],
  'market-analysis': [],
  'crypto-insights': [],
  'investment-strategies': [],
  'industry-trends': []
};

const InsightsPage = () => {
  const { category } = useParams();
  const [activeCategory, setActiveCategory] = useState(category || 'educational');
  const navigate = useNavigate();

  const navigationItems = [
    {
      id: 'educational',
      label: 'Educational',
      icon: '📚',
      description: 'Learn the fundamentals of crypto and blockchain'
    },
    {
      id: 'market-analysis',
      label: 'Market Analysis',
      icon: '📊',
      description: 'Deep dive into market trends and analysis'
    },
    {
      id: 'crypto-insights',
      label: 'Crypto Insights',
      icon: '₿',
      description: 'Expert insights on cryptocurrency markets'
    },
    {
      id: 'investment-strategies',
      label: 'Investment Strategies',
      icon: '📈',
      description: 'Professional trading and investment strategies'
    },
    {
      id: 'industry-trends',
      label: 'Industry Trends',
      icon: '🏢',
      description: 'Latest developments in the crypto industry'
    }
  ];

  const handleCategoryChange = (categoryId) => {
    setActiveCategory(categoryId);
    navigate(`/insights/${categoryId}`);
  };

  const currentArticles = articles[activeCategory] || [];
  const currentCategory = navigationItems.find(item => item.id === activeCategory);

  return (
    <PageLayout>
      <div className="insightspage-container">
        <aside className="insightspage-sidebar">
          <div className="insightspage-sidebar-header">
            <h2>Categories</h2>
            <p>Explore our curated content</p>
          </div>
          <nav className="insightspage-nav">
            {navigationItems.map((item) => (
              <button
                key={item.id}
                className={`insightspage-nav-item ${activeCategory === item.id ? 'active' : ''}`}
                onClick={() => handleCategoryChange(item.id)}
              >
                <span className="insightspage-nav-icon">{item.icon}</span>
                <div className="insightspage-nav-content">
                  <span className="insightspage-nav-label">{item.label}</span>
                  <span className="insightspage-nav-description">{item.description}</span>
                </div>
              </button>
            ))}
          </nav>
        </aside>

        <main className="insightspage-main-content">
          <div className="insightspage-content-header">
            <h1>{currentCategory?.label}</h1>
            <p>{currentCategory?.description}</p>
            <div className="insightspage-content-meta">
              <span>{currentArticles.length} articles</span>
            </div>
          </div>

          {currentArticles.length > 0 ? (
            <div className="insightspage-articles-grid">
              {currentArticles.map(article => (
                <ArticleCard key={article.id} article={article} />
              ))}
            </div>
          ) : (
            <div className="insightspage-empty-state">
              <div className="insightspage-empty-icon">📝</div>
              <h3>No articles yet</h3>
              <p>We're working on creating valuable content for this category.</p>
            </div>
          )}
        </main>
      </div>
    </PageLayout>
  );
};

export default InsightsPage;