import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getBtcMonthlyReturns, getBtcMonthlyStats } from '../services/data_api';
import './MonthlyReturnsHeatmap.css';
import SectionTitleBar from './common/SectionTitleBar';

const MonthlyReturnsHeatmap = () => {
  const { t } = useTranslation();
  const [monthlyReturns, setMonthlyReturns] = useState([]);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // 获取当前年月
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth(); // 0-11

  // 颜色映射函数
  const getColorStyle = (value, year, month, isStats = false) => {
    if (!value || value === 'N/A') return { backgroundColor: '#f5f5f5', color: '#666' };
    
    const numValue = parseFloat(value);
    
    let style = {
      transition: 'all 0.3s ease',
    };

    // 颜色判断逻辑保持不变
    if (numValue > 20) {
      style.backgroundColor = '#1b5e20';
      style.color = '#fff';
    } else if (numValue > 5) {
      style.backgroundColor = '#43a047';
      style.color = '#fff';
    } else if (numValue > 0) {
      style.backgroundColor = '#a5d6a7';
      style.color = '#1b5e20';
    } else if (numValue === 0) {
      style.backgroundColor = '#f5f5f5';
      style.color = '#666';
    } else if (numValue > -5) {
      style.backgroundColor = '#ffcdd2';
      style.color = '#b71c1c';
    } else if (numValue > -20) {
      style.backgroundColor = '#e53935';
      style.color = '#fff';
    } else {
      style.backgroundColor = '#b71c1c';
      style.color = '#fff';
    }

    // 增强当前月份的突出显示效果
    if ((year === currentYear && month === currentMonth) || (isStats && month === currentMonth)) {
      style = {
        ...style,
        animation: 'highlightCell 3s infinite',
        position: 'relative',
        zIndex: 3,
        border: '2px solid rgba(255, 255, 255, 0.8)',
        boxShadow: `
          inset 0 0 0 2px rgba(0,0,0,0.4),
          0 0 20px rgba(0,0,0,0.2),
          0 0 30px rgba(0,0,0,0.1)
        `,
        transform: 'scale(1.1)',
      };
    }

    return style;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [returnsResponse, statsResponse] = await Promise.all([
          getBtcMonthlyReturns(),
          getBtcMonthlyStats()
        ]);
        
        setMonthlyReturns(returnsResponse.data);
        setMonthlyStats(statsResponse.data);
        setLoading(false);
      } catch (err) {
        console.error('Failed to fetch monthly data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div className="loading">Loading...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  // 处理数据为表格格式
  const processData = () => {
    const years = [...new Set(monthlyReturns.map(item => item.year))].sort((a, b) => b - a);
    const months = Array.from({length: 12}, (_, i) => i + 1);
    
    return years.map(year => ({
      year,
      months: months.map(month => {
        const data = monthlyReturns.find(item => item.year === year && item.month === month);
        return (data && data.return_value !== 'N/A') ? data.return_value : '';
      })
    }));
  };

  const tableData = processData();

  // 添加月份名称数组
  const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  return (
    <div 
      className="monthly-returns-section" 
      id="seasonality"  // 确保 ID 正确设置
    >
      <SectionTitleBar 
        title="Seasonality (Monthly Returns)" 
        id="seasonality" 
      />
      <div className="seasonality-description">
        <p>
          Bitcoin's monthly performance trends reveal clear seasonal patterns. Historically,
          <strong> February</strong> and <strong>October</strong> have delivered the strongest returns, making them standout
          months for potential gains. In contrast, <strong>August</strong>, <strong>September</strong>, and <strong>January</strong> tend to show
          weaker performance, signaling the need for caution during these periods. The <strong>average</strong> and
          <strong>median</strong> data highlight which months generally perform better or worse.
        </p>
      </div>
      <div className="heatmap-container">
        <table className="heatmap-table">
          <thead>
            <tr>
              <th className="year-header">Year</th>
              {MONTHS.map((month, index) => (
                <th key={index + 1} className="month-header">
                  {month}
                  <div className="month-number">{index + 1}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map(row => (
              <tr key={row.year}>
                <td className="year-cell">{row.year}</td>
                {row.months.map((value, index) => (
                  <td 
                    key={index}
                    style={value || value === 0 ? getColorStyle(value, row.year, index) : { backgroundColor: '#f5f5f5' }}
                    className="return-cell"
                  >
                    {value || value === 0 ? value : ''}
                  </td>
                ))}
              </tr>
            ))}
            <tr className="stats-row">
              <td>Average</td>
              {monthlyStats.map((stat, index) => (
                <td 
                  key={stat.id}
                  style={stat.mean || stat.mean === 0 ? getColorStyle(stat.mean, null, index, true) : { backgroundColor: '#f5f5f5' }}
                  className="return-cell"
                >
                  {stat.mean || stat.mean === 0 ? stat.mean : ''}
                </td>
              ))}
            </tr>
            <tr className="stats-row">
              <td>Median</td>
              {monthlyStats.map((stat, index) => (
                <td 
                  key={stat.id}
                  style={stat.median || stat.median === 0 ? getColorStyle(stat.median, null, index, true) : { backgroundColor: '#f5f5f5' }}
                  className="return-cell"
                >
                  {stat.median || stat.median === 0 ? stat.median : ''}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MonthlyReturnsHeatmap; 