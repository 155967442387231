import React, { useState, useEffect } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { useTranslation } from 'react-i18next';
import Tooltip from '../../Tooltip';
import { getSectorETFRecommendationRatings } from '../../../services/data_api';
import './SectorRecommendation.css';

const RATING_TOOLTIPS = {
  model_rating: `The Model Rating is based on a comprehensive analysis of all etf indicators, scored from 1 to 5, where higher values indicate greater potential.`,
  technical_rating: `The Technical Rating is a scoring system derived from commonly used technical indicators, providing insights into market trends and potential price movements.`,
  analysts_rating: `The Analysts Rating is given by professional analysts, summarizing their insights and recommendations regarding an etf's potential and risks.`
};

const TECHNICAL_TOOLTIPS = {
  rsi: `The RSI measures the speed and change of price movements, indicating overbought (above 70) or oversold (below 30) conditions.`,
  macd: `MACD is a trend-following momentum indicator that shows the relationship between two moving averages, helping identify potential buy or sell signals.`,
  stoch: `The Stochastic Oscillator compares a security's closing price to its price range over a specific period, indicating momentum and potential overbought (above 80) or oversold (below 20) conditions.`,
  adx: `ADX measures the strength of a trend, with values above 25 indicating a strong trend and below 20 suggesting a weak trend.`,
  cci: `CCI measures the deviation of the price from its average, with values above 100 indicating overbought conditions and below -100 indicating oversold conditions.`,
  atr: `ATR measures market volatility by calculating the average range between high and low prices over a specific period, helping assess potential risk.`,
  roc: `ROC measures the percentage change in price over a specified time frame, indicating momentum; positive values suggest upward momentum, while negative values indicate downward momentum.`,
  williams_r: `Williams %R measures overbought or oversold conditions, with values above -20 indicating overbought and below -80 indicating oversold.`
};

const RecommendationMeter = ({ value }) => {
  return (
    <div className="sector-recommendation-meter">
      <ReactSpeedometer
        value={value}
        minValue={1}
        maxValue={5}
        segments={5}
        currentValueText=""
        customSegmentLabels={[
          { text: "Strong Sell", position: "INSIDE", color: "#fff", fontSize: "11px" },
          { text: "Sell", position: "INSIDE", color: "#fff", fontSize: "11px" },
          { text: "Hold", position: "INSIDE", color: "#333", fontSize: "11px" },
          { text: "Buy", position: "INSIDE", color: "#fff", fontSize: "11px" },
          { text: "Strong Buy", position: "INSIDE", color: "#fff", fontSize: "11px" }
        ]}
        segmentColors={["#D32F2F", "#FF5722", "#FDD835", "#4CAF50", "#2E7D32"]}
        startAngle={0}
        endAngle={180}
        needleColor="#333"
        needleTransition="easeElastic"
        needleTransitionDuration={3000}
        needleHeightRatio={0.7}
        labelFontSize="11px"
        valueTextFontSize="0"
        width={400}
        height={200}
        paddingVertical={20}
        ringWidth={50}
        textColor="#666"
        paddingHorizontal={10}
        segmentSpacing={2}
        maxSegmentLabels={5}
        customSegmentStops={[1, 1.8, 2.6, 3.4, 4.2, 5]}
      />
      <div className="sector-recommendation-meter-label">
        {value.toFixed(2)}
      </div>
    </div>
  );
};

const RatingBar = ({ label, value, tooltip }) => {
  const percentage = ((value - 1) / 4) * 100;
  
  return (
    <div className="sector-recommendation-rating-item">
      <div className="sector-recommendation-rating-label-wrapper">
        <span className="sector-recommendation-rating-label">
          {label}
          {tooltip && <Tooltip content={tooltip} />}
        </span>
      </div>
      <div className="sector-recommendation-rating-container">
        <div className="sector-recommendation-rating-track">
          <div 
            className="sector-recommendation-rating-progress" 
            style={{ width: `${percentage}%` }}
          />
        </div>
        <div className="sector-recommendation-rating-value">
          {value.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

const TechnicalIndicator = ({ label, value, tooltip }) => (
  <div className="sector-recommendation-indicator-card">
    <div className="sector-recommendation-indicator-content">
      <div className="sector-recommendation-indicator-label">
        {label}
        {tooltip && <Tooltip content={tooltip} />}
      </div>
      <div className="sector-recommendation-indicator-value">
        {typeof value === 'number' ? value.toFixed(2) : value}
      </div>
    </div>
  </div>
);

const SectorRecommendation = ({ data: sectorData }) => {
  const { t } = useTranslation();
  const [recommendationData, setRecommendationData] = useState(null);

  useEffect(() => {
    const fetchRecommendationData = async () => {
      try {
        const response = await getSectorETFRecommendationRatings(sectorData.etf);
        setRecommendationData(response.data);
      } catch (error) {
        console.error('Error fetching recommendation data:', error);
      }
    };

    fetchRecommendationData();
  }, [sectorData.etf]);

  if (!recommendationData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="sector-recommendation-container">
      <div className="sector-recommendation-section">
        <h2 className="sector-recommendation-section-title">Ratings</h2>
        <div className="sector-recommendation-header">
          <div className="sector-recommendation-meter-container">
            <RecommendationMeter value={recommendationData.total_rating} />
          </div>
          
          <div className="sector-recommendation-ratings">
            <RatingBar 
              label="Model Rating" 
              value={recommendationData.quant_rating}
              tooltip={RATING_TOOLTIPS.model_rating}
            />
            <RatingBar 
              label="Technical Rating" 
              value={recommendationData.technical_rating}
              tooltip={RATING_TOOLTIPS.technical_rating}
            />
            <RatingBar 
              label="Analysts Rating" 
              value={recommendationData.sa_analysts_rating}
              tooltip={RATING_TOOLTIPS.analysts_rating}
            />
          </div>
        </div>
      </div>

      <div className="sector-recommendation-section">
        <h2 className="sector-recommendation-section-title">Technical Indicators</h2>
        <div className="sector-recommendation-indicators-grid">
          <TechnicalIndicator 
            label="RSI" 
            value={recommendationData.rsi}
            tooltip={TECHNICAL_TOOLTIPS.rsi}
          />
          <TechnicalIndicator 
            label="MACD" 
            value={recommendationData.macd}
            tooltip={TECHNICAL_TOOLTIPS.macd}
          />
          <TechnicalIndicator 
            label="STOCH K" 
            value={recommendationData.stoch_k}
            tooltip={TECHNICAL_TOOLTIPS.stoch}
          />
          <TechnicalIndicator 
            label="STOCH D" 
            value={recommendationData.stoch_d}
            tooltip={TECHNICAL_TOOLTIPS.stoch}
          />
          <TechnicalIndicator 
            label="ADX" 
            value={recommendationData.adx}
            tooltip={TECHNICAL_TOOLTIPS.adx}
          />
          <TechnicalIndicator 
            label="CCI" 
            value={recommendationData.cci}
            tooltip={TECHNICAL_TOOLTIPS.cci}
          />
          <TechnicalIndicator 
            label="ATR" 
            value={recommendationData.atr}
            tooltip={TECHNICAL_TOOLTIPS.atr}
          />
          <TechnicalIndicator 
            label="ROC" 
            value={recommendationData.roc}
            tooltip={TECHNICAL_TOOLTIPS.roc}
          />
          <TechnicalIndicator 
            label="Williams %R" 
            value={recommendationData.williams_r}
            tooltip={TECHNICAL_TOOLTIPS.williams_r}
          />
        </div>
      </div>
    </div>
  );
};

export default SectorRecommendation; 