import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { getMvrvZLatest, getMvrvZStat, getMvrvZHistory } from '../services/data_api';
import SectionTitleBar from './common/SectionTitleBar';
import './MvrvZValue.css';
import Tooltip from './Tooltip';

const formatValue = (value) => {
  if (value >= 1e12) return `${(value / 1e12).toFixed(1)}T`;
  if (value >= 1e9) return `${(value / 1e9).toFixed(1)}B`;
  if (value >= 1e6) return `${(value / 1e6).toFixed(1)}M`;
  if (value >= 1e3) return `${(value / 1e3).toFixed(1)}K`;
  return value.toString();
};

const TABLE_TIME_RANGES = ['1W', '1M', '1Y', 'ALL'];
const PERIOD_MAP = {
  '1W': '1 week',
  '1M': '1 month',
  '1Y': '1 year',
  'ALL': 'All'
};
const CHART_TIME_RANGES = ['ALL', '1Y', '1M', '1W'];

const MvrvZValue = () => {
  const { t } = useTranslation();
  const [latestData, setLatestData] = useState(null);
  const [statData, setStatData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [chartTimeRange, setChartTimeRange] = useState('ALL');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [latestResponse, statResponse, historyResponse] = await Promise.all([
          getMvrvZLatest(),
          getMvrvZStat(),
          getMvrvZHistory()
        ]);
        setLatestData(latestResponse);
        setStatData(statResponse);
        setHistoryData(historyResponse.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getMVRVColor = (value) => {
    if (value <= 1) return '#2E7D32';      // 绿色
    if (value <= 3) return '#F57F17';      // 深黄色
    return '#D32F2F';                      // 红色
  };

  const renderLatestData = () => {
    if (!latestData?.latest_data) return null;
    const { date, mvrv_z, btc_price } = latestData.latest_data;

    return (
      <div className="mvrv-z-value-latest-card">
        <div className="mvrv-z-value-header">
          <div className="mvrv-z-value-title">
            <img src="/images/Bitcoinlogo.png" alt="BTC" className="mvrv-z-value-btc-logo" />
            <div className="mvrv-z-value-title-text">
              <h1>Bitcoin.</h1>
              <span className="mvrv-z-value-symbol">(BTC)</span>
            </div>
          </div>
        </div>
        <div className="mvrv-z-value-data">
          <div className="mvrv-z-value-metric">
            <div className="mvrv-z-value-metric-label">
              MVRV-Z Score
              <Tooltip content={t('tooltips.mrvzScore')} />
            </div>
            <div 
              className="mvrv-z-value-metric-value" 
              style={{ color: getMVRVColor(mvrv_z) }}
            >
              {mvrv_z.toFixed(2)}
            </div>
          </div>
          <div className="mvrv-z-value-metric">
            <div className="mvrv-z-value-metric-label">BTC Price</div>
            <div className="mvrv-z-value-metric-value">
              ${btc_price.toLocaleString()}
            </div>
          </div>
          <div className="mvrv-z-value-date">Date: {date}</div>
        </div>
      </div>
    );
  };

  const renderStatsTable = () => {
    if (!statData?.data) return null;

    const getDataForPeriod = (period) => {
      const apiPeriod = PERIOD_MAP[period];
      return statData.data
        .filter(item => item.period.toLowerCase() === apiPeriod.toLowerCase())
        .sort((a, b) => {
          const order = ['Undervalued', 'Medium', 'Overvalued'];
          return order.indexOf(a.category) - order.indexOf(b.category);
        });
    };

    return (
      <div className="mvrv-z-value-stats-container">
        <div className="mvrv-z-value-stats-header">
          {TABLE_TIME_RANGES.map(range => (
            <button
              key={range}
              className={`mvrv-z-value-range-button ${selectedRange === range ? 'active' : ''}`}
              onClick={() => setSelectedRange(range)}
            >
              {range}
            </button>
          ))}
        </div>
        <div className="mvrv-z-value-table-wrapper">
          <table className="mvrv-z-value-stats-table">
            <thead>
              <tr>
                <th>Category</th>
                <th>Days</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {getDataForPeriod(selectedRange).map((stat, index) => (
                <tr key={index}>
                  <td>{stat.category}</td>
                  <td>{stat.days}</td>
                  <td>{stat.percentage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  const getChartOption = () => {
    if (!historyData) return {};

    let filteredData = [...historyData];
    const now = new Date();
    
    switch(chartTimeRange) {
      case '1W':
        const weekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        filteredData = historyData.filter(item => new Date(item.date) >= weekAgo);
        break;
      case '1M':
        const monthAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
        filteredData = historyData.filter(item => new Date(item.date) >= monthAgo);
        break;
      case '3M':
        const threeMonthsAgo = new Date(now.getTime() - 90 * 24 * 60 * 60 * 1000);
        filteredData = historyData.filter(item => new Date(item.date) >= threeMonthsAgo);
        break;
      case '6M':
        const sixMonthsAgo = new Date(now.getTime() - 180 * 24 * 60 * 60 * 1000);
        filteredData = historyData.filter(item => new Date(item.date) >= sixMonthsAgo);
        break;
      case '1Y':
        const yearAgo = new Date(now.getTime() - 365 * 24 * 60 * 60 * 1000);
        filteredData = historyData.filter(item => new Date(item.date) >= yearAgo);
        break;
      default:
        break;
    }

    return {
      backgroundColor: '#ffffff',
      tooltip: {
        trigger: 'axis',
        axisPointer: { 
          type: 'cross',
          label: {
            backgroundColor: '#6a7985'
          }
        },
        formatter: function(params) {
          const date = params[0].axisValue;
          const mvrvZ = params[0].data;
          const price = params[1].data;
          
          return `<div style="padding: 3px;">
            <div style="margin-bottom: 4px;"><strong>${date}</strong></div>
            <div style="color: #FFB300">
              MVRV-Z Value: ${mvrvZ.toFixed(2)}
            </div>
            <div style="color: #64B5F6">
              BTC Price: $${formatValue(price)}
            </div>
          </div>`;
        }
      },
      legend: {
        data: ['MVRV-Z Value', 'BTC Price'],
        top: 0,
        textStyle: {
          fontSize: 16
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomOnMouseWheel: true
        },
        {
          type: 'slider',
          show: true,
          start: 0,
          end: 100,
          height: 30,
          bottom: 0,
          borderColor: 'transparent',
          backgroundColor: '#f5f5f5',
          fillerColor: 'rgba(26, 115, 232, 0.2)',
          handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#fff',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowOffsetX: 2,
            shadowOffsetY: 2
          },
          textStyle: {
            fontSize: 14
          }
        }
      ],
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: filteredData.map(item => item.date),
        axisLabel: {
          rotate: 45,
          fontSize: 14,
          color: '#666',
          margin: 16
        },
        axisLine: {
          lineStyle: {
            color: '#e0e0e0'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: ['#f5f5f5'],
            type: 'dashed'
          }
        }
      },
      yAxis: [
        {
          type: 'value',
          name: 'MVRV-Z Value',
          position: 'left',
          axisLabel: {
            fontSize: 14,
            color: '#666'
          },
          nameTextStyle: {
            fontSize: 16,
            color: '#666',
            padding: [0, 0, 0, 50]
          },
          splitLine: {
            lineStyle: {
              color: ['#f5f5f5'],
              type: 'dashed'
            }
          }
        },
        {
          type: 'value',
          name: 'BTC Price',
          position: 'right',
          axisLabel: {
            formatter: value => '$' + formatValue(value),
            fontSize: 14,
            color: '#666'
          },
          nameTextStyle: {
            fontSize: 16,
            color: '#666',
            padding: [0, 0, 0, 50]
          },
          splitLine: {
            show: false
          }
        }
      ],
      series: [
        {
          name: 'MVRV-Z Value',
          type: 'line',
          data: filteredData.map(item => item.mvrv_z),
          smooth: true,
          symbol: 'none',
          lineStyle: { 
            width: 3,
            color: '#1a73e8'
          },
          itemStyle: { 
            color: '#1a73e8',
            borderWidth: 2
          }
        },
        {
          name: 'BTC Price',
          type: 'line',
          yAxisIndex: 1,
          data: filteredData.map(item => item.btc_price),
          smooth: true,
          symbol: 'none',
          lineStyle: { 
            width: 3,
            color: '#34a853'
          },
          itemStyle: { 
            color: '#34a853',
            borderWidth: 2
          }
        }
      ]
    };
  };

  if (loading) return <div className="mvrv-z-value-loading">Loading...</div>;
  if (error) return <div className="mvrv-z-value-error">Error: {error}</div>;

  return (
    <div className="mvrv-z-value-container">
      <div className="mvrv-z-value-content">
        {renderLatestData()}
        {renderStatsTable()}
      </div>
      <div className="mvrv-z-value-chart">
        <SectionTitleBar title="Bitcoin MVRV-Z Score" />
        <div className="mvrv-z-value-time-range">
          {CHART_TIME_RANGES.map(range => (
            <button
              key={range}
              className={`mvrv-z-value-range-button ${chartTimeRange === range ? 'active' : ''}`}
              onClick={() => setChartTimeRange(range)}
            >
              {range}
            </button>
          ))}
        </div>
        <ReactECharts 
          option={getChartOption()} 
          style={{ height: '500px' }}
          notMerge={true}
          lazyUpdate={true}
        />
      </div>
      <div className="mvrv-z-value-explanation">
        <section className="mvrv-section">
          <h2>What is the MVRV Z-Score?</h2>
          <p>
            The MVRV Z-Score evaluates whether Bitcoin is overvalued or undervalued by comparing its 
            <strong> circulating market value</strong> to its <strong>realized market value</strong>, 
            standardized by the standard deviation of the circulating market value.
          </p>
        </section>

        <section className="mvrv-section">
          <h2>How is it calculated?</h2>
          <div className="mvrv-formula">
            MVRV-Z Score = (Circulating Market Value - Realized Market Value) / Standard Deviation (Circulating Market Value)
          </div>
          <ul className="mvrv-points">
            <li><strong>Circulating Market Value:</strong> Bitcoin's current market capitalization.</li>
            <li><strong>Realized Market Value:</strong> The total value of all Bitcoins based on their last movement on the blockchain.</li>
          </ul>
          <p>This metric provides a clear, standardized view of market valuation.</p>
        </section>

        <section className="mvrv-section">
          <h2>How to use it for investment decisions?</h2>
          <ul className="mvrv-points">
            <li>
              <span className="score-value red">High Z-Score (&gt;3):</span> Historically indicates 
              overvaluation, signaling potential price corrections. This is a cautionary sign to reduce 
              exposure as the market may be overheated.
            </li>
            <li>
              <span className="score-value green">Low Z-Score (&lt;1):</span> Suggests undervaluation, 
              presenting buying opportunities during market downturns. It reflects pessimistic market 
              sentiment where Bitcoin might be bargains.
            </li>
          </ul>
        </section>

        <section className="mvrv-section">
          <h2>Advantages and Limitations</h2>
          <div className="mvrv-subsection">
            <h3>Advantages:</h3>
            <ul className="mvrv-points">
              <li><strong>Valuation Measurement:</strong> Quantifies Bitcoin's market value against its realized value, providing a clear assessment of over or undervaluation.</li>
              <li><strong>Market Sentiment Indicator:</strong> Identifies extreme market emotions, whether overly optimistic or pessimistic, helping investors gauge potential turning points.</li>
              <li><strong>Standardization:</strong> Enables consistent comparisons across different time periods and market conditions, enhancing analytical reliability.</li>
              <li><strong>Historical Relevance:</strong> Aligns with historical data trends, offering insights based on past market behaviors and price movements.</li>
            </ul>
          </div>
          
          <div className="mvrv-subsection">
            <h3>Limitations:</h3>
            <ul className="mvrv-points">
              <li><strong>Ignores Fundamental Changes:</strong> Does not account for shifts in Bitcoin's fundamentals, such as technological advancements or regulatory developments.</li>
              <li><strong>Not for Short-Term Trading:</strong> Best suited for medium to long-term investment decisions rather than short-term trading strategies.</li>
              <li><strong>Potential for Market Manipulation:</strong> Susceptible to distortions if large holders manipulate circulating or realized values, impacting accuracy.</li>
              <li><strong>Data Dependency:</strong> Relies heavily on accurate blockchain data; any discrepancies can affect the score's reliability.</li>
            </ul>
          </div>
        </section>

        <p className="mvrv-conclusion">
          By leveraging the MVRV Z-Score alongside other indicators, investors can gain a comprehensive 
          and insightful view of Bitcoin's valuation, aiding in more informed and strategic investment decisions.
        </p>
      </div>
    </div>
  );
};

export default MvrvZValue; 