import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { getEarningsCalendar } from '../../../services/data_api';
import './EarningsCalendar.css';
import { format } from 'date-fns';
import Tooltip from '../../Tooltip';

const EarningsCalendar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tempStartDate, setTempStartDate] = useState(null); // 临时存储选择的开始日期
  const [tempEndDate, setTempEndDate] = useState(null); // 临时存储选择的结束日期
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [activeFilter, setActiveFilter] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const response = await getEarningsCalendar(params);
      setData(response.data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setStartDate(today);
    setEndDate(null);
    fetchData({ 
      start_date: formatDateForAPI(today),
    });
  }, []);

  const formatDateForAPI = (date) => {
    if (!date) return null;
    
    const d = new Date(date);
    if (isNaN(d.getTime())) return null;
    
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const filterDataByDateRange = (startDate, endDate) => {
    if (!startDate || !endDate) return [];
    
    return data.filter(item => {
      const reportDate = new Date(item.date);
      const start = new Date(startDate);
      const end = new Date(endDate);
      
      return reportDate >= start && reportDate <= end;
    });
  };

  const getFilteredData = () => {
    if (!startDate) {
      return data;
    } else if (startDate && !endDate) {
      return data.filter(item => {
        const reportDate = new Date(item.date);
        const start = new Date(startDate);
        return reportDate >= start;
      });
    } else {
      return data.filter(item => {
        const reportDate = new Date(item.date);
        const start = new Date(startDate);
        const end = new Date(endDate);
        return reportDate >= start && reportDate <= end;
      });
    }
  };

  const handleQuickFilter = (filter) => {
    if (activeFilter === filter) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      setStartDate(today);
      setEndDate(null);
      setActiveFilter(null);
      setShowDatePicker(false);
      fetchData({ start_date: formatDateForAPI(today) });
      return;
    }

    setActiveFilter(filter);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let start = new Date(today);
    let end = new Date(today);

    switch (filter) {
      case 'all':
        setStartDate(null);
        setEndDate(null);
        fetchData();
        break;
      case 'yesterday':
        start.setDate(today.getDate() - 1);
        end.setDate(today.getDate() - 1);
        setStartDate(start);
        setEndDate(end);
        break;
      case 'today':
        setStartDate(start);
        setEndDate(end);
        break;
      case 'tomorrow':
        start.setDate(today.getDate() + 1);
        end.setDate(today.getDate() + 1);
        setStartDate(start);
        setEndDate(end);
        break;
      case 'thisWeek':
        const thisWeekFirst = today.getDate() - today.getDay() + 1;
        const thisWeekLast = thisWeekFirst + 6;
        start = new Date(today.setDate(thisWeekFirst));
        end = new Date(today.setDate(thisWeekLast));
        setStartDate(start);
        setEndDate(end);
        break;
      case 'nextWeek':
        const nextWeekFirst = today.getDate() - today.getDay() + 8; // +8 是下周一
        const nextWeekLast = nextWeekFirst + 6;
        start = new Date(today.setDate(nextWeekFirst));
        end = new Date(today.setDate(nextWeekLast));
        setStartDate(start);
        setEndDate(end);
        break;
      default:
        return;
    }

    setShowDatePicker(false);
  };

  const handleDatePickerClick = () => {
    if (showDatePicker) {
      handleClearFilters();
    } else {
      setShowDatePicker(true);
      setActiveFilter(null);
    }
  };

  const handleApplyDateRange = () => {
    if (tempStartDate && tempEndDate) {
      setStartDate(tempStartDate);
      setEndDate(tempEndDate);
      setActiveFilter(null);
      setShowDatePicker(false);
    }
  };

  const handleClearFilters = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    setStartDate(today);
    setEndDate(null);
    setTempStartDate(null);
    setTempEndDate(null);
    setActiveFilter(null);
    setShowDatePicker(false);
    fetchData({ start_date: formatDateForAPI(today) });
  };

  const handleTickerClick = (ticker) => {
    navigate(`/long-term/large-cap/${ticker}`);
  };

  const formatCurrency = (value) => {
    if (!value) return '-';
    return `$${(value / 1e9).toFixed(2)}B`;
  };

  const formatDate = (date) => {
    if (!date) return '';
    
    const d = new Date(date);
    if (isNaN(d.getTime())) return '';
    
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  // 在组件内添加日历图标的 SVG
  const CalendarIcon = () => (
    <svg 
      width="16" 
      height="16" 
      viewBox="0 0 24 24" 
      fill="none" 
      stroke="currentColor" 
      strokeWidth="2" 
      strokeLinecap="round" 
      strokeLinejoin="round"
    >
      <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
      <line x1="16" y1="2" x2="16" y2="6"></line>
      <line x1="8" y1="2" x2="8" y2="6"></line>
      <line x1="3" y1="10" x2="21" y2="10"></line>
    </svg>
  );

  // 格式化日期显示
  const formatDisplayDate = (date) => {
    if (!date) return '';
    return date.toLocaleDateString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric' 
    });
  };

  // 获取当前筛选范围的显示文本
  const getDateRangeText = () => {
    if (activeFilter) {
      switch (activeFilter) {
        case 'all': return 'All Dates';
        case 'yesterday': return 'Yesterday';
        case 'today': return 'Today';
        case 'tomorrow': return 'Tomorrow';
        case 'thisWeek': return 'This Week';
        case 'nextWeek': return 'Next Week';
        default: return '';
      }
    } else if (startDate || endDate) {
      if (startDate && !endDate) {
        return `From ${formatDisplayDate(startDate)}`;
      }
      return `${formatDisplayDate(startDate)} - ${formatDisplayDate(endDate)}`;
    }
    return 'From Today';
  };

  // 按日期对数据进行分组
  const groupDataByDate = (data) => {
    const grouped = {};
    data.forEach(item => {
      const date = item.date;
      if (!grouped[date]) {
        grouped[date] = [];
      }
      grouped[date].push(item);
    });
    return grouped;
  };

  // 添加一个格式化大数字的函数
  const formatLargeNumber = (value) => {
    if (!value) return '-';
    
    const num = parseFloat(value);
    
    if (num >= 1e12) {
      return `${(num / 1e12).toFixed(2)}T`;
    } else if (num >= 1e9) {
      return `${(num / 1e9).toFixed(2)}B`;
    } else if (num >= 1e6) {
      return `${(num / 1e6).toFixed(2)}M`;
    } else if (num >= 1e3) {
      return `${(num / 1e3).toFixed(2)}K`;
    }
    
    return num.toFixed(2);
  };

  // 渲染表格数据
  const renderTableData = () => {
    const filteredData = getFilteredData();
    const groupedData = groupDataByDate(filteredData);
    const dates = Object.keys(groupedData).sort();

    if (dates.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="earnings-calendar-no-data">
            No earnings reports found for the selected date range
          </td>
        </tr>
      );
    }

    return dates.map(date => (
      <React.Fragment key={date}>
        <tr className="earnings-calendar-date-header">
          <td colSpan="5">
            <span className="earnings-calendar-date-text">
              {formatDisplayDate(new Date(date.replace(/-/g, '/')))}
            </span>
          </td>
        </tr>
        {groupedData[date].map((item, index) => (
          <tr key={`${item.ticker}-${index}`}>
            <td>
              <span 
                className="earnings-calendar-ticker-link"
                onClick={() => handleTickerClick(item.ticker)}
              >
                {item.ticker}
              </span>
            </td>
            <td>{item.date}</td>
            <td>{item.quarter_date}</td>
            <td>
              <div className="earnings-calendar-comparison">
                <span className="earnings-calendar-reported">{item.eps_reported || '-'}</span>
                <span className="earnings-calendar-separator">/</span>
                <span className="earnings-calendar-prime">{item.eps_prime || '-'}</span>
                <span className="earnings-calendar-separator">/</span>
                <span className="earnings-calendar-consensus">{item.eps_consensus || '-'}</span>
              </div>
            </td>
            <td>
              <div className="earnings-calendar-comparison">
                <span className="earnings-calendar-reported">
                  {formatLargeNumber(item.revenue_reported)}
                </span>
                <span className="earnings-calendar-separator">/</span>
                <span className="earnings-calendar-consensus">
                  {formatLargeNumber(item.revenue_consensus)}
                </span>
              </div>
            </td>
          </tr>
        ))}
      </React.Fragment>
    ));
  };

  // 添加月份导航函数
  const prevMonth = () => {
    setCurrentMonth(prev => {
      const newDate = new Date(prev);
      newDate.setMonth(prev.getMonth() - 1);
      return newDate;
    });
  };

  const nextMonth = () => {
    setCurrentMonth(prev => {
      const newDate = new Date(prev);
      newDate.setMonth(prev.getMonth() + 1);
      return newDate;
    });
  };

  // 修改月份标题显示
  const getMonthTitle = () => {
    if (!currentMonth) return '';
    return format(currentMonth, 'MMMM yyyy');
  };

  if (loading) {
    return <div className="earnings-calendar-loading">Loading...</div>;
  }

  if (error) {
    return <div className="earnings-calendar-error">Error: {error}</div>;
  }

  return (
    <div className="earnings-calendar-container">
      <div className="earnings-calendar-header">
        <h2 className="earnings-calendar-title">Earnings Calendar</h2>
        <div className="earnings-calendar-current-filter">
          <span className="earnings-calendar-date-range">{getDateRangeText()}</span>
        </div>
      </div>
      
      <div className="earnings-calendar-filters-container">
        <div className="earnings-calendar-quick-filters">
          <button 
            className={`earnings-calendar-quick-filter-btn ${activeFilter === 'all' ? 'active' : ''}`}
            onClick={() => handleQuickFilter('all')}
          >
            All
          </button>
          <button 
            className={`earnings-calendar-quick-filter-btn ${activeFilter === 'yesterday' ? 'active' : ''}`}
            onClick={() => handleQuickFilter('yesterday')}
          >
            Yesterday
          </button>
          <button 
            className={`earnings-calendar-quick-filter-btn ${activeFilter === 'today' ? 'active' : ''}`}
            onClick={() => handleQuickFilter('today')}
          >
            Today
          </button>
          <button 
            className={`earnings-calendar-quick-filter-btn ${activeFilter === 'tomorrow' ? 'active' : ''}`}
            onClick={() => handleQuickFilter('tomorrow')}
          >
            Tomorrow
          </button>
          <button 
            className={`earnings-calendar-quick-filter-btn ${activeFilter === 'thisWeek' ? 'active' : ''}`}
            onClick={() => handleQuickFilter('thisWeek')}
          >
            This Week
          </button>
          <button 
            className={`earnings-calendar-quick-filter-btn ${activeFilter === 'nextWeek' ? 'active' : ''}`}
            onClick={() => handleQuickFilter('nextWeek')}
          >
            Next Week
          </button>
          <div className="date-picker-container">
            <button 
              className="custom-date-range-btn"
              onClick={() => setShowDatePicker(!showDatePicker)}
            >
              <CalendarIcon />
              <span>Custom Date Range</span>
            </button>
            
            {showDatePicker && (
              <div className="date-picker-dropdown">
                <div className="date-picker-title">Custom dates</div>
                
                <div className="date-range-inputs">
                  <div className="date-input-group">
                    <label>Start Date</label>
                    <DatePicker
                      selected={tempStartDate}
                      onChange={date => setTempStartDate(date)}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select start date"
                      autoComplete="off"
                    />
                  </div>
                  
                  <div className="date-input-group">
                    <label>End Date</label>
                    <DatePicker
                      selected={tempEndDate}
                      onChange={date => setTempEndDate(date)}
                      dateFormat="yyyy/MM/dd"
                      placeholderText="Select end date"
                      minDate={tempStartDate}
                      autoComplete="off"
                    />
                  </div>
                </div>

                <div className="date-picker-actions">
                  <button 
                    className="apply-btn"
                    onClick={handleApplyDateRange}
                    disabled={!tempStartDate || !tempEndDate}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="earnings-calendar-table-container">
        <div className="earnings-calendar-table-wrapper">
          <table className="earnings-calendar-table">
            <thead>
              <tr>
                <th>Ticker</th>
                <th>Report Date</th>
                <th>Quarter End</th>
                <th>
                  <div className="earnings-calendar-th-content">
                    <Tooltip content={t('EPS_Prime – Our exclusive EPS forecast, derived from precise data sources and expert analysis for superior accuracy.\n\nEPS_Consensus – The market\'s expected earnings per share (EPS) for the company, based on analysts\' forecasts and public data.')} forceDirection="top">
                      <span>EPS</span>
                    </Tooltip>
                    <div className="earnings-calendar-th-subtitle">
                      <span className="earnings-calendar-reported">Reported</span>
                      <span className="earnings-calendar-separator">/</span>
                      <span className="earnings-calendar-prime">Prime</span>
                      <span className="earnings-calendar-separator">/</span>
                      <span className="earnings-calendar-consensus">Consensus</span>
                    </div>
                  </div>
                </th>
                <th>
                  <div className="earnings-calendar-th-content">
                    <Tooltip content={t('Revenue_Consensus – The market\'s projected revenue for the company, aggregated from analysts\' estimates and industry insights.')} forceDirection="top">
                      <span>Revenue</span>
                    </Tooltip>
                    <div className="earnings-calendar-th-subtitle">
                      <span className="earnings-calendar-reported">Reported</span>
                      <span className="earnings-calendar-separator">/</span>
                      <span className="earnings-calendar-consensus">Consensus</span>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {renderTableData()}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default EarningsCalendar; 