import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { getEtfHoldings } from '../../services/data_api';
import './ETFHoldingsChart.css';

const ETFHoldingsChart = ({ ticker }) => {
  const [holdingsData, setHoldingsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      const data = await getEtfHoldings(ticker);
      if (data) {
        setHoldingsData(data);
      } else {
        setError('Failed to load holdings data');
      }
      setLoading(false);
    };
    fetchData();
  }, [ticker]);

  const ChartTitle = () => (
    <div className="etf-vp-holdings-chart-title">
      {ticker} Holdings Composition
    </div>
  );

  const HoldingsTable = ({ data }) => (
    <div className="etf-vp-holdings-table">
      <table>
        <thead>
          <tr>
            <th>Symbol</th>
            <th>Holding</th>
            <th>Weight</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{item.symbol}</td>
              <td>{item.holding}</td>
              <td>{(item.weight * 100).toFixed(2)}%</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  if (loading) {
    return (
      <div className="etf-vp-holdings-chart">
        <ChartTitle />
        <div className="etf-vp-holdings-chart-loading">Loading holdings data...</div>
      </div>
    );
  }

  if (error || !holdingsData || !holdingsData.data || holdingsData.data.length === 0) {
    return (
      <div className="etf-vp-holdings-chart">
        <ChartTitle />
        <div className="etf-vp-holdings-chart-empty">
          <i className="fas fa-chart-pie etf-vp-holdings-chart-empty-icon"></i>
          <p className="etf-vp-holdings-chart-empty-text">
            No data available
          </p>
        </div>
      </div>
    );
  }

  const getChartOption = () => {
    const holdings = holdingsData.data;
    const topHoldings = holdings.slice(0, 10);
    const otherHoldings = holdings.slice(10);
    
    let data = topHoldings.map(item => ({
      name: item.symbol,
      value: (item.weight * 100).toFixed(2),
      fullName: item.holding
    }));

    if (otherHoldings.length > 0) {
      const otherWeight = otherHoldings.reduce((sum, item) => sum + item.weight, 0);
      data.push({
        name: 'Others',
        value: (otherWeight * 100).toFixed(2)
      });
    }

    return {
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          if (params.data.fullName) {
            return `${params.data.name} - ${params.data.fullName}<br/>Weight: ${params.value}%`;
          }
          return `${params.name}: ${params.value}%`;
        }
      },
      legend: {
        orient: 'horizontal',
        top: 0,
        left: 'center',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          fontSize: 12,
          color: '#666'
        }
      },
      series: [
        {
          name: 'Holdings',
          type: 'pie',
          radius: ['40%', '70%'],
          center: ['50%', '55%'],
          avoidLabelOverlap: true,
          itemStyle: {
            borderRadius: 4,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false
          },
          emphasis: {
            label: {
              show: true,
              fontSize: 14,
              fontWeight: 'bold'
            }
          },
          data: data
        }
      ]
    };
  };

  return (
    <div className="etf-vp-holdings-chart">
      <ChartTitle />
      <div className="etf-vp-holdings-content">
        <div className="etf-vp-holdings-chart-container">
          <ReactECharts 
            option={getChartOption()}
            style={{ height: '400px' }}
            opts={{ renderer: 'svg' }}
          />
        </div>
        <HoldingsTable data={holdingsData.data} />
      </div>
    </div>
  );
};

export default ETFHoldingsChart; 