import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StockOverview from './StockDetail/StockOverview';
import StockPremium from './StockDetail/StockPremium';
import StockGrowth from './StockDetail/StockGrowth';
import StockProfitability from './StockDetail/StockProfitability';
import StockMomentum from './StockDetail/StockMomentum';
import StockRisk from './StockDetail/StockRisk';
import StockSentiment from './StockDetail/StockSentiment';
import { 
  getLongTermStockOverview, 
  getLongTermStockPriceReturn, 
  getLongTermStockScreenerDetails,
  getLongTermStockHistory,
  getLongTermStockEPS,  // 新增
  getLongTermStockGrowthRates,
  getStockRevenueEpsHistory,
  getRawStockProfitability,
  getRawStockPremium,
  getLongTermStockRisk,
  getLongTermStockRecommendation
} from '../../services/data_api';
import StockRecommendation from './StockDetail/StockRecommendation';

const LongTermDetails = () => {
  const { category, ticker } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  const [stockData, setStockData] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const [screenerData, setScreenerData] = useState(null);
  const [historyData, setHistoryData] = useState(null);
  const [epsData, setEpsData] = useState(null);
  const [growthRatesData, setGrowthRatesData] = useState({
    yoy: {},
    quarterly: {}
  });
  const [revenueEpsHistoryData, setRevenueEpsHistoryData] = useState(null);
  const [profitabilityData, setProfitabilityData] = useState(null);
  const [premiumMetricsData, setPremiumMetricsData] = useState(null);
  const [riskData, setRiskData] = useState(null);
  const [recommendationData, setRecommendationData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [activeTab, setActiveTab] = useState('overview');

  // 为每个tab添加单独的loading状态
  const [loadingStates, setLoadingStates] = useState({
    overview: true,
    price: true,
    screener: true,
    history: true,
    eps: true,
    growthRates: true,
    revenueEpsHistory: true,
    profitability: true,
    risk: true
  });

  // 添加一个新的 state 来追踪路由变化
  const [isRouteChanging, setIsRouteChanging] = useState(false);

  useEffect(() => {
    // 当 ticker 改变时，标记路由正在变化
    setIsRouteChanging(true);
    setLoading(true);
    setErrors({});
    
    const fetchData = async () => {
      if (!ticker) {
        setIsRouteChanging(false);
        return;
      }

      // 重置所有状态
      setLoadingStates(prev => Object.keys(prev).reduce((acc, key) => ({ ...acc, [key]: true }), {}));

      const updateLoadingState = (key, isLoading) => {
        setLoadingStates(prev => ({ ...prev, [key]: isLoading }));
      };

      const fetchWithErrorHandling = async (key, fetchFn, setDataFn) => {
        try {
          const data = await fetchFn(ticker);
          if (data) {
            setDataFn(data);
          }
        } catch (err) {
          console.error(`Error fetching ${key}:`, err);
          setErrors(prev => ({ ...prev, [key]: err.message }));
        } finally {
          updateLoadingState(key, false);
        }
      };

      // 并行执行所有请求
      await Promise.allSettled([
        fetchWithErrorHandling('overview', getLongTermStockOverview, setStockData),
        fetchWithErrorHandling('price', getLongTermStockPriceReturn, setPriceData),
        fetchWithErrorHandling('screener', getLongTermStockScreenerDetails, setScreenerData),
        fetchWithErrorHandling('history', getLongTermStockHistory, data => {
          if (data?.data) {
            setHistoryData({
              data: data.data.map(item => ({
                date: item.date,
                close: Number(item.close),
                volume: Number(item.volume)
              }))
            });
          }
        }),
        fetchWithErrorHandling('eps', getLongTermStockEPS, setEpsData),
        fetchWithErrorHandling('growthRates', getLongTermStockGrowthRates, data => {
          setGrowthRatesData(data || { yoy: {}, quarterly: {} });
        }),
        fetchWithErrorHandling('revenueEpsHistory', getStockRevenueEpsHistory, setRevenueEpsHistoryData),
        fetchWithErrorHandling('profitability', getRawStockProfitability, setProfitabilityData),
        fetchWithErrorHandling('premiumMetrics', getRawStockPremium, setPremiumMetricsData),
        fetchWithErrorHandling('risk', getLongTermStockRisk, setRiskData),
        fetchWithErrorHandling('recommendation', getLongTermStockRecommendation, setRecommendationData)
      ]);

      setLoading(false);
      setIsRouteChanging(false); // 数据加载完成后重置路由变化状态
    };

    fetchData();
  }, [ticker]);

  const renderTabContent = () => {
    // 如果路由正在变化或者正在加载数据，显示加载状态
    if (isRouteChanging || loading) {
      return (
        <div className="stock-detail-loading">
          <div className="loading-spinner"></div>
          <span>Loading data...</span>
        </div>
      );
    }

    // 检查当前 tab 所需的数据是否都已加载完成
    const isTabDataLoading = () => {
      switch (activeTab) {
        case 'overview':
          return loadingStates.overview || loadingStates.price || loadingStates.screener || loadingStates.history;
        case 'premium':
          return loadingStates.screener;
        case 'growth':
          return loadingStates.screener || loadingStates.eps || loadingStates.growthRates || loadingStates.revenueEpsHistory;
        case 'profitability':
          return loadingStates.screener || loadingStates.profitability;
        case 'risk':
          return loadingStates.risk;
        case 'recommendation':
          return loadingStates.recommendation;
        default:
          return false;
      }
    };

    if (isTabDataLoading()) {
      return <div className="stock-detail-loading">Loading tab data...</div>;
    }

    switch (activeTab) {
      case 'overview':
        return <StockOverview
          data={stockData}
          priceData={priceData}
          screenerData={screenerData}
          historyData={historyData}
          errors={errors}
        />;
      case 'premium':
        return (
          <StockPremium 
            screenerData={screenerData}
            premiumMetricsData={premiumMetricsData?.data?.[0]}
            key={`${ticker}-${activeTab}`}
          />
        );
      case 'growth':
        // 即使有错误，也渲染 StockGrowth 组件
        const growthErrors = {
          noGrowthData: errors.growthRates,
          noHistoryData: errors.revenueEpsHistory
        };
        
        return <StockGrowth 
          screenerData={screenerData} 
          epsData={epsData} 
          growthData={growthRatesData}
          historyData={revenueEpsHistoryData}
          ticker={ticker}
          errors={growthErrors}
        />;
      case 'profitability':
        return (
          <StockProfitability 
            screenerData={screenerData}
            profitabilityData={profitabilityData?.data}
            key={`${ticker}-${activeTab}`}
          />
        );
      case 'momentum':
        return <StockMomentum data={stockData} />;
      case 'risk':
        return <StockRisk data={riskData} />;
      case 'sentiment':
        return <StockSentiment data={stockData} />;
      case 'recommendation':
        return <StockRecommendation data={recommendationData} />;
      default:
        return null;
    }
  };

  // 修改 shouldShowError 函数
  const shouldShowError = () => {
    // 对于 growth 标签页，不显示错误，让 StockGrowth 组件自己处理
    if (activeTab === 'growth') {
      return false; // 始终返回 false，让 StockGrowth 组件自己处理错误
    }
    
    // 其他标签页保持原有逻辑
    const currentTabErrors = Object.entries(errors)
      .filter(([key]) => {
        switch (activeTab) {
          case 'overview':
            return ['overview', 'price', 'screener', 'history'].includes(key);
          case 'premium':
            return ['screener'].includes(key);
          case 'profitability':
            return ['screener', 'profitability'].includes(key);
          case 'risk':
            return ['risk'].includes(key);
          case 'recommendation':
            return ['recommendation'].includes(key);
          default:
            return false;
        }
      })
      .map(([, value]) => value);

    return currentTabErrors.length > 0;
  };

  if (shouldShowError()) {
    return (
      <div className="stock-detail-error">
        Error loading data: {Object.values(errors).join(', ')}
      </div>
    );
  }

  return (
    <div className="stock-detail-container">
      <header className="stock-detail-header">
        <h1 className="stock-detail-title">
          {stockData?.name} ({ticker})
        </h1>
      </header>

      <nav className="stock-detail-tabs">
        <button
          className={`stock-detail-tab-button ${activeTab === 'overview' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button
          className={`stock-detail-tab-button ${activeTab === 'premium' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('premium')}
        >
          Premium
        </button>
        <button
          className={`stock-detail-tab-button ${activeTab === 'growth' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('growth')}
        >
          Growth
        </button>
        <button
          className={`stock-detail-tab-button ${activeTab === 'profitability' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('profitability')}
        >
          Profitability
        </button>
        <button
          className={`stock-detail-tab-button ${activeTab === 'risk' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('risk')}
        >
          Risk
        </button>
        <button
          className={`stock-detail-tab-button ${activeTab === 'recommendation' ? 'stock-detail-tab-active' : ''}`}
          onClick={() => setActiveTab('recommendation')}
        >
          Recommendation
        </button>
      </nav>

      {renderTabContent()}
    </div>
  );
};

export default LongTermDetails;  