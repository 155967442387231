import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PageLayout from './PageLayout';
import './ProductsPage.css';
import FearGreedMeter from './FearGreedMeter';
import { getBtcOverview, getStockOverview, getStockScreenerSingle, getTopPicks, getFirstTopPick, getOtherTopPicks, getStockPriceReturn } from '../services/data_api';
import Tooltip from './Tooltip';
import QASection from './QASection';
import Overview from './Overview';
import MarketCap from './MarketCap';
import EtfNetInflow from './EtfNetInflow';
import FearGreedIndex from './FearGreedIndex';
import { StockComponents, StockScreener } from './btc-stocks';
import { STOCK_LIST } from '../config/stocks';
import { useParams, Outlet, useLocation, useNavigate, Link } from 'react-router-dom';
import { MARKET_CAP_CATEGORIES } from '../config/marketCategories';
import { useSelector } from 'react-redux';
import ProtectedRoute from './ProtectedRoute';
import {
  getBtcPriceReturn, 
  getTopAssetsDetails, 
  getTechnicalSeasonality 
} from '../services/data_api';
import SectionTitleBar from './common/SectionTitleBar';
import ComingSoon from './btc-etf/ComingSoon';
import MvrvZValue from './MvrvZValue';
import etfData from './ETF/etf-data.json';
import ETFScreener from './ETF/ETFScreener';
import ETFDetail from './ETF/ETFDetail';
import { Helmet } from 'react-helmet-async';
import TopETFs from './TopETFs/TopETFs';

const etfList = etfData.etfs;

const isETFTicker = (ticker) => {
  return etfList.some(etf => etf.ETF.toLowerCase() === ticker.toLowerCase());
};

const InfoCard = ({ imageUrl, title, bulletPoints, onClick }) => {
  const { t } = useTranslation();
  
  return (
    <div className="info-card" onClick={onClick}>
      <div className="card-image-container">
        <img src={imageUrl} alt="" className="card-image" />
      </div>
      <div className="card-content">
        <h3 className="card-title">{title}</h3>
        <ul className="bullet-points">
          {bulletPoints.map((point, index) => (
            <li key={index} className="bullet-point">{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Modal = ({ isOpen, onClose, type }) => {
  if (!isOpen) return null;

  const renderContent = () => {
    switch (type) {
      case 'btc':
        return (
          <iframe 
            className="modal-iframe"
            src="/Education Article/Education Article/education article.html"
            title="Why BTC"
            allowFullScreen
          />
        );
      case 'faq':
        return (
          <div className="modal-qa">
            <QASection />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        {renderContent()}
      </div>
    </div>
  );
};

const ProductsPage = ({ defaultContent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
  
  const isSubscriptionValid = () => {
    try {
      const subscriptionData = localStorage.getItem('subscription');
      if (!subscriptionData) return false;

      const subscription = JSON.parse(subscriptionData);
      if (!subscription.data) return false;
      
      return (
        subscription.data.subscription_status === 'active' && 
        new Date(subscription.data.current_period_end) > new Date()
      );
    } catch (error) {
      console.error('Error checking subscription:', error);
      return false;
    }
  };

  const checkAccess = (pageType) => {
    if (pageType === 'etf') {
      return true;
    }
    
    if (pageType === 'pro') {
      if (!isLoggedIn) {
        navigate('/login', { state: { from: location } });
        return false;
      }
      if (!isSubscriptionValid()) {
        navigate('/pricing', { state: { from: location } });
        return false;
      }
      return true;
    }
    if (pageType === 'login') {
      if (!isLoggedIn) {
        navigate('/login', { state: { from: location } });
        return false;
      }
      return true;
    }
    return true;
  };

  const handleNavigation = (pageId) => {
    // 首先处理 overview/dashboard 的导航
    if (pageId === 'overview') {
      setActiveCategory('overview');
      // 重置展开的项目，但保留基本项
      setExpandedItems(['btc', 'btc-related-stocks', 'btc-related-etfs']);
      navigate('/products/btc');
      return;
    }

    if (pageId === 'etf-screener') {
      setActiveCategory('etf-screener');
      navigate('/products/btc/etf-screener');
      return;
    }

    const etfCategory = findEtfCategory(pageId);
    if (etfCategory) {
      navigate(`/products/etfs/${etfCategory}/${pageId.toLowerCase()}`);
      return;
    }

    if (pageId === 'seasonality') {
      navigate('/products/btc/btc-overview#seasonality');
      setActiveCategory('btc-overview');
      return;
    }

    if (pageId === 'etf-overview') {
      setActiveCategory(pageId);
      setExpandedItems(prev => {
        if (!prev.includes('btc-related-etfs')) {
          return [...prev, 'btc-related-etfs'];
        }
        return prev;
      });
      navigate('/products/btc-etf');
      return;
    }

    const pageType = getPageType(pageId);
    
    // 检查登录权限
    if (!isLoggedIn && (pageType === 'login' || pageType === 'pro')) {
      navigate('/login', { 
        state: { 
          from: location.pathname,
          redirectAfterLogin: true 
        } 
      });
      return;
    }
    
    // 检查 PRO 权限，但排除 stock-screener
    if (pageType === 'pro' && pageId !== 'stock-screener') {
      if (!isSubscriptionValid()) {
        navigate('/pricing', { 
          state: { 
            from: location.pathname,
            redirectAfterPurchase: true 
          } 
        });
        return;
      }
    }
    
    setActiveCategory(pageId);
    switch (pageId) {
      case 'stock-screener':
        navigate('/products/btc/stock-screener');
        break;
      case 'overview':
        navigate('/products/btc');
        break;
      case 'btc-overview':
        navigate('/products/btc/btc-overview');
        break;
      case 'market-cap':
        navigate('/products/btc/market-cap');
        break;
      case 'etf-net-inflow':
        navigate('/products/btc/etf-net-inflow');
        break;
      case 'fear-greed-index':
        navigate('/products/btc/fear-greed-index');
        break;
      case 'mvrv-z-value':
        navigate('/products/btc/mvrv-z-value');
        break;
      case 'long-term-overview':
        navigate('/products/btc/long-term-overview');
        break;
      case 'etf-overview':
        navigate('/products/btc-etf');
        break;
      case 'etf-screener':
        navigate('/products/btc/etf-screener');
        break;
      default:
        // 检查是否是 ETF ticker
        if (isETFTicker(pageId)) {
          navigate(`/products/etfs/${pageId.toUpperCase()}`);
        } else {
          navigate('/products/btc');
        }
        break;
    }
  };

  const handleStockClick = (stock, category) => {
    // 检查是否是ETF类别
    if (category.includes('etfs')) {
      setActiveCategory(stock.toLowerCase());
      navigate(`/products/btc/etf/${stock.toUpperCase()}`);
      return;
    }

    // 直接导航到股票详情页
    setActiveCategory(stock.toLowerCase());
    navigate(`/products/stocks/${category}/${stock}`);
  };

  const { t } = useTranslation();
  const { ticker } = useParams();
  const [activeCategory, setActiveCategory] = useState(() => {
    const saved = localStorage.getItem('activeCategory');
    localStorage.removeItem('activeCategory');
    return saved || ticker?.toLowerCase() || 'overview';
  });
  const [expandedItems, setExpandedItems] = useState(() => {
    const saved = localStorage.getItem('expandedItems');
    localStorage.removeItem('expandedItems');
    return saved ? JSON.parse(saved) : ['btc', 'btc-related-stocks', 'btc-related-etfs'];
  });
  const [btcData, setBtcData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [previewData, setPreviewData] = useState([]);
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  useEffect(() => {
    const fetchBtcData = async () => {
      try {
        const [priceResponse, assetsResponse, technicalResponse] = await Promise.all([
          getBtcPriceReturn(),
          getTopAssetsDetails(),
          getTechnicalSeasonality()
        ]);

        const bitcoinAsset = assetsResponse.data.find(asset => asset.name === 'Bitcoin');
        const technicalData = technicalResponse.data[0];
        
        const combinedData = {
          price: priceResponse.data[0].current_price.toFixed(3),
          daily_return: priceResponse.data[0].return_to_previous.toFixed(2) + '%',
          market_cap_T: (bitcoinAsset.market_cap_value / 1000).toFixed(2) + 'T',
          mvrv_z_score: technicalData.MVRV_Z_Score,
          current_month: technicalData.Month,
          monthly_mean: (technicalData.Month_Return_Mean * 100).toFixed(2) + '%',
          fear_greed_index: technicalData.Greed_Fear_Index,
          us_fear_greed_index: technicalData.US_Greed_Fear_Index,
          total_score: technicalData.Total_Score
        };

        setBtcData(combinedData);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching BTC data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBtcData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getTopPicks();
        const priceReturnPromises = response.data.map(stock => 
          getStockPriceReturn(stock.ticker)
            .then(priceData => ({
              ...stock,
              price_return: priceData
            }))
            .catch(() => stock)
        );
        const enrichedData = await Promise.all(priceReturnPromises);
        setPreviewData(enrichedData);
      } catch (error) {
        console.error('Error fetching preview data:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigationItems = [
    { 
      id: 'overview',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <span style={{ fontWeight: 600 }}>BTC Dashboard</span>
          <span className="feature-tag free-tag">FREE</span>
        </div>
      ),
      type: 'page'
    },
    {
      id: 'btc',
      label: 'BTC Briefing',
      type: 'group',
      children: [
        { 
          id: 'btc-overview', 
          label: (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <span>Overview</span>
            </div>
          )
        },
        { 
          id: 'market-cap', 
          label: (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <span>Market Cap</span>
            </div>
          )
        },
        { 
          id: 'etf-net-inflow', 
          label: (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <span>ETF Net Inflow</span>
            </div>
          )
        },
        {
          id: 'fear-greed-index',
          label: (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <span>Fear & Greed Index</span>
            </div>
          )
        },
        { 
          id: 'mvrv-z-value', 
          label: (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <span>MVRV-Z Score</span>
            </div>
          )
        }
      ]
    },
    {
      id: 'btc-related-stocks',
      label: (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <span>BTC-related stocks</span>
          <span className="feature-tag free-tag">FREE</span>
        </div>
      ),
      type: 'group',
      children: [
        { 
          id: 'stock-screener', 
          label: 'Stock Screener',
          type: 'free'
        },
        { 
          id: 'small-cap',
          label: 'Small Market Cap',
          type: 'expandable',
          stocks: [
            'BYON', 'SMLR', 'FRMO', 'CAN', 'HIVE', 'EXOD', 'BTBT', 'BITF', 'BTDR',
            'HUT', 'IREN', 'CIFR', 'WULF', 'CLSK', 'CORZ', 'RIOT', 'MARA'
          ]
        },
        { 
          id: 'medium-cap',
          label: 'Medium Market Cap',
          type: 'expandable',
          stocks: [
            'GLOB', 'SOFI', 'RDDT', 'HOOD', 'SQ', 'MSTR', 'COIN', 'CME', 'PYPL'
          ]
        },
        { 
          id: 'large-cap',
          label: 'Large Market Cap',
          type: 'expandable',
          stocks: [
            'SHOP', 'MELI', 'BLK', 'AMD', 'MA', 'V', 'TSLA', 'META', 'NVDA'
          ]
        }
      ]
    },
    {
      id: 'btc-related-etfs',
      label: 'BTC-related ETFs',
      type: 'group',
      children: [
        { 
          id: 'etf-screener', 
          label: 'ETF Screener',
          type: 'free'
        },
        {
          id: 'spot-etfs',
          label: 'Spot ETFs',
          type: 'expandable',
          stocks: etfList
            .filter(etf => etf.Type === 'Spot')
            .map(etf => etf.ETF)
        },
        {
          id: 'futures-etfs',
          label: 'Futures ETFs',
          type: 'expandable',
          stocks: etfList
            .filter(etf => etf.Type === 'Futures')
            .map(etf => etf.ETF)
        },
        {
          id: 'equity-etfs',
          label: 'Equity ETFs',
          type: 'expandable',
          stocks: etfList
            .filter(etf => etf.Type === 'Equity')
            .map(etf => etf.ETF)
        }
      ]
    }
  ];

  const toggleExpand = (itemId) => {
    // 移除所有权限检查，直接展开/收起
    setExpandedItems(prev => 
      prev.includes(itemId) 
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId]
    );
  };

  const getReturnColor = (returnStr) => {
    const value = parseFloat(returnStr.replace(/[^-\d.]/g, ''));
    
    if (value >= 3) return '#1B5E20';      // 深绿色
    if (value >= 1) return '#2E7D32';      // 浅绿色
    if (value > -1) return '#F57F17';      // 黄色
    if (value > -3) return '#D32F2F';      // 浅色
    return '#B71C1C';                      // 深色
  };

  const getMVRVColor = (value) => {
    if (value <= 1) return '#2E7D32';      // 绿色
    if (value <= 3) return '#F57F17';      // 深黄色
    return '#D32F2F';                      // 红色
  };

  const getMarketAssessment = (totalScore) => {
    if (totalScore > 3.5) {
      return (
        <p>
          According to our indicators, the BTC market shows{' '}
          <span className="highlight-high-potential">significant upside potential</span>
          {' '}with relatively low risk.
        </p>
      );
    } else if (totalScore > 2) {
      return (
        <p>
          According to our indicators, the BTC market has{' '}
          <span className="highlight-moderate">moderate potential and risk</span>.
        </p>
      );
    } else {
      return (
        <p>
          According to our indicators, the BTC market presents a{' '}
          <span className="highlight-high-risk">high downward risk</span>
          {' '}with limited upward potential.
        </p>
      );
    }
  };

  // 修改 getDescription 函数
  const getDescription = (type, grade) => {
    const descriptions = {
      bci: {
        'A': 'Very Strong',
        'B': 'Strong',
        'C': 'Medium',
        'D': 'Weak',
        'E': 'Very Weak'
      },
      vp: {
        'A': 'Very Low',
        'B': 'Low',
        'C': 'Medium',
        'D': 'High',
        'E': 'Very High'
      },
      risk: {
        'A': 'Very Low',
        'B': 'Low',
        'C': 'Medium',
        'D': 'High',
        'E': 'Very High'
      },
      sentiment: {
        'A': 'Very Bullish',
        'B': 'Bullish',
        'C': 'Neutral',
        'D': 'Bearish',
        'E': 'Very Bearish'
      },
      recommendation: {  // 添加 recommendation 映射
        'A': 'Strong Buy',
        'B': 'Buy',
        'C': 'Hold',
        'D': 'Sell',
        'E': 'Strong Sell'
      }
    };

    return descriptions[type]?.[grade] || grade;
  };

  // 补充完整的 renderGrade 函数
  const renderGrade = (grade, type) => {
    if (!grade) return '-';
    
    const getGradeColor = (grade, type) => {
      if (type === 'risk' || type === 'vp') {
        const riskColors = {
          'A': '#1B5E20', // very low - 深绿色
          'B': '#388E3C', // low - 绿色
          'C': '#FDD835', // medium - 黄色
          'D': '#F57C00', // high - 橙色
          'E': '#D32F2F'  // very high - 深红色
        };
        return riskColors[grade] || '#ddd';
      }
      
      const colors = {
        'A': '#388E3C',
        'B': '#388E3C',
        'C': '#FDD835', 
        'D': '#F57C00',
        'E': '#D32F2F'
      };
      return colors[grade] || '#ddd';
    };

    return (
      <div className="preview-grade-container">
        {['bci', 'sentiment', 'recommendation'].includes(type) && (
          <>
            <div className="preview-horizontal-indicator">
              <div className="preview-marker" style={{
                left: grade === 'A' ? '100%' : 
                      grade === 'B' ? '75%' : 
                      grade === 'C' ? '50%' : 
                      grade === 'D' ? '25%' : '0%'
              }}></div>
            </div>
            <span className="preview-grade-description">
              {getDescription(type, grade)}
            </span>
          </>
        )}
        {['vp', 'risk'].includes(type) && (
          <>
            <div className="preview-stacked-bars">
              {[...Array(5)].map((_, i) => (
                <div
                  key={i}
                  className="preview-bar"
                  style={{
                    backgroundColor: i < (
                      grade === 'E' ? 5 :
                      grade === 'D' ? 4 :
                      grade === 'C' ? 3 :
                      grade === 'B' ? 2 : 1
                    ) ? getGradeColor(grade, type) : '#ddd'
                  }}
                />
              ))}
            </div>
            <span className="preview-grade-description">
              {getDescription(type, grade)}
            </span>
          </>
        )}
      </div>
    );
  };

  const renderOverviewContent = () => {
    // 只在加载时显示 Loading
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!previewData || !btcData) return null;  // 返回 null 而不是显示 "No data available"

    const getMonthName = (monthNumber) => {
      const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
      ];
      return months[monthNumber - 1];
    };

    const topPicksDescription = (
      <div className="preview-table-description">
        Top Picks are a selection of representative Bitcoin-related stocks (featuring{' '}
        <span className="highlight">strong Correlation_BTC</span>,{' '}
        <span className="highlight">Low Premium</span>, and{' '}
        <span className="highlight">Low Risk</span>) with solid historical performance,
        strong growth potential, and manageable risks, supported by quantitative modeling,
        diversification strategies, and analysts' insights. These recommendations are
        well-suited for investors with a moderate to high-risk tolerance who aim to
        capture opportunities in the Bitcoin market. For lower-risk options or a portfolio
        tailored to specific investment styles, we recommend using our{' '}
        <span 
          className="highlight clickable-stat"
          onClick={() => handleNavigation('stock-screener')}
        >
          Stock Screener
        </span>{' '}
        tool.
      </div>
    );

    return (
      <div className="overview-content">
        <div className="btc-header">
          <div className="btc-info">
            <div className="btc-title">
              <img src="/images/Bitcoinlogo.png" alt="BTC" className="btc-logo" />
              <h1>Bitcoin. (BTC)</h1>
            </div>
            <div 
              className="price-info seasonality"
              onClick={() => handleNavigation('btc-overview')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNavigation('btc-overview');
                }
              }}
            >
              <span className="label">{t('currentPrice')}:</span>
              <span className="value">${btcData.price}</span>
              <span 
                className="change"
                style={{ color: getReturnColor(btcData.daily_return) }}
              >
                ({btcData.daily_return})
              </span>
            </div>
            <div 
              className="market-cap seasonality"
              onClick={() => handleNavigation('market-cap')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNavigation('market-cap');
                }
              }}
            >
              <div className="label-container">
                <span className="label">Market Cap: </span>
                <span className="value">${btcData.market_cap_T}</span>
                <Tooltip content={t('tooltips.marketCap.btc')} className="tooltip-right" />
              </div>
            </div>
            <div 
              className="mvrv-z seasonality"
              onClick={() => handleNavigation('mvrv-z-value')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNavigation('mvrv-z-value');
                }
              }}
            >
              <div className="label-container">
                <span className="label">MVRV-Z Score: </span>
                <span className="value" style={{ color: getMVRVColor(btcData.mvrv_z_score) }}>
                  {btcData.mvrv_z_score}
                </span>
                <Tooltip content={t('tooltips.mrvzScore')} className="tooltip-right" />
              </div>
            </div>
            <div 
              className="seasonality" 
              onClick={() => handleNavigation('seasonality')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNavigation('seasonality');
                }
              }}
            >
              <div className="label-container">
                <span className="label">Seasonality: {getMonthName(btcData.current_month)}</span>
                <span className="value">
                  <span className="value-tag" style={{ backgroundColor: '#388E3C', color: '#fff' }}>
                    {btcData.monthly_mean}
                  </span>
                </span>
                <Tooltip content={t('tooltips.seasonality')} className="tooltip-right" />
              </div>
            </div>
            <div className="risk-assessment">
              {getMarketAssessment(btcData.total_score)}
            </div>
          </div>
          <div className="fear-greed-meters-container">
            <div 
              onClick={() => handleNavigation('fear-greed-index')}
              role="button"
              tabIndex={0}
              onKeyPress={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  handleNavigation('fear-greed-index');
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <FearGreedMeter value={btcData.fear_greed_index} title="BTC" />
            </div>
            <FearGreedMeter value={btcData.us_fear_greed_index} title="US Market" />
          </div>
        </div>

        <div className="info-cards">
          <InfoCard
            imageUrl="/images/WhyBTC.jpg"
            title="Top 8 Bitcoin-Related Stocks in 2025: A Safer Way to Capitalize on the Bitcoin Boom"
            bulletPoints={[
              "The History and Performance of Bitcoin",
              "Bitcoin in 2025: What Lies Ahead?",
              "Why Invest in Bitcoin-Related Stocks?",
              "Top 8 Bitcoin-Related Stocks in 2025"
            ]}
            onClick={() => handleCardClick('btc')}
          />
          
          <div className="preview-table-container">
            <SectionTitleBar title="Top Picks" id="top-picks" />
            {topPicksDescription}
            <div className="preview-scroll-container">
              <div className="preview-table-wrapper">
                <table className="preview-table">
                  <thead>
                    <tr>
                      {columns.map(column => (
                        <th 
                          key={column.key}
                          className={`preview-align-${column.align}`}
                        >
                          <div className="th-content">
                            {column.tooltip ? (
                              <Tooltip content={t(column.tooltip)} forceDirection="top">
                                <span>{column.label}</span>
                              </Tooltip>
                            ) : (
                              <span>{column.label}</span>
                            )}
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {previewData?.map((stock, index) => (
                      <tr key={stock.ticker || index}>
                        {columns.map(column => (
                          <td 
                            key={column.key}
                            className={`preview-align-${column.align}`}
                          >
                            {renderCell(stock[column.key], stock, column, index)}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="explore-button-container">
              <button className="explore-button" onClick={handleShowMore}>
                <span className="explore-button-title">
                  Explore All Stocks
                </span>
                <span className="explore-button-description">
                  See the full list of BTC-related stocks and access advanced filtering options
                </span>
              </button>
            </div>
          </div>

          <TopETFs />

          <InfoCard
            imageUrl="/images/QA.jpg"
            title={t('qa.title')}
            bulletPoints={[
              t('qa.questions.1.q'),
              t('qa.questions.2.q'),
              t('qa.questions.3.q'),
              '...'
            ]}
            onClick={() => handleCardClick('faq')}
          />
        </div>
      </div>
    );
  };

  const renderContent = () => {
    // 如果数据正在加载，返回加载状态
    if (loading) {
      return <div className="loading-state">Loading...</div>;
    }

    // 如果有错误，返回错误状态
    if (error) {
      return <div className="error-state">{error}</div>;
    }

    // 获取当前路径
    const currentPath = location.pathname;

    // 检查是否是 ETF Screener 页面
    if (currentPath === '/products/btc/etf-screener' || activeCategory === 'etf-screener') {
      return <ETFScreener />;
    }

    // 检查是否是 ETF 详情页面
    if (currentPath.includes('/products/btc/etf/')) {
      return <ETFDetail />;
    }

    // 首先检查是否应该显示概览页面
    if (currentPath === '/products/btc' || activeCategory === 'overview') {
      return btcData ? renderOverviewContent() : null;
    }

    // 然后检查其他路径
    if (currentPath === '/products/btc/etf-screener') {
      return (
        <div className="products-content">
          <ETFScreener />
        </div>
      );
    }
    
    if (currentPath.includes('/products/btc/etf/')) {
      return (
        <div className="products-content">
          <ETFDetail />
        </div>
      );
    }

    // 如果是主概览页面
    if (defaultContent === 'overview' || activeCategory === 'overview') {
      return btcData ? renderOverviewContent() : null;
    }

    // 如果是 stock-screener 页面
    if (currentPath === '/products/btc/stock-screener') {
      return <Outlet />;
    }

    // 如果是股票页面
    if (STOCK_LIST.includes(activeCategory.toUpperCase())) {
      const StockComponent = StockComponents[activeCategory.toUpperCase()];
      return <StockComponent />;
    }

    // 其他页面渲染逻辑
    switch (activeCategory) {
      case 'stock-screener':
        return <StockScreener />;
      case 'btc-overview':
        return btcData ? <Overview /> : null;
      case 'market-cap':
        return <MarketCap />;
      case 'fear-greed-index':
        return <FearGreedIndex />;
      case 'mvrv-z-value':
        return <MvrvZValue />;
      case 'etf-net-inflow':
        return <EtfNetInflow />;

      case 'etf-overview':
        return <ComingSoon />;
      default:
        return null;
    }
  };

  const handleCardClick = (type) => {
    if (type === 'btc') {
      navigate('/insights/educational/top-8-bitcoin-stocks');
    } else {
      setModalType(type);
      setModalOpen(true);
    }
  };

  // 添加一辅助函数来断页面类型
  const getPageType = (pageId) => {
    const freePages = [
      'overview',
      'long-term-overview',
      'etf-screener',
      'etf-analytics',
      'btc-overview',
      'market-cap',
      'etf-net-inflow',
      'fear-greed-index',
      'mvrv-z-value',
      'small-cap',
      'medium-cap',
      'large-cap',
      'stock-screener'  // 添加 stock-screener
    ];
    
    if (freePages.includes(pageId)) return 'free';
    return null;
  };

  // 在组件加载和 URL 参数变化时更新状态
  useEffect(() => {
    if (ticker) {
      const category = findStockCategory(ticker);
      if (category) {
        setExpandedItems(prev => {
          const newItems = new Set([...prev, 'btc-related', category]);
          return Array.from(newItems);
        });
      }
      setActiveCategory(ticker.toLowerCase());
    }
  }, [ticker]);

  // 添加一个函来处理股票分类
  const findStockCategory = (ticker) => {
    if (!ticker) return null;
    const upperTicker = ticker.toUpperCase();
    if (MARKET_CAP_CATEGORIES.SMALL.stocks.includes(upperTicker)) {
      return 'small-cap';
    } else if (MARKET_CAP_CATEGORIES.MEDIUM.stocks.includes(upperTicker)) {
      return 'medium-cap';
    } else if (MARKET_CAP_CATEGORIES.LARGE.stocks.includes(upperTicker)) {
      return 'large-cap';
    }
    return null;
  };

  // 修改 handleShowMore 函数
  const handleShowMore = () => {
    try {
      // 只检查是否已登录
      if (!isLoggedIn) {
        navigate('/login', { 
          state: { 
            from: location.pathname,
            redirectAfterLogin: true 
          } 
        });
        return;
      }

      // 使用与侧边栏相同的导航逻辑
      handleNavigation('stock-screener');
    } catch (error) {
      console.error('Error in handleShowMore:', error);
    }
  };

  // 定义表格列配置
  const columns = [
    { 
      key: 'ticker',
      label: 'Ticker',
      align: 'left',
      render: (value) => (
        <span className="preview-stock-link">
          {value}
        </span>
      )
    },
    { 
      key: 'name', 
      label: 'Name', 
      align: 'left' 
    },
    {
      key: 'price_return',
      label: 'Current Price (Return)',
      align: 'right',
      render: (value, row) => {
        if (!value || row.isBlurred) {
          return (
            <div className="stock-detail-price-info blur-content">
              <span>${value?.current_price || '156.78'}</span>
              <span className="preview-positive">
                +2.45%
              </span>
            </div>
          );
        }
        const { current_price, current_return } = value;
        return (
          <div className="stock-detail-price-info">
            <span>${current_price.toFixed(2)}</span>
            <span className={`${current_return >= 0 ? 'preview-positive' : 'preview-negative'}`}>
              {`${current_return >= 0 ? '+' : ''}${current_return.toFixed(2)}%`}
            </span>
          </div>
        );
      }
    },
    { 
      key: 'recent_performance',
      label: 'Recent Performance',
      align: 'center',
      tooltip: 'tooltips.recentPerformance',
      render: (value, row) => {
        if (row.isBlurred) {
          return row.performance_range || '-';  // 显示区间数据
        }
        if (typeof value === 'number') {
          return (
            <span className={value >= 0 ? 'preview-positive' : 'preview-negative'}>
              {(value * 100).toFixed(2)}%
            </span>
          );
        }
        return '-';
      }
    },
    { 
      key: 'market_cap_category',
      label: 'Market Cap (Billion)',
      align: 'right',
      render: (value) => {
        const categoryMap = {
          'S': 'Small',
          'M': 'Medium',
          'L': 'Large'
        };
        return categoryMap[value] || '-';
      }
    },
    {
      key: 'bci',
      label: 'Correlation_BTC',
      align: 'center',
      tooltip: 'tooltips.bci',
      render: (value) => renderGrade(value, 'bci')
    },
    {
      key: 'vp',
      label: 'Premium',
      align: 'center',
      tooltip: 'tooltips.vp',
      render: (value) => renderGrade(value, 'vp')
    },
    {
      key: 'risk',
      label: 'Risk',
      align: 'center',
      tooltip: 'tooltips.risk',
      render: (value) => renderGrade(value, 'risk')
    },
    {
      key: 'sentiment_rank',
      label: 'Sentiment',
      align: 'center',
      tooltip: 'tooltips.sentiment',
      render: (value) => renderGrade(value, 'sentiment')
    },
    {
      key: 'recommendation',
      label: 'Recommendation',
      align: 'center',
      tooltip: 'tooltips.recommendation',
      render: (value) => renderGrade(value, 'recommendation')
    }
  ];

  // 示例数据
  const mockData = [{
    ticker: "RIOT",
    name: "Riot Platforms, Inc.",
    sector: "Financial Services",
    previous_close: 14.32,  // 确保是数类型
    recent_performance: 0.7475862174198543,  // 确保是数字类型
    market_cap_category: "Medium",
    bci: "A",
    vp: "C",
    risk: "B",
    sentiment: "C"
  }];

  // 修改表格单元格渲染逻辑
  const renderCell = (value, rowData, column) => {
    return column.render ? column.render(value, rowData) : (value || '-');
  };

  const findEtfCategory = (ticker) => {
    if (!ticker) return null;
    const etf = etfList.find(e => e.ETF.toLowerCase() === ticker.toLowerCase());
    if (!etf) return null;
    return etf.Type.toLowerCase() + '-etfs';
  };

  const renderSidebarItem = (item) => {
    return (
      <div className="sidebar-item">
        <span>{item.label}</span>
        <div className="tag-group">
          <span className="feature-tag free-tag">FREE</span>
        </div>
      </div>
    );
  };

  // 在 useEffect 中添加路由监听
  useEffect(() => {
    const path = location.pathname;
    
    // 处理 ETF 详情页路由
    if (path.includes('/products/btc/etf/')) {
      const ticker = path.split('/').pop().toUpperCase();
      const etf = etfList.find(e => e.ETF === ticker);
      if (etf) {
        // 设置展开项
        const expandedItems = ['btc', 'btc-related-etfs'];
        if (etf.Type.toLowerCase() === 'spot') {
          expandedItems.push('spot-etfs');
        } else if (etf.Type.toLowerCase() === 'futures') {
          expandedItems.push('futures-etfs');
        } else if (etf.Type.toLowerCase() === 'equity') {
          expandedItems.push('equity-etfs');
        }
        
        setExpandedItems(expandedItems);
        setActiveCategory(ticker.toLowerCase());
      }
    }
  }, [location.pathname]);

  // 添加折叠切换函数
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  return (
    <>
      <Helmet>
        <title>Bitcoin Investment Dashboard: Insights, Tools, and Top Bitcoin-Related Stocks</title>
        <meta name="description" content="Explore the Bitcoin Investment Dashboard with tools like seasonality trends and Fear & Greed Index. Dive into top Bitcoin-related stocks with expert insights, helping beginners and experts invest in Bitcoin safely and effectively." />
        <meta name="keywords" content="Bitcoin dashboard, cryptocurrency investment, Bitcoin stocks, Fear & Greed Index, Bitcoin analysis tools, crypto investment strategy" />
        <link rel="canonical" href="https://finstockhk.org/products" />
      </Helmet>
      <PageLayout>
        <div className={`products-container ${isSidebarCollapsed ? 'collapsed' : ''}`}>
          <button 
            className="sidebar-toggle" 
            onClick={toggleSidebar}
            aria-label={isSidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {isSidebarCollapsed ? '<' : '>'}
          </button>
          <aside className="products-sidebar">
            <nav className="sidebar-nav">
              {navigationItems.map(item => (
                <div key={item.id} className="nav-item-container">
                  <button
                    className={`sidebar-item ${activeCategory === item.id ? 'active' : ''} ${item.type === 'group' ? 'group' : ''}`}
                    onClick={() => {
                      if (item.type === 'group') {
                        toggleExpand(item.id);
                      } else {
                        handleNavigation(item.id);
                      }
                    }}
                    aria-expanded={expandedItems.includes(item.id)}
                  >
                    <span className="item-content">
                      {item.label}
                    </span>
                    {(item.type === 'group' || item.type === 'expandable') && (
                      <span className={`expand-icon ${expandedItems.includes(item.id) ? 'expanded' : ''}`}>▼</span>
                    )}
                  </button>
                  {item.type === 'group' && expandedItems.includes(item.id) && (
                    <div className="sub-items">
                      {item.children.map(child => {
                        if (child.type === 'expandable') {
                          return (
                            <div key={child.id} className="market-cap-category">
                              <button
                                className={`sidebar-item ${activeCategory === child.id ? 'active' : ''}`}
                                onClick={() => toggleExpand(child.id)}
                              >
                                <span className="item-content">{child.label}</span>
                                <span className="feature-tag free-tag">FREE</span>
                                <span className={`expand-icon ${expandedItems.includes(child.id) ? 'expanded' : ''}`}>▼</span>
                              </button>
                              {expandedItems.includes(child.id) && (
                                <div className="stock-list">
                                  {child.stocks.map(stock => (
                                    <button
                                      key={stock}
                                      className={`sidebar-item stock-item ${activeCategory === stock.toLowerCase() ? 'active' : ''}`}
                                      onClick={() => handleStockClick(stock, child.id)}
                                    >
                                      <span className="item-content">{stock}</span>
                                    </button>
                                  ))}
                                </div>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <button
                              key={child.id}
                              className={`sidebar-item ${activeCategory === child.id ? 'active' : ''} ${getPageType(child.id) ? `${getPageType(child.id)}-feature` : ''}`}
                              onClick={() => handleNavigation(child.id)}
                            >
                              <span className="item-content">{child.label}</span>
                              {getPageType(child.id) === 'pro' && <span className="feature-tag pro-tag">PRO</span>}
                              {getPageType(child.id) === 'free' && <span className="feature-tag free-tag">FREE</span>}
                            </button>
                          );
                        }
                      })}
                    </div>
                  )}
                </div>
              ))}
            </nav>
          </aside>
          <main className="products-main-content">
            {renderContent()}
          </main>
        </div>
      </PageLayout>
      <Modal 
        isOpen={modalOpen} 
        onClose={() => {
          setModalOpen(false);
          setModalType(null);
        }}
        type={modalType}
      />
    </>
  );
};

export default ProductsPage; 
 