import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Tooltip from '../Tooltip';
import { 
  getAllEtfScreenerData, 
  getEtfPriceReturnData, 
  getEtfScreenerData,
  getEtfOverviewData,
  getEtfPriceReturn
} from '../../services/data_api';
import etfData from './etf-data.json';
import './ETFScreener.css';
import { Helmet } from 'react-helmet-async';

const FilterTags = ({ filters, filterConfig, onRemove }) => {
  if (Object.values(filters).every(arr => arr.length === 0)) return null;

  return (
    <div className="etf-screener-filter-tags">
      {Object.entries(filters).map(([type, values]) => 
        values.map(value => (
          <div key={`${type}-${value}`} className="filter-tag">
            <span className="filter-tag-type">{filterConfig[type].label}:</span>
            <span className="filter-tag-value">
              {filterConfig[type].options.find(opt => opt.value === value)?.label}
            </span>
            <button
              className="filter-tag-remove"
              onClick={() => onRemove(type, value)}
            >
              ×
            </button>
          </div>
        ))
      )}
    </div>
  );
};

const FilterSection = ({ onFilter, filters }) => {
  const { t } = useTranslation();
  
  const filterTypes = [
    {
      key: 'type',
      label: 'ETF Type',
      tooltip: 'Filter ETFs by type',
      options: [
        { value: 'Spot', label: 'Spot' },
        { value: 'Futures', label: 'Futures' },
        { value: 'Equity', label: 'Equity' }
      ]
    },
    { 
      key: 'bci', 
      label: 'Correlation_BTC',
      tooltip: t('tooltips.bci'),
      options: [
        { value: 'Very Strong', label: 'Very Strong' },
        { value: 'Strong', label: 'Strong' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Weak', label: 'Weak' },
        { value: 'Very Weak', label: 'Very Weak' }
      ]
    },
    { 
      key: 'premium', 
      label: 'Premium',
      tooltip: t('tooltips.premium'),
      options: [
        { value: 'Very High', label: 'Very High' },
        { value: 'High', label: 'High' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
        { value: 'Very Low', label: 'Very Low' }
      ]
    },
    { 
      key: 'risk', 
      label: 'Risk',
      tooltip: t('tooltips.risk'),
      options: [
        { value: 'Very High', label: 'Very High' },
        { value: 'High', label: 'High' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
        { value: 'Very Low', label: 'Very Low' }
      ]
    }
  ];

  const handleFilterChange = (key, value) => {
    const newFilters = { ...filters };
    if (!newFilters[key]) {
      newFilters[key] = [value];
    } else {
      const index = newFilters[key].indexOf(value);
      if (index === -1) {
        newFilters[key] = [...newFilters[key], value];
      } else {
        newFilters[key] = newFilters[key].filter(v => v !== value);
        if (newFilters[key].length === 0) {
          delete newFilters[key];
        }
      }
    }
    onFilter(newFilters);
  };

  return (
    <div className="etf-screener-filter-section">
      <div className="filter-section-header">
        <div className="filter-section-title">
          <svg className="filter-icon" viewBox="0 0 24 24" width="20" height="20">
            <path fill="currentColor" d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z"/>
          </svg>
          <span>Filters</span>
        </div>
      </div>
      <div className="etf-screener-filter-controls">
        {filterTypes.map(filter => (
          <div key={filter.key} className="etf-screener-filter-group">
            <div className="etf-screener-filter-header">
              <Tooltip content={filter.tooltip}>
                <span>{filter.label}</span>
              </Tooltip>
            </div>
            <div className="etf-screener-checkbox-group">
              {filter.options.map(option => (
                <label key={option.value} className="etf-screener-checkbox-label">
                  <input
                    type="checkbox"
                    checked={filters[filter.key]?.includes(option.value) || false}
                    onChange={() => handleFilterChange(filter.key, option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ETFScreener = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [screenerData, setScreenerData] = useState([]);
  const [filters, setFilters] = useState({
    expense_ratio: [],
    market_cap: [],
    correlation: [],
    premium: [],
    risk: [],
    recommendation: []
  });
  const [sortConfigs, setSortConfigs] = useState([
    { key: 'market_cap', direction: 'descending' } // 默认按市值降序
  ]);
  const tableRef = useRef(null);
  const topScrollRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSort = (key) => {
    setSortConfigs(prevConfigs => {
      const existingIndex = prevConfigs.findIndex(config => config.key === key);
      const newConfigs = [...prevConfigs];

      if (existingIndex === -1) {
        // 添加新的排序
        newConfigs.push({ key, direction: 'descending' });
      } else {
        // 切换排序方向或移除
        const currentConfig = newConfigs[existingIndex];
        if (currentConfig.direction === 'descending') {
          currentConfig.direction = 'ascending';
        } else {
          newConfigs.splice(existingIndex, 1);
        }
      }
      return newConfigs;
    });
  };

  const handleRemoveSort = (key) => {
    setSortConfigs(prevConfigs => 
      prevConfigs.filter(config => config.key !== key)
    );
  };

  const handleRemoveFilter = (type, value) => {
    setFilters(prev => ({
      ...prev,
      [type]: prev[type].filter(v => v !== value)
    }));
  };

  const renderGrade = (grade, type) => {
    if (!grade) return '-';

    const getGradeDescription = (grade, type) => {
      const descriptions = {
        bci: {
          'A': 'Very Strong',
          'B': 'Strong',
          'C': 'Medium',
          'D': 'Weak',
          'E': 'Very Weak'
        },
        premium: {
          'A': 'Very Low',
          'B': 'Low',
          'C': 'Medium',
          'D': 'High',
          'E': 'Very High'
        },
        risk: {
          'A': 'Very Low',
          'B': 'Low',
          'C': 'Medium',
          'D': 'High',
          'E': 'Very High'
        },
        recommendation: {
          'A': 'Strong Buy',
          'B': 'Buy',
          'C': 'Hold',
          'D': 'Sell',
          'E': 'Strong Sell'
        }
      };
      return descriptions[type]?.[grade] || '';
    };

    // Correlation_BTC 和 Recommendation 使用渐变条样式
    if (type === 'bci' || type === 'recommendation') {
      const getIndicatorStyle = (grade) => {
        const position = {
          'A': 90,
          'B': 70,
          'C': 50,
          'D': 30,
          'E': 10
        }[grade] || 50;

        return {
          position: `${position}%`,
          gradient: 'linear-gradient(to right, #ea4335, #fbbc04, #34a853)'  // 红黄绿
        };
      };

      const { position, gradient } = getIndicatorStyle(grade);

      return (
        <div className="etf-screener-indicator">
          <div 
            className="etf-screener-indicator-bar" 
            style={{ background: gradient }}
          >
            <div 
              className="etf-screener-indicator-pointer"
              style={{ left: position }}
            />
          </div>
          <div className="etf-screener-indicator-label">
            {getGradeDescription(grade, type)}
          </div>
        </div>
      );
    }

    // Premium 和 Risk 使用水平条形图样式
    const bars = ['A', 'B', 'C', 'D', 'E'];
    const gradeIndex = bars.indexOf(grade);
    
    return (
      <div className="etf-screener-indicator">
        <div className="etf-screener-bars-container">
          {bars.map((bar, index) => (
            <div 
              key={bar}
              className={`etf-screener-bar-wrapper ${index <= gradeIndex ? 'active' : ''}`}
            >
              <div className={`etf-screener-bar ${type}-${bar}`} />
            </div>
          ))}
        </div>
        <div className="etf-screener-indicator-label">
          {getGradeDescription(grade, type)}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const screenerResult = await getAllEtfScreenerData();
        
        // 并行获取所有ETF的价格数据
        const pricePromises = screenerResult.data.map(etf => 
          getEtfPriceReturnData(etf.etf)
            .then(priceData => ({
              ticker: etf.etf,
              ...priceData
            }))
        );

        const priceResults = await Promise.all(pricePromises);
        
        // 合并数据
        const enrichedData = screenerResult.data.map(etf => {
          const priceData = priceResults.find(p => p.ticker === etf.etf);
          const jsonData = etfData.etfs.find(e => e.ETF === etf.etf) || {};
          return {
            ...etf,
            name: jsonData.Name || etf.etf,
            type: jsonData.Type || 'Unknown',
            current_price: priceData?.current_price,
            current_return: priceData?.current_return
          };
        });

        setScreenerData(enrichedData);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleEtfClick = async (ticker) => {
    try {
      // 确保 ticker 使用大写
      const upperTicker = ticker.toUpperCase();
      
      // 获取 ETF 类型
      const etf = etfData.etfs.find(e => e.ETF === upperTicker);
      const etfType = etf?.Type?.toLowerCase() || 'spot';
      
      // 设置侧边栏状态
      const expandedItems = ['btc', 'btc-related-etfs'];
      if (etfType === 'spot') {
        expandedItems.push('spot-etfs');
      } else if (etfType === 'futures') {
        expandedItems.push('futures-etfs');
      } else if (etfType === 'equity') {
        expandedItems.push('equity-etfs');
      }
      
      // 先设置 localStorage，使用大写的 ticker
      localStorage.setItem('expandedItems', JSON.stringify(expandedItems));
      localStorage.setItem('activeCategory', upperTicker);
      
      // 设置 Redux 状态
      dispatch({
        type: 'SET_SIDEBAR_STATE',
        payload: {
          btcEtfs: true,
          btcStocks: false,
          selectedEtfType: etfType,
          expandedItems
        }
      });

      // 使用大写的 ticker 进行导航
      navigate(`/products/btc/etf/${upperTicker}`);
    } catch (error) {
      console.error('Error handling ETF click:', error);
      navigate(`/products/btc/etf/${ticker.toUpperCase()}`);
    }
  };

  const getFilteredData = () => {
    return screenerData.filter(etf => {
      return Object.entries(filters).every(([type, selectedValues]) => {
        if (selectedValues.length === 0) return true;

        switch (type) {
          case 'expense_ratio':
            return selectedValues.includes(etf.expense_ratio_rank);
          case 'market_cap':
            return selectedValues.includes(etf.market_cap_rank);
          case 'correlation':
            return selectedValues.includes(etf.btc_correlation_index);
          case 'premium':
            return selectedValues.includes(etf.valuation_premium);
          case 'risk':
            return selectedValues.includes(etf.risk);
          case 'recommendation':
            return selectedValues.includes(etf.recommendation);
          default:
            return true;
        }
      });
    });
  };

  const getSortedData = () => {
    return getFilteredData().sort((a, b) => {
      for (const { key, direction } of sortConfigs) {
        let comparison = 0;
        
        // 特殊处理数值型数据
        if (['market_cap', 'expense_ratio', 'current_price'].includes(key)) {
          comparison = (a[key] || 0) - (b[key] || 0);
        } 
        // 特殊处理等级数据
        else if (['btc_correlation_index', 'valuation_premium', 'risk', 'recommendation'].includes(key)) {
          const gradeOrder = { 'A': 5, 'B': 4, 'C': 3, 'D': 2, 'E': 1 };
          comparison = (gradeOrder[a[key]] || 0) - (gradeOrder[b[key]] || 0);
        }
        // 字符串比较
        else {
          comparison = String(a[key] || '').localeCompare(String(b[key] || ''));
        }

        if (comparison !== 0) {
          return direction === 'ascending' ? comparison : -comparison;
        }
      }
      return 0;
    });
  };

  const columns = [
    {
      key: 'etf',
      label: 'Ticker',
      align: 'left',
      render: (value) => (
        <span 
          className="etf-screener-ticker"
          onClick={() => handleEtfClick(value)}
        >
          {value}
        </span>
      )
    },
    {
      key: 'name',
      label: 'Name',
      align: 'left'
    },
    {
      key: 'current_price',
      label: 'Price (Return)',
      align: 'right',
      render: (_, etf) => {
        if (!etf.current_price) return '-';
        return (
          <div className="etf-screener-price" style={{ textAlign: 'right' }}>
            <span className="etf-screener-price-value">
              ${etf.current_price.toFixed(2)}
            </span>
            {etf.current_return && (
              <span className={`etf-screener-return ${etf.current_return >= 0 ? 'positive' : 'negative'}`}>
                ({etf.current_return >= 0 ? '+' : ''}{etf.current_return.toFixed(2)}%)
              </span>
            )}
          </div>
        );
      }
    },
    {
      key: 'expense_ratio',
      label: 'Expense Ratio',
      align: 'right',
      render: (value, etf) => {
        if (!value && value !== 0) return '-';
        return (
          <div className="etf-screener-metric" style={{ textAlign: 'right' }}>
            <span className="etf-screener-metric-value">
              {value.toFixed(2)}%
            </span>
            {etf.expense_ratio_rank && (
              <span className={`etf-screener-metric-rank ${etf.expense_ratio_rank.toLowerCase()}`}>
                {etf.expense_ratio_rank}
              </span>
            )}
          </div>
        );
      }
    },
    {
      key: 'market_cap',
      label: 'Market Cap',
      align: 'right',
      render: (value, etf) => {
        if (!value && value !== 0) return '-';
        return (
          <div className="etf-screener-metric" style={{ textAlign: 'right' }}>
            <span className="etf-screener-metric-value">
              ${value.toFixed(2)}B
            </span>
            {etf.market_cap_rank && (
              <span className={`etf-screener-metric-rank ${etf.market_cap_rank.toLowerCase()}`}>
                {etf.market_cap_rank}
              </span>
            )}
          </div>
        );
      }
    },
    {
      key: 'btc_correlation_index',
      label: 'Correlation BTC',
      tooltip: 'tooltips.bci',
      align: 'center',
      render: (value) => renderGrade(value, 'bci')
    },
    {
      key: 'valuation_premium',
      label: 'Premium',
      tooltip: 'tooltips.vp',
      align: 'center',
      render: (value) => renderGrade(value, 'premium')
    },
    {
      key: 'risk',
      label: 'Risk',
      tooltip: 'tooltips.risk',
      align: 'center',
      render: (value) => renderGrade(value, 'risk')
    },
    {
      key: 'recommendation',
      label: 'Recommendation',
      tooltip: 'tooltips.recommendation',
      align: 'center',
      render: (value) => renderGrade(value, 'recommendation')
    }
  ];

  // 添加滚动同步处理
  const handleScroll = (e, source) => {
    if (source === 'top' && tableRef.current) {
      tableRef.current.scrollLeft = e.target.scrollLeft;
    } else if (source === 'bottom' && topScrollRef.current) {
      topScrollRef.current.scrollLeft = e.target.scrollLeft;
    }
  };

  // 监听表格宽度变化
  useEffect(() => {
    const updateTableWidth = () => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.scrollWidth);
      }
    };

    updateTableWidth();
    window.addEventListener('resize', updateTableWidth);
    return () => window.removeEventListener('resize', updateTableWidth);
  }, [screenerData]);

  // 渲染排序指示器
  const renderSortIndicators = () => {
    if (sortConfigs.length === 0) return null;

    return (
      <div className="etf-screener-sort-indicators">
        <span className="sort-indicators-label">Sort by:</span>
        {sortConfigs.map((config, index) => (
          <div key={config.key} className="sort-indicator">
            <span className="sort-indicator-text">
              {columns.find(col => col.key === config.key)?.label}
              {index + 1 > 1 && <span className="sort-indicator-order">{index + 1}</span>}
            </span>
            <span className={`sort-indicator-direction ${config.direction}`}>
              {config.direction === 'ascending' ? '↑' : '↓'}
            </span>
            <button
              className="sort-indicator-remove"
              onClick={() => handleRemoveSort(config.key)}
            >
              ×
            </button>
          </div>
        ))}
      </div>
    );
  };

  // 筛选器配置
  const filterConfig = {
    expense_ratio: {
      label: 'Expense Ratio',
      options: [
        { value: 'Low', label: 'Low' },
        { value: 'Medium', label: 'Medium' },
        { value: 'High', label: 'High' }
      ]
    },
    market_cap: {
      label: 'Market Cap',
      options: [
        { value: 'Large', label: 'Large' },
        { value: 'Medium', label: 'Medium' },
        { value: 'Small', label: 'Small' }
      ]
    },
    correlation: {
      label: 'Correlation BTC',
      options: [
        { value: 'A', label: 'Very Strong' },
        { value: 'B', label: 'Strong' },
        { value: 'C', label: 'Medium' },
        { value: 'D', label: 'Weak' },
        { value: 'E', label: 'Very Weak' }
      ]
    },
    premium: {
      label: 'Premium',
      options: [
        { value: 'A', label: 'Very Low' },
        { value: 'B', label: 'Low' },
        { value: 'C', label: 'Medium' },
        { value: 'D', label: 'High' },
        { value: 'E', label: 'Very High' }
      ]
    },
    risk: {
      label: 'Risk',
      options: [
        { value: 'A', label: 'Very Low' },
        { value: 'B', label: 'Low' },
        { value: 'C', label: 'Medium' },
        { value: 'D', label: 'High' },
        { value: 'E', label: 'Very High' }
      ]
    },
    recommendation: {
      label: 'Recommendation',
      options: [
        { value: 'A', label: 'Strong Buy' },
        { value: 'B', label: 'Buy' },
        { value: 'C', label: 'Hold' },
        { value: 'D', label: 'Sell' },
        { value: 'E', label: 'Strong Sell' }
      ]
    }
  };

  // 处理筛选器变化
  const handleFilterChange = (type, value) => {
    setFilters(prev => ({
      ...prev,
      [type]: prev[type].includes(value)
        ? prev[type].filter(v => v !== value)
        : [...prev[type], value]
    }));
  };

  // 渲染筛选器
  const renderFilters = () => (
    <div className="etf-screener-filter-section">
      <div className="etf-screener-filter-controls">
        {Object.entries(filterConfig).map(([key, config]) => (
          <div key={key} className="etf-screener-filter-group">
            <div className="etf-screener-filter-header">
              {config.label}
            </div>
            <div className="etf-screener-checkbox-group">
              {config.options.map(option => (
                <label key={option.value} className="etf-screener-checkbox-label">
                  <input
                    type="checkbox"
                    checked={filters[key].includes(option.value)}
                    onChange={() => handleFilterChange(key, option.value)}
                  />
                  {option.label}
                </label>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  if (loading) return <div className="etf-screener-loading">Loading...</div>;
  if (error) return <div className="etf-screener-error">Error: {error}</div>;

  return (
    <>
      <Helmet>
        <title>Bitcoin ETF Screener - FinStock Insights</title>
        <meta name="description" content="Compare and analyze Bitcoin ETFs with our comprehensive screener. Filter by expense ratio, market cap, BTC correlation, premium, risk level, and get investment recommendations." />
      </Helmet>
      <div className="etf-screener-container">
        <h1 className="etf-screener-title">Bitcoin ETF Screener</h1>
        <div className="etf-screener-filters-wrapper">
          {renderFilters()}
        </div>
        {renderSortIndicators()}
        <FilterTags 
          filters={filters} 
          filterConfig={filterConfig} 
          onRemove={handleFilterChange} 
        />
        
        {/* 顶部滚动条 */}
        <div className="etf-screener-scroll-container">
          <div 
            className="etf-screener-top-scroll-wrapper"
            ref={topScrollRef}
            onScroll={(e) => handleScroll(e, 'top')}
          >
            <div 
              className="etf-screener-scroll-content"
              style={{ width: `${tableWidth}px` }}
            />
          </div>

          {/* 表格容器 */}
          <div 
            className="etf-screener-table-wrapper" 
            ref={tableRef}
            onScroll={(e) => handleScroll(e, 'bottom')}
          >
            <table className="etf-screener-table">
              <thead>
                <tr>
                  {columns.map(column => (
                    <th 
                      key={column.key}
                      onClick={() => handleSort(column.key)}
                      className={`sortable-header ${
                        sortConfigs.find(config => config.key === column.key)
                          ? `sorted-${sortConfigs.find(config => config.key === column.key).direction}`
                          : ''
                      }`}
                    >
                      <div className="etf-screener-th-content">
                        {column.tooltip ? (
                          <Tooltip content={t(column.tooltip)} forceDirection="top">
                            <span>{column.label}</span>
                          </Tooltip>
                        ) : (
                          <span>{column.label}</span>
                        )}
                        <div className="sort-arrows">
                          <span className="sort-arrow up">▲</span>
                          <span className="sort-arrow down">▼</span>
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {getSortedData().map(etf => (
                  <tr key={etf.etf}>
                    {columns.map(column => (
                      <td key={column.key}>
                        {column.render ? 
                          column.render(etf[column.key], etf) : 
                          etf[column.key]}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ETFScreener; 