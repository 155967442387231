import React from 'react';
import Tooltip from '../Tooltip';
import './ETFVpMetrics.css';
import ETFNavPremiumChart from './ETFNavPremiumChart';
import ETFHoldingsChart from './ETFHoldingsChart';

const MetricCard = ({ label, value, tooltip }) => (
  <div className="etf-vp-metric-card">
    <div className="etf-vp-metric-header">
      <div className="etf-vp-metric-label">
        {label}
        {tooltip && <Tooltip content={tooltip} />}
      </div>
    </div>
    <div className="etf-vp-metric-value">{value}</div>
  </div>
);

const RatingIndicator = ({ grade }) => {
  const getLabel = (grade) => {
    switch (grade) {
      case 'A': return 'Very Low';
      case 'B': return 'Low';
      case 'C': return 'Medium';
      case 'D': return 'High';
      case 'E': return 'Very High';
      default: return 'N/A';
    }
  };

  const getColor = (grade) => {
    switch (grade) {
      case 'A': return '#2E7D32'; // Very Low - Dark Green
      case 'B': return '#4CAF50'; // Low - Light Green
      case 'C': return '#FFA726'; // Medium - Orange
      case 'D': return '#EF5350'; // High - Light Red
      case 'E': return '#B71C1C'; // Very High - Darker Red
      default: return '#9E9E9E';
    }
  };

  const getPosition = (grade) => {
    switch (grade) {
      case 'A': return -144; // Very Low
      case 'B': return -72;  // Low
      case 'C': return 0;    // Medium
      case 'D': return 72;   // High
      case 'E': return 144;  // Very High
      default: return 0;
    }
  };

  return (
    <div className="etf-vp-rating">
      <div className="etf-vp-rating-gauge">
        <div className="etf-vp-rating-circle">
          <div className="etf-vp-rating-segments">
            {['A', 'B', 'C', 'D', 'E'].map((g) => (
              <div
                key={g}
                className={`etf-vp-rating-segment segment-${g}`}
                style={{
                  opacity: g === grade ? 1 : 0.3
                }}
              />
            ))}
          </div>
          <div 
            className="etf-vp-rating-needle"
            style={{
              transform: `rotate(${getPosition(grade)}deg)`,
              backgroundColor: '#1a1a1a'
            }}
          />
          <div className="etf-vp-rating-center" />
        </div>
      </div>
      <div className="etf-vp-rating-labels">
        <span className={grade === 'A' ? 'active' : ''}>Very Low</span>
        <span className={grade === 'B' ? 'active' : ''}>Low</span>
        <span className={grade === 'C' ? 'active' : ''}>Medium</span>
        <span className={grade === 'D' ? 'active' : ''}>High</span>
        <span className={grade === 'E' ? 'active' : ''}>Very High</span>
      </div>
      <div className="etf-vp-rating-value" style={{ color: getColor(grade) }}>
        {getLabel(grade)}
      </div>
    </div>
  );
};

const ETFVpMetrics = ({ vpData }) => {
  if (!vpData) return <div className="etf-vp-loading">Loading...</div>;

  const formatValue = (value) => {
    if (value === null || value === undefined || isNaN(value)) return '-';
    return value.toFixed(2) + '%';
  };

  return (
    <div className="etf-vp-container">
      <div className="etf-vp-rating-section">
        <RatingIndicator grade={vpData.vp_rank || 'N/A'} />
      </div>

      <div className="etf-vp-metrics-grid">
        <MetricCard
          label="NAV"
          value={vpData.nav ? vpData.nav.toFixed(2) : '-'}
          tooltip="Net Asset Value (NAV) represents the per-share value of an ETF's underlying holdings. It's calculated by taking the total value of all assets in the fund, minus any liabilities, divided by the number of outstanding shares."
        />
        <MetricCard
          label="NAV Premium"
          value={formatValue(vpData.premium_discount_percent)}
          tooltip="NAV Premium/Discount shows the difference between an ETF's market price and its NAV, expressed as a percentage. A positive number (premium) means the ETF trades above its NAV, while a negative number (discount) means it trades below NAV."
        />
      </div>

      <div className="etf-vp-charts-container">
        <ETFNavPremiumChart ticker={vpData.ticker} />
        <ETFHoldingsChart ticker={vpData.ticker} />
      </div>
    </div>
  );
};

export default ETFVpMetrics; 