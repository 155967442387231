import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import { getEtfRiskMetrics } from '../../services/data_api';
import './ETFRiskMetrics.css';

const RiskGauge = ({ grade }) => {
  const getLabel = (grade) => {
    switch (grade) {
      case 'A': return 'Very Low Risk';
      case 'B': return 'Low Risk';
      case 'C': return 'Medium Risk';
      case 'D': return 'High Risk';
      case 'E': return 'Very High Risk';
      default: return 'N/A';
    }
  };

  const getColor = (grade) => {
    switch (grade) {
      case 'A': return '#2E7D32'; // Very Low - Dark Green
      case 'B': return '#4CAF50'; // Low - Light Green
      case 'C': return '#FFA726'; // Medium - Orange
      case 'D': return '#EF5350'; // High - Light Red
      case 'E': return '#B71C1C'; // Very High - Darker Red
      default: return '#9E9E9E';
    }
  };

  const getPosition = (grade) => {
    switch (grade) {
      case 'A': return -144; // Very Low
      case 'B': return -72;  // Low
      case 'C': return 0;    // Medium
      case 'D': return 72;   // High
      case 'E': return 144;  // Very High
      default: return 0;
    }
  };

  return (
    <div className="etfdetails-risk-gauge">
      <div className="etfdetails-risk-gauge-circle">
        <div className="etfdetails-risk-gauge-segments">
          {['A', 'B', 'C', 'D', 'E'].map((g) => (
            <div
              key={g}
              className={`etfdetails-risk-gauge-segment segment-${g}`}
              style={{
                opacity: g === grade ? 1 : 0.3
              }}
            />
          ))}
        </div>
        <div 
          className="etfdetails-risk-gauge-needle"
          style={{
            transform: `rotate(${getPosition(grade)}deg)`,
            backgroundColor: '#1a1a1a'
          }}
        />
        <div className="etfdetails-risk-gauge-center" />
      </div>
      <div className="etfdetails-risk-gauge-value" style={{ color: getColor(grade) }}>
        {getLabel(grade)}
      </div>
    </div>
  );
};

const MetricCard = ({ label, value, tooltip }) => (
  <div className="etfdetails-risk-metric-card">
    <div className="etfdetails-risk-metric-label">
      {label}
      {tooltip && <Tooltip content={tooltip} />}
    </div>
    <div className="etfdetails-risk-metric-value">{value}</div>
  </div>
);

const ETFRiskMetrics = ({ ticker }) => {
  const [riskData, setRiskData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRiskData = async () => {
      try {
        setLoading(true);
        const data = await getEtfRiskMetrics(ticker);
        setRiskData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRiskData();
  }, [ticker]);

  if (loading) return <div className="etfdetails-risk-loading">Loading...</div>;
  if (error) return <div className="etfdetails-risk-error">Error: {error}</div>;
  if (!riskData) return null;

  const formatValue = (value, isPercentage = false) => {
    if (value === undefined || value === null) return 'N/A';
    if (typeof value !== 'number') return value;
    const formattedValue = value.toFixed(2);
    return isPercentage ? `${formattedValue}%` : formattedValue;
  };

  const renderLeverageNote = () => {
    if (ticker === 'BITX' || ticker === 'BITU') {
      return (
        <div className="etfdetails-risk-note-section">
          <h3 className="etfdetails-risk-note-title">Note</h3>
          <h4 className="etfdetails-risk-note-subtitle">2x Leveraged Long Bitcoin ETF</h4>
          <p className="etfdetails-risk-note-text">
            A 2x leveraged long Bitcoin ETF seeks to deliver twice the daily return of Bitcoin's price. For example, if Bitcoin rises 10% in a day, the ETF gains 20%; if Bitcoin drops 10%, the ETF loses 20%.
          </p>
          <p className="etfdetails-risk-note-text">
            <strong>Risks:</strong> These ETFs are designed for short-term trading and are unsuitable for long-term holding due to compounding effects and volatility decay, which can erode value even if Bitcoin's price remains flat over time.
          </p>
          <div className="etfdetails-risk-note-example">
            <h5 className="etfdetails-risk-note-example-title">Example:</h5>
            <ul className="etfdetails-risk-note-list">
              <li>Day 1: Bitcoin rises 10%, from $20,000 to $22,000. The ETF gains 20%, increasing from $100 to $120.</li>
              <li>Day 2: Bitcoin falls back 9.09%, from $22,000 to $20,000 ((22,000 - 20,000) / 22,000 * 100). The ETF loses 18.18% (2 × 9.09%), dropping from $120 to $98.18.</li>
            </ul>
            <p className="etfdetails-risk-note-text">
              <strong>Result:</strong> Despite Bitcoin returning to its initial price of $20,000, the ETF's value has dropped from $100 to $98.18, highlighting the risks of compounding and volatility drag in leveraged ETFs.
            </p>
          </div>
          <p className="etfdetails-risk-note-conclusion">
            <strong>Conclusion:</strong> 2x leveraged ETFs are high-risk tools for active traders and require close monitoring. They are not suitable for long-term investment due to the risks of compounding and volatility decay.
          </p>
        </div>
      );
    } else if (ticker === 'BITI') {
      return (
        <div className="etfdetails-risk-note-section">
          <h3 className="etfdetails-risk-note-title">Note</h3>
          <h4 className="etfdetails-risk-note-subtitle">1x Short Bitcoin ETF</h4>
          <p className="etfdetails-risk-note-text">
            A 1x short Bitcoin ETF delivers the inverse daily return of Bitcoin's price, meaning if Bitcoin rises 5%, the ETF drops 5%, and vice versa. These ETFs are primarily used for short-term hedging rather than standalone investment, as they are not suitable for long-term holding. Over time, compounding effects, volatility drag, and sustained price increases in Bitcoin can lead to significant losses, especially in bullish markets.
          </p>
          <div className="etfdetails-risk-note-example">
            <h5 className="etfdetails-risk-note-example-title">Example: Bitcoin's 10x Growth</h5>
            <p className="etfdetails-risk-note-text">
              If Bitcoin rose from $10,000 in 2020 to $100,000 today (a 1,000% increase), a 1x Short Bitcoin ETF would have lost 90% of its value, dropping from $100 to $10. This demonstrates the risk of near-total loss in prolonged uptrends.
            </p>
          </div>
          <p className="etfdetails-risk-note-conclusion">
            <strong>Conclusion:</strong> Short Bitcoin ETFs are best used for short-term risk management and should be approached cautiously due to the risk of severe value erosion in rising markets.
          </p>
        </div>
      );
    }
    return null;
  };

  const isLeverageHighlight = (label, value) => {
    if (label !== 'Leverage') return false;
    return (
      ((ticker === 'BITX' || ticker === 'BITU') && value === '2x') || 
      (ticker === 'BITI' && value === '-1x')
    );
  };

  return (
    <div className="etfdetails-risk-container">
      {/* Trading Risk Section */}
      <div className="etfdetails-risk-section">
        <h2 className="etfdetails-risk-title">Trading Risk</h2>
        <div className="etfdetails-risk-content">
          <RiskGauge grade={riskData.tri_grade} />
          <div className="etfdetails-risk-metrics">
            <div className="etfdetails-risk-metrics-grid">
              <MetricCard 
                label="Volatility"
                value={`${formatValue(riskData.volatility * 100)}%`}
                tooltip="A measure of how much the ETF's price fluctuates over time. Higher volatility indicates greater price swings and potentially higher risk."
              />
              <MetricCard 
                label="Beta (BTC)"
                value={formatValue(riskData.beta)}
                tooltip="Measures the ETF's price sensitivity relative to Bitcoin. A beta of 1 means the ETF moves in line with Bitcoin, while higher/lower values indicate greater/lesser sensitivity."
              />
              <MetricCard 
                label="Volume (60D)"
                value={`${formatValue(riskData.volume_60d / 1000000)}M`}
                tooltip="The average daily trading volume over the past 60 days. Higher volume typically indicates better liquidity and easier trading."
              />
              <MetricCard 
                label="Turnover ratio"
                value={`${formatValue(riskData.turnover_ratio * 100)}%`}
                tooltip="The percentage of an ETF's holdings that have been replaced during the past year. A higher ratio indicates more active trading and potentially higher transaction costs."
              />
              <MetricCard 
                label="Maximum drawdown"
                value={`${formatValue(riskData.maximum_drawdown * 100)}%`}
                tooltip="The largest peak-to-trough decline in the ETF's value. It represents the maximum loss an investor could have experienced if they bought at the peak and sold at the bottom."
              />
              <MetricCard 
                label="VaR (5%)"
                value={`${formatValue(riskData.var * 100)}%`}
                tooltip="Value at Risk (VaR) indicates the maximum potential loss with 95% confidence over a specific time period. For example, a 5% VaR of -10% means there's a 5% chance of losing more than 10%."
              />
              <MetricCard 
                label="Sharpe ratio"
                value={formatValue(riskData.sharpe_ratio)}
                tooltip="A measure of risk-adjusted return, calculated as excess return per unit of risk. A higher Sharpe ratio indicates better risk-adjusted performance."
              />
              <MetricCard 
                label="Sortino Ratio"
                value={formatValue(riskData.sortino_ratio)}
                tooltip="Similar to the Sharpe ratio but only considers downside volatility. A higher Sortino ratio indicates better risk-adjusted returns relative to downside risk."
              />
            </div>
          </div>
        </div>
      </div>

      {/* ETF Risk Section */}
      <div className="etfdetails-risk-section">
        <h2 className="etfdetails-risk-title">ETF Risk</h2>
        <div className="etfdetails-risk-content">
          <RiskGauge grade={riskData.etf_risk_rank} />
          <div className="etfdetails-risk-metrics">
            <div className="etfdetails-risk-metrics-grid">
              <MetricCard 
                label="Leverage"
                value={`${riskData.leverage}x`}
                tooltip="The degree to which the ETF uses borrowed money or derivatives to amplify returns. Higher leverage means higher potential returns but also higher risk."
              />
              <MetricCard 
                label="Tracking error"
                value={`${formatValue(riskData.track_error * 100)}%`}
                tooltip="The difference between the ETF's returns and its benchmark index. A lower tracking error indicates the ETF more closely follows its benchmark."
              />
              <MetricCard 
                label="AUM"
                value={`${formatValue(riskData.aum / 1000000000)}B`}
                tooltip="Assets Under Management (AUM) represents the total market value of all financial assets which an ETF manages on behalf of its clients and investors. It's a key metric used to measure the size and success of an ETF."
              />
              <MetricCard 
                label="Expense Ratio"
                value={`${formatValue(riskData.expense_ratio)}%`}
                tooltip="The annual fee that the ETF charges investors for managing their money. For example, an expense ratio of 0.50% means that for every $10,000 invested, the ETF charges $50 per year in management fees."
              />
              <MetricCard 
                label="Issuer"
                value={riskData.issuer}
                tooltip="The financial institution that creates and manages the ETF. The issuer's reputation and track record are important factors to consider."
              />
              <MetricCard 
                label="Fund Lifecycle"
                value={`${riskData.lifecycle_d}D`}
                tooltip="The number of days since the ETF's inception. Generally, longer-running funds have more established track records."
              />
            </div>
          </div>
        </div>
      </div>

      {renderLeverageNote()}
    </div>
  );
};

export default ETFRiskMetrics; 