import React, { useState, useEffect } from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { getEtfRecommendationRatings } from '../../services/data_api';
import './ETFRecommendation.css';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';

const getValueFromGrade = (grade) => {
  switch(grade) {
    case 'A': return 5;  // Strong Buy
    case 'B': return 4;  // Buy
    case 'C': return 3;  // Hold
    case 'D': return 2;  // Sell
    case 'E': return 1;  // Strong Sell
    default: return 3;   // 默认 Hold
  }
};

const getRecommendationLabel = (grade) => {
  switch(grade) {
    case 'A': return 'Strong Buy';
    case 'B': return 'Buy';
    case 'C': return 'Hold';
    case 'D': return 'Sell';
    case 'E': return 'Strong Sell';
    default: return 'Hold';
  }
};

const RecommendationMeter = ({ value }) => {
  const meterValue = getValueFromGrade(value);
  
  return (
    <div className="etfdetails-rec-meter">
      <ReactSpeedometer
        value={meterValue}
        minValue={1}
        maxValue={5}
        segments={5}
        currentValueText=""
        customSegmentLabels={[
          {
            text: "Strong Sell",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Sell",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Hold",
            position: "INSIDE",
            color: "#333",
            fontSize: "11px"
          },
          {
            text: "Buy",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Strong Buy",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          }
        ]}
        segmentColors={[
          "#D32F2F",
          "#FF5722",
          "#FDD835",
          "#4CAF50",
          "#2E7D32"
        ]}
        startAngle={0}
        endAngle={180}
        needleColor="#333"
        needleTransition="easeElastic"
        needleTransitionDuration={3000}
        needleHeightRatio={0.7}
        labelFontSize="11px"
        valueTextFontSize="0"
        width={400}
        height={200}
        paddingVertical={20}
        ringWidth={50}
        textColor="#666"
        paddingHorizontal={10}
        segmentSpacing={2}
        maxSegmentLabels={5}
        customSegmentStops={[1, 1.8, 2.6, 3.4, 4.2, 5]}
      />
      <div className="etfdetails-rec-meter-label">
        {getRecommendationLabel(value)}
      </div>
    </div>
  );
};

const RatingBar = ({ label, value, tooltip }) => {
  const percentage = ((value - 1) / 4) * 100;
  
  return (
    <div className="etfdetails-rec-rating-item">
      <div className="etfdetails-rec-rating-label-wrapper">
        <span className="etfdetails-rec-rating-label">
          {label}
          {tooltip && <Tooltip content={tooltip} />}
        </span>
      </div>
      <div className="etfdetails-rec-rating-container">
        <div className="etfdetails-rec-rating-track">
          <div 
            className="etfdetails-rec-rating-fill" 
            style={{ width: `${percentage}%` }} 
          />
        </div>
        <div className="etfdetails-rec-rating-value">{value.toFixed(2)}</div>
      </div>
    </div>
  );
};

const TechnicalIndicator = ({ label, value, tooltip }) => (
  <div className="etfdetails-rec-indicator-card">
    <div className="etfdetails-rec-indicator-content">
      <span className="etfdetails-rec-indicator-label">
        {label}
        {tooltip && <Tooltip content={tooltip} />}
      </span>
      <span className="etfdetails-rec-indicator-value">
        {value?.toFixed(2) || 'N/A'}
      </span>
    </div>
  </div>
);

const ETFRecommendation = ({ ticker }) => {
  const [ratingData, setRatingData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getEtfRecommendationRatings(ticker);
        setRatingData(data);
      } catch (error) {
        console.error('Error fetching ETF recommendation data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [ticker]);

  if (loading) return <div className="etfdetails-rec-loading">Loading...</div>;
  if (!ratingData) return null;

  return (
    <div className="etfdetails-rec-container">
      <div className="etfdetails-rec-section">
        <h2 className="etfdetails-rec-section-title">Ratings</h2>
        <div className="etfdetails-rec-header">
          <div className="etfdetails-rec-meter-container">
            <RecommendationMeter value={ratingData.recommendation} />
          </div>
          
          <div className="etfdetails-rec-ratings">
            <RatingBar 
              label="Model Rating" 
              value={ratingData.model_rating}
              tooltip="The Model Rating is based on a comprehensive analysis of BTC and stock indicators (like MVRV-Z Value, Fear & Greed Index, Correlation_BTC, etc.), scored from 1 to 5, where higher values indicate greater potential."
            />
            <RatingBar 
              label="Technical Rating" 
              value={ratingData.technical_rating}
              tooltip="The Technical Rating is a scoring system derived from commonly used technical indicators, providing insights into market trends and potential price movements."
            />
          </div>
        </div>
      </div>

      <div className="etfdetails-rec-section">
        <h2 className="etfdetails-rec-section-title">Technical Indicators</h2>
        <div className="etfdetails-rec-indicators-grid">
          <TechnicalIndicator 
            label="RSI (Relative Strength Index)" 
            value={ratingData.rsi}
            tooltip="The RSI measures the speed and change of price movements, indicating overbought (above 70) or oversold (below 30) conditions."
          />
          <TechnicalIndicator 
            label="MACD (Moving Average Convergence Divergence)" 
            value={ratingData.macd}
            tooltip="MACD is a trend-following momentum indicator that shows the relationship between two moving averages, helping identify potential buy or sell signals."
          />
          <TechnicalIndicator 
            label="STOCH K (Stochastic Oscillator)" 
            value={ratingData.stoch_k}
            tooltip="The Stochastic Oscillator compares a security's closing price to its price range over a specific period, indicating momentum and potential overbought (above 80) or oversold (below 20) conditions."
          />
          <TechnicalIndicator 
            label="ADX (Average Directional Index)" 
            value={ratingData.adx}
            tooltip="ADX measures the strength of a trend, with values above 25 indicating a strong trend and below 20 suggesting a weak trend."
          />
          <TechnicalIndicator 
            label="CCI (Commodity Channel Index)" 
            value={ratingData.cci}
            tooltip="CCI measures the deviation of the price from its average, with values above 100 indicating overbought conditions and below -100 indicating oversold conditions."
          />
          <TechnicalIndicator 
            label="ATR (Average True Range)" 
            value={ratingData.atr}
            tooltip="ATR measures market volatility by calculating the average range between high and low prices over a specific period, helping assess potential risk."
          />
          <TechnicalIndicator 
            label="ROC (Rate of Change)" 
            value={ratingData.roc}
            tooltip="ROC measures the percentage change in price over a specified time frame, indicating momentum; positive values suggest upward momentum, while negative values indicate downward momentum."
          />
          <TechnicalIndicator 
            label="Williams %R" 
            value={ratingData.williams_r}
            tooltip="Williams %R measures overbought or oversold conditions, with values above -20 indicating overbought and below -80 indicating oversold."
          />
        </div>
      </div>
    </div>
  );
};

export default ETFRecommendation; 