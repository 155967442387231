import React from 'react';

const StockSentiment = ({ data }) => {
  return (
    <div className="stock-sentiment-container">
      <h2>Market Sentiment</h2>
      {/* 市场情绪指标区域 */}
      <div className="sentiment-metrics">
        <div className="metric-row">
          <span>Analyst Rating</span>
          <span>{data?.analyst_rating || 'N/A'}</span>
        </div>
      </div>
    </div>
  );
};

export default StockSentiment; 