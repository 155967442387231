import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link } from 'react-router-dom';
import { 
  getMajorIndicesPriceReturn,
  getTechnicalSeasonality,
  getMarketStageInsight,
  getSectorETFTopGainersLosers,
  getSectorSpotlightAnalysis,
  getEarningsCalendar,
  getStrategyPerformanceMetrics,
  getStrategyEdgeReview,
  getStrategyPerformanceHistory
} from '../../../services/data_api';
import FearGreedMeter from '../../FearGreedMeter';
import './LongTermOverview.css';
import { format, isAfter, isBefore, addDays } from 'date-fns';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
import Tooltip from '../../Tooltip';

const TOOLTIPS = {
  marketIndices: {
    'S&P 500': 'A market-cap-weighted index tracking 500 large-cap U.S. companies, widely regarded as the best gauge of overall domestic equity market performance.',
    'Dow Jones': 'A price-weighted index of 30 prominent U.S. blue-chip companies, reflecting trends across major industries and historic economic benchmarks.',
    'NASDAQ': 'A tech-heavy, market-cap-weighted index encompassing all stocks on the Nasdaq exchange, known for its concentration of high-growth technology and innovation-driven firms.'
  },
  fearGreed: 'The Fear & Greed Index gauges investor sentiment in financial markets: lower values signal widespread fear (often seen as a potential buying opportunity), while higher values reflect excessive greed (which may precede a market correction). It aggregates data like volatility, momentum, and investor behavior to quantify emotional extremes.',
  marketInsight: 'Powered by cutting-edge LLMs and AI-driven quantitative models, this analytical engine synthesizes multi-source financial data to deliver precise market cycle diagnostics across three horizons: Long-term (1+ years), Mid-term (1-12 months), and Short-term (1-60 days). Each cycle receives an A-E sentiment grade (A=Most optimistic, E=Most bearish), derived from machine-validated indicators like yield curve dynamics, earnings revisions, and volatility patterns - all contextualized through next-gen language models scanning global financial narratives.',
  sectorSpotlight: 'This module first displays the historical top 3 and bottom 3 performing industries, then employs advanced LLM analysis to identify the current highest-potential sectors (based on earnings momentum, valuation gaps, and macro resilience) and highest-risk sectors (exposed to rate sensitivity, geopolitical shocks, or cyclical downturns). The AI cross-references real-time financial metrics with institutional-grade industry frameworks for actionable insights.',
  earningsRadar: 'This calendar tool compares market consensus EPS forecasts with our exclusive EPS forecast from precise data sources and expert analysis for superior accuracy, helping investors anticipate earnings surprises.',
  strategies: {
    'Pinnacle Strategy': 'This strategy combines leading tech giants with high-conviction innovators in AI and cloud infrastructure, targeting investors seeking growth dominance through established companies\' financial resilience and cutting-edge innovation pipelines while maintaining moderate risk exposure.',
    'Growth Strategy': 'This strategy focuses on emerging mid-cap companies and disruptive players in SaaS and fintech sectors, catering to aggressive growth investors who prioritize maximum return potential despite higher volatility from evolving business models and macroeconomic fluctuations.',
    'Stable Strategy': 'This strategy prioritizes low-volatility defensive leaders and cash-generative technology enablers, designed for risk-averse investors seeking capital preservation through steady dividends, recession-resistant industries, and mature market leadership.'
  },
  strategyEdge: 'This module compares the three core strategies\' historical performance (annualized returns) against S&P 500 and NASDAQ benchmarks, while integrating real-time LLM analysis. Our AI assesses current macro conditions to assign clear strategy ratings (A=Strong Buy, E=Strong Avoid) with actionable insights– merging proven track records with adaptive market intelligence.'
};

const LongTermOverview = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [marketIndices, setMarketIndices] = useState([]);
  const [fearGreedData, setFearGreedData] = useState(null);
  const [marketInsights, setMarketInsights] = useState([]);
  const [sectorGainersLosers, setSectorGainersLosers] = useState([]);
  const [sectorAnalysis, setSectorAnalysis] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState('1y');
  const [error, setError] = useState(null);
  const [earningsData, setEarningsData] = useState([]);
  const [earningsLoading, setEarningsLoading] = useState(true);
  const [earningsError, setEarningsError] = useState(null);
  const [strategyMetrics, setStrategyMetrics] = useState([]);
  const [strategyReviews, setStrategyReviews] = useState([]);
  const [strategyLoading, setStrategyLoading] = useState(true);
  const [strategyError, setStrategyError] = useState(null);
  const [strategyHistoryData, setStrategyHistoryData] = useState({});
  const [strategyHistoryLoading, setStrategyHistoryLoading] = useState(true);
  const [strategyHistoryError, setStrategyHistoryError] = useState(null);
  const [selectedStrategyRange, setSelectedStrategyRange] = useState('ALL');
  const strategyChartRef = useRef(null);

  // ETF to sector name mapping
  const etfSectorMap = {
    XLC: 'Communication Services',
    XLRE: 'Real Estate',
    XLK: 'Technology',
    XLF: 'Financial Services',
    XLP: 'Consumer Staples',
    XLY: 'Consumer Discretionary',
    XLI: 'Industrials',
    XLB: 'Materials',
    XLU: 'Utilities',
    XLE: 'Energy'
  };

  // Helper function to format percentage
  const formatPercentage = (value) => {
    if (value === null || value === undefined) return '-';
    return `${value.toFixed(2)}%`;
  };

  // Helper function to clean the analysis text
  const cleanAnalysisText = (text) => {
    if (!text) return '';
    return text.replace(/'/g, '');
  };

  // Navigate to sector detail page
  const navigateToSector = (etf) => {
    navigate(`/long-term/sector/${etf.toLowerCase()}`);
  };

  // Fetch earnings data
  const fetchEarningsData = async () => {
    try {
      setEarningsLoading(true);
      const response = await getEarningsCalendar();
      
      // Filter out data from today onwards for the next two days
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      
      const twoDaysLater = addDays(today, 2);
      twoDaysLater.setHours(23, 59, 59, 999);
      
      const filteredData = response.data.filter(item => {
        if (!item.report_date) return false;
        
        try {
          const reportDate = new Date(item.report_date);
          if (isNaN(reportDate.getTime())) return false;
          
          return !isBefore(reportDate, today) && !isAfter(reportDate, twoDaysLater);
        } catch (e) {
          console.error("Invalid date format:", item.report_date);
          return false;
        }
      });
      
      setEarningsData(filteredData);
    } catch (err) {
      setEarningsError(err.message);
    } finally {
      setEarningsLoading(false);
    }
  };

  // Fetch strategy data
  const fetchStrategyData = async () => {
    try {
      setStrategyLoading(true);
      
      // 并行获取两个API的数据
      const [metricsResponse, reviewsResponse] = await Promise.all([
        getStrategyPerformanceMetrics(),
        getStrategyEdgeReview()
      ]);
      
      setStrategyMetrics(metricsResponse.data || []);
      setStrategyReviews(reviewsResponse.data || []);
    } catch (err) {
      console.error("Error fetching strategy data:", err);
      setStrategyError(err.message);
    } finally {
      setStrategyLoading(false);
    }
  };

  // Fetch strategy history data
  const fetchStrategyHistoryData = async () => {
    try {
      setStrategyHistoryLoading(true);
      const response = await getStrategyPerformanceHistory();
      if (response?.data) {
        setStrategyHistoryData(response.data);
      }
    } catch (err) {
      console.error("Error fetching strategy history data:", err);
      setStrategyHistoryError(err.message);
    } finally {
      setStrategyHistoryLoading(false);
    }
  };

  useEffect(() => {
    const fetchOverviewData = async () => {
      try {
        setLoading(true);
        
        // Fetch major indices data
        const indicesResponse = await getMajorIndicesPriceReturn();
        if (indicesResponse?.data) {
          setMarketIndices(indicesResponse.data);
        }
        
        // Fetch Fear & Greed Index data
        const technicalData = await getTechnicalSeasonality();
        if (technicalData?.data && technicalData.data.length > 0 && 
            technicalData.data[0].US_Greed_Fear_Index !== undefined) {
          setFearGreedData({
            value: technicalData.data[0].US_Greed_Fear_Index
          });
        }
        
        // Fetch market stage insights
        const marketStageResponse = await getMarketStageInsight();
        if (marketStageResponse?.data) {
          const cleanedInsights = marketStageResponse.data.map(insight => ({
            ...insight,
            analysis: cleanAnalysisText(insight.analysis)
          }));
          setMarketInsights(cleanedInsights);
        }
        
        // Fetch sector gainers and losers
        const sectorGainersLosersResponse = await getSectorETFTopGainersLosers();
        if (sectorGainersLosersResponse?.data) {
          setSectorGainersLosers(sectorGainersLosersResponse.data);
        }
        
        // Fetch sector spotlight analysis
        const sectorAnalysisResponse = await getSectorSpotlightAnalysis();
        if (sectorAnalysisResponse?.data) {
          setSectorAnalysis(sectorAnalysisResponse.data);
        }
        
      } catch (err) {
        console.error('Error fetching overview data:', err);
        setError('Failed to load overview data. Please try again later.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchOverviewData();
    fetchEarningsData();
    fetchStrategyData();
    fetchStrategyHistoryData();
  }, []);

  // Get rank class based on the rank letter
  const getRankClass = (rank) => {
    switch (rank) {
      case 'A': return 'longtermoverview-rank-a';
      case 'B': return 'longtermoverview-rank-b';
      case 'C': return 'longtermoverview-rank-c';
      case 'D': return 'longtermoverview-rank-d';
      case 'E': return 'longtermoverview-rank-e';
      default: return 'longtermoverview-rank-c';
    }
  };
  
  // Filter sector data by timeframe and type
  const getFilteredSectorData = (type) => {
    return sectorGainersLosers
      .filter(item => item.duration === selectedTimeframe && item.type === type)
      .sort((a, b) => type === 'Top Gainers' ? 
        b.return_value - a.return_value : 
        a.return_value - b.return_value)
      .slice(0, 3); // Get top 3
  };

  // Handle timeframe change
  const handleTimeframeChange = (timeframe) => {
    setSelectedTimeframe(timeframe);
  };

  // Sector Spotlight Section
  const renderSectorSpotlight = () => {
    return (
      <div className="longterm-overview-section">
        <h2 className="longterm-section-title">
          {t('Sector Spotlight')}
          <Tooltip content={TOOLTIPS.sectorSpotlight} forceDirection="bottom">
            <span></span>
          </Tooltip>
        </h2>
        
        <div className="longtermoverview-timeframe-selector">
          <div className="longtermoverview-timeframe-label">
            {t('Potential Sectors')}
          </div>
          <div className="longtermoverview-timeframe-buttons">
            <button 
              className={`longtermoverview-timeframe-button ${selectedTimeframe === 'ALL' ? 'active' : ''}`}
              onClick={() => handleTimeframeChange('ALL')}
            >
              {t('ALL')}
            </button>
            <button 
              className={`longtermoverview-timeframe-button ${selectedTimeframe === '1y' ? 'active' : ''}`}
              onClick={() => handleTimeframeChange('1y')}
            >
              {t('1Y')}
            </button>
            <button 
              className={`longtermoverview-timeframe-button ${selectedTimeframe === '1m' ? 'active' : ''}`}
              onClick={() => handleTimeframeChange('1m')}
            >
              {t('1M')}
            </button>
            <button 
              className={`longtermoverview-timeframe-button ${selectedTimeframe === '1w' ? 'active' : ''}`}
              onClick={() => handleTimeframeChange('1w')}
            >
              {t('1W')}
            </button>
          </div>
        </div>
        
        <div className="longtermoverview-sector-spotlight-grid">
          {/* Left Column - Tables */}
          <div className="longtermoverview-sector-tables">
            {/* Top Performing Sectors */}
            <div className="longtermoverview-sector-table-container">
              <h3 className="longtermoverview-sector-table-title">
                {t('Top Performing Sectors')}
              </h3>
              <table className="longtermoverview-sector-table">
                <thead>
                  <tr>
                    <th>{t('Symbol')}</th>
                    <th>{t('Sector')}</th>
                    <th>{t('Return')}</th>
                  </tr>
                </thead>
                <tbody>
                  {getFilteredSectorData('Top Gainers').map((sector, index) => (
                    <tr key={index} onClick={() => navigateToSector(sector.etf)} className="longtermoverview-sector-row">
                      <td className="longtermoverview-sector-symbol">{sector.etf}</td>
                      <td className="longtermoverview-sector-name">{etfSectorMap[sector.etf] || sector.sector}</td>
                      <td className="longtermoverview-sector-return positive">
                        {formatPercentage(sector.return_value)}
                      </td>
                    </tr>
                  ))}
                  {getFilteredSectorData('Top Gainers').length === 0 && (
                    <tr>
                      <td colSpan="3" className="longtermoverview-no-data">
                        {t('No data available for selected timeframe')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            
            {/* Worst Performing Sectors */}
            <div className="longtermoverview-sector-table-container">
              <h3 className="longtermoverview-sector-table-title">
                {t('Worst Performing Sectors')}
              </h3>
              <table className="longtermoverview-sector-table">
                <thead>
                  <tr>
                    <th>{t('Symbol')}</th>
                    <th>{t('Sector')}</th>
                    <th>{t('Return')}</th>
                  </tr>
                </thead>
                <tbody>
                  {getFilteredSectorData('Top Losers').map((sector, index) => (
                    <tr key={index} onClick={() => navigateToSector(sector.etf)} className="longtermoverview-sector-row">
                      <td className="longtermoverview-sector-symbol">{sector.etf}</td>
                      <td className="longtermoverview-sector-name">{etfSectorMap[sector.etf] || sector.sector}</td>
                      <td className="longtermoverview-sector-return negative">
                        {formatPercentage(sector.return_value)}
                      </td>
                    </tr>
                  ))}
                  {getFilteredSectorData('Top Losers').length === 0 && (
                    <tr>
                      <td colSpan="3" className="longtermoverview-no-data">
                        {t('No data available for selected timeframe')}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          
          {/* Right Column - Sector Analysis Visualization */}
          <div className="longtermoverview-sector-analysis-container">
            <div className="longtermoverview-sector-categories">
              <div className="longtermoverview-sector-category potential">
                <h3 className="longtermoverview-category-title">
                  {t('Potential Sectors')}
                </h3>
                <div className="longtermoverview-sector-tags">
                  {sectorAnalysis.map(item => (
                    <div 
                      key={`potential-${item.id}`} 
                      className="longtermoverview-sector-tag potential"
                      onClick={() => {
                        const etf = Object.keys(etfSectorMap).find(key => 
                          etfSectorMap[key] === item.potential || 
                          key === item.potential
                        );
                        if (etf) navigateToSector(etf);
                      }}
                    >
                      {item.potential}
                    </div>
                  ))}
                </div>
              </div>
              
              <div className="longtermoverview-sector-category risk">
                <h3 className="longtermoverview-category-title">
                  {t('Risk Sectors')}
                </h3>
                <div className="longtermoverview-sector-tags">
                  {sectorAnalysis.map(item => (
                    <div 
                      key={`risk-${item.id}`} 
                      className="longtermoverview-sector-tag risk"
                      onClick={() => {
                        const etf = Object.keys(etfSectorMap).find(key => 
                          etfSectorMap[key] === item.risk || 
                          key === item.risk
                        );
                        if (etf) navigateToSector(etf);
                      }}
                    >
                      {item.risk}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            
            {/* Sector Analysis Text */}
            <div className="longtermoverview-sector-analysis">
              <ul>
                {sectorAnalysis.map((item) => (
                  <li key={item.id} className="longtermoverview-analysis-item">
                    {item.analysis}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Render earnings data
  const renderEarningsData = () => {
    if (earningsLoading) {
      return (
        <tr>
          <td colSpan="5" className="earnings-radar-loading">
            {t('Loading earnings data...')}
          </td>
        </tr>
      );
    }

    if (earningsError) {
      return (
        <tr>
          <td colSpan="5" className="earnings-radar-error">
            {t('Error loading earnings data:')} {earningsError}
          </td>
        </tr>
      );
    }

    if (!earningsData || earningsData.length === 0) {
      return (
        <tr>
          <td colSpan="5" className="earnings-radar-no-data">
            {t('No upcoming earnings reports in the next two days.')} {' '}
            <span 
              className="earnings-radar-calendar-link-highlight"
              onClick={() => navigate('/long-term/earnings-calendar')}
            >
              {t('View full earnings calendar')}
            </span>
          </td>
        </tr>
      );
    }

    // Group data by date
    const groupedByDate = {};
    earningsData.forEach(item => {
      if (!item.report_date) return;
      
      try {
        const dateObj = new Date(item.report_date);
        if (isNaN(dateObj.getTime())) return;
        
        const date = item.report_date;
        if (!groupedByDate[date]) {
          groupedByDate[date] = [];
        }
        groupedByDate[date].push(item);
      } catch (e) {
        console.error("Error processing date:", item.report_date);
      }
    });

    // Render grouped data
    const rows = [];
    Object.keys(groupedByDate).sort().forEach(date => {
      try {
        const dateObj = new Date(date);
        if (isNaN(dateObj.getTime())) return;
        
        rows.push(
          <tr key={`date-${date}`} className="earnings-radar-date-header">
            <td colSpan="5">
              {format(dateObj, 'EEEE, MMMM d, yyyy')}
            </td>
          </tr>
        );

        groupedByDate[date].forEach((item, idx) => {
          rows.push(
            <tr key={`${date}-${item.ticker || idx}`}>
              <td>
                <Link to={`/stock/${item.ticker}`} className="earnings-radar-ticker-link">
                  {item.ticker}
                </Link>
              </td>
              <td>{format(new Date(item.report_date), 'MM/dd/yyyy')}</td>
              <td>{item.quarter_end && !isNaN(new Date(item.quarter_end).getTime()) 
                  ? format(new Date(item.quarter_end), 'MM/dd/yyyy') 
                  : '-'}</td>
              <td>
                {item.eps_reported !== null && item.eps_reported !== undefined 
                  ? `$${Number(item.eps_reported).toFixed(2)}` 
                  : '-'} / 
                {item.eps_prime !== null && item.eps_prime !== undefined 
                  ? `$${Number(item.eps_prime).toFixed(2)}` 
                  : '-'} / 
                {item.eps_consensus !== null && item.eps_consensus !== undefined 
                  ? `$${Number(item.eps_consensus).toFixed(2)}` 
                  : '-'}
              </td>
              <td>
                {item.revenue_reported !== null && item.revenue_reported !== undefined 
                  ? `$${(Number(item.revenue_reported) / 1000000).toFixed(2)}M` 
                  : '-'} / 
                {item.revenue_consensus !== null && item.revenue_consensus !== undefined 
                  ? `$${(Number(item.revenue_consensus) / 1000000).toFixed(2)}M` 
                  : '-'}
              </td>
            </tr>
          );
        });
      } catch (e) {
        console.error("Error rendering date group:", date, e);
      }
    });

    return rows;
  };

  // Define time ranges
  const STRATEGY_TIME_RANGES = [
    { label: '1M', days: 30 },
    { label: '3M', days: 90 },
    { label: '6M', days: 180 },
    { label: '1Y', days: 365 },
    { label: 'ALL', days: 0 }
  ];

  // Define strategy chart colors
  const STRATEGY_CHART_COLORS = [
    '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
    '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'
  ];

  // Handle strategy range change
  const handleStrategyRangeChange = (range) => {
    setSelectedStrategyRange(range);
    if (strategyChartRef.current) {
      const chart = strategyChartRef.current.getEchartsInstance();
      chart.setOption(getStrategyChartOption(), true);
    }
  };

  // Filter data based on selected range
  const filterStrategyDataByRange = (data, range) => {
    if (range === 'ALL' || !data) return data;
    
    const cutoffDate = dayjs().subtract(
      STRATEGY_TIME_RANGES.find(r => r.label === range).days,
      'days'
    );
    
    return data.filter(item => dayjs(item.date).isAfter(cutoffDate));
  };

  // Get strategy chart option
  const getStrategyChartOption = () => {
    if (!strategyHistoryData || Object.keys(strategyHistoryData).length === 0) {
      return {};
    }
    
    // 处理数据
    const series = [];
    const legendData = [];
    let xAxisData = [];
    
    // 获取所有策略名称
    const strategyNames = Object.keys(strategyHistoryData);
    
    // 处理每个策略的数据
    strategyNames.forEach((strategy, index) => {
      const strategyData = strategyHistoryData[strategy] || [];
      const filteredData = filterStrategyDataByRange(strategyData, selectedStrategyRange);
      
      if (filteredData.length === 0) return;
      
      // 提取日期作为x轴数据
      if (xAxisData.length === 0) {
        xAxisData = filteredData.map(item => item.date);
      }
      
      // 添加到图例
      legendData.push(strategy);
      
      // 创建系列
      series.push({
        name: strategy,
        type: 'line',
        data: filteredData.map(item => item.value),
        symbol: 'none',
        smooth: 0.2,
        lineStyle: {
          width: 3,
          color: STRATEGY_CHART_COLORS[index % STRATEGY_CHART_COLORS.length]
        },
        emphasis: {
          lineStyle: {
            width: 4
          }
        }
      });
    });
    
    // 返回图表配置
    return {
      tooltip: {
        trigger: 'axis',
        position: function (pt) {
          // 将tooltip定位在鼠标上方
          return [pt[0], '10%'];
        },
        confine: true, // 确保tooltip不会超出图表区域
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderColor: '#e0e0e0',
        borderWidth: 1,
        textStyle: {
          color: '#333'
        },
        formatter: function(params) {
          const date = params[0].axisValue;
          let html = `<div style="padding:8px;line-height:1.5;">
                      <div style="margin-bottom:8px;font-weight:500;border-bottom:1px solid #eee;padding-bottom:5px;">${dayjs(date).format('YYYY-MM-DD')}</div>`;
          
          params.forEach(param => {
            const color = param.color;
            const name = param.seriesName;
            const value = param.value.toFixed(2);
            html += `<div style="display:flex;justify-content:space-between;align-items:center;margin:3px 0;">
                      <span style="display:inline-flex;align-items:center;">
                        <span style="display:inline-block;width:10px;height:10px;background:${color};margin-right:5px;border-radius:50%;"></span>
                        <span>${name}:</span>
                      </span>
                      <span style="font-weight:bold;margin-left:15px;">${value}</span>
                    </div>`;
          });
          
          return html;
        }
      },
      legend: {
        data: legendData,
        bottom: 0,
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10,
        textStyle: {
          color: '#666',
          fontSize: 12
        },
        itemGap: 15
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '18%',
        top: '5%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: xAxisData,
        axisLine: {
          lineStyle: {
            color: '#ccc'
          }
        },
        axisLabel: {
          formatter: function(value) {
            const screenWidth = window.innerWidth;
            // 在小屏幕上使用更简短的日期格式
            if (screenWidth < 768) {
              // 只显示月份，对于跨年的数据点显示年份
              const date = dayjs(value);
              const currentYear = dayjs().year();
              if (date.year() !== currentYear) {
                return date.format('MM/YY');
              }
              return date.format('MM/DD');
            }
            return dayjs(value).format('MM/DD/YY');
          },
          color: '#666',
          fontSize: 10, // 减小字体大小
          margin: 8,  // 减小边距
          rotate: window.innerWidth < 768 ? 45 : 0, // 增加旋转角度
          interval: function(index, value) {
            // 更激进地减少标签数量
            const totalPoints = xAxisData.length;
            const screenWidth = window.innerWidth;
            
            if (screenWidth < 576) {
              // 超小屏幕 - 大幅减少标签
              if (totalPoints > 60) return index % 60 === 0;
              if (totalPoints > 30) return index % 30 === 0;
              return index % 15 === 0;
            } else if (screenWidth < 768) {
              // 小屏幕
              if (totalPoints > 365) return index % 120 === 0;
              if (totalPoints > 180) return index % 60 === 0;
              if (totalPoints > 60) return index % 30 === 0;
              return index % 15 === 0;
            } else if (screenWidth < 992) {
              // 中等屏幕
              if (totalPoints > 365) return index % 90 === 0;
              if (totalPoints > 180) return index % 45 === 0;
              if (totalPoints > 60) return index % 20 === 0;
              return index % 10 === 0;
            } else {
              // 大屏幕
              if (totalPoints > 365) return index % 60 === 0;
              if (totalPoints > 180) return index % 30 === 0;
              if (totalPoints > 90) return index % 15 === 0;
              if (totalPoints > 30) return index % 7 === 0;
              return index % 3 === 0;
            }
          },
          align: 'center',
          hideOverlap: true // 自动隐藏重叠的标签
        },
        splitLine: {
          show: false
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisLabel: {
          color: '#666',
          fontSize: 11,
          formatter: function(value) {
            return value.toFixed(1);
          }
        },
        splitLine: {
          lineStyle: {
            color: '#eee',
            type: 'dashed'
          }
        },
        min: function(value) {
          // 设置y轴最小值为0或略小于数据最小值
          return Math.max(0, Math.floor(value.min * 10) / 10);
        }
      },
      series: series,
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false
        }
      ],
      animation: true,
      color: STRATEGY_CHART_COLORS
    };
  };

  // 在组件中添加窗口大小变化的监听器
  useEffect(() => {
    const handleResize = () => {
      if (strategyChartRef.current) {
        const chart = strategyChartRef.current.getEchartsInstance();
        chart.setOption(getStrategyChartOption(), true);
        chart.resize();
      }
    };

    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [strategyHistoryData, selectedStrategyRange]); // 依赖项包括图表数据和选择的时间范围

  if (loading) {
    return (
      <div className="longterm-overview-loading">
        <div className="loading-spinner"></div>
        <span>Loading overview data...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="longterm-overview-error">
        <div className="error-icon">⚠️</div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="longterm-overview-container">
      <div className="longterm-overview-header">
        <h1>{t('Financial Market Overview')}</h1>
      </div>

      {/* Market Snapshot Section */}
      <div className="longterm-overview-section">
        <h2 className="longterm-section-title">
          {t('Market Snapshot')}
        </h2>
        <div className="longtermoverview-market-snapshot-grid">
          {marketIndices.map((index) => {
            const displayName = index.index_name.replace(' Index', '').replace('Industrial Average', '');
            const returnPercentage = index.return_percentage;
            const isPositive = returnPercentage >= 0;
            
            // 获取对应的描述文本
            let description = '';
            if (index.index_name.includes('S&P 500')) {
              description = 'A market-cap-weighted index tracking 500 large-cap U.S. companies, widely regarded as the best gauge of overall domestic equity market performance.';
            } else if (index.index_name.includes('Dow Jones')) {
              description = 'A price-weighted index of 30 prominent U.S. blue-chip companies, reflecting trends across major industries and historic economic benchmarks.';
            } else if (index.index_name.includes('NASDAQ')) {
              description = 'A tech-heavy, market-cap-weighted index encompassing all stocks on the Nasdaq exchange, known for its concentration of high-growth technology and innovation-driven firms.';
            }
            
            return (
              <div key={index.ticker} className="longtermoverview-market-index-card index-card">
                <div className="longtermoverview-market-index-name">
                  {displayName}
                </div>
                <div className="longtermoverview-market-index-value">
                  {index.current_price.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                  })}
                </div>
                <div className={`longtermoverview-market-index-change ${isPositive ? 'positive' : 'negative'}`}>
                  {formatPercentage(returnPercentage)}
                </div>
                {description && (
                  <div className="longtermoverview-market-index-description">
                    <p>{description}</p>
                  </div>
                )}
              </div>
            );
          })}
          
          {/* Fear & Greed Index Card */}
          <div className="longtermoverview-market-index-card fear-greed-card">
            {fearGreedData ? (
              <div className="longtermoverview-fear-greed-wrapper">
                <FearGreedMeter 
                  value={parseInt(fearGreedData.value)} 
                  title="US Market" 
                />
              </div>
            ) : (
              <div className="longtermoverview-no-data">
                Fear & Greed data not available
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Market Insight Section */}
      <div className="longterm-overview-section">
        <h2 className="longterm-section-title">
          {t('Market Insight')}
        </h2>
        
        {/* Market Cycle Cards */}
        <div className="longtermoverview-market-insight-grid">
          {marketInsights.map((insight, index) => (
            <div key={index} className="longtermoverview-market-insight-card">
              <div className="longtermoverview-market-insight-header">
                <h3 className="longtermoverview-market-insight-cycle">
                  {insight.cycle.replace('-term', '-TERM')}
                </h3>
                <div className="longtermoverview-market-insight-stage">{insight.stage}</div>
                <div className={`longtermoverview-market-insight-rank ${getRankClass(insight.rank)}`}>
                  {insight.rank}
                </div>
              </div>
            </div>
          ))}
        </div>
        
        {/* Market Analysis Text */}
        <div className="longtermoverview-market-analysis">
          <ul>
            {marketInsights.map((insight, index) => (
              <li key={index} className="longtermoverview-analysis-item">
                <strong>{insight.cycle}:</strong> {insight.analysis}
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* Sector Spotlight Section */}
      {renderSectorSpotlight()}

      {/* Earnings Radar Section */}
      <div className="longterm-earnings-radar">
        <div className="earnings-radar-title">
          {t('Earnings Radar')}
          <Tooltip content={TOOLTIPS.earningsRadar} forceDirection="bottom">
            <span></span>
          </Tooltip>
        </div>
        
        <div className="earnings-radar-table-container">
          <table className="earnings-radar-table">
            <thead>
              <tr>
                <th>Ticker</th>
                <th>Report Date</th>
                <th>Quarter End</th>
                <th>EPS (Reported/Prime/Consensus)</th>
                <th>Revenue (Reported/Consensus)</th>
              </tr>
            </thead>
            <tbody>
              {renderEarningsData()}
            </tbody>
          </table>
        </div>
      </div>

      {/* Strategy Edge Section */}
      <div className="longterm-strategy-edge">
        <div className="strategy-edge-title">
          {t('Strategy Edge')}
          <Tooltip content={TOOLTIPS.strategyEdge} forceDirection="bottom">
            <span></span>
          </Tooltip>
        </div>
        
        <div className="strategy-edge-content">
          {/* 左侧图表区域 */}
          <div className="strategy-edge-chart-container">
            <div className="strategy-edge-chart-range-selector">
              {STRATEGY_TIME_RANGES.map(range => (
                <button
                  key={range.label}
                  className={`strategy-edge-chart-range-btn ${
                    selectedStrategyRange === range.label ? 'active' : ''
                  }`}
                  onClick={() => handleStrategyRangeChange(range.label)}
                >
                  {range.label}
                </button>
              ))}
            </div>
            {strategyHistoryLoading ? (
              <div className="strategy-edge-chart-loading">
                {t('Loading chart data...')}
              </div>
            ) : strategyHistoryError ? (
              <div className="strategy-edge-chart-error">
                {t('Error loading chart data:')} {strategyHistoryError}
              </div>
            ) : (
              <ReactECharts
                ref={strategyChartRef}
                option={getStrategyChartOption()}
                style={{ height: '100%', width: '100%' }}
                notMerge={true}
              />
            )}
          </div>
          
          {/* 右侧数据区域 */}
          <div className="strategy-edge-data">
            {strategyLoading ? (
              <div className="strategy-edge-loading">
                {t('Loading strategy data...')}
              </div>
            ) : strategyError ? (
              <div className="strategy-edge-error">
                {t('Error loading strategy data:')} {strategyError}
              </div>
            ) : (
              <>
                {/* 年化收益表格 */}
                <div className="strategy-edge-returns">
                  <div className="strategy-edge-returns-title">
                    {t('Annualized Returns')}
                  </div>
                  <table className="strategy-edge-returns-table">
                    <thead>
                      <tr>
                        <th>{t('Strategy/Index')}</th>
                        <th>{t('Annualized Return (%)')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {strategyMetrics.map((item) => (
                        <tr key={item.strategy}>
                          <td>{item.strategy}</td>
                          <td className="strategy-edge-returns-value positive">{item.annual_return}%</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                
                {/* 策略评论 */}
                <div className="strategy-edge-reviews">
                  <div className="strategy-edge-reviews-title">
                    {t('Strategy Reviews')}
                  </div>
                  {strategyReviews.map((review) => (
                    <div key={`strategy-review-${review.strategy}`} className="strategy-edge-review-item">
                      <div className="strategy-edge-review-header">
                        <div className="strategy-edge-review-strategy">
                          {review.strategy} Strategy
                        </div>
                        <div className={`strategy-edge-review-rating ${review.rating}`}>
                          {review.rating}
                        </div>
                      </div>
                      <div className="strategy-edge-review-text">
                        {review.review}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Q&A Section */}
      <div className="longterm-overview-section longterm-qa-section">
        <h2 className="longterm-section-title">
          {t('Frequently Asked Questions')}
        </h2>
        
        <div className="longterm-qa-container">
          {[
            {
              question: "How does your market overview help me understand the current state of the stock market?",
              answer: "Our market overview leverages advanced AI technology and real-time internet data to provide a data-driven analysis of the current market stage. We evaluate it across three time horizons: long-term (over a year), mid-term (a few months to a year), and short-term (1-60 days). For each, we identify whether the market is in a bull, bear, or transitional phase, supported by specific data and insights that explain these conclusions. This clear, actionable breakdown empowers you to understand market dynamics and make informed investment decisions tailored to your goals."
            },
            {
              question: "What makes your sector analysis different from other financial websites?",
              answer: "Our Sector Analysis focuses on the 11 major US stock market sectors, evaluated through representative <strong>ETFs</strong>. We highlight the top and bottom three performers across multiple time frames (all-time, past year, past month, and past week). Using the latest AI and internet data, we predict the three sectors with the most potential and the three with the highest risks, backed by detailed rationales. Each ETF analysis includes holdings, risk profiles, a unique Sector Heat Score (based on volume, momentum, and fund flow), and a recommendation score—offering a comprehensive, data-rich toolset for sector-based investing."
            },
            {
              question: "How are the stocks in your Long-term Stocks pool chosen, and why should I trust them for my portfolio?",
              answer: <>
                <p>Our Long-term Stocks pool consists of approximately 100 US stocks selected for their exceptional mid-to-long-term investment value, balancing strong growth potential with manageable risk. The number and composition may vary slightly as the pool is <strong>rebalanced</strong> as needed based on market conditions. The selection process combines:</p>
                <ul className="longterm-qa-list">
                  <li><strong>Quantitative Scoring:</strong> Stocks are evaluated across Growth, Momentum, Profitability, Premium, Risk, and Sentiment, yielding a composite score.</li>
                  <li><strong>Fund Favorability:</strong> We confirm if historically successful funds ("whales") favor these stocks, adding credibility.</li>
                </ul>
                <p>Each stock includes detailed data on these dimensions and a recommendation score, making it a reliable foundation for building a high-quality, diversified portfolio focused on sustainable growth, not short-term speculation.</p>
              </>
            },
            {
              question: "How can your earnings calendar improve my investment decisions?",
              answer: <>
                <p>Our earnings calendar is a powerful tool for staying ahead of the market, tailored to our Long-term Stocks pool. For each stock, we provide:</p>
                <ul className="longterm-qa-list">
                  <li><strong>EPS Prime:</strong> Our exclusive, data-driven earnings per share forecast.</li>
                  <li><strong>Consensus:</strong> Market-predicted EPS and revenue figures.</li>
                  <li><strong>Reported:</strong> Actual EPS and revenue results.</li>
                </ul>
                <p>Presented in an intuitive calendar format, this feature helps you track upcoming earnings, anticipate market reactions, and optimize your investment timing—whether capitalizing on beats or avoiding surprises.</p>
              </>
            },
            {
              question: "What are the PrimePicks strategies, and how do they differ from each other?",
              answer: <>
                <p>Our <strong>PrimePicks</strong> strategies are premium investment approaches designed for mid-to-long-term investors, with stock pools <strong>rebalanced</strong> as needed based on market conditions:</p>
                <ul className="longterm-qa-list">
                  <li><strong>Pinnacle Strategy:</strong> Targets high-growth, large-cap tech stocks with strong innovation potential.</li>
                  <li><strong>Growth Strategy:</strong> Focuses on smaller companies with exceptional growth prospects for maximum returns.</li>
                  <li><strong>Stable Strategy:</strong> Prioritizes consistent, low-risk stocks for steady growth and capital preservation.</li>
                </ul>
                <p>On our Overview page, you can explore their historical <strong>outperformance</strong> against the S&P 500 and NASDAQ, plus AI-driven evaluations for today's market. Unlock detailed selection criteria, holdings, and performance insights with a membership—perfect for those prioritizing sustainable gains over short-term volatility.</p>
              </>
            },
            {
              question: "How do the PrimePicks strategies stack up against standard market indices?",
              answer: <>
                <p>Our <strong>PrimePicks</strong> strategies consistently outperform the S&P 500 and NASDAQ:</p>
                <ul className="longterm-qa-list">
                  <li><strong>Pinnacle Strategy:</strong> 29.69% annualized return, thriving in tech-driven markets.</li>
                  <li><strong>Growth Strategy:</strong> 36.29% annualized return, leading with high-growth potential.</li>
                  <li><strong>Stable Strategy:</strong> 15.26% annualized return, delivering solid gains with lower volatility.</li>
                </ul>
                <p>The Overview page showcases these returns alongside all-time metrics like Sharpe ratios, while membership unlocks detailed strategy pages with performance data for all-time, the past year, past six months, and past three months—giving you a clear view of recent trends.</p>
              </>
            },
            {
              question: "Which investors would benefit most from each PrimePicks strategy?",
              answer: <>
                <p>Each <strong>PrimePicks</strong> strategy caters to a distinct mid-to-long-term investor profile, not short-term speculators, with stock pools <strong>rebalanced</strong> as needed based on market conditions:</p>
                <ul className="longterm-qa-list">
                  <li><strong>Pinnacle Strategy:</strong> Ideal for those seeking high returns and comfortable with tech-sector volatility—perfect for innovation-focused investors with a longer horizon.</li>
                  <li><strong>Growth Strategy:</strong> Suited for aggressive investors with high-risk tolerance, chasing big gains from smaller, growth-oriented firms over time.</li>
                  <li><strong>Stable Strategy:</strong> Best for cautious investors prioritizing steady growth and capital preservation over extended periods.</li>
                </ul>
                <p>Align your goals and risk tolerance with the right strategy. Membership provides deeper insights to guide your mid-to-long-term investment decisions.</p>
              </>
            },
            {
              question: "How does your AI technology boost the value of your analysis and recommendations?",
              answer: <>
                <p>Our AI technology enhances the platform with unparalleled advantages, guided by professional system prompts that ensure rigorous, expert-level analysis:</p>
                <ul className="longterm-qa-list">
                  <li><strong>Real-Time Data Integration:</strong> Continuously incorporates the latest market information.</li>
                  <li><strong>Objective, Professional Frameworks:</strong> Applies data-driven models within structured prompts to eliminate bias and enhance analytical depth.</li>
                  <li><strong>Predictive Analytics:</strong> Forecasts market trends, sector potential, and stock performance with precision.</li>
                  <li><strong>Tailored Recommendations:</strong> Delivers customized PrimePicks strategy advice based on current conditions.</li>
                </ul>
                <p>From market overviews to premium strategies, this AI-driven approach keeps you ahead. Unlock its full potential with a membership.</p>
              </>
            }
          ].map((qa, index) => (
            <div className="longterm-qa-item" key={index}>
              <button 
                className="longterm-qa-question"
                onClick={(e) => {
                  e.currentTarget.parentElement.classList.toggle('expanded');
                }}
              >
                <span className="longterm-qa-icon">Q</span>
                <h3>{qa.question}</h3>
                <span className="longterm-qa-toggle">+</span>
              </button>
              <div className="longterm-qa-answer">
                <span className="longterm-qa-icon">A</span>
                <div className="longterm-qa-answer-content">
                  {typeof qa.answer === 'string' ? <p>{qa.answer}</p> : qa.answer}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LongTermOverview; 