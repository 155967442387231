import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { getEtfDailyPriceRatios } from '../../services/data_api';
import './ETFPriceRatioChart.css';

const TIME_RANGES = {
  '1M': 30,
  '3M': 90,
  '6M': 180,
  '1Y': 365,
  'ALL': Number.MAX_SAFE_INTEGER
};

const ETFPriceRatioChart = ({ ticker }) => {
  const [priceRatioData, setPriceRatioData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('1Y');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await getEtfDailyPriceRatios(ticker);
      setPriceRatioData(data);
      setLoading(false);
    };
    fetchData();
  }, [ticker]);

  if (loading) {
    return <div className="etf-chart-loading">Loading chart data...</div>;
  }

  if (!priceRatioData || !priceRatioData.data || priceRatioData.data.length === 0) {
    return <div className="etf-chart-error">No price ratio data available</div>;
  }

  const getChartData = () => {
    const days = TIME_RANGES[selectedRange];
    const filteredData = priceRatioData.data
      .slice(-days)
      .map(item => [item.date, item.price_ratio]);
    return filteredData;
  };

  const option = {
    title: {
      show: false
    },
    tooltip: {
      trigger: 'axis',
      formatter: function(params) {
        const date = params[0].value[0];
        const value = params[0].value[1].toFixed(8);
        return `${date}<br/>Price Ratio: ${value}`;
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '15%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'time',
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      axisLabel: {
        formatter: '{MM}-{dd}',
        color: '#666'
      }
    },
    yAxis: {
      type: 'value',
      scale: true,
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      axisLabel: {
        color: '#666',
        formatter: (value) => value.toFixed(6)
      },
      splitLine: {
        lineStyle: {
          color: '#eee'
        }
      }
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        name: 'Price Ratio',
        type: 'line',
        data: getChartData(),
        symbol: 'none',
        lineStyle: {
          color: '#1a73e8',
          width: 2
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(26,115,232,0.2)'
              },
              {
                offset: 1,
                color: 'rgba(26,115,232,0.05)'
              }
            ]
          }
        }
      }
    ]
  };

  return (
    <div className="etf-price-ratio-chart">
      <div className="etf-price-ratio-chart-header">
        <div className="etf-price-ratio-chart-title">
          {ticker} - BTC Price Ratios Over Time
        </div>
        <div className="etf-price-ratio-chart-time-range">
          {Object.keys(TIME_RANGES).map(range => (
            <button
              key={range}
              className={`etf-price-ratio-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
              onClick={() => setSelectedRange(range)}
            >
              {range}
            </button>
          ))}
        </div>
      </div>
      <ReactECharts 
        option={option}
        style={{ height: '400px' }}
        opts={{ renderer: 'svg' }}
      />
    </div>
  );
};

export default ETFPriceRatioChart; 