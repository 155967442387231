import React from 'react';
import { Link } from 'react-router-dom';
import './ArticleCard.css';

const ArticleCard = ({ article }) => {
  return (
    <Link to={`/insights/${article.category}/${article.slug}`} className="insights-article-card">
      <div className="insights-article-image">
        <img src={article.coverImage} alt={article.title} />
      </div>
      <div className="insights-article-content">
        <div className="insights-article-meta">
          <span className="insights-article-category">{article.categoryLabel}</span>
          <span className="insights-article-date">{article.date}</span>
        </div>
        <h2 className="insights-article-title">{article.title}</h2>
        <p className="insights-article-excerpt">{article.excerpt}</p>
        <div className="insights-article-footer">
          <div className="insights-article-author">
            <img src={article.author.avatar} alt={article.author.name} />
            <div>
              <div>{article.author.name}</div>
              {/* <div>{article.author.role}</div> */}
            </div>
          </div>
          <span className="insights-article-read-time">{article.readTime} min read</span>
        </div>
      </div>
    </Link>
  );
};

export default ArticleCard;