import React, { useState, useEffect } from 'react';
import './StockProfitability.css';
import StockProfitabilityMetrics from './StockProfitabilityMetrics';

const PROFITABILITY_LEVELS = {
  'A': { text: 'Very Strong', color: '#22c55e', secondaryColor: '#86efac' },
  'B': { text: 'Strong', color: '#86efac', secondaryColor: '#bbf7d0' },
  'C': { text: 'Moderate', color: '#fbbf24', secondaryColor: '#fde68a' },
  'D': { text: 'Weak', color: '#f87171', secondaryColor: '#fca5a5' },
  'E': { text: 'Very Weak', color: '#dc2626', secondaryColor: '#f87171' }
};

const StockProfitability = ({ screenerData, profitabilityData }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [currentProfitability, setCurrentProfitability] = useState(null);

  useEffect(() => {
    let isMounted = true;

    const initializeComponent = () => {
      if (!isMounted) return;

      setIsAnimating(false);
      setCurrentProfitability(null);

      if (screenerData?.profitability) {
        // 使用 setTimeout 替代 requestAnimationFrame，确保有足够的时间重置状态
        setTimeout(() => {
          if (!isMounted) return;
          
          setCurrentProfitability(screenerData.profitability);
          setIsAnimating(true);
        }, 50);
      }
    };

    initializeComponent();

    // 清理函数
    return () => {
      isMounted = false;
    };
  }, [screenerData]);

  if (!screenerData) {
    return (
      <div className="longterm-profitability-container">
        <div className="longterm-profitability-loading">
          <i className="fas fa-spinner fa-spin"></i>
          <span>Loading profitability data...</span>
        </div>
      </div>
    );
  }

  const { profitability } = screenerData;
  const currentLevel = PROFITABILITY_LEVELS[profitability];
  const scorePercentage = (5 - Object.keys(PROFITABILITY_LEVELS).indexOf(profitability)) * 20;

  return (
    <div className="longterm-profitability-container">
      <div className="longterm-profitability-header">
        <h2 className="longterm-profitability-title">Profitability Score</h2>
        <div className="longterm-profitability-subtitle">Based on financial metrics analysis</div>
      </div>
      
      <div className="longterm-profitability-main">
        <div className="longterm-profitability-circle-wrapper">
          {/* 背景装饰圆环 */}
          <div className="longterm-profitability-decoration-ring"></div>
          
          {/* 主要进度圆环 */}
          <svg className="longterm-profitability-progress" viewBox="0 0 120 120">
            <defs>
              <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="100%">
                <stop offset="0%" stopColor={currentLevel.color} />
                <stop offset="100%" stopColor={currentLevel.secondaryColor} />
              </linearGradient>
            </defs>
            <circle className="longterm-profitability-progress-bg" cx="60" cy="60" r="54" />
            <circle 
              className="longterm-profitability-progress-bar" 
              cx="60" 
              cy="60" 
              r="54"
              style={{
                stroke: 'url(#progressGradient)',
                strokeDasharray: `${scorePercentage * 3.39}, 339.292`
              }}
            />
          </svg>
          
          {/* 中心内容 */}
          <div className="longterm-profitability-circle-content">
            <div 
              className="longterm-profitability-status"
              style={{
                background: `linear-gradient(135deg, ${currentLevel.color}, ${currentLevel.secondaryColor})`,
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent'
              }}
            >
              {currentLevel.text}
            </div>
          </div>
          
          {/* 装饰点 */}
          <div className="longterm-profitability-decoration-dots">
            {[...Array(8)].map((_, i) => (
              <div 
                key={i} 
                className="longterm-profitability-dot"
                style={{
                  transform: `rotate(${i * 45}deg) translateX(140px)`
                }}
              />
            ))}
          </div>
        </div>
      </div>

      <StockProfitabilityMetrics profitabilityData={profitabilityData} />
    </div>
  );
};

export default StockProfitability; 