import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import { getTopETFs, getEtfPriceReturnData } from '../../services/data_api';
import etfDataJson from '../ETF/etf-data.json';
import SectionTitleBar from '../common/SectionTitleBar';
import './TopETFs.css';

const TopETFs = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [etfData, setEtfData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const renderGrade = (grade, type) => {
    if (!grade) return '-';

    const getGradeDescription = (grade, type) => {
      const descriptions = {
        bci: {
          'A': 'Very Strong',
          'B': 'Strong',
          'C': 'Medium',
          'D': 'Weak',
          'E': 'Very Weak'
        },
        premium: {
          'A': 'Very Low',
          'B': 'Low',
          'C': 'Medium',
          'D': 'High',
          'E': 'Very High'
        },
        risk: {
          'A': 'Very Low',
          'B': 'Low',
          'C': 'Medium',
          'D': 'High',
          'E': 'Very High'
        },
        recommendation: {
          'A': 'Strong Buy',
          'B': 'Buy',
          'C': 'Hold',
          'D': 'Sell',
          'E': 'Strong Sell'
        }
      };
      return descriptions[type]?.[grade] || '';
    };

    if (type === 'bci' || type === 'recommendation') {
      const getIndicatorStyle = (grade) => {
        const position = {
          'A': 90,
          'B': 70,
          'C': 50,
          'D': 30,
          'E': 10
        }[grade] || 50;

        return {
          position: `${position}%`,
          gradient: 'linear-gradient(to right, #ea4335, #fbbc04, #34a853)'
        };
      };

      const { position, gradient } = getIndicatorStyle(grade);

      return (
        <div className="etf-screener-indicator">
          <div className="etf-screener-indicator-bar" style={{ background: gradient }}>
            <div 
              className="etf-screener-indicator-pointer"
              style={{ left: position }}
            />
          </div>
          <div className="etf-screener-indicator-label">
            {getGradeDescription(grade, type)}
          </div>
        </div>
      );
    }

    const bars = ['A', 'B', 'C', 'D', 'E'];
    const gradeIndex = bars.indexOf(grade);
    
    return (
      <div className="etf-screener-indicator">
        <div className="etf-screener-bars-container">
          {bars.map((bar, index) => (
            <div 
              key={bar}
              className={`etf-screener-bar-wrapper ${index <= gradeIndex ? 'active' : ''}`}
            >
              <div className={`etf-screener-bar ${type.toLowerCase()}-${bar}`} />
            </div>
          ))}
        </div>
        <div className="etf-screener-indicator-label">
          {getGradeDescription(grade, type)}
        </div>
      </div>
    );
  };

  const columns = [
    {
      key: 'etf',
      label: 'Ticker',
      align: 'left',
      render: (value) => (
        <span 
          className="etf-screener-ticker"
          onClick={() => navigate(`/products/btc/etf/${value}`)}
        >
          {value}
        </span>
      )
    },
    {
      key: 'type',
      label: 'Type',
      align: 'left'
    },
    {
      key: 'current_price',
      label: 'Price (Return)',
      align: 'right',
      render: (_, etf) => {
        if (!etf.current_price) return '-';
        return (
          <div className="etf-screener-price">
            <span className="etf-screener-price-value">
              ${etf.current_price.toFixed(2)}
            </span>
            {etf.current_return && (
              <span className={`etf-screener-return ${etf.current_return >= 0 ? 'positive' : 'negative'}`}>
                ({etf.current_return >= 0 ? '+' : ''}{etf.current_return.toFixed(2)}%)
              </span>
            )}
          </div>
        );
      }
    },
    {
      key: 'expense_ratio',
      label: 'Expense Ratio',
      align: 'right',
      render: (value, etf) => (
        <div className="etf-screener-metric">
          <span className="etf-screener-metric-value">{value}%</span>
          <span className={`etf-screener-metric-rank ${etf.expense_ratio_rank.toLowerCase()}`}>
            {etf.expense_ratio_rank}
          </span>
        </div>
      )
    },
    {
      key: 'market_cap',
      label: 'Market Cap',
      align: 'right',
      render: (value, etf) => (
        <div className="etf-screener-metric">
          <span className="etf-screener-metric-value">${value.toFixed(2)}B</span>
          <span className={`etf-screener-metric-rank ${etf.market_cap_rank.toLowerCase()}`}>
            {etf.market_cap_rank}
          </span>
        </div>
      )
    },
    {
      key: 'bci',
      label: 'Correlation_BTC',
      tooltip: 'tooltips.bci',
      align: 'center',
      render: (value) => renderGrade(value, 'bci')
    },
    {
      key: 'vp',
      label: 'Premium',
      tooltip: 'tooltips.vp',
      align: 'center',
      render: (value) => renderGrade(value, 'premium')
    },
    {
      key: 'risk',
      label: 'Risk',
      tooltip: 'tooltips.risk',
      align: 'center',
      render: (value) => renderGrade(value, 'risk')
    },
    {
      key: 'recommendation',
      label: 'Recommendation',
      tooltip: 'tooltips.recommendation',
      align: 'center',
      render: (value) => renderGrade(value, 'recommendation')
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getTopETFs();
        
        // 并行获取所有ETF的价格数据
        const pricePromises = response.data.map(etf => 
          getEtfPriceReturnData(etf.etf)
            .then(priceData => ({
              ticker: etf.etf,
              ...priceData
            }))
        );

        const priceResults = await Promise.all(pricePromises);
        
        // 合并数据
        const enrichedData = response.data.map(etf => {
          const priceData = priceResults.find(p => p.ticker === etf.etf);
          const jsonData = etfDataJson.etfs.find(e => e.ETF === etf.etf) || {};
          return {
            ...etf,
            name: jsonData.Name || etf.etf,
            type: jsonData.Type || 'Unknown',
            current_price: priceData?.current_price,
            current_return: priceData?.current_return
          };
        });

        setEtfData({ data: enrichedData });
        setLoading(false);
      } catch (err) {
        console.error('Error fetching top ETFs:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!etfData?.data) return null;

  return (
    <div className="top-etfs-section">
      <div className="top-etfs-header">
        <SectionTitleBar title="Top ETFs" />
        <p className="top-etfs-description">
          Top ETFs showcase the most representative Bitcoin-related <span className="highlight">ETFs</span> across three categories: 
          <span className="highlight"> Spot</span>, <span className="highlight">Futures</span>, and <span className="highlight">Equities</span>. 
          Each ETF is selected for its <span className="highlight">strong historical performance</span>, 
          <span className="highlight">growth potential</span>, and <span className="highlight">manageable risks</span>, 
          supported by <span className="highlight">quantitative modeling</span> (featuring 
          strong Correlation_BTC, Low Premium, and Low Risk), <span className="highlight">diversification strategies</span>, and 
          <span className="highlight"> analysts' insights</span>.
        </p>
      </div>

      <div className="etf-screener-table-wrapper">
        <table className="etf-screener-table">
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.key} style={{ textAlign: column.align }}>
                  {column.tooltip ? (
                    <Tooltip content={t(column.tooltip)} forceDirection="top">
                      <span>{column.label}</span>
                    </Tooltip>
                  ) : (
                    <span>{column.label}</span>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {etfData.data.map(etf => (
              <tr key={etf.etf}>
                {columns.map(column => (
                  <td key={column.key} style={{ textAlign: column.align }}>
                    {column.render ? column.render(etf[column.key], etf) : etf[column.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button 
        className="top-etfs-explore-button"
        onClick={() => {
          navigate('/products/btc/etf-screener');
        }}
      >
        <span className="top-etfs-explore-title">Explore All ETFs</span>
        <span className="top-etfs-explore-description">
          See the full list of BTC-related ETFs and access advanced filtering options
        </span>
      </button>
    </div>
  );
};

export default TopETFs; 