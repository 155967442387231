import React from 'react';
import './StockGrowth.css';
import StockGrowthChart from './StockGrowthChart';
import Tooltip from '../../Tooltip';

const RatingIndicator = ({ rating }) => {
  const getLabel = (rating) => {
    switch (rating) {
      case 'A': return 'Very High';
      case 'B': return 'High';
      case 'C': return 'Medium';
      case 'D': return 'Low';
      case 'E': return 'Very Low';
      default: return 'N/A';
    }
  };

  const getColor = (rating) => {
    switch (rating) {
      case 'A': return '#43a047'; // Very High - Dark Green
      case 'B': return '#66bb6a'; // High - Light Green
      case 'C': return '#ffa726'; // Medium - Orange
      case 'D': return '#ff7043'; // Low - Light Red
      case 'E': return '#ef5350'; // Very Low - Dark Red
      default: return '#9E9E9E';
    }
  };

  const getPosition = (rating) => {
    switch (rating) {
      case 'E': return -144; // Very Low (左侧)
      case 'D': return -72;  // Low
      case 'C': return 0;    // Medium
      case 'B': return 72;   // High
      case 'A': return 144;  // Very High (右侧)
      default: return 0;
    }
  };

  return (
    <div className="longterm-growth-rating">
      <div className="longterm-growth-rating-gauge">
        <div className="longterm-growth-rating-circle">
          <div className="longterm-growth-rating-segments">
            {['E', 'D', 'C', 'B', 'A'].map((g) => (
              <div
                key={g}
                className={`longterm-growth-rating-segment segment-${g}`}
                style={{
                  opacity: g === rating ? 1 : 0.3
                }}
              />
            ))}
          </div>
          <div 
            className="longterm-growth-rating-needle"
            style={{
              transform: `rotate(${getPosition(rating)}deg)`,
              backgroundColor: '#1a1a1a'
            }}
          />
          <div className="longterm-growth-rating-center" />
        </div>
      </div>
      <div className="longterm-growth-rating-labels">
        <span className={rating === 'E' ? 'active' : ''}>Very Low</span>
        <span className={rating === 'D' ? 'active' : ''}>Low</span>
        <span className={rating === 'C' ? 'active' : ''}>Medium</span>
        <span className={rating === 'B' ? 'active' : ''}>High</span>
        <span className={rating === 'A' ? 'active' : ''}>Very High</span>
      </div>
      <div 
        className="longterm-growth-rating-value" 
        style={{ color: getColor(rating) }}
      >
        {getLabel(rating)}
      </div>
    </div>
  );
};

const METRIC_TOOLTIPS = {
  eps_growth: `Earnings Per Share Growth measures the percentage increase in a company's EPS over time. It indicates the company's profitability growth and its ability to increase shareholder value. Strong EPS growth suggests improving business performance and potential for higher stock returns.`,
  
  revenue_growth: `Revenue Growth shows the percentage increase in a company's total sales over time. It indicates market demand for the company's products/services and its ability to expand its business. Consistent revenue growth suggests strong market position and effective business strategy.`,
  
  ebitda_growth: `EBITDA Growth measures the increase in earnings before interest, taxes, depreciation, and amortization. It shows the growth in core operational profitability, excluding financial and accounting decisions. Strong EBITDA growth indicates improving operational efficiency and business expansion.`,
  
  free_cash_flow_growth: `Free Cash Flow Growth shows the increase in cash generated after capital expenditures. It indicates the company's ability to generate increasing cash for shareholders, debt reduction, or reinvestment. Strong FCF growth suggests improving financial flexibility and business sustainability.`,
  
  operating_cash_flow_growth: `Operating Cash Flow Growth measures the increase in cash generated from core business operations. It shows the company's ability to generate increasing cash from its main business activities. Strong growth indicates improving operational efficiency and healthy business expansion.`,
  
  working_capital_growth: `Working Capital Growth shows the increase in funds available for day-to-day operations. It indicates the company's ability to manage short-term assets and liabilities effectively. Positive growth suggests improving operational efficiency and financial health.`,
  
  roe_growth: `Return on Equity Growth measures the increase in the company's efficiency at generating profits from shareholders' equity. It indicates improving profitability and management effectiveness. Consistent ROE growth suggests sustainable value creation for shareholders.`
};

const EPSMetrics = ({ epsData }) => {
  const formatValue = (value) => {
    if (value === null || value === undefined) return '-';
    return `$${Math.abs(value).toFixed(2)}`;
  };

  const isNegative = (value) => {
    return value < 0;
  };

  const getValueClassName = (value) => {
    if (value === null || value === undefined) return '';
    return `longterm-growth-eps-value ${isNegative(value) ? 'negative' : 'positive'}`;
  };

  return (
    <div className="longterm-growth-eps">
      <h3 className="longterm-growth-eps-title">EPS (Quarterly)</h3>
      <div className="longterm-growth-eps-grid">
        <div className="longterm-growth-eps-item">
          <span className="longterm-growth-eps-label">Current</span>
          <span className={getValueClassName(epsData?.current)}>
            {isNegative(epsData?.current) ? '-' : ''}{formatValue(epsData?.current)}
          </span>
        </div>
        <div className="longterm-growth-eps-item">
          <span className="longterm-growth-eps-label">Estimate</span>
          <span className={getValueClassName(epsData?.estimate)}>
            {isNegative(epsData?.estimate) ? '-' : ''}{formatValue(epsData?.estimate)}
          </span>
        </div>
        <div className="longterm-growth-eps-item">
          <span className="longterm-growth-eps-label">Previous</span>
          <span className={getValueClassName(epsData?.previous)}>
            {isNegative(epsData?.previous) ? '-' : ''}{formatValue(epsData?.previous)}
          </span>
        </div>
      </div>
    </div>
  );
};

const GrowthMetrics = ({ growthData }) => {
  const formatPercentage = (value) => {
    if (value === null || value === undefined) return '-';
    const formattedValue = (value * 100).toFixed(2);
    return `${formattedValue}%`;
  };

  const metrics = [
    [
      {
        title: 'EPS Growth',
        tooltip: METRIC_TOOLTIPS.eps_growth,
        quarterly: growthData?.quarterly?.diluted_eps_growth,
        yoy: growthData?.yoy?.diluted_eps_growth
      },
      {
        title: 'Revenue Growth',
        tooltip: METRIC_TOOLTIPS.revenue_growth,
        quarterly: growthData?.quarterly?.total_revenue_growth,
        yoy: growthData?.yoy?.total_revenue_growth
      },
      {
        title: 'EBITDA Growth',
        tooltip: METRIC_TOOLTIPS.ebitda_growth,
        quarterly: growthData?.quarterly?.ebitda_growth,
        yoy: growthData?.yoy?.ebitda_growth
      },
      {
        title: 'EBIT Growth',
        quarterly: growthData?.quarterly?.ebit_growth,
        yoy: growthData?.yoy?.ebit_growth
      }
    ],
    [
      {
        title: 'Free Cash Flow Growth',
        tooltip: METRIC_TOOLTIPS.free_cash_flow_growth,
        quarterly: growthData?.quarterly?.free_cash_flow_growth,
        yoy: growthData?.yoy?.free_cash_flow_growth
      },
      {
        title: 'Operating Cash Flow Growth',
        tooltip: METRIC_TOOLTIPS.operating_cash_flow_growth,
        quarterly: growthData?.quarterly?.operating_cash_flow_growth,
        yoy: growthData?.yoy?.operating_cash_flow_growth
      },
      {
        title: 'Working Capital Growth',
        tooltip: METRIC_TOOLTIPS.working_capital_growth,
        quarterly: growthData?.quarterly?.working_capital_growth,
        yoy: growthData?.yoy?.working_capital_growth
      },
      {
        title: 'ROE Growth',
        tooltip: METRIC_TOOLTIPS.roe_growth,
        quarterly: growthData?.quarterly?.roe_growth,
        yoy: growthData?.yoy?.roe_growth
      }
    ]
  ];

  const MetricCard = ({ metric }) => (
    <div className="longterm-growth-metric-card">
      <div className="longterm-growth-metric-title">
        {metric.title}
        {metric.tooltip && <Tooltip content={metric.tooltip} />}
      </div>
      <div className="longterm-growth-metric-values">
        <div className="longterm-growth-metric-row">
          <span className="longterm-growth-metric-period">Quarterly</span>
          <span className={`longterm-growth-metric-value ${metric.quarterly < 0 ? 'negative' : 'positive'}`}>
            {formatPercentage(metric.quarterly)}
          </span>
        </div>
        <div className="longterm-growth-metric-row">
          <span className="longterm-growth-metric-period">YoY</span>
          <span className={`longterm-growth-metric-value ${metric.yoy < 0 ? 'negative' : 'positive'}`}>
            {formatPercentage(metric.yoy)}
          </span>
        </div>
      </div>
    </div>
  );

  return (
    <div className="longterm-growth-metrics">
      {metrics.map((row, rowIndex) => (
        <div key={rowIndex} className="longterm-growth-metrics-row">
          {row.map((metric, index) => (
            <MetricCard key={index} metric={metric} />
          ))}
        </div>
      ))}
    </div>
  );
};

const StockGrowth = ({ screenerData, epsData, growthData, historyData, ticker, errors }) => {
  // 创建默认数据，以防数据缺失
  const safeGrowthData = growthData || { yoy: {}, quarterly: {} };
  
  // 检查是否有任何错误需要显示
  const hasErrors = !growthData || !historyData || errors?.noGrowthData || errors?.noHistoryData;
  
  // 构建错误消息
  let errorMessage = "Some growth data could not be loaded. Displaying available information.";
  if (errors?.noGrowthData && errors?.noHistoryData) {
    errorMessage = "Failed to fetch growth rates data and revenue/eps history. Displaying available information.";
  } else if (errors?.noGrowthData) {
    errorMessage = "Failed to fetch growth rates data. Displaying available information.";
  } else if (errors?.noHistoryData) {
    errorMessage = "Failed to fetch revenue and eps history. Displaying available information.";
  }
  
  return (
    <div className="longterm-growth-container">
      {/* 显示错误提示，但不阻止组件渲染 */}
      {hasErrors && (
        <div className="longterm-growth-warning">
          <div className="longterm-growth-warning-icon">⚠️</div>
          <p>{errorMessage}</p>
        </div>
      )}
      
      {/* 上半部分 - EPS和Growth Rating */}
      <div className="longterm-growth-overview">
        <div className="longterm-growth-content">
          <div className="longterm-growth-left">
            <EPSMetrics epsData={epsData || {}} />
          </div>
          <div className="longterm-growth-rating-section">
            <h3 className="longterm-growth-title">Growth</h3>
            <RatingIndicator rating={screenerData?.growth || 'C'} />
          </div>
        </div>
      </div>

      {/* 下半部分 - Growth Metrics */}
      <div className="longterm-growth-details">
        <GrowthMetrics growthData={safeGrowthData} />
      </div>

      {/* 添加历史走势图 - 只在有数据时显示 */}
      {historyData ? (
        <StockGrowthChart historyData={historyData} ticker={ticker} />
      ) : (
        <div className="stock-growth-chart">
          <div className="stock-growth-chart-header">
            <div className="stock-growth-chart-title">
              {ticker} - Revenue & EPS History
            </div>
          </div>
          <div className="stock-growth-chart-empty">
            <i className="fas fa-database stock-growth-chart-empty-icon"></i>
            <p className="stock-growth-chart-empty-text">Revenue and EPS history data is not available</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default StockGrowth; 