import React from 'react';
import './StockRisk.css';
import { formatNumber } from '../../../utils/formatters';
import Tooltip from '../../Tooltip';

const RiskGauge = ({ grade }) => {
  const getGradeColor = (grade) => {
    switch (grade) {
      case 'A': return '#2E7D32';
      case 'B': return '#4CAF50';
      case 'C': return '#FFA726';
      case 'D': return '#EF5350';
      case 'E': return '#B71C1C';
      default: return '#FFA726';
    }
  };

  const getGradeText = (grade) => {
    switch (grade) {
      case 'A': return 'Very Low';
      case 'B': return 'Low';
      case 'C': return 'Medium';
      case 'D': return 'High';
      case 'E': return 'Very High';
      default: return 'Medium';
    }
  };

  return (
    <div className="longterm-risk-gauge">
      <div className="longterm-risk-gauge-circle">
        <div className="longterm-risk-gauge-segments">
          {['A', 'B', 'C', 'D', 'E'].map((segment) => (
            <div
              key={segment}
              className={`longterm-risk-gauge-segment segment-${segment} ${
                segment === grade ? 'active' : ''
              }`}
            />
          ))}
        </div>
      </div>
      <div className="longterm-risk-gauge-value" style={{ color: getGradeColor(grade) }}>
        {getGradeText(grade)}
      </div>
    </div>
  );
};

const METRIC_TOOLTIPS = {
  turnover_ratio: `Turnover Ratio measures the percentage of a company's shares that are traded over a period. High turnover suggests active trading and good liquidity, but may also indicate higher volatility. Low turnover might suggest stable ownership but potentially limited liquidity.`,

  max_drawdown: `Maximum Drawdown represents the largest peak-to-trough decline in an investment's value over a specific period. It measures the worst possible loss an investor could have experienced. Lower drawdowns indicate better downside protection and risk management.`,

  var_95: `Value at Risk (VaR) at 5% confidence level estimates the potential loss that won't be exceeded 95% of the time. It helps quantify the downside risk of an investment. A lower VaR suggests lower potential losses and less risk.`,

  sharpe_ratio: `Sharpe Ratio measures risk-adjusted return by comparing excess returns to standard deviation. It shows how much excess return you receive for the volatility of holding a riskier asset. Higher ratios indicate better risk-adjusted performance.`,

  current_ratio: `Current Ratio measures a company's ability to pay short-term obligations by comparing current assets to current liabilities. A ratio above 1 indicates good short-term liquidity, while ratios below 1 might suggest potential liquidity problems.`,

  roa: `Return on Assets (ROA) measures how efficiently a company uses its assets to generate profits. It shows the percentage of profit a company earns relative to its total assets. Higher ROA indicates more efficient asset utilization and better management effectiveness.`,

  interest_coverage_ratio: `Interest Coverage Ratio measures how easily a company can pay its interest obligations from earnings. It indicates the margin of safety in meeting debt obligations. Higher ratios suggest stronger ability to service debt and lower financial risk.`,

  altman_z_score: `Altman Z-Score is a formula that predicts the probability of a company going bankrupt. Scores above 3 indicate financial strength, while scores below 1.8 suggest potential financial distress. The model combines multiple financial ratios to assess overall financial health.`
};

const MetricCard = ({ label, value, tooltip }) => (
  <div className="longterm-risk-metric-card">
    <div className="longterm-risk-metric-label">
      {label}
      {tooltip && <Tooltip content={tooltip} />}
    </div>
    <div className="longterm-risk-metric-value">
      {value ?? 'N/A'}
    </div>
  </div>
);

const StockRisk = ({ data, isLoading, error }) => {
  if (isLoading) {
    return (
      <div className="longterm-risk-container">
        <div className="longterm-risk-loading">Loading risk data...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="longterm-risk-container">
        <div className="longterm-risk-error">
          Failed to load risk data. Please try again later.
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="longterm-risk-container">
        <div className="longterm-risk-error">No risk data available.</div>
      </div>
    );
  }

  const formatPercentage = (value) => {
    if (value == null) return 'N/A';
    return `${(value * 100).toFixed(2)}%`;
  };

  const formatDecimal = (value, decimals = 2) => {
    if (value == null) return 'N/A';
    return value.toFixed(decimals);
  };

  const formatVolume = (value) => {
    if (value == null) return 'N/A';
    return formatNumber(value);
  };

  return (
    <div className="longterm-risk-container">
      {/* Trading Risk Section */}
      <div className="longterm-risk-section">
        <h2 className="longterm-risk-title">Trading Risk</h2>
        <div className="longterm-risk-content">
          <RiskGauge grade={data.tri_grade || 'C'} />
          <div className="longterm-risk-metrics">
            <div className="longterm-risk-metrics-grid">
              <MetricCard 
                label="Volatility (5Y)"
                value={formatPercentage(data.volatility)}
                tooltip="Measures the degree of variation in the stock's price over time"
              />
              <MetricCard 
                label="Beta (5Y)"
                value={formatDecimal(data.beta_spy)}
                tooltip="Measures the stock's sensitivity to market movements relative to the S&P 500"
              />
              <MetricCard 
                label="Volume (60D)"
                value={formatVolume(data.volume_60d_avg)}
                tooltip="Average daily trading volume over the past 60 days"
              />
              <MetricCard 
                label="Turnover ratio (60D)"
                value={formatPercentage(data.turnover_ratio)}
                tooltip={METRIC_TOOLTIPS.turnover_ratio}
              />
              <MetricCard 
                label="Maximum drawdown (5Y)"
                value={formatPercentage(data.max_drawdown)}
                tooltip={METRIC_TOOLTIPS.max_drawdown}
              />
              <MetricCard 
                label="VaR(5%) (5Y)"
                value={formatPercentage(data.var_95)}
                tooltip={METRIC_TOOLTIPS.var_95}
              />
              <MetricCard 
                label="Sharpe ratio (5Y)"
                value={formatDecimal(data.sharpe_ratio)}
                tooltip={METRIC_TOOLTIPS.sharpe_ratio}
              />
              <MetricCard 
                label="Sortino Ratio (5Y)"
                value={formatDecimal(data.sortino_ratio)}
                tooltip="Risk-adjusted return focusing on downside volatility"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Financial Risk Section */}
      <div className="longterm-risk-section">
        <h2 className="longterm-risk-title">Financial Risk</h2>
        <div className="longterm-risk-content">
          <RiskGauge grade={data.fri_rank || 'C'} />
          <div className="longterm-risk-metrics">
            <div className="longterm-risk-metrics-grid">
              <MetricCard 
                label="Debt to Equity (mrq)"
                value={formatPercentage(data.debt_to_equity / 100)}
                tooltip="Total debt relative to shareholders' equity"
              />
              <MetricCard 
                label="Current Ratio (mrq)"
                value={formatDecimal(data.current_ratio)}
                tooltip={METRIC_TOOLTIPS.current_ratio}
              />
              <MetricCard 
                label="Profit Margin"
                value={formatPercentage(data.profit_margin)}
                tooltip="Net income as a percentage of revenue"
              />
              <MetricCard 
                label="ROA (ttm)"
                value={formatPercentage(data.roa)}
                tooltip={METRIC_TOOLTIPS.roa}
              />
              <MetricCard 
                label="Interest Coverage Ratio"
                value={formatDecimal(data.interest_coverage_ratio)}
                tooltip={METRIC_TOOLTIPS.interest_coverage_ratio}
              />
              <MetricCard 
                label="Quick Ratio (mrq)"
                value={formatDecimal(data.quick_ratio)}
                tooltip="Liquid assets relative to current liabilities"
              />
              <MetricCard 
                label="Cash Flow Coverage"
                value={formatDecimal(data.cash_flow_interest_coverage_ratio)}
                tooltip="Operating cash flow relative to interest expenses"
              />
              <MetricCard 
                label="Altman Z-Score"
                value={formatDecimal(data.altman_z_score)}
                tooltip={METRIC_TOOLTIPS.altman_z_score}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockRisk; 