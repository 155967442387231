import React, { useState, useEffect } from 'react';
import { getSectorETFHeatScore, getSectorETFFundFlows } from '../../../services/data_api';
import ReactECharts from 'echarts-for-react';
import './SectorHeat.css';
import Tooltip from '../../Tooltip';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

const HEAT_TOOLTIPS = {
  heat_score: `The Sector Heat Score is a comprehensive metric that combines various technical indicators to measure the overall market sentiment and momentum of the sector ETF.`,
  
  rvol: `Relative Volume compares the current trading volume to the average volume over a specific period, indicating whether the current trading activity is higher or lower than usual.`,
  
  relative_fund_flow: `Relative Fund Flow compares the current fund flow to the historical average, showing whether the current capital movement is stronger or weaker than typical.`,
  
  fund_flow_aum: `Fund Flow to AUM Ratio measures the net fund flow relative to the fund's total assets under management, indicating the significance of capital movement relative to fund size.`,
  
  momentum: `Momentum measures the rate of acceleration of the ETF's price movement, indicating the strength and continuity of a price trend.`,
  
  rsi: `Relative Strength Index (RSI) measures the speed and magnitude of recent price changes to evaluate overbought or oversold conditions, with values above 70 indicating overbought and below 30 indicating oversold.`,
  
  macd: `Moving Average Convergence Divergence (MACD) shows the relationship between two moving averages of an ETF's price, helping identify changes in trend direction, strength, and momentum.`
};

const SectorHeat = ({ data }) => {
  const [heatData, setHeatData] = useState(null);
  const [flowData, setFlowData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [heatResponse, flowResponse] = await Promise.all([
          getSectorETFHeatScore(data.etf),
          getSectorETFFundFlows(data.etf)
        ]);
        
        // 确保正确获取热力数据
        setHeatData(heatResponse.data);  // 修改这里，使用 response.data
        setFlowData(flowResponse.data);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [data.etf]);

  const formatNumber = (value) => {
    if (value === undefined || value === null) return 'N/A';
    return Number(value).toFixed(2);
  };

  const getGradeColor = (grade) => {
    switch (grade) {
      case 'A': return '#1B5E20';
      case 'B': return '#2E7D32';
      case 'C': return '#FFC107';
      case 'D': return '#D32F2F';
      case 'E': return '#B71C1C';
      default: return '#666666';
    }
  };

  const getHeatIcon = (grade) => {
    const baseClass = "sector-heat-icon fas";
    switch (grade) {
      case 'A':
        return `${baseClass} fa-fire heat-excellent`; // 火焰 - 最热
      case 'B':
        return `${baseClass} fa-temperature-high heat-good`; // 高温
      case 'C':
        return `${baseClass} fa-temperature-half heat-neutral`; // 中温
      case 'D':
        return `${baseClass} fa-temperature-low heat-poor`; // 低温
      case 'E':
        return `${baseClass} fa-snowflake heat-cold`; // 寒冷
      default:
        return `${baseClass} fa-temperature-empty`;
    }
  };

  const getFlowChartOption = () => {
    if (!flowData || !Array.isArray(flowData)) {
      return { title: { text: 'No data available' } };
    }

    const filterDataByRange = (data, range) => {
      if (range === 'ALL') return data;
      const cutoffDate = new Date();
      cutoffDate.setDate(cutoffDate.getDate() - TIME_RANGES.find(r => r.label === range).days);
      return data.filter(item => new Date(item.date) >= cutoffDate);
    };

    // 将数据转换为百万单位
    const convertToMillions = (value) => {
      return value * 1000; // 假设原始数据单位为十亿(B)，转换为百万(M)
    };

    const validData = filterDataByRange(flowData, selectedRange);
    const processedData = validData.map(item => ({
      ...item,
      fund_flow: convertToMillions(item.fund_flow)
    }));

    return {
      backgroundColor: '#ffffff',
      tooltip: {
        trigger: 'axis',
        axisPointer: { 
          type: 'cross',
          lineStyle: {
            color: '#1a73e8',
            width: 1,
            opacity: 0.5
          }
        },
        formatter: function(params) {
          const date = params[0].axisValue;
          const flow = params[0].data;
          return `<div style="padding: 8px;">
            <div style="margin-bottom: 8px; font-weight: 600; font-size: 14px;">${date}</div>
            <div style="color: ${flow >= 0 ? '#34a853' : '#ea4335'}; font-size: 16px; font-weight: 500;">
              Fund Flow: ${flow.toFixed(2)}M
            </div>
          </div>`;
        },
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
        borderColor: '#eee',
        borderWidth: 1,
        padding: [5, 10],
        textStyle: {
          color: '#333'
        }
      },
      grid: {
        left: '8%',
        right: '10%',
        bottom: '15%',
        top: '8%',
        containLabel: true
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 5,
          borderColor: '#ddd',
          fillerColor: 'rgba(26, 115, 232, 0.1)',
          handleStyle: {
            color: '#1a73e8',
            borderColor: '#1a73e8'
          },
          emphasis: {
            handleStyle: {
              color: '#1557b0'
            }
          },
          textStyle: {
            color: '#666'
          },
          borderRadius: 3
        }
      ],
      xAxis: {
        type: 'category',
        data: processedData.map(item => item.date),
        axisLabel: {
          rotate: 45,
          color: '#666',
          fontSize: 12,
          fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif"
        },
        axisLine: {
          lineStyle: {
            color: '#ddd'
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f5f5f5'
          }
        }
      },
      yAxis: {
        type: 'value',
        name: 'Fund Flow (Million $)',
        nameLocation: 'end',
        nameGap: 15,
        nameRotate: 0,
        position: 'right',
        nameTextStyle: {
          fontSize: 14,
          fontWeight: 500,
          fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
          color: '#666',
          padding: [0, 0, 0, 10]
        },
        axisLabel: {
          formatter: '{value}M',
          color: '#666',
          fontSize: 12,
          fontFamily: "'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif",
          align: 'left'
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#ddd'
          }
        },
        splitLine: {
          lineStyle: {
            color: '#f5f5f5'
          }
        }
      },
      series: [
        {
          name: 'Fund Flow',
          type: 'bar',
          data: processedData.map(item => item.fund_flow),
          itemStyle: {
            color: (params) => {
              return params.data >= 0 ? '#34a853' : '#ea4335';
            },
            borderRadius: [2, 2, 0, 0]
          },
          emphasis: {
            itemStyle: {
              opacity: 0.8
            }
          },
          barWidth: '60%',
          animation: true,
          animationDuration: 1000
        }
      ]
    };
  };

  if (loading) {
    return (
      <div className="sector-heat-container">
        <div className="sector-heat-loading">Loading heat data...</div>
      </div>
    );
  }

  if (error || !heatData) {
    return (
      <div className="sector-heat-container">
        <div className="sector-heat-error">Failed to load heat data</div>
      </div>
    );
  }

  return (
    <div className="sector-heat-container">
      <div className="sector-heat-header">
        <div className="sector-heat-title">
          Sector Heat Score
          <Tooltip content={HEAT_TOOLTIPS.heat_score} />
        </div>
        <div className="sector-heat-score-wrapper">
          <div className="sector-heat-score">
            {formatNumber(heatData.heat_score)}
          </div>
          <i className={getHeatIcon(heatData.grade)}></i>
        </div>
      </div>

      <div className="sector-heat-metrics-grid">
        <div className="sector-heat-metric">
          <div className="sector-heat-metric-label">
            Relative Volume:
            <Tooltip content={HEAT_TOOLTIPS.rvol} />
          </div>
          <div className="sector-heat-metric-value">{formatNumber(heatData.rvol)}</div>
        </div>

        <div className="sector-heat-metric">
          <div className="sector-heat-metric-label">
            Relative Fund Flow:
            <Tooltip content={HEAT_TOOLTIPS.relative_fund_flow} />
          </div>
          <div className="sector-heat-metric-value">{formatNumber(heatData.relative_fund_flow)}</div>
        </div>

        <div className="sector-heat-metric">
          <div className="sector-heat-metric-label">
            Fund Flow/AUM:
            <Tooltip content={HEAT_TOOLTIPS.fund_flow_aum} />
          </div>
          <div className="sector-heat-metric-value">{formatNumber(heatData.fund_flow_aum)}</div>
        </div>

        <div className="sector-heat-metric">
          <div className="sector-heat-metric-label">
            Momentum:
            <Tooltip content={HEAT_TOOLTIPS.momentum} />
          </div>
          <div className="sector-heat-metric-value">{formatNumber(heatData.momentum)}</div>
        </div>

        <div className="sector-heat-metric">
          <div className="sector-heat-metric-label">
            RSI:
            <Tooltip content={HEAT_TOOLTIPS.rsi} />
          </div>
          <div className="sector-heat-metric-value">{formatNumber(heatData.rsi)}</div>
        </div>

        <div className="sector-heat-metric">
          <div className="sector-heat-metric-label">
            MACD:
            <Tooltip content={HEAT_TOOLTIPS.macd} />
          </div>
          <div className="sector-heat-metric-value">{formatNumber(heatData.macd)}</div>
        </div>
      </div>

      {/* Historical Fund Flow Chart */}
      <div className="sector-heat-chart-section">
        <div className="sector-heat-chart-header">
          <h2>Historical Fund Flow</h2>
          <div className="sector-heat-time-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`sector-heat-time-range-button ${selectedRange === range.label ? 'active' : ''}`}
                onClick={() => setSelectedRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>
        <ReactECharts 
          option={getFlowChartOption()} 
          style={{ height: '600px' }}
          className="sector-heat-chart"
        />
      </div>
    </div>
  );
};

export default SectorHeat; 