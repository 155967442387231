import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";
import { useTranslation } from 'react-i18next';
import Tooltip from '../../Tooltip';
import './StockRecommendation.css';

const RATING_TOOLTIPS = {
  model_rating: `The Model Rating is based on a comprehensive analysis of all stock indicators, scored from 1 to 5, where higher values indicate greater potential.`,
  
  technical_rating: `The Technical Rating is a scoring system derived from commonly used technical indicators, providing insights into market trends and potential price movements.`,
  
  wall_street_rating: `The Wall Street Rating reflects evaluations provided by Wall Street analysts, offering a perspective on the stock's performance and market sentiment.`,
  
  analysts_rating: `The Analysts Rating is given by professional analysts, summarizing their insights and recommendations regarding a stock's potential and risks.`
};

const TECHNICAL_TOOLTIPS = {
  rsi: `The RSI measures the speed and change of price movements, indicating overbought (above 70) or oversold (below 30) conditions.`,
  
  macd: `MACD is a trend-following momentum indicator that shows the relationship between two moving averages, helping identify potential buy or sell signals.`,
  
  stoch: `The Stochastic Oscillator compares a security's closing price to its price range over a specific period, indicating momentum and potential overbought (above 80) or oversold (below 20) conditions.`,
  
  adx: `ADX measures the strength of a trend, with values above 25 indicating a strong trend and below 20 suggesting a weak trend.`,
  
  cci: `CCI measures the deviation of the price from its average, with values above 100 indicating overbought conditions and below -100 indicating oversold conditions.`,
  
  atr: `ATR measures market volatility by calculating the average range between high and low prices over a specific period, helping assess potential risk.`,
  
  roc: `ROC measures the percentage change in price over a specified time frame, indicating momentum; positive values suggest upward momentum, while negative values indicate downward momentum.`,
  
  williams_r: `Williams %R measures overbought or oversold conditions, with values above -20 indicating overbought and below -80 indicating oversold.`
};

const RecommendationMeter = ({ value }) => {
  return (
    <div className="longterm-recommendation-meter">
      <ReactSpeedometer
        value={value}
        minValue={1}
        maxValue={5}
        segments={5}
        currentValueText=""
        customSegmentLabels={[
          {
            text: "Strong Sell",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Sell",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Hold",
            position: "INSIDE",
            color: "#333",
            fontSize: "11px"
          },
          {
            text: "Buy",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          },
          {
            text: "Strong Buy",
            position: "INSIDE",
            color: "#fff",
            fontSize: "11px"
          }
        ]}
        segmentColors={[
          "#D32F2F",
          "#FF5722",
          "#FDD835",
          "#4CAF50",
          "#2E7D32"
        ]}
        startAngle={0}
        endAngle={180}
        needleColor="#333"
        needleTransition="easeElastic"
        needleTransitionDuration={3000}
        needleHeightRatio={0.7}
        labelFontSize="11px"
        valueTextFontSize="0"
        width={400}
        height={200}
        paddingVertical={20}
        ringWidth={50}
        textColor="#666"
        paddingHorizontal={10}
        segmentSpacing={2}
        maxSegmentLabels={5}
        customSegmentStops={[1, 1.8, 2.6, 3.4, 4.2, 5]}
      />
      <div className="longterm-recommendation-meter-label">
        {value.toFixed(2)}
      </div>
    </div>
  );
};

const RatingBar = ({ label, value, tooltip }) => {
  const percentage = ((value - 1) / 4) * 100;
  
  return (
    <div className="longterm-recommendation-rating-item">
      <div className="longterm-recommendation-rating-label-wrapper">
        <span className="longterm-recommendation-rating-label">
          {label}
          {tooltip && <Tooltip content={tooltip} />}
        </span>
      </div>
      <div className="longterm-recommendation-rating-container">
        <div className="longterm-recommendation-rating-track">
          <div 
            className="longterm-recommendation-rating-progress" 
            style={{ width: `${percentage}%` }}
          />
        </div>
        <div className="longterm-recommendation-rating-value">
          {value.toFixed(2)}
        </div>
      </div>
    </div>
  );
};

const TechnicalIndicator = ({ label, value, tooltip }) => (
  <div className="longterm-recommendation-indicator-card">
    <div className="longterm-recommendation-indicator-content">
      <div className="longterm-recommendation-indicator-label">
        {label}
        {tooltip && <Tooltip content={tooltip} />}
      </div>
      <div className="longterm-recommendation-indicator-value">
        {typeof value === 'number' ? value.toFixed(2) : value}
      </div>
    </div>
  </div>
);

const StockRecommendation = ({ data }) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <div className="longterm-recommendation-container">
      <div className="longterm-recommendation-section">
        <h2 className="longterm-recommendation-section-title">Ratings</h2>
        <div className="longterm-recommendation-header">
          <div className="longterm-recommendation-meter-container">
            <RecommendationMeter value={data.total_rating} />
          </div>
          
          <div className="longterm-recommendation-ratings">
            <RatingBar 
              label="Model Rating" 
              value={data.quant_rating}
              tooltip={RATING_TOOLTIPS.model_rating}
            />
            <RatingBar 
              label="Technical Rating" 
              value={data.technical_rating}
              tooltip={RATING_TOOLTIPS.technical_rating}
            />
            <RatingBar 
              label="Wall Street Rating" 
              value={data.wall_street_rating}
              tooltip={RATING_TOOLTIPS.wall_street_rating}
            />
            <RatingBar 
              label="Analysts Rating" 
              value={data.sa_analysts_rating}
              tooltip={RATING_TOOLTIPS.analysts_rating}
            />
          </div>
        </div>
      </div>

      <div className="longterm-recommendation-section">
        <h2 className="longterm-recommendation-section-title">Technical Indicators</h2>
        <div className="longterm-recommendation-indicators-grid">
          <TechnicalIndicator 
            label="RSI" 
            value={data.rsi}
            tooltip={TECHNICAL_TOOLTIPS.rsi}
          />
          <TechnicalIndicator 
            label="MACD" 
            value={data.macd}
            tooltip={TECHNICAL_TOOLTIPS.macd}
          />
          <TechnicalIndicator 
            label="STOCH K" 
            value={data.stoch_k}
            tooltip={TECHNICAL_TOOLTIPS.stoch}
          />
          <TechnicalIndicator 
            label="STOCH D" 
            value={data.stoch_d}
            tooltip={TECHNICAL_TOOLTIPS.stoch}
          />
          <TechnicalIndicator 
            label="ADX" 
            value={data.adx}
            tooltip={TECHNICAL_TOOLTIPS.adx}
          />
          <TechnicalIndicator 
            label="CCI" 
            value={data.cci}
            tooltip={TECHNICAL_TOOLTIPS.cci}
          />
          <TechnicalIndicator 
            label="ATR" 
            value={data.atr}
            tooltip={TECHNICAL_TOOLTIPS.atr}
          />
          <TechnicalIndicator 
            label="ROC" 
            value={data.roc}
            tooltip={TECHNICAL_TOOLTIPS.roc}
          />
          <TechnicalIndicator 
            label="Williams %R" 
            value={data.williams_r}
            tooltip={TECHNICAL_TOOLTIPS.williams_r}
          />
        </div>
      </div>
    </div>
  );
};

export default StockRecommendation; 