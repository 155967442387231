import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { getLongTermStockScreener } from '../../services/data_api';
import PageLayout from '../PageLayout';
import './LongTermPage.css';
import LongTermOverview from './Overview/LongTermOverview';
import LongTermScreener from './Screener/LongTermScreener';
import LongTermDetails from './LongTermDetails';
import EarningsCalendar from './EarningsCalendar/EarningsCalendar';
import SectorDetails from './SectorDetails/SectorDetails';
import GrowthStrategyPicks from './PrimePicks/GrowthStrategyPicks';
import PinnacleStrategyPicks from './PrimePicks/PinnacleStrategyPicks';
import StableStrategyPicks from './PrimePicks/StableStrategyPicks';

const LongTermPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeCategory, setActiveCategory] = useState('overview');
  const [expandedItems, setExpandedItems] = useState(['long-term-stocks', 'sector-analysis', 'prime-picks']);
  const [stocksByMarketCap, setStocksByMarketCap] = useState({
    'small-cap': [],
    'medium-cap': [],
    'large-cap': []
  });
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const sectorETFs = [
    { etf: 'XLC', name: 'Communication Services', sector: 'Communication Services' },
    { etf: 'XLRE', name: 'Real Estate', sector: 'Real Estate' },
    { etf: 'XLV', name: 'Health Care', sector: 'Healthcare' },
    { etf: 'XLK', name: 'Technology', sector: 'Technology' },
    { etf: 'XLF', name: 'Financial', sector: 'Financial Services' },
    { etf: 'XLP', name: 'Consumer Staples', sector: 'Consumer Staples' },
    { etf: 'XLY', name: 'Consumer Discretionary', sector: 'Consumer Discretionary' },
    { etf: 'XLI', name: 'Industrial', sector: 'Industrials' },
    { etf: 'XLB', name: 'Materials', sector: 'Materials' },
    { etf: 'XLU', name: 'Utilities', sector: 'Utilities' },
    { etf: 'XLE', name: 'Energy', sector: 'Energy' }
  ];

  // 首先添加策略类型常量
  const STRATEGY_TYPES = ['growth', 'pinnacle', 'stable'];

  // 获取股票数据
  useEffect(() => {
    const fetchStockData = async () => {
      try {
        console.log('Fetching stock data...');
        const response = await getLongTermStockScreener();
        const stocks = response?.data || [];
        
        // 添加数据验证
        const validStocks = stocks.filter(stock => 
          stock && 
          stock.ticker && 
          stock.market_cap_rank && 
          typeof stock.market_cap_rank === 'string'
        );
        
        // 按市值分类股票
        const categorizedStocks = validStocks.reduce((acc, stock) => {
          const category = (stock.market_cap_rank || 'small').toLowerCase() + '-cap';
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push({
            ticker: stock.ticker,
            recommendation: stock.recommendation
          });
          return acc;
        }, {
          'small-cap': [],
          'medium-cap': [],
          'large-cap': []
        });

        console.log('Categorized stocks:', categorizedStocks);
        setStocksByMarketCap(categorizedStocks);
      } catch (error) {
        console.error('Error fetching stock data:', error);
        // 设置默认数据
        setStocksByMarketCap({
          'small-cap': [
            { ticker: 'MARA', recommendation: 'B' },
            { ticker: 'RIOT', recommendation: 'B' }
          ],
          'medium-cap': [
            { ticker: 'COIN', recommendation: 'A' },
            { ticker: 'MSTR', recommendation: 'B' }
          ],
          'large-cap': [
            { ticker: 'NVDA', recommendation: 'A' },
            { ticker: 'AMD', recommendation: 'B' }
          ]
        });
      }
    };

    fetchStockData();
  }, []);

  // 修改 location 变化监听逻辑
  useEffect(() => {
    const path = location.pathname;
    console.log('Current path:', path);

    // 处理 sector ETF 路径
    if (path.includes('/long-term/sector/')) {
      const etf = path.split('/').pop();
      console.log('Setting active category for sector:', etf);
      setActiveCategory(`sector-${etf.toLowerCase()}`);
      
      // 确保 Sector Analysis 展开
      if (!expandedItems.includes('sector-analysis')) {
        setExpandedItems(prev => [...prev, 'sector-analysis']);
      }
    } else {
      // 处理其他路径
      const matches = path.match(/\/long-term\/(.*?)(\/|$)/);
      if (matches && matches[1]) {
        setActiveCategory(matches[1]);
      }
    }

    // 检查是否是 PrimePicks 策略路径
    if (path.includes('/prime-picks/')) {
      const strategyMatch = path.match(/\/prime-picks\/(.+)$/);
      if (strategyMatch && STRATEGY_TYPES.includes(strategyMatch[1])) {
        setActiveCategory(`prime-picks-${strategyMatch[1]}`);
        
        if (!expandedItems.includes('prime-picks')) {
          setExpandedItems(prev => [...prev, 'prime-picks']);
        }
      }
    }
  }, [location.pathname]);

  const handleNavigation = (itemId) => {
    setActiveCategory(itemId);
    if (itemId.startsWith('sector/')) {
      navigate(`/long-term/${itemId}`);
    } else {
      navigate(`/long-term/${itemId}`);
    }
  };

  const handleStockClick = (stock, category) => {
    if (!stock || !category) {
      console.error('Invalid stock or category:', { stock, category });
      return;
    }
    
    try {
      setActiveCategory(stock.toLowerCase());
      navigate(`/long-term/${category}/${stock}`);
    } catch (error) {
      console.error('Error in handleStockClick:', error);
      // 可以添加用户提示
      // toast.error('Failed to navigate to stock details');
    }
  };

  const handleSectorETFClick = (etf) => {
    if (!etf) {
      console.error('Invalid ETF:', etf);
      return;
    }
    
    try {
      // 设置活动类别
      const sectorPath = `sector/${etf.toLowerCase()}`;
      console.log('Setting active category to:', `sector-${etf.toLowerCase()}`);
      setActiveCategory(`sector-${etf.toLowerCase()}`);
      
      // 更新展开的项目
      if (!expandedItems.includes('sector-analysis')) {
        setExpandedItems([...expandedItems, 'sector-analysis']);
      }
      
      // 导航到新路径
      const targetPath = `/long-term/${sectorPath}`;
      console.log('Navigating to:', targetPath);
      navigate(targetPath, { replace: true });  // 使用 replace 来避免历史堆栈问题
    } catch (error) {
      console.error('Error in handleSectorETFClick:', error);
    }
  };

  const toggleExpand = (itemId) => {
    setExpandedItems(prev => 
      prev.includes(itemId) 
        ? prev.filter(id => id !== itemId)
        : [...prev, itemId]
    );
  };

  const renderStockList = (category) => {
    if (!category) {
      console.error('Invalid category:', category);
      return null;
    }

    const stocks = stocksByMarketCap[category] || [];
    console.log(`Rendering stock list for ${category}:`, stocks);
    
    return (
      <div className="longterm-stock-list">
        {stocks.map(stock => {
          if (!stock?.ticker) {
            console.error('Invalid stock data:', stock);
            return null;
          }
          
          return (
            <button
              key={stock.ticker}
              className={`longterm-sidebar-item longterm-stock-item ${
                activeCategory === stock.ticker.toLowerCase() ? 'active' : ''
              }`}
              onClick={() => handleStockClick(stock.ticker, category)}
            >
              <span className="item-content">{stock.ticker}</span>
              <span className="longterm-feature-tag longterm-free-tag">FREE</span>
            </button>
          );
        })}
      </div>
    );
  };

  // 添加 PrimePicks 导航处理函数
  const handlePrimePicksNavigation = (strategy) => {
    // 检查用户是否有 PRO 权限
    if (!isSubscriptionValid()) {
      // 如果没有权限，重定向到定价页面
      navigate('/pricing', { 
        state: { 
          from: `/long-term/prime-picks/${strategy}`,
          redirectAfterPurchase: true 
        } 
      });
      return;
    }
    
    // 有权限，正常导航
    setActiveCategory(`prime-picks-${strategy}`);
    navigate(`/long-term/prime-picks/${strategy}`);
  };

  // 检查订阅状态是否有效
  const isSubscriptionValid = () => {
    try {
      const subscriptionData = localStorage.getItem('subscription');
      if (!subscriptionData) return false;

      const subscription = JSON.parse(subscriptionData);
      if (!subscription.data) return false;
      
      return (
        subscription.data.subscription_status === 'active' && 
        new Date(subscription.data.current_period_end) > new Date()
      );
    } catch (error) {
      console.error('Error checking subscription:', error);
      return false;
    }
  };

  // 添加 PrimePicks 渲染函数
  const renderPrimePicks = () => (
    <>
      <button
        className="longterm-sidebar-item group primepicks-header"
        onClick={() => toggleExpand('prime-picks')}
        aria-expanded={expandedItems.includes('prime-picks')}
      >
        <span className="item-content primepicks-title">PrimePicks</span>
        <span className={`longterm-expand-icon ${expandedItems.includes('prime-picks') ? 'expanded' : ''}`}>▼</span>
      </button>
      {expandedItems.includes('prime-picks') && (
        <div className="longterm-sub-items">
          {STRATEGY_TYPES.map(strategy => (
            <button
              key={`prime-picks-${strategy}`}
              className={`longterm-sidebar-item ${
                activeCategory === `prime-picks-${strategy}` ? 'active' : ''
              }`}
              onClick={() => handlePrimePicksNavigation(strategy)}
            >
              <span className="item-content">
                {strategy.charAt(0).toUpperCase() + strategy.slice(1)} Strategy
              </span>
              <span className="longterm-feature-tag longterm-pro-tag">PRO</span>
            </button>
          ))}
        </div>
      )}
    </>
  );

  const renderContent = () => {
    const path = location.pathname;
    console.log('Current path:', path);
    
    // 检查是否是 sector 路径
    if (path.includes('/sector/')) {
      console.log('Rendering SectorDetails component');
      return <SectorDetails />;
    }
    
    // 检查是否是 prime-picks 路径
    if (path.includes('/prime-picks/')) {
      const strategyMatch = path.match(/\/prime-picks\/(.+)$/);
      if (strategyMatch && STRATEGY_TYPES.includes(strategyMatch[1])) {
        const strategy = strategyMatch[1];
        switch (strategy) {
          case 'growth':
            return <GrowthStrategyPicks />;
          case 'pinnacle':
            return <PinnacleStrategyPicks />;
          case 'stable':
            return <StableStrategyPicks />;
          default:
            return <div>Strategy not found</div>;
        }
      }
    }
    
    switch (activeCategory) {
      case 'overview':
        return <LongTermOverview />;
      case 'stock-screener':
        return <LongTermScreener />;
      case 'earnings-calendar':
        return <EarningsCalendar />;
      default:
        // 检查是否是股票详情页面
        const allStocks = [
          ...stocksByMarketCap['small-cap'],
          ...stocksByMarketCap['medium-cap'],
          ...stocksByMarketCap['large-cap']
        ];
        
        // 从 URL 中获取 ticker
        const tickerMatch = path.match(/\/long-term\/.*?\/(.+)$/);
        const urlTicker = tickerMatch ? tickerMatch[1].toUpperCase() : null;
        
        // 如果 URL 中有 ticker，直接渲染详情页
        if (urlTicker) {
          return <LongTermDetails />;
        }
        
        // 否则检查是否匹配侧边栏的股票
        const matchingStock = allStocks.find(
          s => s.ticker.toLowerCase() === activeCategory.toLowerCase()
        );
        
        if (matchingStock) {
          return <LongTermDetails />;
        }
        
        return <div>Please select a category</div>;
    }
  };

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  // 修改渲染侧边栏项目的逻辑
  const renderSectorETFs = () => (
    <div className="longterm-sub-items">
      {sectorETFs.map(({ etf }) => (
        <button
          key={etf}
          className={`longterm-sidebar-item ${
            activeCategory === `sector-${etf.toLowerCase()}` ? 'active' : ''
          }`}
          onClick={() => handleSectorETFClick(etf)}
        >
          <span className="item-content">{etf}</span>
          <span className="longterm-feature-tag longterm-free-tag">FREE</span>
        </button>
      ))}
    </div>
  );

  // 修改 Sector Analysis 部分的渲染
  const renderSectorAnalysis = () => (
    <>
      <button
        className="longterm-sidebar-item group"
        onClick={() => toggleExpand('sector-analysis')}
        aria-expanded={expandedItems.includes('sector-analysis')}
      >
        <span>Sector Analysis</span>
        <span className={`longterm-expand-icon ${expandedItems.includes('sector-analysis') ? 'expanded' : ''}`}>▼</span>
      </button>
      {expandedItems.includes('sector-analysis') && renderSectorETFs()}
    </>
  );

  return (
    <>
      <Helmet>
        <title>Long-term Investment - FinStock Insights</title>
      </Helmet>
      <PageLayout>
        <div className={`longterm-container ${isSidebarCollapsed ? 'collapsed' : ''}`}>
          <button 
            className="longterm-sidebar-toggle" 
            onClick={toggleSidebar}
            aria-label={isSidebarCollapsed ? "Expand sidebar" : "Collapse sidebar"}
          >
            {isSidebarCollapsed ? '<' : '>'}
          </button>
          <aside className="longterm-sidebar">
            <nav className="longterm-sidebar-nav">
              <div className="longterm-nav-item-container">
                <button
                  className={`longterm-sidebar-item ${activeCategory === 'overview' ? 'active' : ''}`}
                  onClick={() => handleNavigation('overview')}
                >
                  <span className="item-content">Overview</span>
                  <span className="longterm-feature-tag longterm-free-tag">FREE</span>
                </button>
              </div>

              <div className="longterm-nav-item-container">
                <button
                  className={`longterm-sidebar-item ${activeCategory === 'earnings-calendar' ? 'active' : ''}`}
                  onClick={() => handleNavigation('earnings-calendar')}
                >
                  <span className="item-content">Earnings Calendar</span>
                  <span className="longterm-feature-tag longterm-free-tag">FREE</span>
                </button>
              </div>

              <div className="longterm-nav-item-container">
                <button
                  className="longterm-sidebar-item group"
                  onClick={() => toggleExpand('long-term-stocks')}
                  aria-expanded={expandedItems.includes('long-term-stocks')}
                >
                  <span className="item-content">Long-term Stocks</span>
                  <span className="longterm-expand-icon">▼</span>
                </button>

                {expandedItems.includes('long-term-stocks') && (
                  <div className="longterm-sub-items">
                    <button
                      className={`longterm-sidebar-item ${activeCategory === 'stock-screener' ? 'active' : ''}`}
                      onClick={() => handleNavigation('stock-screener')}
                    >
                      <span className="item-content">Stock Screener</span>
                      <span className="longterm-feature-tag longterm-free-tag">FREE</span>
                    </button>

                    {['small-cap', 'medium-cap', 'large-cap'].map(category => (
                      <div key={category} className="longterm-market-cap-category">
                        <button
                          className={`longterm-sidebar-item ${activeCategory === category ? 'active' : ''}`}
                          onClick={() => toggleExpand(category)}
                          aria-expanded={expandedItems.includes(category)}
                        >
                          <span className="item-content">
                            {category === 'small-cap' ? 'Small Market Cap' :
                             category === 'medium-cap' ? 'Medium Market Cap' :
                             'Large Market Cap'}
                          </span>
                          <span className="longterm-feature-tag longterm-free-tag">FREE</span>
                          <span className={`longterm-expand-icon ${expandedItems.includes(category) ? 'expanded' : ''}`}>▼</span>
                        </button>
                        {expandedItems.includes(category) && renderStockList(category)}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div className="longterm-nav-item-container">
                {renderSectorAnalysis()}
              </div>

              <div className="longterm-nav-item-container">
                {renderPrimePicks()}
              </div>
            </nav>
          </aside>
          <main className="longterm-main-content">
            {renderContent()}
          </main>
        </div>
      </PageLayout>
    </>
  );
};

export default LongTermPage;  