import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import { getBciAHistory, getBciALatest, getBciATickersList } from '../services/data_api';
import dayjs from 'dayjs';
import './BciAHistoryChart.css';
import SectionTitleBar from './common/SectionTitleBar';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

// 预定义20种区分度高的颜色
const COLORS = [
  '#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd',
  '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf',
  '#393b79', '#637939', '#8c6d31', '#843c39', '#7b4173',
  '#5254a3', '#637939', '#b5cf6b', '#cedb9c', '#8c6d31'
];

const formatValue = (value) => {
  if (typeof value !== 'number') return 'N/A';
  return `$${value.toFixed(2)}B`;
};

const BciAHistoryChart = () => {
  const { t } = useTranslation();
  const [tickers, setTickers] = useState([]);
  const [historicalData, setHistoricalData] = useState({});
  const [latestData, setLatestData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');

  const filterDataByRange = (dates, range) => {
    if (range === 'ALL') return dates;
    const cutoffDate = dayjs().subtract(
      TIME_RANGES.find(r => r.label === range).days,
      'days'
    );
    return dates.filter(date => dayjs(date).isAfter(cutoffDate));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // 1. 获取股票列表
        const tickersResponse = await getBciATickersList();
        const tickersList = tickersResponse.data.map(item => item.ticker);
        setTickers(tickersList);

        // 2. 并行获取所有数据
        const [latestResponse, ...historicalResponses] = await Promise.all([
          getBciALatest(),
          ...tickersList.map(ticker => getBciAHistory(ticker))
        ]);

        // 3. 处理历史数据
        const processedHistoricalData = {};
        historicalResponses.forEach((response, index) => {
          const ticker = tickersList[index];
          processedHistoricalData[ticker] = response.data;
        });
        setHistoricalData(processedHistoricalData);

        // 4. 处理最新数据，只保留在 tickersList 中的股票
        if (latestResponse?.data) {
          const filteredLatestData = latestResponse.data.filter(
            item => tickersList.includes(item.ticker)
          );
          setLatestData(filteredLatestData);
        }
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getChartOption = () => {
    if (!historicalData || Object.keys(historicalData).length === 0) return {};

    // 获取所有日期
    const allDates = [...new Set(
      Object.values(historicalData)
        .flat()
        .map(item => item.date)
    )].sort();

    // 根据选择的时间范围过滤日期
    const filteredDates = filterDataByRange(allDates, selectedRange);

    return {
      backgroundColor: '#ffffff',
      tooltip: {
        trigger: 'axis',
        axisPointer: { type: 'cross' },
        formatter: function(params) {
          const date = params[0]?.axisValue || '';
          let result = `<div style="padding: 3px;">
            <div style="margin-bottom: 4px;"><strong>${date}</strong></div>`;
          
          params.forEach((param, index) => {
            const value = typeof param.value === 'number' ? formatValue(param.value) : 'N/A';
            const color = COLORS[index % COLORS.length];
            result += `<div style="color: ${color}; padding: 3px 0;">
              <span style="display: inline-block; width: 10px; height: 10px; background-color: ${color}; border-radius: 50%; margin-right: 8px;"></span>
              ${param.seriesName}: ${value}
            </div>`;
          });
          
          return result + '</div>';
        }
      },
      legend: {
        data: tickers,
        top: '10%',
        left: 'center',
        textStyle: { fontSize: 14 }
      },
      grid: {
        left: '5%',
        right: '5%',
        bottom: '15%',
        top: '25%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredDates,
        axisLabel: {
          rotate: 45,
          interval: 'auto',
          showMaxLabel: true,
          hideOverlap: false
        }
      },
      yAxis: {
        type: 'value',
        name: 'Value (B$)',
        nameTextStyle: {
          fontSize: 16,
          fontWeight: 500,
          padding: [0, 0, 0, 50]
        },
        axisLabel: {
          formatter: value => `$${value.toFixed(1)}B`,
          fontSize: 14
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#f0f0f0',
            type: 'dashed'
          }
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          type: 'slider',
          show: true,
          height: 30,
          bottom: 5
        }
      ],
      series: tickers.map((ticker, index) => ({
        name: ticker,
        type: 'line',
        data: filteredDates.map(date => {
          const dataPoint = historicalData[ticker]?.find(item => item.date === date);
          return dataPoint?.market_cap || null;
        }),
        smooth: true,
        symbol: 'circle',
        symbolSize: 1,
        lineStyle: {
          width: 2,
          color: COLORS[index % COLORS.length]
        },
        itemStyle: {
          color: COLORS[index % COLORS.length]
        },
        connectNulls: true
      }))
    };
  };

  // 处理时间范围变化
  const handleRangeChange = (range) => {
    setSelectedRange(range);
    // 强制重新渲染图表
    if (chartRef.current) {
      const chart = chartRef.current.getEchartsInstance();
      chart.setOption(getChartOption(), true);
    }
  };

  // 添加图表引用
  const chartRef = useRef(null);

  const getTableData = () => {
    if (!latestData) return [];
    
    return latestData
      .sort((a, b) => b.market_cap - a.market_cap)
      .map(item => ({
        stock: item.ticker,
        value: formatValue(item.market_cap)
      }));
  };

  if (loading) return <div className="loading-state">{t('loading')}</div>;
  if (error) return <div className="error-state">{t('error')}: {error}</div>;

  return (
    <div className="marketcap-bciAhistory-comparison">
      <div className="marketcap-bciAhistory-container">
        <div className="marketcap-bciAhistory-table-section">
          <SectionTitleBar title={t('Market Cap Analysis of BTC-related Stocks')} />
          <div className="marketcap-bciAhistory-subtitle">(very strong relationship)</div>
          <div className="marketcap-bciAhistory-timestamp">
            {latestData?.[0]?.date && dayjs(latestData[0].date).format('YYYY-MM-DD HH:mm:ss')}
          </div>
          <div className="marketcap-bciAhistory-table-wrapper">
            <table className="marketcap-bciAhistory-table">
              <thead>
                <tr>
                  <th>Stock</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {getTableData().map(({ stock, value }) => (
                  <tr key={stock}>
                    <td>{stock}</td>
                    <td>{value}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="marketcap-bciAhistory-chart-wrapper">
          <div className="marketcap-bciAhistory-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`marketcap-bciAhistory-range-btn ${
                  selectedRange === range.label ? 'active' : ''
                }`}
                onClick={() => handleRangeChange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
          <div className="marketcap-bciAhistory-chart">
            <ReactECharts 
              ref={chartRef}
              option={getChartOption()}
              style={{ height: '100%', minHeight: '500px' }}
              notMerge={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BciAHistoryChart; 