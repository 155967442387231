import { STOCKS } from '../config/stocks';

const apiBaseUrl = 'https://my-worker.helloswx.workers.dev';

// 添加超时控制的请求函数
const makeApiRequest = async (url, options = {}) => {
  // 设置超时时间为10秒
  const timeout = 10000;

  try {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), timeout);

    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin,
        ...options.headers
      }
    });

    clearTimeout(timeoutId);

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || `Request failed: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    if (error.name === 'AbortError') {
      throw new Error('Request timeout');
    }
    console.error('API request failed:', error);
    // 返回模拟数据用于开发
    if (process.env.NODE_ENV === 'development') {
      return getMockData(url);
    }
    throw error;
  }
};

// 模拟数据
const getMockData = (url) => {
  if (url.includes('btc-overview')) {
    return {
      price: 72200.06,
      daily_return: '0.19%',
      market_cap_T: '1.40T',
      mvrv_z_score: 1.68,
      current_month: 10,
      monthly_mean: '18.81%',
      fear_greed_index: 72
    };
  }

  if (url.includes('stock-overview')) {
    return {
      data: [
        {
          ticker: 'MSTR',
          name: 'MicroStrategy Inc.',
          close: 247.31,
          recent_performance: '15.59%',
          bci: 'A',
          vp: 'B',
          risk: 'A',
          sentiment_rank: 'C'
        },
        // ... 可以添加更多模拟数据
      ]
    };
  }

  if (url.includes('btc/history')) {
    return {
      total: 100,
      data: Array.from({ length: 30 }, (_, i) => ({
        id: i + 1,
        date: new Date(Date.now() - (30 - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        open_price: 65000 + Math.random() * 1000,
        high_price: 66000 + Math.random() * 1000,
        low_price: 64000 + Math.random() * 1000,
        close_price: 65500 + Math.random() * 1000,
        volume: 20000000 + Math.random() * 10000000
      }))
    };
  }

  if (url.includes('price-return-minute')) {
    return {
      total: 1,
      data: [{
        id: 1,
        ticker: "BTC-USD",
        current_price: 68947.9140625,
        previous_close: 68741.1171875,
        return_to_previous: 0.3008343237075063,
        all_time_high: 73738,
        return_to_ath: -6.496088770376198
      }]
    };
  }

  if (url.includes('overview-base')) {
    return {
      total: 1,
      data: [{
        id: 1,
        volume_24h: 34249846784,
        volume_10d_avg: 35943838641.7,
        previous_close: 68741.1171875,
        circulating_supply: 19777496,
        total_supply: 21000000,
        launch_date: "2009-01-03",
        all_time_high: 73738,
        ath_date: "2024-03-14T07:10:36.635Z"
      }]
    };
  }

  if (url.includes('monthly-returns')) {
    return {
      total: 123,
      data: Array.from({ length: 120 }, (_, i) => ({
        id: i + 1,
        year: 2014 + Math.floor(i / 12),
        month: (i % 12) + 1,
        price: 30000 + Math.random() * 40000,
        return_value: `${(Math.random() * 40 - 20).toFixed(2)}%`
      }))
    };
  }

  if (url.includes('monthly-stats')) {
    return {
      total: 12,
      data: Array.from({ length: 12 }, (_, i) => ({
        id: i + 1,
        month: i + 1,
        mean: `${(Math.random() * 30 - 15).toFixed(2)}%`,
        median: `${(Math.random() * 30 - 15).toFixed(2)}%`
      }))
    };
  }

  if (url.includes('etf-net-inflow/latest')) {
    return {
      date: "2024-11-04",
      daily_net_inflow: -541068862.82,
      cumulative_net_inflow: 23613574647.89,
      daily_total_volume: 2223924706.94,
      total_assets: 67441181077.93,
      btc_price: null
    };
  }

  if (url.includes('etf-net-inflow')) {
    return {
      total: 100,
      data: Array.from({ length: 100 }, (_, i) => ({
        id: i + 1,
        date: new Date(Date.now() - (100 - i) * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
        net_inflow: -541068862.82 + Math.random() * 100000000,
        total_volume: 2223924706.94 + Math.random() * 100000000,
        assets: 67441181077.93 + Math.random() * 100000000
      }))
    };
  }

  // 匹配 stock-overview/{ticker} 格式的请求
  const stockOverviewMatch = url.match(/\/api\/stock-overview\/([A-Z]+)/);
  if (stockOverviewMatch) {
    const ticker = stockOverviewMatch[1];
    const stockInfo = STOCKS[ticker] || {
      name: `${ticker} Inc.`,
      sector: 'Technology'
    };

    return {
      ticker: ticker,
      name: stockInfo.name,
      sector: stockInfo.sector,
      close: 139.34 + Math.random() * 100,
      recent_performance: (-5 + Math.random() * 10).toFixed(2),
      bci: ['A', 'B', 'C', 'D', 'E'][Math.floor(Math.random() * 5)],
      vp: ['A', 'B', 'C', 'D', 'E'][Math.floor(Math.random() * 5)],
      risk: ['A', 'B', 'C', 'D', 'E'][Math.floor(Math.random() * 5)],
      sentiment_rank: ['A', 'B', 'C', 'D', 'E'][Math.floor(Math.random() * 5)]
    };
  }

  // 匹配 price-return-minute/{ticker} 格式的请求
  const priceReturnMatch = url.match(/\/api\/btc\/price-return-minute\/([A-Z]+)/);
  if (priceReturnMatch) {
    const ticker = priceReturnMatch[1];
    return {
      ticker: ticker,
      current_price: 200 + Math.random() * 100,
      previous_close: 180 + Math.random() * 100,
      current_return: (-10 + Math.random() * 20).toFixed(2)
    };
  }

  if (url.includes('/api/btc/stock-overview/')) {
    const ticker = url.split('/').pop();
    const stockInfo = STOCKS[ticker] || {
      name: `${ticker} Inc.`,
      sector: 'Technology',
      introduction: `${ticker} company description...`
    };

    return {
      ticker,
      name: stockInfo.name,
      sector: stockInfo.sector,
      previous_close: 254.31,
      market_cap: 63666507776,
      previous_volume: 35358100,
      avg_10d_volume: 15188737.5,
      recent_performance: '31.10',
      introduction: stockInfo.introduction
    };
  }

  if (url.includes('price-ratio-history')) {
    const ticker = url.split('/').pop();
    return {
      ticker,
      total: 901,
      data: Array.from({ length: 900 }, (_, i) => ({
        date: new Date(2021, 3, 14 + i).toISOString().split('T')[0],
        ticker,
        price_ratio: 0.003 + Math.random() * 0.004
      }))
    };
  }

  if (url.includes('stock-screener')) {
    return {
      total: 35,
      data: [
        {
          ticker: "AMD",
          name: "Advanced Micro Devices, Inc.",
          sector: "Technology",
          previous_close: 139.3000030517578,
          daily_return: -0.0301469169661835,
          recent_performance: -0.1281762165728136,
          market_cap_billion: 226.057437184,
          btc_correlation_index: "D",
          valuation_premium: "D",
          risk: "A",
          sentiment_rank: "A",
          recommendation: "B"
        },
        {
          ticker: "BITF",
          name: "Bitfarms Ltd.",
          sector: "Financial Services",
          previous_close: 2.265000104904175,
          daily_return: -0.1579925439687279,
          recent_performance: 0.1439394359016472,
          market_cap_billion: 1.08041408,
          btc_correlation_index: "B",
          valuation_premium: "B",
          risk: "C",
          sentiment_rank: "A",
          recommendation: "B"
        }
        // ... 可以添加多模拟数据
      ]
    };
  }

  return null;
};

// 获 BTC Overview 数据
export async function getBtcOverview() {
  return makeApiRequest(`${apiBaseUrl}/api/btc-overview`);
}

// 获取股票概览数据
export async function getStockOverview() {
  return makeApiRequest(`${apiBaseUrl}/api/stock-overview`);
}

// ... 现有代码 ...

// 获取 BTC 价格和回报数据
export async function getBtcPriceReturn() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/price-return-minute`);
}

// 获取 BTC 基础概览数据
export async function getBtcOverviewBase() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/overview-base`);
}

// 格式化数字为带T/B/M的字符串
export const formatNumber = (number) => {
  if (number >= 1e12) return `${(number / 1e12).toFixed(2)}T`;
  if (number >= 1e9) return `${(number / 1e9).toFixed(2)}B`;
  if (number >= 1e6) return `${(number / 1e6).toFixed(2)}M`;
  return number.toLocaleString();
};

// 获取 BTC 历史数据
export async function getBtcHistory() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/history`);
}

// 获 BTC 月度收益数据
export async function getBtcMonthlyReturns() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/monthly-returns`);
}

// 获取 BTC 月度统计数据
export async function getBtcMonthlyStats() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/monthly-stats`);
}

// 获取顶级产详情
export async function getTopAssetsDetails() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/top-assets-details`);
}

// 获取总市值更新数据
export async function getTotalMarketCapsUpdate() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/total-market-caps-update`);
}

// 获取比特币市值
export async function getBtcMarketCap() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/top-assets-details/Bitcoin`);
}

// 新增获取股票列表的接口
export async function getBciATickersList() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/bci-a-tickers-list`);
}

// 修改获取历史数据的接口，接受 ticker 参数
export async function getBciAHistory(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/btc/bci-a-history/${ticker}`);
}

// getBciALatest 保持不变，因为它返回所有股票的最新数据
export async function getBciALatest() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/bci-a-latest`);
}

// 获取顶级资产市值历史数据
export async function getTopMarketCapsHistory(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/btc/top-market-caps-history/${ticker}`);
}

// 获取顶级资产市值列表
export async function getTopMarketCapsTickersList() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/top-market-caps-tickers-list`);
}

// 获取ETF净流入数据
export async function getEtfNetInflowLatest() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/etf-net-inflow/latest`);
}

// 获取ETF净流入历史数据
export async function getEtfNetInflowHistory() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/etf-net-inflow`);
}

// 获取单个股票详情数据
export async function getStockData(ticker) {
  // 在实际API实现之前使用模拟数据
  const stockInfo = STOCKS[ticker] || {
    name: `${ticker} Inc.`,
    sector: 'Technology',
    introduction: `${ticker} company description...`
  };

  return {
    ticker: ticker,
    companyName: stockInfo.name,
    price: 200 + Math.random() * 100,
    priceChange: -10 + Math.random() * 20,
    marketCap: (10 + Math.random() * 90).toFixed(2),
    volume: {
      previous: (1 + Math.random() * 4).toFixed(2),
      avg10d: (1 + Math.random() * 4).toFixed(2)
    },
    eps: (-3 + Math.random() * 6).toFixed(2),
    sector: stockInfo.sector,
    introduction: stockInfo.introduction,
    keyAspects: [
      {
        title: 'Bitcoin Holdings',
        description: 'Company specific Bitcoin holdings and strategy.'
      },
      {
        title: 'Core Business',
        description: 'Company specific core business description.'
      },
      {
        title: 'Market Position',
        description: 'Company specific market position and strategy.'
      }
    ],
    btcCorrelatedIndex: {
      value: ['very weak', 'weak', 'medium', 'strong', 'very strong'][Math.floor(Math.random() * 5)],
      score: Math.random()
    },
    valuationPremium: {
      value: ['low', 'medium', 'high'][Math.floor(Math.random() * 3)],
      score: Math.random()
    },
    risk: {
      value: ['low', 'medium', 'high', 'very high'][Math.floor(Math.random() * 4)],
      score: Math.random()
    },
    newsSentiment: {
      value: ['negative', 'neutral', 'positive'][Math.floor(Math.random() * 3)],
      score: Math.random()
    }
  };
}

// 获取单个股票概览数据
export async function getStockDetailOverview(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/btc/stock-screener/${ticker}`);
}

// 修改获取价格和回报数据的接口，接受 ticker 参数
export async function getStockPriceReturn(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/btc/btc_stock_price_return_1minute/${ticker}`);
}

// 添加新的股票详情接口
export async function getStockBasicInfo(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/btc/stock-overview/${ticker}`);
}

// 获取股票风险数据
export async function getStockRiskData(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/btc/stock-risk/${ticker}`);
}

// 获取股票历史价格数据
export async function getStockHistory(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/stock/history/${ticker}`);
}

// 获取股票 BCI 数据
export async function getStockBciData(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/stock-bci/${ticker}`);
    return response;
  } catch (error) {
    console.error(`Error fetching BCI data for ${ticker}:`, error);
    return null;
  }
}

// 辅助函数：根据分数计算等级
function getBciGrade(score) {
  if (!score) return 'medium';
  if (score >= 0.8) return 'very strong';
  if (score >= 0.6) return 'strong';
  if (score >= 0.4) return 'medium';
  if (score >= 0.2) return 'weak';
  return 'very weak';
}

// 获取股票价格比率历史数据
export async function getStockPriceRatioHistory(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/price-ratio-history/${ticker}`);
    return response;
  } catch (error) {
    console.error('Error fetching price ratio history:', error);
    return { data: [] };
  }
}

// 获取 BTC 余额历史数据
export async function getStockBalanceHistory(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/balance-history/${ticker}`);
    // 直接返回原始数据，不做字段映射
    if (response && Array.isArray(response.data)) {
      return {
        data: response.data
      };
    }
    return { data: [] };
  } catch (error) {
    console.error(`Error fetching balance history for ${ticker}:`, error);
    return { data: [] };
  }
}

// 获取 Valuation Premium Rank 数据
export async function getValuationPremiumRank(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/btc/valuation-premium-ranks/${ticker}`);
}

// 获取 NAV Premium 历史数据  
export async function getNavPremiumHistory(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/nav-premium-history/${ticker}`);
    return response.data || []; // 如果 response.data 为空,返回一个空数组
  } catch (error) {
    console.error(`Error fetching NAV premium history for ${ticker}:`, error);
    return []; // 返回一个空数组而不是对象
  }
}

export async function getValuationPremium(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/valuation-premium-ranks/${ticker}`);
    return response;
  } catch (error) {
    console.error(`Error fetching valuation premium data for ${ticker}:`, error);
    return {
      ticker,
      date: null,
      nav_premium_btc: null,
      nav_historical_percentile: null,
      pe: null,
      pe_percentile: null,
      ps: null,
      ps_percentile: null,
      pb: null,
      pb_percentile: null,
      ev_ebitda: null,
      ev_ebitda_percentile: null,
      ev_sales: null,
      ev_sales_percentile: null,
      free_cash_flow_yield: null,
      processed_free_cash_flow_yield: null,
      processed_free_cash_flow_yield_percentile: null,
      pe_horizontal_percentile: null,
      ps_horizontal_percentile: null,
      pb_horizontal_percentile: null,
      ev_ebitda_horizontal_percentile: null,
      ev_sales_horizontal_percentile: null,
      processed_free_cash_flow_yield_horizontal_percentile: null,
      pe_composite_score: null,
      ps_composite_score: null,
      pb_composite_score: null,
      ev_ebitda_composite_score: null,
      ev_sales_composite_score: null,
      processed_free_cash_flow_yield_composite_score: null,
      premium_indicator: null,
      adjustment_factor: null,
      category: null
    };
  }
}

// 获取股票推荐评级数据
export async function getStockRecommendationRatings(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/recommendation-ratings/${ticker}`);
    return response;
  } catch (error) {
    console.error('Error fetching recommendation ratings:', error);
    return {
      ticker,
      rsi: null,
      macd: null,
      stoch_k: null,
      stoch_d: null,
      adx: null,
      cci: null,
      atr: null,
      roc: null,
      williams_r: null,
      technical_rating: null,
      model_rating: null,
      wall_street_rating: null,
      analysts_rating: null,
      total_rating: null,
      recommendation: null
    };
  }
}

// 添加新的股票筛选器数据获取函数
export async function getStockScreenerData() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/stock-screener`);
}

// 获取股票类别数据
export async function getStockCategory(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/stock-category/${ticker}`);
    return response;
  } catch (error) {
    console.error(`Error fetching stock category for ${ticker}:`, error);
    return {
      ticker,
      category_en: "Other",
      category_cn: "其他"
    };
  }
}

// 获取单个股票的筛选数据
export async function getStockScreenerSingle(ticker) {
  return makeApiRequest(`${apiBaseUrl}/api/btc/stock-screener/${ticker}`);
}

// 添加获取热门股票列表的函数
// ... 现有代码 ...

// 添加新的 API 函数
export async function getTopPicks() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/top-picks`);
}

export async function getFirstTopPick() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/top-picks/first`);
}

export async function getOtherTopPicks() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/top-picks/others`);
}

// 添加新的技术指标和季节性数据获取函数
export async function getTechnicalSeasonality() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/technical-seasonality`);
}

// Add these new functions
export async function getFearGreedStats() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/fear-greed-stat`);
}

export async function getFearGreedHistory() {
  return makeApiRequest(`${apiBaseUrl}/api/btc/fear-greed-index/history`);
}

// MVRV-Z Value APIs
export const getMvrvZLatest = async () => {
  return makeApiRequest('https://my-worker.helloswx.workers.dev/api/btc/mvrv-z/latest');
};

export const getMvrvZStat = async () => {
  return makeApiRequest('https://my-worker.helloswx.workers.dev/api/btc/mvrv-z-stat');
};

export const getMvrvZHistory = async () => {
  return makeApiRequest('https://my-worker.helloswx.workers.dev/api/btc/mvrv-z/history');
};

// Add this new function
export async function getEtfBciData(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/etf-bci/${ticker}`);
    return response;
  } catch (error) {
    console.error(`Error fetching ETF BCI data for ${ticker}:`, error);
    return null;
  }
}

// 添加获取ETF每日价格比率数据的函数
export async function getEtfDailyPriceRatios(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/etf-daily-price-ratios/${ticker}`);
    return response;
  } catch (error) {
    console.error(`Error fetching ETF daily price ratios for ${ticker}:`, error);
    return null;
  }
}

// 添加获取ETF BTC持仓数据的函数
export async function getEtfBtcBalance(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/etf-btc-balance/${ticker}`);
    return response;
  } catch (error) {
    console.error(`Error fetching ETF BTC balance for ${ticker}:`, error);
    return null;
  }
}

export async function getEtfVpMetrics(ticker) {
  try {
    const response = await makeApiRequest(`${apiBaseUrl}/api/btc/etf-vp-metrics/${ticker}`);
    return response.data;
  } catch (error) {
    console.error(`Error fetching ETF VP metrics for ${ticker}:`, error);
    return null;
  }
}

export const getEtfNavPremium = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/btc/etf-nav-premium/${ticker}`);
    if (!response.ok) {
      throw new Error('Failed to fetch NAV premium data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching NAV premium data:', error);
    return null;
  }
};

// 添加新的 API 调用函数
export const getEtfHoldings = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/etf-holdings/${ticker}`);
    if (!response.ok) {
      return null;
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching ETF holdings:', error);
    return null;
  }
};

export const getEtfScreenerData = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/btc/etf-screener/${ticker}`);
    if (!response.ok) return null;
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching ETF screener data:', error);
    return null;
  }
};

export const getEtfOverviewData = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/btc/etf-overview/${ticker}`);
    if (!response.ok) return null;
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching ETF overview data:', error);
    return null;
  }
};

export const getEtfPriceReturn = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/btc/etf-price-return/${ticker}`);
    if (!response.ok) return null;
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching ETF price return:', error);
    return null;
  }
};

export const getEtfHistoryData = async (ticker) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/btc/etf-history/${ticker}`);
    if (!response.ok) return null;
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching ETF history data:', error);
    return null;
  }
};

export const getEtfNetInflow = async (ticker) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/btc/etf-overview-net-inflow/${ticker}`);
    if (!response.ok) return null;
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching ETF net inflow data:', error);
    return null;
  }
};

export const getEtfRiskMetrics = async (ticker) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/btc/etf-risk-metrics/${ticker}`);
    if (!response.ok) {
      throw new Error('Failed to fetch ETF risk metrics');
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching ETF risk metrics:', error);
    throw error;
  }
};

export const getEtfRecommendationRatings = async (ticker) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/btc/etf-recommendation-ratings/${ticker}`);
    if (!response.ok) {
      throw new Error('Failed to fetch ETF recommendation ratings');
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching ETF recommendation ratings:', error);
    throw error;
  }
};

// 新增 ETF 相关的 API 函数
export const getAllEtfScreenerData = async () => {
  try {
    const response = await fetch('https://my-worker.helloswx.workers.dev/api/btc/etf-screener');
    if (!response.ok) {
      throw new Error('Failed to fetch ETF screener data');
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching ETF screener data:', error);
    return { data: [] };
  }
};

export const getEtfPriceReturnData = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/btc/etf-price-return/${ticker}`);
    if (!response.ok) {
      throw new Error('Failed to fetch ETF price return data');
    }
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error(`Error fetching ETF price return for ${ticker}:`, error);
    return {
      current_price: null,
      current_return: null
    };
  }
};

export const getEtfScreenerSingleData = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/btc/etf-screener/${ticker}`);
    if (!response.ok) {
      throw new Error('Failed to fetch ETF screener data');
    }
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error(`Error fetching ETF screener data for ${ticker}:`, error);
    return null;
  }
};

export const getTopETFs = async () => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/btc/etf-top-picks`);
    if (!response.ok) {
      throw new Error('Failed to fetch top ETFs');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching top ETFs:', error);
    return { data: [] };
  }
};

// 获取长期投资股票筛选数据
export async function getLongTermStockScreener() {
  try {
    const response = await fetch('https://my-worker.helloswx.workers.dev/api/stock/stock-screener', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin
      },
      mode: 'cors',
      credentials: 'omit'
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({}));
      throw new Error(errorData.error || `Request failed with status ${response.status}`);
    }

    const data = await response.json();
    console.log('Stock screener data:', data); // 添加日志
    return data;
  } catch (error) {
    console.error('Error details:', error); // 添加详细错误日志
    // 在开发环境返回模拟数据
    if (process.env.NODE_ENV === 'development') {
      return {
        data: [
          {
            ticker: 'AAPL',
            market_cap_rank: 'Large',
            recommendation: 'B'
          },
          {
            ticker: 'MSFT',
            market_cap_rank: 'Large',
            recommendation: 'A'
          },
          {
            ticker: 'GOOGL',
            market_cap_rank: 'Large',
            recommendation: 'B'
          },
          {
            ticker: 'AMD',
            market_cap_rank: 'Medium',
            recommendation: 'A'
          },
          {
            ticker: 'NVDA',
            market_cap_rank: 'Medium',
            recommendation: 'B'
          }
        ]
      };
    }
    return { data: [] };
  }
}

export async function getLongTermStockRecommendation(ticker) {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/raw-stock-recommendation/${ticker}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error('Error fetching stock recommendation:', error);
    throw error;
  }
}

export async function getLongTermStockOverview(ticker) {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/raw-stock-overview/${ticker}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Origin': window.location.origin
      },
      mode: 'cors',
      credentials: 'omit'
    });

    if (!response.ok) {
      throw new Error(`Request failed with status ${response.status}`);
    }

    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error('Error fetching long-term stock overview:', error);
    throw error;
  }
}


// 获取长期股票价格和回报率
export async function getLongTermStockPriceReturn(ticker) {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/stock/price-return-1min/${ticker}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error('Error fetching long-term stock price return:', error);
    throw error;
  }
}

// 获取长期股票筛选数据
export async function getLongTermStockScreenerDetails(ticker) {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/stock/stock-screener/${ticker}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error('Error fetching long-term stock screener data:', error);
    throw error;
  }
}

// 获取股票历史数据
export const getLongTermStockHistory = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/raw-stock-history/${ticker}`);
    if (!response.ok) {
      throw new Error('Failed to fetch stock history data');
    }
    return response.json();
  } catch (error) {
    console.error('Error fetching stock history:', error);
    return null;
  }
};

export const getLongTermStockEPS = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/raw-stock-eps/${ticker}`);
    if (!response.ok) throw new Error('Failed to fetch EPS data');
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error('Error fetching EPS data:', error);
    throw error;
  }
};

export const getLongTermStockGrowthRates = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/raw-stock-growth-rates/${ticker}`);
    if (!response.ok) throw new Error('Failed to fetch growth rates data');
    const { data } = await response.json();

    // 确保数据格式化正确
    const yoyData = data.find(d => d.period === 'yoy') || {};
    const quarterlyData = data.find(d => d.period === 'quarterly') || {};

    return {
      yoy: {
        diluted_eps_growth: yoyData.diluted_eps_growth,
        total_revenue_growth: yoyData.total_revenue_growth,
        ebitda_growth: yoyData.ebitda_growth,
        ebit_growth: yoyData.ebit_growth,
        free_cash_flow_growth: yoyData.free_cash_flow_growth,
        operating_cash_flow_growth: yoyData.operating_cash_flow_growth,
        working_capital_growth: yoyData.working_capital_growth,
        roe_growth: yoyData.roe_growth
      },
      quarterly: {
        diluted_eps_growth: quarterlyData.diluted_eps_growth,
        total_revenue_growth: quarterlyData.total_revenue_growth,
        ebitda_growth: quarterlyData.ebitda_growth,
        ebit_growth: quarterlyData.ebit_growth,
        free_cash_flow_growth: quarterlyData.free_cash_flow_growth,
        operating_cash_flow_growth: quarterlyData.operating_cash_flow_growth,
        working_capital_growth: quarterlyData.working_capital_growth,
        roe_growth: quarterlyData.roe_growth
      }
    };
  } catch (error) {
    console.error('Error fetching growth rates:', error);
    throw error;
  }
};

export const getStockRevenueEpsHistory = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/raw-stock-revenue-eps-history/${ticker}`);
    if (!response.ok) throw new Error('Failed to fetch revenue and eps history');
    return await response.json();
  } catch (error) {
    console.error('Error fetching revenue and eps history:', error);
    throw error;
  }
};

export const getRawStockProfitability = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/raw-stock-profitability/${ticker}`);
    if (!response.ok) {
      throw new Error('Failed to fetch profitability data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching raw stock profitability:', error);
    throw error;
  }
};

export const getRawStockPremium = async (ticker) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/raw-stock-premium/${ticker}`);
    if (!response.ok) {
      throw new Error('Failed to fetch premium metrics data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching raw stock premium:', error);
    throw error;
  }
};

// 获取股票风险数据
export async function getLongTermStockRisk(ticker) {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/raw-stock-risk/${ticker}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const result = await response.json();
    return result.data;
  } catch (error) {
    console.error('Error fetching stock risk data:', error);
    throw error;
  }
}

export const getEarningsCalendar = async () => {
  try {
    const response = await fetch('https://my-worker.helloswx.workers.dev/api/earnings-calendar');
    if (!response.ok) {
      throw new Error('Failed to fetch earnings calendar data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching earnings calendar:', error);
    throw error;
  }
};

// 获取sector ETF概览数据
export const getSectorETFOverview = async (etf) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/sector-etf-overview/${etf.toUpperCase()}`);
    if (!response.ok) throw new Error('Failed to fetch sector data');
    return await response.json();
  } catch (error) {
    console.error('Error fetching sector ETF data:', error);
    throw error;
  }
};

export const getSectorETFHoldings = async (etf) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/sector-etf-holdings/${etf}`);
    if (!response.ok) {
      throw new Error('Failed to fetch sector ETF holdings data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching sector ETF holdings:', error);
    throw error;
  }
};

export const getSectorETFHistory = async (etf) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/sector-etf-history/${etf}`);
    if (!response.ok) {
      throw new Error('Failed to fetch sector ETF history data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching sector ETF history:', error);
    throw error;
  }
};

export const getSectorETFHeatScore = async (etf) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/sector-etf-heat-score/${etf}`);
    if (!response.ok) {
      throw new Error('Failed to fetch sector ETF heat score data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching sector ETF heat score:', error);
    throw error;
  }
};

export const getSectorETFFundFlows = async (etf) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/sector-etf-fund-flows/${etf}`);
    if (!response.ok) {
      throw new Error('Failed to fetch sector ETF fund flows data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching sector ETF fund flows:', error);
    throw error;
  }
};

export const getSectorETFRiskMetrics = async (etf) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/sector-etf-risk-metrics/${etf}`);
    if (!response.ok) {
      throw new Error('Failed to fetch sector ETF risk metrics');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching sector ETF risk metrics:', error);
    throw error;
  }
};

export const getSectorETFRecommendationRatings = async (etf) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/sector-etf-recommendation-ratings/${etf}`);
    if (!response.ok) {
      throw new Error('Failed to fetch sector ETF recommendation ratings');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching sector ETF recommendation ratings:', error);
    throw error;
  }
};

export const getSectorETFPriceReturn = async (ticker) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/sector-etf-price-return-1minute/${ticker}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching sector ETF price return:', error);
    return null;
  }
};

// Get major indices price return data
export const getMajorIndicesPriceReturn = async () => {
  try {
    const response = await fetch('https://my-worker.helloswx.workers.dev/api/major-indices-price-return-1minute');
    if (!response.ok) {
      throw new Error('Failed to fetch major indices data');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching major indices data:', error);
    throw error;
  }
};

// Get market stage insights
export const getMarketStageInsight = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/market-stage-insight`);
  } catch (error) {
    console.error('Error fetching market stage insights:', error);
    throw error;
  }
};

// Get sector ETF top gainers and losers
export const getSectorETFTopGainersLosers = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/sector-etf-top-gainers-losers`);
  } catch (error) {
    console.error('Error fetching sector ETF top gainers and losers:', error);
    throw error;
  }
};

// Get sector spotlight analysis
export const getSectorSpotlightAnalysis = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/sector-spotlight-analysis`);
  } catch (error) {
    console.error('Error fetching sector spotlight analysis:', error);
    throw error;
  }
};

// 获取策略性能指标
export const getStrategyPerformanceMetrics = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/strategy-performance-metrics-equal`);
  } catch (error) {
    console.error('Error fetching strategy performance metrics:', error);
    throw error;
  }
};

// 获取策略评论
export const getStrategyEdgeReview = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/strategy-edge-review`);
  } catch (error) {
    console.error('Error fetching strategy edge review:', error);
    throw error;
  }
};

// 获取策略历史表现数据
export const getStrategyPerformanceHistory = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/strategy-performance-history-equal`);
  } catch (error) {
    console.error('Error fetching strategy performance history:', error);
    throw error;
  }
};

// 获取特定策略的性能历史数据
export const getAllStrategyPerformanceHistory = async (strategy) => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/all-strategy-performance-history-equal/${encodeURIComponent(strategy)}`);
  } catch (error) {
    console.error(`Error fetching performance history for ${strategy}:`, error);
    throw error;
  }
};

// 获取策略性能指标数据
export const getAllStrategyPerformanceMetrics = async (strategy) => {
  try {
    const response = await fetch(`https://my-worker.helloswx.workers.dev/api/all-strategy-performance-metrics-equal/${encodeURIComponent(strategy)}`);
    if (!response.ok) {
      throw new Error(`Failed to fetch performance metrics for ${strategy}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Error fetching performance metrics for ${strategy}:`, error);
    throw error;
  }
};

// Get current holdings for Growth Strategy
export const getGrowthCurrentHoldings = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/growth-current-holdings-info`);
  } catch (error) {
    console.error('Error fetching growth current holdings:', error);
    throw error;
  }
};

// Get current holdings for Pinnacle Strategy
export const getPinnacleCurrentHoldings = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/pinnacle-current-holdings-info`);
  } catch (error) {
    console.error('Error fetching pinnacle current holdings:', error);
    throw error;
  }
};

// Get current holdings for Stable Strategy
export const getStableCurrentHoldings = async () => {
  try {
    return makeApiRequest(`${apiBaseUrl}/api/stable-current-holdings-info`);
  } catch (error) {
    console.error('Error fetching stable current holdings:', error);
    throw error;
  }
};

// Get strategy pool sector statistics
export const getStrategyPoolSectorStats = async (strategy) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/strategy-pool-sector-stats/${strategy}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching sector stats for ${strategy}:`, error);
    throw error;
  }
};

// Get strategy pool market cap statistics
export const getStrategyPoolCapStats = async (strategy) => {
  try {
    const response = await fetch(`${apiBaseUrl}/api/strategy-pool-cap-stats/${strategy}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching market cap stats for ${strategy}:`, error);
    throw error;
  }
};

