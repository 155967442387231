import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import ReactECharts from 'echarts-for-react';
import dayjs from 'dayjs';
import { useNavigate, useLocation } from 'react-router-dom';
import { 
  getAllStrategyPerformanceHistory, 
  getAllStrategyPerformanceMetrics,
  getPinnacleCurrentHoldings,
  getLongTermStockPriceReturn,
  getLongTermStockScreener,
  getStrategyPoolSectorStats,
  getStrategyPoolCapStats
} from '../../../services/data_api';
import './PrimePicks.css';

const defaultStrategyData = {
  howItWorks: "The Pinnacle Strategy targets high-growth stocks, focusing on large-cap tech companies selected for their market dominance and innovation potential. The portfolio is rebalanced as needed based on market conditions to maintain alignment with its growth objectives.",
  features: [
    "The Pinnacle Strategy achieves a strong annualized return of 29.69% with a Sharpe ratio of 1.01, indicating impressive risk-adjusted returns that significantly surpass the S&P 500 and NASDAQ across all time periods.",
    "It experiences a higher maximum drawdown of -31.67% and a win rate of 53.78%, suggesting greater volatility and the potential for substantial losses during market corrections.",
    "Heavily tied to large-cap tech stocks and industry concentration risks, it thrives in tech-driven bull markets but is vulnerable to tech sector downturns or shifting market trends."
  ],
  considerations: "Ideal for investors seeking high returns and comfortable with volatility tied to the tech sector, it offers strong growth potential balanced against concentrated risk exposure."
};

const PinnacleStrategyPicks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [strategyData, setStrategyData] = useState(null);
  const [performanceData, setPerformanceData] = useState({
    pinnacleStrategy: [],
    sp500: [],
    nasdaq: []
  });
  const [selectedRange, setSelectedRange] = useState('ALL');
  const chartRef = useRef(null);
  const [metricsData, setMetricsData] = useState([]);
  const [metricsLoading, setMetricsLoading] = useState(true);
  const [metricsError, setMetricsError] = useState(null);
  const [selectedMetricRange, setSelectedMetricRange] = useState('ALL');
  
  // Add states for holdings data
  const [holdingsData, setHoldingsData] = useState([]);
  const [holdingsLoading, setHoldingsLoading] = useState(true);
  const [holdingsError, setHoldingsError] = useState(null);
  const [stockPrices, setStockPrices] = useState({});
  const [pricesLoading, setPricesLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: 'ticker', direction: 'ascending' });
  const [stockCategories, setStockCategories] = useState({});

  // Add these state variables for portfolio distribution
  const [sectorDistribution, setSectorDistribution] = useState([]);
  const [capDistribution, setCapDistribution] = useState([]);
  const [distributionLoading, setDistributionLoading] = useState(true);
  const [distributionError, setDistributionError] = useState(null);

  // Check subscription on component mount
  useEffect(() => {
    const checkAccess = () => {
      try {
        const subscriptionData = localStorage.getItem('subscription');
        if (!subscriptionData) {
          navigate('/pricing', { state: { from: location.pathname } });
          return;
        }

        const subscription = JSON.parse(subscriptionData);
        if (!subscription.data || 
            subscription.data.subscription_status !== 'active' || 
            new Date(subscription.data.current_period_end) <= new Date()) {
          navigate('/pricing', { state: { from: location.pathname } });
        }
      } catch (error) {
        console.error('Error checking access:', error);
        navigate('/pricing', { state: { from: location.pathname } });
      }
    };

    checkAccess();
  }, [navigate, location]);

  useEffect(() => {
    const fetchStrategyData = async () => {
      try {
        setLoading(true);
        
        // 并行获取三个策略的历史数据
        const [pinnacleData, sp500Data, nasdaqData] = await Promise.all([
          getAllStrategyPerformanceHistory('Pinnacle Strategy'),
          getAllStrategyPerformanceHistory('S&P 500'),
          getAllStrategyPerformanceHistory('NASDAQ')
        ]);
        
        setPerformanceData({
          pinnacleStrategy: pinnacleData.data || [],
          sp500: sp500Data.data || [],
          nasdaq: nasdaqData.data || []
        });
        
        // 设置策略描述数据
        const data = {
          name: 'Pinnacle Strategy',
          howItWorks: 'The Pinnacle Strategy targets high-growth stocks, focusing on large-cap tech companies selected for their market dominance and innovation potential. The portfolio is rebalanced as needed based on market conditions to maintain alignment with its growth objectives.',
          features: [
            'The Pinnacle Strategy achieves a strong annualized return of 29.69% with a Sharpe ratio of 1.01, indicating impressive risk-adjusted returns that significantly surpass the S&P 500 and NASDAQ across all time periods.',
            'It experiences a higher maximum drawdown of -31.67% and a win rate of 53.78%, suggesting greater volatility and the potential for substantial losses during market corrections.',
            'Heavily tied to large-cap tech stocks and industry concentration risks, it thrives in tech-driven bull markets but is vulnerable to tech sector downturns or shifting market trends.'
          ],
          considerations: 'Ideal for investors seeking high returns and comfortable with volatility tied to the tech sector, it offers strong growth potential balanced against concentrated risk exposure.'
        };
        
        setStrategyData(data);
        
        setLoading(false);
      } catch (err) {
        console.error('Error fetching Pinnacle Strategy data:', err);
        setError(err.message || 'Failed to load strategy data');
        setLoading(false);
      }
    };

    fetchStrategyData();
    
    // 添加窗口大小变化的监听器
    const handleResize = () => {
      if (chartRef.current) {
        const chart = chartRef.current.getEchartsInstance();
        chart.resize();
      }
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchMetricsData = async () => {
      try {
        setMetricsLoading(true);
        
        // Fetch metrics data for all three strategies
        const [pinnacleMetrics, sp500Metrics, nasdaqMetrics] = await Promise.all([
          getAllStrategyPerformanceMetrics('Pinnacle Strategy'),
          getAllStrategyPerformanceMetrics('S&P 500'),
          getAllStrategyPerformanceMetrics('NASDAQ')
        ]);
        
        // Combine the data
        const allMetrics = {
          'Pinnacle Strategy': pinnacleMetrics.data || [],
          'S&P 500': sp500Metrics.data || [],
          'NASDAQ': nasdaqMetrics.data || []
        };
        
        setMetricsData(allMetrics);
        setMetricsLoading(false);
      } catch (err) {
        console.error('Error fetching metrics data:', err);
        setMetricsError(err.message || 'Failed to load metrics data');
        setMetricsLoading(false);
      }
    };

    fetchMetricsData();
  }, []);

  // Add useEffect for fetching holdings data
  useEffect(() => {
    const fetchHoldingsData = async () => {
      try {
        setHoldingsLoading(true);
        const response = await getPinnacleCurrentHoldings();
        setHoldingsData(response.data || []);
        setHoldingsError(null);
      } catch (err) {
        console.error('Error fetching Pinnacle Strategy holdings:', err);
        setHoldingsError(err.message || 'Failed to load holdings data');
      } finally {
        setHoldingsLoading(false);
      }
    };

    fetchHoldingsData();
  }, []);

  // Fix the useEffect for fetching stock prices to match the actual API response structure
  useEffect(() => {
    const fetchStockPrices = async () => {
      if (!holdingsData.length || holdingsLoading) return;
      
      try {
        setPricesLoading(true);
        
        // Create an array of promises for each ticker's price data
        const pricePromises = holdingsData.map(holding => 
          getLongTermStockPriceReturn(holding.ticker)
            .then(priceData => ({ ticker: holding.ticker, priceData }))
            .catch(err => {
              console.error(`Error fetching price for ${holding.ticker}:`, err);
              return { ticker: holding.ticker, priceData: null };
            })
        );
        
        // Wait for all promises to resolve
        const results = await Promise.all(pricePromises);
        
        // Convert array of results to an object with tickers as keys
        const pricesObj = results.reduce((acc, { ticker, priceData }) => {
          if (priceData) {
            acc[ticker] = {
              price: priceData.current_price,
              return: priceData.current_return
            };
          } else {
            acc[ticker] = null;
          }
          return acc;
        }, {});
        
        setStockPrices(pricesObj);
      } catch (err) {
        console.error('Error fetching stock prices:', err);
      } finally {
        setPricesLoading(false);
      }
    };
    
    fetchStockPrices();
  }, [holdingsData, holdingsLoading]);

  // Add useEffect for fetching stock categories
  useEffect(() => {
    const fetchStockCategories = async () => {
      try {
        const response = await getLongTermStockScreener();
        const stocks = response?.data || [];
        
        // Create a map of ticker to market cap category
        const categoriesMap = stocks.reduce((acc, stock) => {
          if (stock && stock.ticker && stock.market_cap_rank) {
            acc[stock.ticker] = stock.market_cap_rank.toLowerCase() + '-cap';
          }
          return acc;
        }, {});
        
        setStockCategories(categoriesMap);
      } catch (error) {
        console.error('Error fetching stock categories:', error);
      }
    };
    
    fetchStockCategories();
  }, []);

  // Add useEffect for fetching portfolio distribution data
  useEffect(() => {
    const fetchDistributionData = async () => {
      try {
        setDistributionLoading(true);
        
        // Fetch sector distribution data for Pinnacle
        const sectorResponse = await getStrategyPoolSectorStats('Pinnacle');
        
        // Fetch market cap distribution data for Pinnacle
        const capResponse = await getStrategyPoolCapStats('Pinnacle');
        
        setSectorDistribution(sectorResponse.data || []);
        setCapDistribution(capResponse.data || []);
        setDistributionLoading(false);
      } catch (err) {
        console.error('Error fetching distribution data:', err);
        setDistributionError(err.message || 'Failed to load distribution data');
        setDistributionLoading(false);
      }
    };
    
    fetchDistributionData();
  }, []);

  // 处理时间范围选择
  const handleRangeChange = (range) => {
    setSelectedRange(range);
    if (chartRef.current) {
      const chart = chartRef.current.getEchartsInstance();
      chart.dispatchAction({
        type: 'dataZoom',
        ...getZoomRangeBySelection(range)
      });
    }
  };

  // 根据选择的时间范围获取缩放范围
  const getZoomRangeBySelection = (range) => {
    if (!performanceData.pinnacleStrategy.length) return { start: 0, end: 100 };
    
    const totalPoints = performanceData.pinnacleStrategy.length;
    const today = new Date();
    
    switch (range) {
      case '1M':
        const oneMonthAgo = new Date();
        oneMonthAgo.setMonth(today.getMonth() - 1);
        return getZoomRangeByDate(oneMonthAgo);
      case '3M':
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(today.getMonth() - 3);
        return getZoomRangeByDate(threeMonthsAgo);
      case '6M':
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(today.getMonth() - 6);
        return getZoomRangeByDate(sixMonthsAgo);
      case '1Y':
        const oneYearAgo = new Date();
        oneYearAgo.setFullYear(today.getFullYear() - 1);
        return getZoomRangeByDate(oneYearAgo);
      case 'ALL':
      default:
        return { start: 0, end: 100 };
    }
  };

  // 根据日期获取缩放范围
  const getZoomRangeByDate = (date) => {
    if (!performanceData.pinnacleStrategy.length) return { start: 0, end: 100 };
    
    const targetDate = date.getTime();
    const allDates = performanceData.pinnacleStrategy.map(item => new Date(item.date).getTime());
    const oldestDate = Math.min(...allDates);
    const newestDate = Math.max(...allDates);
    const totalRange = newestDate - oldestDate;
    
    // 如果目标日期早于最早的数据点，返回全部范围
    if (targetDate <= oldestDate) return { start: 0, end: 100 };
    
    // 计算百分比位置
    const percentPosition = ((targetDate - oldestDate) / totalRange) * 100;
    return { start: Math.max(0, Math.floor(percentPosition)), end: 100 };
  };

  // 获取当前选择范围内的数据
  const getFilteredData = () => {
    if (!performanceData.pinnacleStrategy.length) return { dates: [], pinnacleStrategy: [], sp500: [], nasdaq: [] };
    
    const totalDays = performanceData.pinnacleStrategy.length;
    let startIndex = 0;
    
    switch (selectedRange) {
      case '1M':
        startIndex = Math.max(0, totalDays - 30);
        break;
      case '3M':
        startIndex = Math.max(0, totalDays - 90);
        break;
      case '6M':
        startIndex = Math.max(0, totalDays - 180);
        break;
      case '1Y':
        startIndex = Math.max(0, totalDays - 365);
        break;
      case 'ALL':
      default:
        startIndex = 0;
    }
    
    return {
      dates: performanceData.pinnacleStrategy.slice(startIndex).map(item => item.date),
      pinnacleStrategy: performanceData.pinnacleStrategy.slice(startIndex).map(item => item.value),
      sp500: performanceData.sp500.slice(startIndex).map(item => item.value),
      nasdaq: performanceData.nasdaq.slice(startIndex).map(item => item.value)
    };
  };

  // 准备图表数据
  const getChartOption = () => {
    if (!performanceData.pinnacleStrategy.length) return {};
    
    // 提取日期和值
    const dates = performanceData.pinnacleStrategy.map(item => item.date);
    
    return {
      tooltip: {
        trigger: 'axis',
        formatter: function(params) {
          const date = dayjs(params[0].axisValue).format('YYYY-MM-DD');
          let result = `<div style="font-weight:bold;margin-bottom:5px;">${date}</div>`;
          
          params.forEach(param => {
            const color = param.color;
            const name = param.seriesName;
            const value = param.value.toFixed(2);
            result += `<div style="display:flex;align-items:center;margin:3px 0;">
              <span style="display:inline-block;width:10px;height:10px;background:${color};margin-right:5px;border-radius:50%;"></span>
              <span style="margin-right:15px;">${name}:</span>
              <span style="font-weight:bold;">${value}</span>
            </div>`;
          });
          
          return result;
        }
      },
      legend: {
        data: ['Pinnacle Strategy', 'S&P 500', 'NASDAQ'],
        top: 10,
        textStyle: {
          color: '#333'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '15%',
        top: '40px',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dates,
        axisLine: {
          lineStyle: {
            color: '#ccc'
          }
        },
        axisLabel: {
          formatter: function(value) {
            const screenWidth = window.innerWidth;
            if (screenWidth < 768) {
              return dayjs(value).format('MM/YY');
            }
            return dayjs(value).format('YYYY-MM');
          },
          color: '#666',
          fontSize: 11,
          margin: 12,
          rotate: window.innerWidth < 768 ? 45 : 0,
          interval: function(index, value) {
            const totalPoints = dates.length;
            const screenWidth = window.innerWidth;
            
            if (screenWidth < 576) {
              if (totalPoints > 60) return index % 60 === 0;
              if (totalPoints > 30) return index % 30 === 0;
              return index % 15 === 0;
            } else if (screenWidth < 768) {
              if (totalPoints > 365) return index % 120 === 0;
              if (totalPoints > 180) return index % 60 === 0;
              if (totalPoints > 60) return index % 30 === 0;
              return index % 15 === 0;
            } else {
              if (totalPoints > 365) return index % 90 === 0;
              if (totalPoints > 180) return index % 45 === 0;
              if (totalPoints > 60) return index % 20 === 0;
              return index % 10 === 0;
            }
          },
          hideOverlap: true
        }
      },
      yAxis: {
        type: 'value',
        axisLine: {
          show: false
        },
        axisLabel: {
          color: '#666',
          formatter: '{value}'
        },
        splitLine: {
          lineStyle: {
            color: '#eee'
          }
        }
      },
      dataZoom: [
        {
          type: 'slider',
          show: true,
          start: getZoomRangeBySelection(selectedRange).start,
          end: getZoomRangeBySelection(selectedRange).end,
          height: 30,
          bottom: 10,
          borderColor: 'transparent',
          backgroundColor: '#f8f9fa',
          fillerColor: '#d2e3fc',
          handleIcon: 'path://M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
          handleSize: '80%',
          handleStyle: {
            color: '#1a73e8',
            shadowBlur: 3,
            shadowColor: 'rgba(0, 0, 0, 0.2)',
            shadowOffsetX: 1,
            shadowOffsetY: 1
          },
          textStyle: {
            color: '#5f6368'
          }
        },
        {
          type: 'inside',
          start: getZoomRangeBySelection(selectedRange).start,
          end: getZoomRangeBySelection(selectedRange).end,
          zoomOnMouseWheel: true,
          moveOnMouseMove: true,
          preventDefaultMouseMove: false
        }
      ],
      series: [
        {
          name: 'Pinnacle Strategy',
          type: 'line',
          data: performanceData.pinnacleStrategy.map(item => item.value),
          symbol: 'none',
          lineStyle: {
            width: 3,
            color: '#1a73e8'
          }
        },
        {
          name: 'S&P 500',
          type: 'line',
          data: performanceData.sp500.map(item => item.value),
          symbol: 'none',
          lineStyle: {
            width: 2,
            color: '#e53935'
          }
        },
        {
          name: 'NASDAQ',
          type: 'line',
          data: performanceData.nasdaq.map(item => item.value),
          symbol: 'none',
          lineStyle: {
            width: 2,
            color: '#43a047'
          }
        }
      ]
    };
  };

  // Add function to get metrics for current range
  const getMetricsForCurrentRange = () => {
    const rangeMap = {
      'ALL': 'All Time',
      '1Y': '1 Year',
      '6M': '6 Months',
      '3M': '3 Months'
    };
    
    const currentRange = rangeMap[selectedMetricRange];
    
    if (!metricsData || !metricsData['Pinnacle Strategy'] || !metricsData['S&P 500'] || !metricsData['NASDAQ']) {
      return null;
    }
    
    // Find data for the current time range
    const pinnacleMetric = metricsData['Pinnacle Strategy'].find(item => item.duration === currentRange);
    const sp500Metric = metricsData['S&P 500'].find(item => item.duration === currentRange);
    const nasdaqMetric = metricsData['NASDAQ'].find(item => item.duration === currentRange);
    
    if (!pinnacleMetric || !sp500Metric || !nasdaqMetric) {
      return null;
    }
    
    return {
      pinnacleMetric,
      sp500Metric,
      nasdaqMetric
    };
  };

  // Format market cap exactly like in Growth Strategy
  const formatMarketCap = (marketCap) => {
    if (marketCap === null || marketCap === undefined) return 'N/A';
    
    // Handle numeric values
    if (typeof marketCap === 'number') {
      if (marketCap >= 1000000000000) {
        return `$${(marketCap / 1000000000000).toFixed(2)}T`;
      } else if (marketCap >= 1000000000) {
        return `$${(marketCap / 1000000000).toFixed(2)}B`;
      } else if (marketCap >= 1000000) {
        return `$${(marketCap / 1000000).toFixed(2)}M`;
      } else {
        return `$${marketCap.toLocaleString()}`;
      }
    }
    
    // Handle string values (which might include currency symbols and suffixes)
    if (typeof marketCap === 'string') {
      // Try to extract the numeric part
      const numericPart = parseFloat(marketCap.replace(/[^\d.-]/g, ''));
      if (isNaN(numericPart)) return marketCap;
      
      // Format based on magnitude
      if (numericPart >= 1e12) {
        return `$${(numericPart / 1e12).toFixed(2)}T`;
      } else if (numericPart >= 1e9) {
        return `$${(numericPart / 1e9).toFixed(2)}B`;
      } else if (numericPart >= 1e6) {
        return `$${(numericPart / 1e6).toFixed(2)}M`;
      } else {
        return `$${numericPart.toFixed(2)}`;
      }
    }
    
    return 'N/A';
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getFilteredAndSortedHoldings = () => {
    const filtered = holdingsData.filter(holding => 
      holding.ticker.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (holding.name && holding.name.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (holding.sector && holding.sector.toLowerCase().includes(searchTerm.toLowerCase()))
    );
    
    return filtered.sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const handleTickerClick = (ticker) => {
    // Use the category from our map, or default to 'small-cap'
    const category = stockCategories[ticker] || 'small-cap';
    navigate(`/long-term/${category}/${ticker}`);
  };

  const handleSectorClick = (sector) => {
    // Map sector names to ETF tickers
    const sectorToETFMap = {
      'Technology': 'XLK',
      'Financial Services': 'XLF',
      'Consumer Cyclical': 'XLY',
      'Consumer Defensive': 'XLP',
      'Consumer Staples': 'XLP',
      'Consumer Discretionary': 'XLY',
      'Healthcare': 'XLV',
      'Industrials': 'XLI',
      'Communication Services': 'XLC',
      'Energy': 'XLE',
      'Utilities': 'XLU',
      'Real Estate': 'XLRE',
      'Materials': 'XLB',
      'Basic Materials': 'XLB'
    };
    
    const etf = sectorToETFMap[sector];
    if (etf) {
      navigate(`/long-term/sector/${etf}`);
    }
  };

  // Add function to generate market cap distribution chart options
  const getMarketCapChartOptions = () => {
    return {
      title: {
        text: 'Pinnacle Pool - Market Cap Distribution',
        left: 'center',
        textStyle: {
          fontSize: 14,
          fontWeight: 'normal'
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          // Create a colored dot matching the slice color
          const colorDot = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:${params.color};"></span>`;
          return `Market Cap<br/>${colorDot}${params.name}: ${params.value.toFixed(2)}%`;
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: '#ccc',
        borderWidth: 1,
        textStyle: {
          color: '#333'
        },
        extraCssText: 'box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);'
      },
      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
        data: capDistribution.map(item => item.cap_category),
        itemWidth: 12,
        itemHeight: 12,
        textStyle: {
          fontSize: 12
        }
      },
      series: [
        {
          name: 'Market Cap',
          type: 'pie',
          radius: '65%',
          center: ['50%', '50%'],
          data: capDistribution.map(item => ({
            name: item.cap_category,
            value: item.percentage
          })),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: true,
            formatter: '{b}: {d}%',
            fontSize: 14,
            fontWeight: 'normal',
            position: 'outside'
          },
          labelLine: {
            show: true,
            length: 15,
            length2: 10
          },
          itemStyle: {
            borderRadius: 4,
            borderColor: '#fff',
            borderWidth: 2
          }
        }
      ],
      color: ['#4e79a7', '#f28e2c', '#59a14f']
    };
  };

  // Add function to generate sector distribution chart options
  const getSectorChartOptions = () => {
    return {
      title: {
        text: 'Pinnacle Pool - Sector Distribution',
        left: 'center',
        textStyle: {
          fontSize: 14,
          fontWeight: 'normal'
        }
      },
      tooltip: {
        trigger: 'item',
        formatter: function(params) {
          // Create a colored dot matching the slice color
          const colorDot = `<span style="display:inline-block;margin-right:5px;border-radius:50%;width:10px;height:10px;background-color:${params.color};"></span>`;
          return `${colorDot}${params.name}: ${params.value.toFixed(2)}%`;
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        borderColor: '#ccc',
        borderWidth: 1,
        textStyle: {
          color: '#333'
        },
        extraCssText: 'box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);'
      },
      legend: {
        orient: 'horizontal',
        bottom: 'bottom',
        data: sectorDistribution.map(item => item.sector),
        itemWidth: 12,
        itemHeight: 12,
        textStyle: {
          fontSize: 12
        }
      },
      series: [
        {
          name: 'Sector',
          type: 'pie',
          radius: '65%',
          center: ['50%', '50%'],
          data: sectorDistribution.map(item => ({
            name: item.sector,
            value: item.percentage
          })),
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          },
          label: {
            show: true,
            formatter: '{b}: {d}%',
            fontSize: 14,
            fontWeight: 'normal',
            position: 'outside'
          },
          labelLine: {
            show: true,
            length: 15,
            length2: 10
          },
          itemStyle: {
            borderRadius: 4,
            borderColor: '#fff',
            borderWidth: 2
          }
        }
      ],
      color: ['#4e79a7', '#f28e2c', '#59a14f', '#e15759', '#76b7b2', '#edc949', '#af7aa1', '#ff9da7']
    };
  };

  // 使用默认值
  const safeStrategyData = strategyData || defaultStrategyData;

  if (loading) {
    return (
      <div className="primePicks-loading">
        <div className="primePicks-loading-spinner"></div>
        <span>Loading Pinnacle Strategy data...</span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="primePicks-error">
        <div className="primePicks-error-icon">⚠️</div>
        <p>{error}</p>
      </div>
    );
  }

  return (
    <div className="primePicks-container">
      <Helmet>
        <title>Pinnacle Strategy - FinStock Insights</title>
      </Helmet>
      
      <div className="primePicks-header">
        <h1 className="primePicks-title">Pinnacle Strategy</h1>
      </div>
      
      <div className="primePicks-overview-section">
        <h2 className="primePicks-section-title">Strategy Overview</h2>
        
        <div className="primePicks-overview-content">
          <div className="primePicks-chart-wrapper">
            <h3 className="primePicks-chart-title">Pinnacle Strategy vs Market Indices</h3>
            <div className="primePicks-chart-range-selector">
              {['1M', '3M', '6M', '1Y', 'ALL'].map(range => (
                <button
                  key={range}
                  className={`primePicks-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
                  onClick={() => handleRangeChange(range)}
                >
                  {range}
                </button>
              ))}
            </div>
            <div className="primePicks-chart-container">
              <ReactECharts 
                option={getChartOption()} 
                style={{ height: '400px', width: '100%' }}
                ref={chartRef}
                opts={{ renderer: 'canvas' }}
                onEvents={{
                  datazoom: (params) => {
                    if (params.start !== getZoomRangeBySelection(selectedRange).start ||
                        params.end !== getZoomRangeBySelection(selectedRange).end) {
                      setSelectedRange('custom');
                    }
                  }
                }}
              />
            </div>
          </div>
          
          <div className="primePicks-strategy-details">
            <h3 className="primePicks-strategy-name">Pinnacle Strategy</h3>
            
            <ul className="primePicks-strategy-info-list">
              <li className="primePicks-strategy-info-item">
                <span className="primePicks-info-title">How it works:</span>
                <span className="primePicks-info-text">
                  {safeStrategyData.howItWorks}
                </span>
              </li>
              
              <li className="primePicks-strategy-info-item">
                <span className="primePicks-info-title">Features:</span>
                <ul className="primePicks-features-list">
                  {(safeStrategyData.features || []).map((feature, index) => (
                    <li key={index} className="primePicks-feature-item">
                      {feature}
                    </li>
                  ))}
                </ul>
              </li>
              
              <li className="primePicks-strategy-info-item">
                <span className="primePicks-info-title">Considerations:</span>
                <span className="primePicks-info-text">
                  {safeStrategyData.considerations}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
      <div className="primePicks-metrics-section">
        <h2 className="primePicks-section-title">{t('Performance Metrics')}</h2>
        
        <div className="primePicks-metrics-range-selector">
          <button
            className={`primePicks-metrics-range-btn ${selectedMetricRange === 'ALL' ? 'active' : ''}`}
            onClick={() => setSelectedMetricRange('ALL')}
          >
            ALL
          </button>
          <button
            className={`primePicks-metrics-range-btn ${selectedMetricRange === '1Y' ? 'active' : ''}`}
            onClick={() => setSelectedMetricRange('1Y')}
          >
            1Y
          </button>
          <button
            className={`primePicks-metrics-range-btn ${selectedMetricRange === '6M' ? 'active' : ''}`}
            onClick={() => setSelectedMetricRange('6M')}
          >
            6M
          </button>
          <button
            className={`primePicks-metrics-range-btn ${selectedMetricRange === '3M' ? 'active' : ''}`}
            onClick={() => setSelectedMetricRange('3M')}
          >
            3M
          </button>
        </div>
        
        {metricsLoading ? (
          <div className="primePicks-metrics-loading">
            <div className="primePicks-loading-spinner"></div>
            <span>{t('Loading metrics data...')}</span>
          </div>
        ) : metricsError ? (
          <div className="primePicks-metrics-error">
            <div className="primePicks-error-icon">⚠️</div>
            <p>{metricsError}</p>
          </div>
        ) : (
          <div className="primePicks-metrics-table-container">
            <table className="primePicks-metrics-table">
              <thead>
                <tr>
                  <th>{t('Strategy')}</th>
                  <th>{t('Duration')}</th>
                  <th>{t('Annual Return (%)')}</th>
                  <th>{t('Sharpe Ratio')}</th>
                  <th>{t('Max Drawdown (%)')}</th>
                  <th>{t('VaR (95%)')}</th>
                  <th>{t('Volatility (%)')}</th>
                  <th>{t('Beta')}</th>
                  <th>{t('Alpha (%)')}</th>
                  <th>{t('Win Rate (%)')}</th>
                </tr>
              </thead>
              <tbody>
                {getMetricsForCurrentRange() ? (
                  <>
                    <tr className="primePicks-metrics-row primePicks-metrics-row-highlight">
                      <td>{t('Pinnacle Strategy')}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.duration}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.annual_return.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.sharpe_ratio.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.max_drawdown.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.var_95.toFixed(3)}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.volatility.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.beta.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.alpha.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().pinnacleMetric.win_rate.toFixed(2)}</td>
                    </tr>
                    <tr className="primePicks-metrics-row">
                      <td>{t('S&P 500')}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.duration}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.annual_return.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.sharpe_ratio.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.max_drawdown.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.var_95.toFixed(3)}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.volatility.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.beta.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.alpha.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().sp500Metric.win_rate.toFixed(2)}</td>
                    </tr>
                    <tr className="primePicks-metrics-row">
                      <td>{t('NASDAQ')}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.duration}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.annual_return.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.sharpe_ratio.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.max_drawdown.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.var_95.toFixed(3)}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.volatility.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.beta.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.alpha.toFixed(2)}</td>
                      <td>{getMetricsForCurrentRange().nasdaqMetric.win_rate.toFixed(2)}</td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="10" className="primePicks-no-data">{t('No data available for selected time range')}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      
      <div className="primePicks-holdings-section">
        <h2 className="primePicks-section-title">Current Holdings</h2>
        
        <div className="primePicks-holdings-controls">
          <div className="primePicks-search-container">
            <input
              type="text"
              placeholder={t('Search ticker or sector...')}
              value={searchTerm}
              onChange={handleSearch}
              className="primePicks-search-input"
            />
            <i className="primePicks-search-icon">🔍</i>
          </div>
          
          <div className="primePicks-sort-container">
            <span>{t('Sort by')}:</span>
            <select 
              className="primePicks-sort-select"
              onChange={(e) => handleSort(e.target.value)}
              value={sortConfig.key}
            >
              <option value="ticker">{t('Ticker')}</option>
              <option value="name">{t('Name')}</option>
              <option value="market_cap">{t('Market Cap')}</option>
              <option value="sector">{t('Sector')}</option>
              <option value="pe">{t('PE Ratio')}</option>
              <option value="eps">{t('EPS')}</option>
              <option value="weight">{t('Weight')}</option>
              <option value="created_date">{t('Created Date')}</option>
            </select>
          </div>
        </div>
        
        {holdingsLoading || pricesLoading ? (
          <div className="primePicks-holdings-loading">
            <div className="primePicks-loading-spinner"></div>
            <span>{t('Loading holdings data...')}</span>
          </div>
        ) : holdingsError ? (
          <div className="primePicks-holdings-error">
            <div className="primePicks-error-icon">⚠️</div>
            <p>{holdingsError}</p>
          </div>
        ) : (
          <div className="primePicks-holdings-table-container">
            <table className="primePicks-holdings-table">
              <thead>
                <tr>
                  <th>{t('Ticker')}</th>
                  <th>{t('Name')}</th>
                  <th>{t('Price (Return)')}</th>
                  <th>{t('Market Cap')}</th>
                  <th>{t('Sector')}</th>
                  <th>{t('PE Ratio')}</th>
                  <th>{t('EPS')}</th>
                  <th>{t('Weight')}</th>
                  <th>{t('Created Date')}</th>
                </tr>
              </thead>
              <tbody>
                {getFilteredAndSortedHoldings().length > 0 ? (
                  getFilteredAndSortedHoldings().map((holding, index) => (
                    <tr key={index} className="primePicks-holdings-row">
                      <td className="primePicks-holdings-ticker">
                        <span 
                          className="primePicks-ticker-link"
                          onClick={() => handleTickerClick(holding.ticker)}
                        >
                          {holding.ticker}
                        </span>
                      </td>
                      <td>{holding.name || 'N/A'}</td>
                      <td className="primePicks-holdings-price">
                        {stockPrices[holding.ticker] ? (
                          <div className="primePicks-price-container">
                            <span className="primePicks-price-value">
                              ${stockPrices[holding.ticker].price.toFixed(2)}
                            </span>
                            <span className={`primePicks-return-value ${stockPrices[holding.ticker].return >= 0 ? 'positive' : 'negative'}`}>
                              ({stockPrices[holding.ticker].return.toFixed(2)}%)
                            </span>
                          </div>
                        ) : 'Loading...'}
                      </td>
                      <td className="primePicks-holdings-market-cap">{formatMarketCap(holding.market_cap)}</td>
                      <td>
                        {holding.sector ? (
                          <span 
                            className="primePicks-sector-link"
                            onClick={() => handleSectorClick(holding.sector)}
                          >
                            {holding.sector}
                          </span>
                        ) : 'N/A'}
                      </td>
                      <td>{holding.pe !== null && holding.pe !== undefined ? holding.pe.toFixed(2) : 'N/A'}</td>
                      <td>
                        {holding.eps !== null && holding.eps !== undefined ? (
                          <span className={`primePicks-eps-value ${holding.eps >= 0 ? 'positive' : 'negative'}`}>
                            ${holding.eps.toFixed(2)}
                          </span>
                        ) : 'N/A'}
                      </td>
                      <td>{holding.weight !== null && holding.weight !== undefined ? `${(holding.weight * 100).toFixed(2)}%` : 'N/A'}</td>
                      <td>{holding.created_date || 'N/A'}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="9" className="primePicks-holdings-no-data">
                      {searchTerm ? t('No holdings match your search criteria') : t('No holdings data available')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
      
      {/* Add portfolio distribution section */}
      <div className="primePicks-distribution-section">
        <h2 className="primePicks-section-title">Portfolio Distribution</h2>
        
        {distributionLoading ? (
          <div className="primePicks-distribution-loading">
            <div className="primePicks-loading-spinner"></div>
            <span>{t('Loading distribution data...')}</span>
          </div>
        ) : distributionError ? (
          <div className="primePicks-distribution-error">
            <div className="primePicks-error-icon">⚠️</div>
            <p>{distributionError}</p>
          </div>
        ) : (
          <div className="primePicks-distribution-content">
            <div className="primePicks-distribution-row">
              <div className="primePicks-distribution-column">
                <h3 className="primePicks-distribution-subtitle">Market Cap Distribution</h3>
                <div className="primePicks-distribution-chart">
                  <ReactECharts 
                    option={getMarketCapChartOptions()} 
                    style={{ height: '400px', width: '100%' }}
                    opts={{ renderer: 'canvas' }}
                  />
                </div>
              </div>
              
              <div className="primePicks-distribution-column">
                <h3 className="primePicks-distribution-subtitle">Sector Distribution</h3>
                <div className="primePicks-distribution-chart">
                  <ReactECharts 
                    option={getSectorChartOptions()} 
                    style={{ height: '400px', width: '100%' }}
                    opts={{ renderer: 'canvas' }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PinnacleStrategyPicks; 