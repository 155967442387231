import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ children, level = 'pro' }) => {
  const location = useLocation();
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
  const subscription = useSelector(state => state.auth?.subscription);

  // 检查 PRO 权限
  const checkAccess = (level) => {
    try {
      const subscriptionData = localStorage.getItem('subscription');
      if (!subscriptionData) return false;

      const subscription = JSON.parse(subscriptionData);
      if (!subscription || !subscription.data) return false;

      return subscription.data.subscription_status === 'active' && 
             new Date(subscription.data.current_period_end) > new Date();
    } catch (error) {
      console.error('Error checking access:', error);
      return false;
    }
  };

  // 检查订阅状态
  const isSubscriptionValid = () => {
    try {
      const subscriptionData = localStorage.getItem('subscription');
      if (!subscriptionData) {
        console.log('No subscription data found');
        return false;
      }

      const subscription = JSON.parse(subscriptionData);
      if (!subscription || !subscription.data) {
        console.log('Invalid subscription data structure');
        return false;
      }

      const isValid = subscription.data.subscription_status === 'active' && 
                     new Date(subscription.data.current_period_end) > new Date();

      console.log('Subscription check result:', {
        status: subscription.data.subscription_status,
        endDate: subscription.data.current_period_end,
        isValid
      });

      return isValid;
    } catch (error) {
      console.error('Error checking subscription:', error);
      return false;
    }
  };

  // 基础登录检查
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // 根据权限级别检查
  if (level === 'pro') {
    if (!checkAccess('pro')) {
      return <Navigate to="/pricing" state={{ from: location }} replace />;
    }

    if (!isSubscriptionValid()) {
      return <Navigate to="/pricing" state={{ from: location }} replace />;
    }
  }

  return children;
};

export default ProtectedRoute; 