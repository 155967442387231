// 添加一个简单的模式控制
const STRIPE_MODE = 'live'; // 改成 'live' 即可切换到生产模式

// Stripe 配置
export const STRIPE_CONFIG = {
  test: {
    publicKey: 'pk_test_51ORGxLBR55EZVoQVKiY1FzoeCvSj4MUHT7vRF6ilMnWoFuqExkPGgLJRsHbWpZInkQOQe4wZHHHi0qySl0v4vTfu001sf5N5z3'
  },
  live: {
    publicKey: 'pk_live_51ORGxLBR55EZVoQVSthIcBn46e0YPp7nWDaEDWDLSMsm42WbWiMDa5Zs3AmcTq82b0PTmj3TBOOzyf5fsyCqz8zn00w2EeL41o'
  }
};

// 当前环境配置
export const STRIPE_PUBLIC_KEY = STRIPE_CONFIG[STRIPE_MODE].publicKey;

// 价格 ID 配置
export const PRICE_IDS = {
  test: {
    monthly: 'price_1QWxzWBR55EZVoQVkcLbCsvC',
    yearly: 'price_1QWxz8BR55EZVoQV7nPkRHUo'
  },
  live: {
    monthly: 'price_1QXjJQBR55EZVoQVoES3Rnqd',
    yearly: 'price_1QXjJQBR55EZVoQVM5mSnV4G'
  }
};

// 更新 PRODUCTS 配置
export const PRODUCTS = [
  {
    id: 'prod_2',
    name: 'pro',
    description: 'keyBenefitsOfPro',
    monthlyPriceId: PRICE_IDS[STRIPE_MODE].monthly,
    yearlyPriceId: PRICE_IDS[STRIPE_MODE].yearly,
    monthlyPrice: 15.00,
    yearlyPrice: 144.00,
    features: ['livePickedStockScreener', 'instantInsights', 'comparisonWithKeyAssets']
  }
];

// 如果有其他配置，也可以在这里添加
