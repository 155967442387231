import React from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../Tooltip';
import './BciMetrics.css';
import BciPriceRatioChart from './BciPriceRatioChart';
import BciBalanceChart from './BciBalanceChart';

const MetricCard = ({ label, value, tooltip }) => (
  <div className="stock-bci-metric-card">
    <div className="stock-bci-metric-header">
      {tooltip ? (
        <Tooltip content={tooltip}>
          <span className="stock-bci-metric-label">{label}</span>
        </Tooltip>
      ) : (
        <span className="stock-bci-metric-label">{label}</span>
      )}
    </div>
    <div className="stock-bci-metric-value">{value}</div>
  </div>
);

const BciMetrics = ({ bciData, priceRatioHistory, balanceHistory }) => {
  const { t, i18n } = useTranslation();
  const category = i18n.language === 'zh' ? bciData?.category_cn : bciData?.category_en;

  const formatValue = (value) => {
    if (value === null || value === undefined || isNaN(value)) return '-';
    return Number(value).toFixed(6);
  };

  if (!bciData) return <div className="stock-bci-loading">{t('loading')}</div>;

  // ���义所有需要展示的指标项
  const metrics = [
    {
      key: 'BTC_Relationship_Score',
      label: 'BTC Relationship Score',
      tooltip: 'The BTC Relationship Score is a metric from 1 to 10 that reflects the correlation between a company\'s business and Bitcoin, based on AI analysis. A higher score indicates a stronger relationship with Bitcoin.'
    },
    {
      key: 'Last_Day_BTC_Price_Ratio',
      label: 'BTC Price Ratio',
      tooltip: 'A ratio comparing the stock price to the price of Bitcoin, illustrating how the company\'s stock performance correlates with Bitcoin\'s market movements.'
    },
    {
      key: 'BTC_per_Share',
      label: 'BTC Per Share',
      tooltip: 'The amount of Bitcoin represented by each outstanding share of the company, indicating the level of Bitcoin exposure per share.'
    },
    {
      key: 'Correlation',
      label: 'Correlation with Bitcoin',
      tooltip: 'A statistical measure that defines the strength and direction of the linear relationship between the stock\'s price and Bitcoin\'s price.'
    },
    {
      key: 'Beta',
      label: 'Beta with Bitcoin',
      tooltip: 'A measure of the stock\'s volatility in relation to Bitcoin, indicating how sensitive the stock is to Bitcoin price movements.'
    },
    {
      key: 'Alpha',
      label: 'Alpha with Bitcoin',
      tooltip: 'A metric showing the stock\'s excess return compared to what would be expected based on its correlation with Bitcoin.'
    }
  ];

  // 确保数据是数组
  const safeRatioHistory = Array.isArray(priceRatioHistory) ? priceRatioHistory : [];
  const safeBalanceHistory = Array.isArray(balanceHistory) ? balanceHistory : [];

  return (
    <div className="stock-bci-container">
      <div className="stock-bci-header">
        <p className="stock-bci-category">
          {category || (i18n.language === 'zh' ? '其他' : 'Other')}
        </p>
      </div>

      <div className="stock-bci-metrics-grid">
        {metrics.map(metric => (
          <MetricCard
            key={metric.key}
            label={metric.label}
            value={formatValue(bciData[metric.key])}
            tooltip={metric.tooltip}
          />
        ))}
      </div>

      <div className="stock-bci-charts-container">
        <div className="stock-bci-chart">
          <BciPriceRatioChart 
            data={safeRatioHistory} 
          />
        </div>
        
        <div className="stock-bci-chart">
          <BciBalanceChart 
            data={safeBalanceHistory} 
          />
        </div>
      </div>
    </div>
  );
};

export default BciMetrics; 