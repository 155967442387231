import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams, Link } from 'react-router-dom';
import { callLoginApi } from '../login_api';
import './ResetPasswordPage.css';

const ResetPasswordPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: ''
  });
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(false);
  const [isValidating, setIsValidating] = useState(true);
  const token = searchParams.get('token');

  useEffect(() => {
    const validateToken = async () => {
      if (!token) {
        setMessage(t('errors.invalidToken'));
        setIsValidating(false);
        return;
      }

      try {
        await callLoginApi('/forgot-password-validate', { token });
        setIsTokenValid(true);
      } catch (error) {
        console.error('Token validation error:', error);
        if (error.message === 'Token expired') {
          setMessage(t('errors.tokenExpired'));
        } else {
          setMessage(t('errors.invalidOrExpiredToken'));
        }
        setIsTokenValid(false);
      } finally {
        setIsValidating(false);
      }
    };

    validateToken();
  }, [token, t]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
    setMessage('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');

    const { password, confirmPassword } = formData;

    if (password.length < 8) {
      setMessage(t('errors.passwordTooShort'));
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setMessage(t('errors.passwordsDoNotMatch'));
      setIsLoading(false);
      return;
    }

    try {
      await callLoginApi('/forgot-password-new-password', {
        token,
        password
      });

      setMessage(t('passwordResetSuccess'));
      setTimeout(() => navigate('/login'), 2000);
    } catch (error) {
      console.error('Password reset error:', error);
      if (!navigator.onLine) {
        setMessage(t('errors.networkError'));
      } else if (error.message === 'Invalid token') {
        setMessage(t('errors.invalidToken'));
      } else {
        setMessage(t('errors.serverError'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isValidating) {
    return (
      <div className="reset-password-container">
        <div className="loading-message">
          <div className="spinner"></div>
          <span>{t('validatingToken')}</span>
        </div>
      </div>
    );
  }

  if (!isTokenValid) {
    return (
      <div className="reset-password-container">
        <div className="error-message">
          {message}
          <p>
            <Link to="/forgot-password">{t('requestNewResetLink')}</Link>
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="reset-password-container">
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <h2>{t('resetPassword')}</h2>
        {message && (
          <div className={`message ${message === t('passwordResetSuccess') ? 'success' : 'error'}`}>
            {message}
          </div>
        )}
        <div className="form-group">
          <label htmlFor="password">{t('newPassword')}</label>
          <input
            type="password"
            id="password"
            value={formData.password}
            onChange={handleChange}
            required
            minLength={8}
            disabled={isLoading}
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">{t('confirmPassword')}</label>
          <input
            type="password"
            id="confirmPassword"
            value={formData.confirmPassword}
            onChange={handleChange}
            required
            minLength={8}
            disabled={isLoading}
          />
        </div>
        <button
          type="submit"
          className={`submit-button ${isLoading ? 'loading' : ''}`}
          disabled={isLoading}
        >
          {isLoading ? (
            <div className="button-content">
              <div className="spinner"></div>
              <span>{t('loading')}</span>
            </div>
          ) : (
            t('resetPassword')
          )}
        </button>
      </form>
    </div>
  );
};

export default ResetPasswordPage;