import React from 'react';

const StockMomentum = ({ data }) => {
  return (
    <div className="stock-momentum-container">
      <h2>Momentum Indicators</h2>
      {/* 动量指标区域 */}
      <div className="momentum-metrics">
        <div className="metric-row">
          <span>RSI</span>
          <span>{data?.rsi || 'N/A'}</span>
        </div>
      </div>
    </div>
  );
};

export default StockMomentum; 