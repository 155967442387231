import React from 'react';
import './StockPremiumMetrics.css';
import Tooltip from '../../Tooltip';

const METRICS_CONFIG = [
  {
    id: 'valuation',
    title: 'Equity Multiples',
    metrics: [
      { key: 'pe', label: 'PE (TTM)', format: 'decimal' },
      { key: 'ps', label: 'PS (TTM)', format: 'decimal' },
      { key: 'pb', label: 'PB', format: 'decimal' },
    ]
  },
  {
    id: 'enterprise',
    title: 'Enterprise Multiples',
    metrics: [
      { key: 'ev_ebitda', label: 'EV/EBITDA', format: 'decimal' },
      { key: 'ev_ebit', label: 'EV/EBIT', format: 'decimal' },
      { key: 'ev_sales', label: 'EV/Sales', format: 'decimal' },
    ]
  },
  {
    id: 'yield',
    title: 'Cash Flow Valuation',
    metrics: [
      { key: 'free_cash_flow_yield', label: 'Free Cash Flow Yield', format: 'percentage' },
    ]
  }
];

const METRIC_TOOLTIPS = {
  nav_premium: `A measure comparing a company's market price to its Net Asset Value (NAV). A positive premium means the stock trades above NAV, while a negative premium (discount) means it trades below NAV. High premiums often indicate strong market confidence but may also suggest increased valuation risk, while discounts might represent potential opportunities.`,
  
  pe: `Price-to-Earnings (P/E) ratio measures the company's share price relative to its earnings per share (EPS). A high P/E suggests investors expect higher earnings growth and are willing to pay more for those expectations. A low P/E might indicate either market skepticism or an undervalued opportunity. Industry context is crucial for interpretation.`,
  
  pb: `Price-to-Book (P/B) ratio compares market price to book value per share. A P/B below 1 suggests the stock might be undervalued, though this could also indicate underlying problems. Higher P/B ratios are typical for companies with strong growth prospects or high-quality intangible assets not reflected in book value.`,
  
  ev_ebitda: `Enterprise Value to EBITDA ratio evaluates the company's total value (including debt) relative to its operating performance. Lower ratios might indicate undervaluation, while higher ratios could suggest premium pricing or growth expectations. This metric is particularly useful for comparing companies with different capital structures.`,
  
  free_cash_flow_yield: `Free Cash Flow Yield shows how much free cash flow a company generates relative to its market value. Higher yields typically indicate better value and stronger financial health, suggesting the company can fund growth, pay dividends, or reduce debt. Lower yields might signal either overvaluation or heavy reinvestment in growth.`
};

const formatValue = (value, format) => {
  if (value === undefined || value === null) return 'N/A';
  
  switch (format) {
    case 'percentage':
      return `${(value * 100).toFixed(2)}%`;
    case 'decimal':
      return value.toFixed(2);
    default:
      return value.toString();
  }
};

const MetricCard = ({ label, value, tooltip }) => (
  <div className="longterm-premium-metric-card">
    <div className="longterm-premium-metric-header">
      <span className="longterm-premium-metric-label">
        {label}
        {tooltip && <Tooltip content={tooltip} />}
      </span>
    </div>
    <div className="longterm-premium-metric-value">{value}</div>
  </div>
);

const StockPremiumMetrics = ({ premiumMetricsData }) => {
  if (!premiumMetricsData) return null;

  return (
    <div className="longterm-premium-metrics">
      {METRICS_CONFIG.map(section => (
        <div key={section.id} className="longterm-premium-metrics-section">
          <h3 className="longterm-premium-metrics-title">{section.title}</h3>
          <div className="longterm-premium-metrics-grid">
            {section.metrics.map(metric => (
              <MetricCard
                key={metric.key}
                label={metric.label}
                value={formatValue(premiumMetricsData[metric.key], metric.format)}
                tooltip={METRIC_TOOLTIPS[metric.key]}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StockPremiumMetrics; 