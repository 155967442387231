import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './StockGrowthChart.css';

const TIME_RANGES = {
  '1Y': 365,
  '2Y': 730,
  'ALL': Number.MAX_SAFE_INTEGER
};

const formatLargeNumber = (value) => {
  if (value >= 1e9) return `${(value / 1e9).toFixed(2)}B`;
  if (value >= 1e6) return `${(value / 1e6).toFixed(2)}M`;
  if (value >= 1e3) return `${(value / 1e3).toFixed(2)}K`;
  return value.toFixed(2);
};

const StockGrowthChart = ({ historyData, ticker }) => {
  const [selectedRange, setSelectedRange] = useState('ALL');

  if (!historyData) {
    return (
      <div className="stock-growth-chart">
        <div className="stock-growth-chart-header">
          <div className="stock-growth-chart-title">
            {ticker} - Revenue & EPS History
          </div>
          <div className="stock-growth-chart-time-range">
            {Object.keys(TIME_RANGES).map(range => (
              <button
                key={range}
                className={`stock-growth-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
                onClick={() => setSelectedRange(range)}
                disabled
              >
                {range}
              </button>
            ))}
          </div>
        </div>
        <div className="stock-growth-chart-loading">
          <i className="fas fa-spinner fa-spin stock-growth-chart-loading-icon"></i>
          <p className="stock-growth-chart-loading-text">Loading chart data...</p>
        </div>
      </div>
    );
  }

  if (!historyData.data || historyData.data.length === 0) {
    return (
      <div className="stock-growth-chart">
        <div className="stock-growth-chart-header">
          <div className="stock-growth-chart-title">
            {ticker} - Revenue & EPS History
          </div>
          <div className="stock-growth-chart-time-range">
            {Object.keys(TIME_RANGES).map(range => (
              <button
                key={range}
                className={`stock-growth-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
                onClick={() => setSelectedRange(range)}
                disabled
              >
                {range}
              </button>
            ))}
          </div>
        </div>
        <div className="stock-growth-chart-empty">
          <i className="fas fa-database stock-growth-chart-empty-icon"></i>
          <p className="stock-growth-chart-empty-text">No history data available</p>
        </div>
      </div>
    );
  }

  const getChartData = () => {
    if (!historyData?.data) return { dates: [], revenue: [], eps: [] };

    const days = TIME_RANGES[selectedRange];
    const currentDate = new Date();
    const cutoffDate = new Date();
    cutoffDate.setDate(currentDate.getDate() - days);

    // 按日期排序数据
    const sortedData = [...historyData.data].sort((a, b) => 
      new Date(a.date) - new Date(b.date)
    );

    // 根据选择的时间范围过滤数据
    const filteredData = selectedRange === 'ALL' 
      ? sortedData 
      : sortedData.filter(item => new Date(item.date) > cutoffDate);

    return {
      dates: filteredData.map(item => item.date),
      revenue: filteredData.map(item => item.total_revenue),
      eps: filteredData.map(item => item.diluted_eps)
    };
  };

  const chartData = getChartData();

  const option = {
    title: {
      show: false
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function(params) {
        const date = params[0].axisValue;
        const revenue = formatLargeNumber(params[0].value);
        const eps = params[1].value.toFixed(2);
        return `<div style="padding: 3px;">
          <div style="margin-bottom: 4px;"><strong>${date}</strong></div>
          <div style="color: #1a73e8">Revenue: $${revenue}</div>
          <div style="color: #34a853">EPS: $${eps}</div>
        </div>`;
      }
    },
    legend: {
      data: ['Revenue', 'EPS'],
      top: 30,
      textStyle: {
        fontSize: 14
      }
    },
    grid: {
      left: '5%',
      right: '5%',
      top: '15%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: chartData.dates,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: '#999'
        }
      },
      axisLabel: {
        fontSize: 12,
        color: '#666',
        formatter: (value) => {
          // 格式化日期显示
          const date = new Date(value);
          return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
        }
      }
    },
    yAxis: [
      {
        type: 'value',
        name: 'Revenue',
        position: 'left',
        axisLabel: {
          formatter: value => '$' + formatLargeNumber(value),
          fontSize: 12,
          color: '#666'
        },
        splitLine: {
          lineStyle: {
            color: '#f5f5f5',
            type: 'dashed'
          }
        }
      },
      {
        type: 'value',
        name: 'EPS',
        position: 'right',
        axisLabel: {
          formatter: value => '$' + value.toFixed(2),
          fontSize: 12,
          color: '#666'
        },
        splitLine: {
          show: false
        }
      }
    ],
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        name: 'Revenue',
        type: 'line',
        data: chartData.revenue,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 3,
          color: '#1a73e8'
        }
      },
      {
        name: 'EPS',
        type: 'line',
        yAxisIndex: 1,
        data: chartData.eps,
        smooth: true,
        symbol: 'none',
        lineStyle: {
          width: 3,
          color: '#34a853'
        }
      }
    ]
  };

  return (
    <div className="stock-growth-chart">
      <div className="stock-growth-chart-header">
        <div className="stock-growth-chart-title">
          {ticker} - Revenue & EPS History
        </div>
        <div className="stock-growth-chart-time-range">
          {Object.keys(TIME_RANGES).map(range => (
            <button
              key={range}
              className={`stock-growth-chart-range-btn ${selectedRange === range ? 'active' : ''}`}
              onClick={() => setSelectedRange(range)}
            >
              {range}
            </button>
          ))}
        </div>
      </div>
      <ReactECharts 
        option={option}
        style={{ height: '400px' }}
        opts={{ renderer: 'svg' }}
        notMerge={true}
      />
    </div>
  );
};

export default StockGrowthChart; 