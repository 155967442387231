import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getNavPremiumHistory } from '../../services/data_api';
import './ValuationPremium.css';
import ReactECharts from 'echarts-for-react';
import { PieChart, Pie, Cell } from 'recharts';
import Tooltip from '../Tooltip';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'All', days: 0 },
];

const filterDataByRange = (data, days) => {
  if (!days) return data;
  
  const cutoffDate = new Date();
  cutoffDate.setDate(cutoffDate.getDate() - days);
  
  return data.filter(item => new Date(item.date) >= cutoffDate);
};

const ValuationMetricItem = ({ label, value, tooltip }) => {
  const { t } = useTranslation();
  return (
    <div className="vp-metric-card">
      <div className="vp-metric-header">
        <span className="vp-metric-label">{label}</span>
        {tooltip && (
          <Tooltip content={tooltip}>
            <span></span>
          </Tooltip>
        )}
      </div>
      <div className="vp-metric-value">{value}</div>
    </div>
  );
};

const VALUATION_COLORS = {
  'A': {
    inner: '#4CAF50',
    outer: ['#4CAF50', '#4CAF50', '#4CAF50', '#4CAF50']
  },
  'B': {
    inner: '#4CAF50',
    outer: ['#4CAF50', '#4CAF50', '#4CAF50', '#F44336']
  },
  'C': {
    inner: ['#4CAF50', '#F44336'],
    outer: ['#4CAF50', '#4CAF50', '#F44336', '#F44336']
  },
  'D': {
    inner: '#F44336',
    outer: ['#4CAF50', '#F44336', '#F44336', '#F44336']
  },
  'E': {
    inner: '#F44336',
    outer: ['#F44336', '#F44336', '#F44336', '#F44336']
  }
};

const VALUATION_LABELS = {
  'A': 'Very Low',
  'B': 'Low',
  'C': 'Medium',
  'D': 'High',
  'E': 'Very High'
};

const ValuationPieChart = ({ grade }) => {
  const outerData = VALUATION_COLORS[grade].outer.map((color, index) => ({
    name: `outer-${index}`,
    value: 100 / VALUATION_COLORS[grade].outer.length
  }));

  const innerData = Array.isArray(VALUATION_COLORS[grade].inner)
    ? VALUATION_COLORS[grade].inner.map((color, index) => ({
        name: `inner-${index}`,
        value: 100 / VALUATION_COLORS[grade].inner.length
      }))
    : [{ name: 'inner', value: 100 }];

  return (
    <div className="vp-chart-container">
      <PieChart width={180} height={180}>
        <Pie
          data={outerData}
          cx={90}
          cy={90}
          innerRadius={60}
          outerRadius={80}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {outerData.map((entry, index) => (
            <Cell key={`outer-${index}`} fill={VALUATION_COLORS[grade].outer[index]} />
          ))}
        </Pie>
        <Pie
          data={innerData}
          cx={90}
          cy={90}
          innerRadius={0}
          outerRadius={60}
          dataKey="value"
          startAngle={90}
          endAngle={-270}
        >
          {Array.isArray(VALUATION_COLORS[grade].inner)
            ? innerData.map((entry, index) => (
                <Cell key={`inner-${index}`} fill={VALUATION_COLORS[grade].inner[index]} />
              ))
            : <Cell fill={VALUATION_COLORS[grade].inner} />
          }
        </Pie>
      </PieChart>
      <div className="vp-level-label">
        {VALUATION_LABELS[grade]}
      </div>
    </div>
  );
};

const getChartOption = (data, selectedRange) => {
  if (!data || !Array.isArray(data)) {
    return {};
  }

  const filteredData = filterDataByRange(
    data,
    TIME_RANGES.find(r => r.label === selectedRange)?.days
  );

  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params) => {
        const date = params[0].axisValue;
        const value = params[0].value;
        return `${date}<br/>NAV Premium: ${value.toFixed(2)}`;
      }
    },
    legend: {
      data: ['NAV Premium'],
      top: 0
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      top: '40px',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: filteredData.map(item => item.date),
      boundaryGap: false
    },
    yAxis: {
      type: 'value',
      axisLabel: {
        formatter: (value) => value.toFixed(2)
      }
    },
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100
      },
      {
        type: 'slider',
        start: 0,
        end: 100
      }
    ],
    series: [
      {
        name: 'NAV Premium',
        type: 'line',
        data: filteredData.map(item => item.nav_premium),
        smooth: true,
        symbol: 'none',
        lineStyle: {
          color: '#1a73e8',
          width: 2
        },
        areaStyle: {
          color: {
            type: 'linear',
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: 'rgba(26,115,232,0.2)'
              },
              {
                offset: 1,
                color: 'rgba(26,115,232,0.05)'
              }
            ]
          }
        }
      }
    ]
  };
};

const ValuationPremium = ({ data, ticker }) => {
  const { t } = useTranslation();
  const [navPremiumHistory, setNavPremiumHistory] = useState([]);
  const [selectedRange, setSelectedRange] = useState(TIME_RANGES[4]);

  useEffect(() => {
    const fetchNavPremiumHistory = async () => {
      try {
        const data = await getNavPremiumHistory(ticker);
        setNavPremiumHistory(data);
      } catch (error) {
        console.error('Error fetching NAV premium history:', error);
        setNavPremiumHistory([]);
      }
    };

    fetchNavPremiumHistory();
  }, [ticker]);

  const getCategoryLabel = (category) => {
    switch (category) {
      case 'A':
        return 'Very Undervalued';
      case 'B':
        return 'Undervalued';
      case 'C':
        return 'Fair Value';
      case 'D':
        return 'Overvalued';
      case 'E':
        return 'Very Overvalued';
      default:
        return 'N/A';
    }
  };

  const handleRangeChange = (range) => {
    setSelectedRange(range);
  };

  const filteredHistory = useMemo(() => {
    if (selectedRange.days === 0) return navPremiumHistory;

    const endDate = new Date();
    const startDate = new Date(endDate.getTime() - selectedRange.days * 24 * 60 * 60 * 1000);
    return navPremiumHistory.filter((item) => new Date(item.date) >= startDate);
  }, [navPremiumHistory, selectedRange]);

  const formatValue = (value) => {
    if (value === null || value === undefined) return '-';
    if (typeof value === 'string') return value;
    return value.toFixed(2);
  };

  return (
    <div className="valuation-premium-container">
      {data ? (
        <>
          <div className="vp-chart-section">
            <ValuationPieChart grade={data.category} />
          </div>
          <div className="valuation-premium-metrics-grid">
            <ValuationMetricItem 
              label="NAV Premium"
              value={formatValue(data.nav_premium_btc)}
              tooltip="The percentage difference between the company's stock price and its Net Asset Value in Bitcoin, showing whether the stock is trading at a premium or discount relative to its Bitcoin reserves."
            />
            <ValuationMetricItem 
              label="P/E Ratio"
              value={formatValue(data.pe)}
              tooltip="A valuation metric comparing a company's stock price to its earnings per share. A higher P/E suggests investors expect higher growth, while a lower P/E may indicate undervaluation or slower growth prospects."
            />
            <ValuationMetricItem 
              label="P/B Ratio"
              value={formatValue(data.pb)}
              tooltip="A ratio comparing a company's market value to its book value. A P/B ratio below 1 may indicate undervaluation, while higher ratios suggest investors expect management to create more value from assets."
            />
            <ValuationMetricItem 
              label="EV/EBITDA"
              value={formatValue(data.ev_ebitda)}
              tooltip="A ratio measuring a company's total value relative to its operating performance. Lower ratios may indicate undervaluation, while higher ratios suggest premium pricing or growth expectations."
            />
            <ValuationMetricItem 
              label="FCF Yield"
              value={formatValue(data.free_cash_flow_yield)}
              tooltip="Shows the free cash flow relative to market value. Higher yields indicate better value and financial health, while lower yields may suggest overvaluation or cash flow concerns."
            />
          </div>
          <div className="vp-history-chart">
            <div className="vp-chart-header">
              <h3 className="vp-chart-title">NAV Premium (BTC)</h3>
              <div className="vp-time-range-selector">
                {TIME_RANGES.map(range => (
                  <button
                    key={range.label}
                    className={`vp-range-button ${
                      selectedRange === range ? 'active' : ''
                    }`}
                    onClick={() => handleRangeChange(range)}
                  >
                    {range.label}
                  </button>
                ))}
              </div>
            </div>
            <ReactECharts 
              option={getChartOption(navPremiumHistory, selectedRange)} 
              style={{ height: '400px' }}
              className="vp-echarts"
            />
          </div>
        </>
      ) : (
        <div className="valuation-premium-loading">{t('loading')}</div>
      )}
    </div>
  );
};

export default ValuationPremium; 