import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { callLoginApi, initiateGoogleLogin, handleGoogleAuthCallback } from '../login_api';
import { getSubscriptionInfo } from '../services/stripe_api';
import './LoginPage.css';

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector(state => state.auth?.isLoggedIn || false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/profile');
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    // 检查 URL hash
    const hash = window.location.hash.substring(1); // 移除开头的 #
    const hashParams = new URLSearchParams(hash);
    const googleAuth = hashParams.get('google_auth');
    
    // 检查 URL search params (用于错误处理)
    const urlParams = new URLSearchParams(window.location.search);
    const error = urlParams.get('error');

    console.log('Current URL:', window.location.href);
    console.log('Hash params:', Object.fromEntries(hashParams.entries()));
    console.log('googleAuth:', googleAuth);
    console.log('error:', error);

    if (error) {
        setMessage(t('loginFailed'));
        return;
    }

    if (googleAuth === 'success') {
        console.log('Starting Google auth success flow');
        setIsLoading(true);
        callLoginApi('/load-user')
            .then(async (userInfo) => {
                console.log('Received user info:', userInfo);
                if (!userInfo || !userInfo.userid) {
                    throw new Error('Invalid user data');
                }

                let subscriptionData = null;
                try {
                    subscriptionData = await getSubscriptionInfo(userInfo.userid);
                } catch (error) {
                    console.error('Failed to fetch subscription:', error);
                }

                dispatch({
                    type: 'SET_USER_DATA',
                    payload: {
                        ...userInfo,
                        subscription: subscriptionData
                    }
                });

                window.history.replaceState({}, document.title, window.location.pathname);
                navigate('/profile');
            })
            .catch(error => {
                console.error('Failed to load user data:', error);
                setMessage(t('failedToLoadUserData'));
            })
            .finally(() => {
                setIsLoading(false);
                // 清除 hash
                window.location.hash = '';
            });
    }
  }, [dispatch, navigate, t]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage('');  // 清除之前的错误信息
    
    try {
      // 1. 登录验证
      const loginResult = await callLoginApi('/login', { username: email, password });
      
      if (loginResult.message === 'Login successful') {
        try {
          // 2. 获取用户详细信息
          const userInfo = await callLoginApi('/load-user');
          
          if (!userInfo || !userInfo.userid) {
            throw new Error('Invalid user data received');
          }

          // 3. 获取订阅信息
          let subscriptionData = null;
          try {
            subscriptionData = await getSubscriptionInfo(userInfo.userid);
          } catch (subscriptionError) {
            console.error('Failed to fetch subscription:', subscriptionError);
          }

          // 4. 更新 Redux 状态
          dispatch({ 
            type: 'SET_USER_DATA', 
            payload: {
              ...userInfo,
              subscription: subscriptionData
            }
          });
          navigate('/profile');
        } catch (error) {
          console.error('Failed to load user data:', error);
          setMessage(t('failedToLoadUserData'));  // "加载用户信息失败，请稍后重试"
        }
      } else {
        setMessage(t('invalidCredentials'));  // "邮箱或密码错误"
      }
    } catch (error) {
      console.error('Login error:', error);
      // 根据错误类型设置不同的错误信息
      if (!navigator.onLine) {
        setMessage(t('errors.networkError'));  // "网络连接失败，请检查网络设置"
      } else if (error.status === 429) {
        setMessage(t('errors.tooManyAttempts'));  // "登录尝试次数过多，请稍后再试"
      } else if (error.status === 401) {
        setMessage(t('errors.invalidCredentials'));  // "邮箱或密码错误"
      } else if (error.status === 403) {
        setMessage(t('errors.accountLocked'));  // "账号已被锁定，请联系支持"
      } else {
        setMessage(t('errors.serverError'));  // "服务器错误，请稍后重试"
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleLogin = () => {
    setIsLoading(true);
    initiateGoogleLogin();
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <h2>{t('logIn')}</h2>
        {message && <div className="message error">{message}</div>}
        
        <button
          type="button"
          className="google-login-button"
          onClick={handleGoogleLogin}
          disabled={isLoading}
        >
          <img 
            src="https://developers.google.com/identity/images/g-logo.png" 
            alt="Google logo" 
          />
          <span>{t('signInWithGoogle')}</span>
        </button>

        <div className="divider">{t('or')}</div>

        <div className="form-group">
          <label htmlFor="email">{t('email')}</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">{t('password')}</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button 
          type="submit" 
          className={`login-button ${isLoading ? 'loading' : ''}`}
          disabled={isLoading} 
        >
          {isLoading ? (
            <div className="button-content">
              <div className="spinner"></div>
              <span>{'loading ...'}</span>
            </div>
          ) : (
            t('logIn')
          )}
        </button>
      </form>
      <p className="toggle-text">
        <Link to="/register">{t('register')}</Link>
      </p>
      <p className="forgot-password-text">
        {t('forgotPasswordNote')} <a href="mailto:FinStockInsight@outlook.com">FinStockInsight@outlook.com</a>
      </p>
    </div>
  );
};

export default LoginPage;