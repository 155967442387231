import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageLayout from './PageLayout';
import './PaymentFailurePage.css';

const PaymentFailurePage = () => {
  const navigate = useNavigate();

  return (
    <PageLayout>
      <div className="payment-result-page failure">
        <div className="failure-container">
          <div className="failure-icon">
            <svg viewBox="0 0 24 24" width="64" height="64">
              <path fill="#D32F2F" d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
            </svg>
          </div>
          <h2>Payment Failed</h2>
          <p className="message">Something went wrong with your payment</p>
          <div className="action-buttons">
            <button className="retry-button" onClick={() => navigate('/pricing')}>
              Try Again
            </button>
            <button className="profile-button" onClick={() => navigate('/profile')}>
              Go to Profile
            </button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default PaymentFailurePage; 