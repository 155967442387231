import React, { useState } from 'react';
import ReactECharts from 'echarts-for-react';
import './BciPriceRatioChart.css';

const TIME_RANGES = [
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: '6M', days: 180 },
  { label: '1Y', days: 365 },
  { label: 'ALL', days: 0 }
];

const BciPriceRatioChart = ({ data }) => {
  const [selectedRange, setSelectedRange] = useState('ALL');

  const filterDataByRange = (data, range) => {
    if (!range || range === 'ALL') return data;
    const cutoffDate = new Date();
    cutoffDate.setDate(cutoffDate.getDate() - range);
    return data.filter(item => new Date(item.date) >= cutoffDate);
  };

  const getChartOption = () => {
    if (!data || !Array.isArray(data)) {
      return {
        title: {
          text: 'No Price Ratio Data Available',
          textStyle: {
            color: '#666',
            fontSize: 14,
            fontWeight: 'normal'
          },
          left: 'center',
          top: 'center'
        }
      };
    }

    const filteredData = filterDataByRange(
      data,
      TIME_RANGES.find(r => r.label === selectedRange)?.days
    );

    return {
      tooltip: {
        trigger: 'axis',
        formatter: (params) => {
          const date = params[0].axisValue;
          const value = params[0].value;
          return `${date}<br/>Price Ratio: ${value.toFixed(6)}`;
        }
      },
      legend: {
        data: ['Price Ratio'],
        top: 0
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        top: '40px',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredData.map(item => item.date),
        boundaryGap: false
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: (value) => value.toFixed(6)
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100
        },
        {
          type: 'slider',
          start: 0,
          end: 100
        }
      ],
      series: [
        {
          name: 'Price Ratio',
          type: 'line',
          data: filteredData.map(item => item.value),
          smooth: true,
          symbol: 'none',
          lineStyle: {
            color: '#1a73e8',
            width: 2
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(26,115,232,0.2)'
                },
                {
                  offset: 1,
                  color: 'rgba(26,115,232,0.05)'
                }
              ]
            }
          }
        }
      ]
    };
  };

  return (
    <div className="stock-bci-chart-container">
      <div className="stock-bci-chart-header">
        <h3 className="stock-bci-chart-title">BTC Price Ratio History</h3>
        <div className="stock-bci-time-range-selector">
          {TIME_RANGES.map(range => (
            <button
              key={range.label}
              className={`stock-bci-range-button ${
                selectedRange === range.label ? 'active' : ''
              }`}
              onClick={() => setSelectedRange(range.label)}
            >
              {range.label}
            </button>
          ))}
        </div>
      </div>
      <ReactECharts 
        option={getChartOption()} 
        style={{ height: '400px' }}
        className="stock-bci-echarts"
      />
    </div>
  );
};

export default BciPriceRatioChart; 