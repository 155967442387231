import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import { getEtfScreenerData, getEtfOverviewData, getEtfPriceReturn, getEtfHistoryData, getEtfNetInflow } from '../../services/data_api';
import etfData from './etf-data.json';
import Tooltip from '../Tooltip';
import './ETFOverview.css';

const RatingIndicator = ({ rating, type }) => {
  // 定义不同类型指标的配置
  const configs = {
    correlation: {
      colors: 'linear-gradient(to right, #ef5350, #ffa726, #66bb6a)',
      labels: ['Very Weak', 'Weak', 'Medium', 'Strong', 'Very Strong'],
      value: rating === 'A' ? 4 : rating === 'B' ? 3 : rating === 'C' ? 2 : rating === 'D' ? 1 : 0
    },
    premium: {
      colors: 'linear-gradient(to right, #66bb6a, #ffa726, #ef5350)',
      labels: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
      value: rating === 'A' ? 0 : rating === 'B' ? 1 : rating === 'C' ? 2 : rating === 'D' ? 3 : 4
    },
    risk: {
      colors: 'linear-gradient(to right, #66bb6a, #ffa726, #ef5350)',
      labels: ['Very Low', 'Low', 'Medium', 'High', 'Very High'],
      value: rating === 'A' ? 0 : rating === 'B' ? 1 : rating === 'C' ? 2 : rating === 'D' ? 3 : 4
    }
  };

  const config = configs[type];
  const position = `${(config.value * 25)}%`;
  const label = config.labels[config.value];

  return (
    <div className="etfdetails-overview-rating">
      <div className="etfdetails-overview-rating-bar-wrapper">
        <div 
          className="etfdetails-overview-rating-bar"
          style={{ background: config.colors }}
        >
          <div 
            className="etfdetails-overview-rating-marker" 
            style={{ left: position }} 
          />
        </div>
        <div className="etfdetails-overview-rating-labels">
          {config.labels.map((text, index) => (
            <span 
              key={index}
              className={`etfdetails-overview-rating-label ${index === config.value ? 'active' : ''}`}
            >
              {text}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const MetricCard = ({ title, value, type, isLoading }) => {
  if (isLoading) {
    return (
      <div className="etfdetails-overview-metric-card">
        <div className="etfdetails-overview-metric-title">{title}</div>
        <div className="etfdetails-overview-loading">Loading...</div>
      </div>
    );
  }

  return (
    <div className="etfdetails-overview-metric-card">
      <div className="etfdetails-overview-metric-title">{title}</div>
      {type && <RatingIndicator rating={value} type={type} />}
    </div>
  );
};

const TIME_RANGES = [
  { label: '1W', days: 7 },
  { label: '1M', days: 30 },
  { label: '3M', days: 90 },
  { label: 'ALL', days: 365 }
];

const ETFBasicInfo = ({ type, leverage, inceptionDate }) => {
  // 添加默认值和空值检查
  const safeType = type || 'N/A';
  const safeLeverage = leverage || null;
  const safeInceptionDate = inceptionDate || 'N/A';

  return (
    <div className="etfdetails-overview-basic-info">
      <div className="etfdetails-overview-basic-item">
        <span className="etfdetails-overview-basic-label">
          ETF Type
          <Tooltip content="ETF Type indicates how the fund is structured and what kind of assets it holds. Common types include Physical (directly holds the underlying asset), Futures (uses derivative contracts), and Synthetic (uses swaps or other complex instruments)." />
        </span>
        <span className={`etfdetails-overview-basic-tag ${safeType.toLowerCase() === 'n/a' ? '' : safeType.toLowerCase()}`}>
          {safeType}
        </span>
      </div>
      
      {safeLeverage && safeType.toLowerCase() === 'futures' && (
        <div className="etfdetails-overview-basic-item">
          <span className="etfdetails-overview-basic-label">Leverage</span>
          <span className="etfdetails-overview-basic-tag leverage">
            {safeLeverage}x
          </span>
        </div>
      )}
      
      <div className="etfdetails-overview-basic-item">
        <span className="etfdetails-overview-basic-label">Inception Date</span>
        <span className="etfdetails-overview-basic-value">
          {safeInceptionDate}
        </span>
      </div>
    </div>
  );
};

const ETFOverview = ({ ticker }) => {
  const [screenerData, setScreenerData] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [priceData, setPriceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [historyData, setHistoryData] = useState(null);
  const [selectedRange, setSelectedRange] = useState('ALL');
  const [error, setError] = useState(null);
  const [netInflowData, setNetInflowData] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setError(null);

        // 添加小延迟确保路由完全更新
        await new Promise(resolve => setTimeout(resolve, 100));

        const [screener, overview, price] = await Promise.all([
          getEtfScreenerData(ticker).catch(err => {
            console.error('Error fetching screener data:', err);
            return null;
          }),
          getEtfOverviewData(ticker).catch(err => {
            console.error('Error fetching overview data:', err);
            return null;
          }),
          getEtfPriceReturn(ticker).catch(err => {
            console.error('Error fetching price data:', err);
            return null;
          })
        ]);

        // 检查是否所有数据都获取失败
        if (!screener && !overview && !price) {
          throw new Error('Failed to load ETF data');
        }

        setScreenerData(screener);
        setOverviewData(overview);
        setPriceData(price);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching ETF data:', err);
        if (retryCount < maxRetries) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, 1000 * (retryCount + 1));
        } else {
          setError('Failed to load ETF data');
          setLoading(false);
        }
      }
    };

    if (ticker) {
      fetchData();
    }

    // 清理函数
    return () => {
      setScreenerData(null);
      setOverviewData(null);
      setPriceData(null);
      setError(null);
    };
  }, [ticker, retryCount]);

  useEffect(() => {
    const fetchHistoryData = async () => {
      try {
        const data = await getEtfHistoryData(ticker);
        if (data) {
          setHistoryData(data);
        }
      } catch (err) {
        console.error('Error fetching ETF history data:', err);
        // 历史数据加载失败不影响其他部分的显示
      }
    };
    
    if (ticker) {
      fetchHistoryData();
    }
  }, [ticker]);

  useEffect(() => {
    const fetchNetInflowData = async () => {
      try {
        const data = await getEtfNetInflow(ticker);
        if (data) {
          setNetInflowData(data);
        }
      } catch (err) {
        console.error('Error fetching ETF net inflow data:', err);
      }
    };
    
    if (ticker) {
      fetchNetInflowData();
    }
  }, [ticker]);

  const formatLargeNumber = (num) => {
    if (!num) return 'N/A';
    if (num >= 1e9) {
      return `$${(num / 1e9).toFixed(2)}B`;
    }
    if (num >= 1e6) {
      return `$${(num / 1e6).toFixed(2)}M`;
    }
    return `$${num.toFixed(2)}`;
  };

  const formatPrice = (current, change) => {
    if (!current || change === undefined) return 'N/A';
    const changeStr = change > 0 ? `+${change.toFixed(2)}` : change.toFixed(2);
    return (
      <>
        ${current.toFixed(2)} <span className={`etfdetails-overview-price-change ${change >= 0 ? 'positive' : 'negative'}`}>({changeStr}%)</span>
      </>
    );
  };

  const formatVolume = (current, average) => {
    if (!current || !average) return 'N/A';
    return (
      <div className="etfdetails-overview-volume-wrapper">
        <div>Previous: {(current).toLocaleString()}</div>
        <div>10D avg: {(average).toLocaleString()}</div>
      </div>
    );
  };

  // Add ETF introduction mapping
  const etfIntroductions = {
    IBIT: "The shares are intended to constitute a simple means of making an investment similar to an investment in bitcoin rather than by acquiring, holding and trading bitcoin directly on a peer-to-peer or other basis or via a digital asset exchange.",
    GBTC: "The trust's Bitcoins are carried, for financial statement purposes, at fair value as required by U.S. generally accepted accounting principles (\"GAAP\"). The trust determines the fair value of Bitcoins based on the price provided by the Digital Asset Market that the trust considers its principal market as of 4:00 p.m., New York time, on the valuation date.",
    FBTC: "The index is constructed using bitcoin price feeds from eligible bitcoin spot markets and a volume-weighted median price (\"VWMP\") methodology, calculated every 15 seconds based on VWMP spot market data over rolling sixty-minute increments. In seeking to achieve its investment objective, the trust will hold bitcoin and will value its shares daily as of 4:00 p.m. EST using the same methodology used to calculate the index. All of the trust's bitcoin will be held by the custodian.",
    ARKB: "The index is designed to reflect the performance of bitcoin in U.S. dollars. In seeking to achieve its investment objective, the trust will hold bitcoin and will value its shares daily based on the index.",
    BITB: "In seeking to achieve its investment objective, the will trust will hold bitcoin and accrue the sponsor's management fee (the \"Sponsor Fee\") in U.S. dollars. The trust will value its bitcoin holdings, net assets and the shares daily based on the BRRNY. It is passively managed and does not pursue active management investment strategies, and the Sponsor does not actively manage the bitcoin held by the trust.",
    BTC: "While an investment in the shares is not a direct investment in Bitcoin, the shares are designed to provide investors with a cost-effective and convenient way to gain investment exposure to Bitcoin.",
    BITO: "The fund adviser seeks to achieve its investment objective primarily through managed exposure to bitcoin futures contracts. The fund does not invest directly in bitcoin. The fund adviser will generally hold its bitcoin futures contracts during periods in which the value of bitcoin and bitcoin futures are flat or declining as well as during periods in which the value of bitcoin or bitcoin futures is rising. It is non-diversified.",
    HODL: "In seeking to achieve its investment objective, the trust will hold bitcoin and will value its sares daily based on the reported MarketVectorTM Bitcoin Benchmark Rate and process all creations and redemptions in transactions with authorized participants.",
    BITU: "The index is designed to measure the performance of a single bitcoin traded in USD and seeks to provide a proxy for the bitcoin market. Under normal circumstances, the fund will invest at least 80% of the fund's assets in, or provide exposure to, financial instruments that ProShare Advisors believes, in combination, should produce daily returns consistent with the daily target. The fund is non-diversified.",
    BRRR: "In seeking to achieve its investment objective, the Trust will hold bitcoin and will value its Shares daily based on the value of bitcoin as reflected by the index, which is an independently calculated value based on an aggregation of executed trade flow of major bitcoin spot exchanges.",
    BTCO: "The benchmark is designed to provide an estimated fair market value price for bitcoin, based on the execution price of bitcoin on its principal market. The value of bitcoin held by the trust is determined based on the estimated fair market value (\"FMV\") price for bitcoin, reflecting the execution price of bitcoin on its principal market as determined each day by Lukka Inc., an independent third-party digital asset data company.",
    EZBC: "The shares are intended to offer a convenient means of making an investment similar to an investment in bitcoin relative to acquiring, holding and trading bitcoin directly on a peer-to-peer or other basis or via a digital asset platform. The shares have been designed to remove obstacles associated with the complexities and operational burdens involved in a direct investment in bitcoin by providing an investment with a value that reflects the price of the bitcoin owned by the fund at such time, less the fund's expenses.",
    BTCW: "In seeking to achieve its investment objective, the trust will hold bitcoin and will value its shares daily based on the value of bitcoin as reflected by the CME CF Bitcoin Reference Rate – New York Variant (the \"Reference Rate\"), which is an independently calculated value based on an aggregation of executed trade flow of major bitcoin spot platforms.",
    BITX: "The fund is an ETF that seeks to achieve its investment objective by investing its assets principally in cash-settled bitcoin futures contracts that trade only on an exchange registered with the Commodity Futures Trading Commission, which currently is the Chicago Mercantile Exchange, and cash,cash-like instruments or high-quality securities that serve as collateral to the fund's investments in Bitcoin Futures Contracts. The fund is non-diversified.",
    ARKW: "The fund is an actively-managed exchange-traded fund (\"ETF\") that will invest under normal circumstances primarily (at least 80% of its assets) in domestic and foreign equity securities of companies that are relevant to the fund's investment theme of next generation internet. Under normal circumstances, substantially all of the fund's assets will be invested in equity securities, including common stocks, partnership interests, business trust shares and other equity investments or ownership interests in business enterprises. The fund is non-diversified.",
    WGMI: "The fund is an actively-managed exchange-traded fund (\"ETF\") that will invest at least 80% of its net assets (plus borrowings for investment purposes) in securities of companies that derive at least 50% of their revenue or profits from bitcoin mining operations and/or from providing specialized chips, hardware and software or other services to companies engaged in bitcoin mining. It will not directly invest in bitcoin, or indirectly through the use of derivatives or through investments in funds or trusts that hold bitcoin. The fund is non-diversified.",
    BITQ: "The index was designed by Bitwise Index Services, LLC to measure the performance of companies involved in servicing the cryptocurrency markets, including crypto mining firms, crypto mining equipment suppliers, crypto financial services companies, or other financial institutions servicing primarily crypto-related clientele (i.e., the crypto ecosystem). Normally, the fund invests at least 80% of its net assets in securities of Crypto Innovators. It is non-diversified.",
    BITI: "The fund invests in financial instruments that ProShare Advisors believes, in combination, should produce daily returns consistent with the daily target. It seeks to benefit from decreases in the price of bitcoin futures contracts for a single day. The index is designed to measure the performance of a single bitcoin traded in USD and seeks to provide a proxy for the bitcoin market. It is non-diversified.",
    IBLC: "The fund generally will invest at least 80% of its assets in the component securities of its underlying index and in investments that have economic characteristics that are substantially identical to the component securities of its underlying index and may invest up to 20% of its assets in certain futures, options and swap contracts, cash and cash equivalents. It is non-diversified.",
    FDIG: "The fund normally invests at least 80% of assets in equity securities included in the index and in depositary receipts representing securities included in the index. The index is designed to reflect the performance of a global universe of companies engaged in activities related to cryptocurrency, related blockchain technology, and digital payments processing. The fund is non-diversified.",
    CRPT: "Under normal market conditions, the fund will invest at least 80% of its net assets (plus any investment borrowings) in the common stocks and American Depositary Receipts (\"ADRs\") of Crypto Industry Companies and Digital Economy Companies. Under normal market conditions, the fund will invest at least 50% of its net assets in Crypto Industry Companies. The remainder of the fund's net assets used to satisfy the 80% test set forth above will be invested in Digital Economy Companies. The fund is non-diversified.",
    BKCH: "The fund invests at least 80% of its total assets, plus borrowings for investments purposes (if any), in the securities of the index and in American Depositary Receipts (\"ADRs\") and Global Depositary Receipts (\"GDRs\") based on the securities in the underlying index. The underlying index is designed to provide exposure to companies that are positioned to benefit from further advances in the field of blockchain technology. The fund is non-diversified.",
    STCE: "The fund will not invest in cryptocurrency or digital assets directly. The investment adviser typically seeks to track the total return of the index by replicating the index. It is non-diversified.",
    DAPP: "The fund normally invests at least 80% of its total assets in securities of Digital Transformation Companies. The index is a global index that tracks the performance of Digital Transformation Companies. The fund will not invest in digital assets (including cryptocurrencies) (i) directly or (ii) indirectly through the use of digital asset derivatives. It also will not invest in initial coin offerings. The fund is non-diversified.",
    GFOF: "The index is designed by Bloomberg Index Services Limited to consist of U.S. and non-U.S. equity securities of companies that have been classified by the index provider as providing exposure to the \"Future of Finance\", as identified by the intersection of finance, technology and digital assets. Under normal circumstances, at least 80% of the fund's net assets, plus borrowings for investment purposes, will be invested in the component securities of the index. It is non-diversified.",
    BLCN: "Under normal circumstances, at least 80% of the fund's assets, will be invested in component securities of the index. The index is designed to measure the returns of companies that are committing material resources to developing, researching, supporting, innovating or utilizing blockchain technology for their proprietary use or for use by others (\"Blockchain Companies\").",
    BLOK: "The fund is an actively managed ETF that seeks to provide total return by investing at least 80% of its net assets in the equity securities of companies actively involved in the development and utilization of \"transformational data sharing technologies\". It may invest in non-U.S. equity securities, including depositary receipts."
  };

  const getHistoryChartOption = () => {
    if (!historyData) return {};

    const selectedDays = TIME_RANGES.find(r => r.label === selectedRange).days;
    const filteredData = historyData.slice(-selectedDays);

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        },
        formatter: function(params) {
          const date = params[0].axisValue;
          const price = params[0].data;
          const volume = params[1].data;
          return `
            <div style="font-size:16px;color:#333;line-height:1.6">
              <div style="margin-bottom:10px;font-weight:500">${date}</div>
              <div style="display:flex;justify-content:space-between;align-items:center">
                <span style="color:#1a73e8">●</span>
                <span style="margin:0 10px">Price:</span>
                <span style="font-weight:500">$${price.toFixed(2)}</span>
              </div>
              <div style="display:flex;justify-content:space-between;align-items:center">
                <span style="color:#34a853">●</span>
                <span style="margin:0 10px">Volume:</span>
                <span style="font-weight:500">${(volume/1000000).toFixed(2)}M</span>
              </div>
            </div>
          `;
        }
      },
      legend: {
        data: ['Price', 'Volume'],
        top: '0%',
        left: 'center',
        textStyle: {
          fontSize: 14,
          color: '#666'
        },
        itemGap: 30,
        icon: 'circle',
        itemWidth: 8,
        itemHeight: 8,
        borderRadius: 4
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '3%',
        top: '8%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredData.map(item => item.date),
        boundaryGap: true,
        axisLine: { lineStyle: { color: '#E0E0E0' } },
        axisTick: { show: false },
        axisLabel: {
          color: '#666',
          fontSize: 14,
          margin: 16
        }
      },
      yAxis: [
        {
          type: 'value',
          name: 'Price',
          nameTextStyle: {
            fontSize: 14,
            padding: [0, 0, 0, 50]
          },
          position: 'left',
          axisLine: { show: true, lineStyle: { color: '#1a73e8' } },
          axisLabel: {
            color: '#666',
            fontSize: 14,
            formatter: (value) => `$${value.toFixed(2)}`
          },
          splitLine: { 
            show: true,
            lineStyle: { 
              type: 'dashed',
              color: '#E0E0E0'
            }
          }
        },
        {
          type: 'value',
          name: 'Volume',
          nameTextStyle: {
            fontSize: 14,
            padding: [0, 50, 0, 0]
          },
          position: 'right',
          axisLine: { show: true, lineStyle: { color: '#34a853' } },
          axisLabel: {
            color: '#666',
            fontSize: 14,
            formatter: (value) => `${(value/1000000).toFixed(0)}M`
          },
          splitLine: { show: false }
        }
      ],
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false
        }
      ],
      series: [
        {
          name: 'Price',
          type: 'line',
          data: filteredData.map(item => item.price),
          smooth: true,
          symbol: 'none',
          lineStyle: {
            width: 2,
            color: '#1a73e8'
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0, y: 0, x2: 0, y2: 1,
              colorStops: [{
                offset: 0,
                color: 'rgba(26,115,232,0.2)'
              }, {
                offset: 1,
                color: 'rgba(26,115,232,0)'
              }]
            }
          }
        },
        {
          name: 'Volume',
          type: 'bar',
          yAxisIndex: 1,
          data: filteredData.map(item => item.volume),
          itemStyle: {
            color: '#34a853',
            opacity: 0.8
          }
        }
      ]
    };
  };

  const getNetInflowChartOption = () => {
    if (!netInflowData) return {};

    const selectedDays = TIME_RANGES.find(r => r.label === selectedRange).days;
    const filteredData = netInflowData.slice(-selectedDays);

    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function(params) {
          const date = params[0].axisValue;
          const value = params[0].data;
          return `
            <div style="font-size:14px;color:#333;line-height:1.5">
              <div style="margin-bottom:8px;font-weight:500">${date}</div>
              <div style="display:flex;justify-content:space-between;align-items:center">
                <span>Net Inflow:</span>
                <span style="margin-left:12px;color:${value >= 0 ? '#34a853' : '#ea4335'}">
                  ${(value/1000000).toFixed(2)}M
                </span>
              </div>
            </div>
          `;
        }
      },
      grid: {
        left: '3%',
        right: '3%',
        bottom: '3%',
        top: '8%',
        containLabel: true
      },
      xAxis: {
        type: 'category',
        data: filteredData.map(item => item.date),
        axisLine: { lineStyle: { color: '#E0E0E0' } },
        axisTick: { show: false },
        axisLabel: {
          color: '#666',
          fontSize: 12
        }
      },
      yAxis: {
        type: 'value',
        name: 'Net Inflow',
        nameTextStyle: {
          fontSize: 14,
          padding: [0, 0, 0, 50]
        },
        axisLine: { show: true, lineStyle: { color: '#666' } },
        axisLabel: {
          color: '#666',
          fontSize: 12,
          formatter: (value) => `${(value/1000000).toFixed(0)}M`
        },
        splitLine: {
          lineStyle: {
            type: 'dashed',
            color: '#E0E0E0'
          }
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          zoomLock: false
        }
      ],
      series: [
        {
          type: 'bar',
          data: filteredData.map(item => item.net_inflow),
          itemStyle: {
            color: (params) => {
              return params.data >= 0 ? '#34a853' : '#ea4335';
            }
          }
        }
      ]
    };
  };

  const renderPriceChart = () => {
    if (!historyData || historyData.length === 0) {
      return (
        <div className="etfdetails-overview-history-chart">
          <div className="etfdetails-overview-chart-header">
            <h3>Historical Price & Volume</h3>
          </div>
          <div className="etfdetails-overview-no-data">
            No historical data available
          </div>
        </div>
      );
    }
    
    return (
      <div className="etfdetails-overview-history-chart">
        <div className="etfdetails-overview-chart-header">
          <h3>Historical Price & Volume</h3>
          <div className="etfdetails-overview-time-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`etfdetails-overview-range-button ${selectedRange === range.label ? 'etfdetails-overview-range-button-active' : ''}`}
                onClick={() => setSelectedRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>
        <ReactECharts 
          option={getHistoryChartOption()} 
          style={{ height: '400px' }}
          notMerge={true}
        />
      </div>
    );
  };

  const renderNetInflowChart = () => {
    if (!netInflowData || netInflowData.length === 0) {
      return (
        <div className="etfdetails-overview-netinflow-chart">
          <div className="etfdetails-overview-chart-header">
            <h3>Net Inflow</h3>
          </div>
          <div className="etfdetails-overview-no-data">
            No net inflow data available
          </div>
        </div>
      );
    }

    return (
      <div className="etfdetails-overview-netinflow-chart">
        <div className="etfdetails-overview-chart-header">
          <h3>Net Inflow</h3>
          <div className="etfdetails-overview-time-range-selector">
            {TIME_RANGES.map(range => (
              <button
                key={range.label}
                className={`etfdetails-overview-range-button ${selectedRange === range.label ? 'etfdetails-overview-range-button-active' : ''}`}
                onClick={() => setSelectedRange(range.label)}
              >
                {range.label}
              </button>
            ))}
          </div>
        </div>
        <ReactECharts 
          option={getNetInflowChartOption()} 
          style={{ height: '400px' }}
          notMerge={true}
        />
      </div>
    );
  };

  // 从json中获取ETF的基本信息
  const etfInfo = etfData.etfs.find(etf => etf.ETF === ticker) || {};

  if (error) {
    return (
      <div className="etfdetails-overview-error">
        <p>{error}</p>
        <button 
          onClick={() => setRetryCount(0)} 
          className="etfdetails-overview-retry-button"
        >
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="etfdetails-overview-container">
      {/* 评级指标区域 */}
      <div className="etfdetails-overview-ratings-section">
        <div className="etfdetails-overview-ratings-grid">
          <MetricCard
            title="Correlation BTC"
            value={screenerData?.btc_correlation_index}
            type="correlation"
            isLoading={loading}
          />
          <MetricCard
            title="Premium"
            value={screenerData?.valuation_premium}
            type="premium"
            isLoading={loading}
          />
          <MetricCard
            title="Risk"
            value={screenerData?.risk}
            type="risk"
            isLoading={loading}
          />
        </div>
      </div>

      {/* 数值指标区域 */}
      <div className="etfdetails-overview-metrics-section">
        <div className="etfdetails-overview-metrics-grid">
          <div className="etfdetails-overview-data-card">
            <div className="etfdetails-overview-data-label">Price</div>
            <div className="etfdetails-overview-data-value">
              {formatPrice(priceData?.current_price, priceData?.current_return)}
            </div>
          </div>
          
          <div className="etfdetails-overview-data-card">
            <div className="etfdetails-overview-data-label">Market Cap</div>
            <div className="etfdetails-overview-data-value">
              {formatLargeNumber(overviewData?.market_cap)}
            </div>
          </div>
          
          <div className="etfdetails-overview-data-card">
            <div className="etfdetails-overview-data-label">
              AUM
              <Tooltip content="Assets Under Management (AUM) represents the total market value of all financial assets which an ETF manages on behalf of its clients and investors. It's a key metric used to measure the size and success of an ETF." />
            </div>
            <div className="etfdetails-overview-data-value">
              {formatLargeNumber(overviewData?.aum)}
            </div>
          </div>
          
          <div className="etfdetails-overview-data-card">
            <div className="etfdetails-overview-data-label">VOLUME</div>
            <div className="etfdetails-overview-data-value">
              {formatVolume(overviewData?.volume, overviewData?.average_volume)}
            </div>
          </div>
          
          <div className="etfdetails-overview-data-card">
            <div className="etfdetails-overview-data-label">
              Expense Ratio
              <Tooltip content="The expense ratio represents the annual fee that the ETF charges investors for managing their money. For example, an expense ratio of 0.50% means that for every $10,000 invested, the ETF charges $50 per year in management fees." />
            </div>
            <div className="etfdetails-overview-data-value">
              {overviewData?.expense_ratio ? `${overviewData.expense_ratio}%` : 'N/A'}
            </div>
          </div>
        </div>
      </div>

      {/* 基本信息区域 */}
      <ETFBasicInfo 
        type={etfInfo.Type}
        leverage={etfInfo.Leverage}
        inceptionDate={etfInfo.inceptionDate}
      />

      <div className="etfdetails-overview-introduction">
        <h3 className="etfdetails-overview-introduction-title">Introduction</h3>
        <p className="etfdetails-overview-introduction-text">
          {etfIntroductions[ticker] || 'No introduction available.'}
          <span className="etfdetails-overview-introduction-source">(Source: Yahoo Finance)</span>
        </p>
      </div>

      {renderPriceChart()}
      {renderNetInflowChart()}
    </div>
  );
};

export default ETFOverview; 