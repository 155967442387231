import React, { useState, useEffect } from 'react';
import './ETFDetail.css';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import ReactECharts from 'echarts-for-react';
import etfData from './etf-data.json';
import ETFBciMetrics from './ETFBciMetrics';
import { getEtfBciData, getEtfVpMetrics } from '../../services/data_api';
import ETFVpMetrics from './ETFVpMetrics';
import ETFNavPremiumChart from './ETFNavPremiumChart';
import ETFOverview from './ETFOverview';
import ETFRiskMetrics from './ETFRiskMetrics';
import ETFRecommendation from './ETFRecommendation';

const tabs = [
  { key: 'overview', label: 'Overview' },
  { key: 'bci', label: 'BTC Correlated Index' },
  { key: 'vp', label: 'Valuation Premium' },
  { key: 'risk', label: 'Risk' },
  { key: 'recommendation', label: 'Recommendation' }
];

const ETFDetail = () => {
  const { ticker } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('overview');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [etfInfo, setEtfInfo] = useState(null);
  const [bciData, setBciData] = useState(null);
  const [vpData, setVpData] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  useEffect(() => {
    const currentTicker = ticker.toUpperCase();
    if (ticker !== currentTicker) {
      const newPath = location.pathname.replace(ticker, currentTicker);
      navigate(newPath, { replace: true });
    }
  }, [ticker, navigate, location]);

  useEffect(() => {
    const fetchETFData = async () => {
      try {
        setLoading(true);
        setError(null);
        const etf = etfData.etfs.find(e => e.ETF.toUpperCase() === ticker.toUpperCase());
        if (!etf) {
          throw new Error('ETF not found');
        }
        setEtfInfo(etf);
        setLoading(false);
      } catch (err) {
        console.error('Error fetching ETF data:', err);
        if (retryCount < maxRetries) {
          setTimeout(() => {
            setRetryCount(prev => prev + 1);
          }, 1000 * (retryCount + 1));
        } else {
          setError(err.message);
          setLoading(false);
        }
      }
    };

    fetchETFData();
  }, [ticker, retryCount]);

  useEffect(() => {
    const fetchBciData = async () => {
      const data = await getEtfBciData(ticker);
      setBciData(data);
    };
    
    if (activeTab === 'bci') {
      fetchBciData();
    }
  }, [ticker, activeTab]);

  useEffect(() => {
    const fetchVpData = async () => {
      const data = await getEtfVpMetrics(ticker);
      setVpData(data);
    };
    
    if (activeTab === 'vp') {
      fetchVpData();
    }
  }, [ticker, activeTab]);

  const renderTabContent = () => {
    switch (activeTab) {
      case 'overview':
        return <ETFOverview ticker={ticker} />;
      case 'bci':
        return <ETFBciMetrics bciData={bciData} />;
      case 'vp':
        return <ETFVpMetrics vpData={vpData} />;
      case 'risk':
        return <ETFRiskMetrics ticker={ticker} />;
      case 'recommendation':
        return <ETFRecommendation ticker={ticker} />;
      default:
        return null;
    }
  };

  if (loading) return <div className="etf-detail-loading">Loading...</div>;
  if (error) return <div className="etf-detail-error">Error: {error}</div>;

  return (
    <div className="etf-detail-container">
      <header className="etf-detail-header">
        <h1 className="etf-detail-title">
          {etfInfo?.Name} ({etfInfo?.ETF})
        </h1>
      </header>

      <nav className="etf-detail-tab-navigation">
        {tabs.map(tab => (
          <button
            key={tab.key}
            className={`etf-detail-tab-button ${activeTab === tab.key ? 'etf-detail-tab-active' : ''}`}
            onClick={() => setActiveTab(tab.key)}
          >
            {tab.label}
          </button>
        ))}
      </nav>

      {renderTabContent()}
    </div>
  );
};

export default ETFDetail; 