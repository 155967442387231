import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { callLoginApi } from '../login_api';
import PageLayout from './PageLayout';
import './UserProfilePage.css';
import { getSubscriptionInfo } from '../services/stripe_api';
import { CacheService } from '../services/cache_service';
import { Spinner } from './Spinner';

const formatDate = (dateString) => {
  if (!dateString) return 'Invalid Date';
  try {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return 'Invalid Date';
    
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    }).format(date);
  } catch (error) {
    console.error('Date formatting error:', error);
    return 'Invalid Date';
  }
};

const UserProfilePage = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth?.userData);
  const subscription = useSelector(state => state.auth?.subscription);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [isSubscriptionLoading, setIsSubscriptionLoading] = useState(false);
  const [subscriptionRetryCount, setSubscriptionRetryCount] = useState(0);
  const MAX_SUBSCRIPTION_RETRIES = 3;

  // 添加订阅过期检查函数
  const isSubscriptionExpired = (subscription) => {
    if (!subscription || !subscription.periodEnd) {
      return true;
    }

    // 如果状态不是 active，视为过期
    if (subscription.status !== 'active') {
      return true;
    }

    // 检查结束时间是否已过
    const endDate = new Date(subscription.periodEnd);
    const now = new Date();
    return endDate < now;
  };

  useEffect(() => {
    const loadUserInfo = async () => {
      // 1. 检查缓存
      const cachedUserData = CacheService.get('userData');
      if (cachedUserData) {
        dispatch({ type: 'SET_USER_DATA', payload: cachedUserData });
        setLoading(false);
        return;
      }

      // 2. 加载用户基本信息
      try {
        const userInfo = await callLoginApi('/load-user');
        dispatch({ type: 'SET_USER_DATA', payload: userInfo });
        CacheService.set('userData', userInfo);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadUserInfo();
  }, []);

  useEffect(() => {
    const loadSubscription = async () => {
      if (!userData?.userid || subscriptionRetryCount >= MAX_SUBSCRIPTION_RETRIES) return;

      setIsSubscriptionLoading(true);
      try {
        // 1. 检查缓存
        const cachedSubscription = CacheService.get('subscription');
        if (cachedSubscription && !isSubscriptionExpired(cachedSubscription)) {
          dispatch({ type: 'UPDATE_SUBSCRIPTION_DATA', payload: cachedSubscription });
          setIsSubscriptionLoading(false);
          return;
        }

        // 2. 加载订阅信息
        const subscriptionData = await getSubscriptionInfo(userData.userid);
        dispatch({ type: 'UPDATE_SUBSCRIPTION_DATA', payload: subscriptionData });
        
        // 只缓存有效的订阅数据
        if (subscriptionData.subscription_status !== 'unknown') {
          CacheService.set('subscription', subscriptionData);
        }
        
        setSubscriptionError(null);
      } catch (error) {
        console.error('Subscription fetch error:', error);
        // 只在非网络错误时显示错误信息
        if (!(error instanceof TypeError)) {
          setSubscriptionError(error.message);
        }
      } finally {
        setIsSubscriptionLoading(false);
      }
    };

    loadSubscription();
  }, [userData?.userid, subscriptionRetryCount]);

  const renderSubscriptionInfo = () => {
    // 添加调试日志
    console.log('Subscription data:', subscription);
    
    return (
      <div className="subscription-card">
        <div className="card-decorator"></div>
        <div className="subscription-header">
          <h2 className="profile-section-title">{t('subscriptionDetails')}</h2>
          <span className={`status-badge ${subscription?.status}`}>
            {subscription?.status === 'active' ? t('active') : t('inactive')}
          </span>
        </div>

        <div className="subscription-info">
          <div className="info-row">
            <span className="info-label">{t('currentPlan')}</span>
            <span className="info-value highlight">
              {subscription?.type === 'monthly' ? t('monthlyPlan') : 
               subscription?.type === 'yearly' ? t('yearlyPlan') : 
               t('none')}
            </span>
          </div>

          {subscription?.status === 'active' && (
            <div className="info-row">
              <span className="info-label">{t('periodEnd')}</span>
              <span className="info-value">
                {new Date(subscription?.periodEnd).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                })}
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (loading) {
    return (
      <PageLayout>
        <div className="loading">{t('loading')}...</div>
      </PageLayout>
    );
  }

  if (error) {
    return (
      <PageLayout>
        <div className="error-message">{t('failedToLoadUserData')}: {error}</div>
      </PageLayout>
    );
  }

  if (!userData) {
    return (
      <PageLayout>
        <div className="error-message">{t('noUserData')}</div>
      </PageLayout>
    );
  }

  // 获取用户名首字母作为头像显示
  const getInitials = () => {
    return userData.firstName ? userData.firstName[0].toUpperCase() : '?';
  };

  return (
    <PageLayout>
      <div className="user-profile-page">
        <div className="profile-grid">
          <div className="user-info-card">
            <div className="card-decorator"></div>
            <div className="user-avatar">
              {getInitials()}
            </div>
            <div className="user-info">
              <h2 className="user-name">{`${userData.firstName} ${userData.lastName}`}</h2>
              <div className="info-group">
                <div className="info-label">{t('email')}</div>
                <p className="info-value">{userData.username}</p>
              </div>
            </div>
          </div>

          <div className="subscription-card">
            <div className="card-decorator"></div>
            <div className="subscription-header">
              <h2 className="profile-section-title">{t('subscriptionDetails')}</h2>
              <span className={`status-badge ${subscription?.status}`}>
                {subscription?.status === 'active' ? t('active') : t('inactive')}
              </span>
            </div>

            <div className="subscription-info">
              <div className="info-row">
                <span className="info-label">{t('currentPlan')}</span>
                <span className="info-value highlight">
                  {subscription?.type === 'monthly' ? t('monthlyPlan') : 
                   subscription?.type === 'yearly' ? t('yearlyPlan') : 
                   t('none')}
                </span>
              </div>

              {subscription?.status === 'active' && (
                <div className="info-row">
                  <span className="info-label">{t('periodEnd')}</span>
                  <span className="info-value">
                    {new Date(subscription?.periodEnd).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'long',
                      day: 'numeric'
                    })}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default UserProfilePage;